import * as React from "react";
import {Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import {ProblemParams} from "../sharedDefs/curriculumDefs";
import NumberKeypad from "./NumberKeypad";
import {blockStyles, gridStyles, rootStyles, textStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import AnswerBlock from "./AnswerBlock";


const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
export function MultiplicationWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params?.prompts || {};
  const {
    product
  } = curriculumStudentResponse?.responseKeyValues?.set || {};

  return <View testID="MultiplicationWidgetCoach"

    style={rootStyles.coachRoot}>
    <View style={gridStyles.gridRowContainer}>

      <Text style={textStyles.coachProblemText}>
        {a}
        {'x'}
        {b}
        {'='}
      </Text>
      <View style={blockStyles.coachProblemBlock}>
        <Text style={textStyles.coachProblemText}>
          {product}
        </Text>
      </View>

    </View>
  </View>;
}
export function MultiplicationWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    inWorkbench,
    onResponse
  } = props;

  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params.prompts || {};
  const {
    product
  } = curriculumStudentResponse?.responseKeyValues?.set || {};


  const [widgetWidth, setWidgetWidth] = React.useState(0);



  const onKeyPress = React.useCallback((_answer: string) => {

    debug('onKeyPress', _answer, product, JSON.stringify(curriculumStudentResponse.responseKeyValues));
    let newProduct = _answer;

    if (product && parseInt(product,10) <10) {
      newProduct = product + _answer;
      debug('onKeyPress append '+product , _answer,'=',newProduct);
    }

    if (parseInt(newProduct, 10) >= 100) {
      newProduct = '';
    }
    debug('onKeyPress newProduct', newProduct);
    onResponse('product', newProduct);
  },[curriculumStudentResponse, curriculumStudentInteraction]);

  debug('MULTIPLICATION INTERACTION ID  ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
  return <View key="1"  testID="MultiplicationWidgetStudent" style={[rootStyles.studentRoot]} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);

  }}>
    <View style={gridStyles.gridColumnCenteredContainer}>
      <View  style={gridStyles.gridRowContainer}>
        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {a}
          {' '}
              x
          {b}
          {' '}
              =
        </Text>

        <AnswerBlock  widgetWidth={widgetWidth} actual={product} expected={problem?.params?.expectedResponseKeyValues?.set?.product} ></AnswerBlock>

      </View>
      <View  style={gridStyles.gridRowContainer}>
        <NumberKeypad  widgetWidth={widgetWidth} inWorkbench={inWorkbench} onKeyPress={onKeyPress} />
      </View>
    </View>
  </View>;
}

const defaultProgressionSetId = 'Math.K.CC.A.3.MultiplySingleDigits.1';

const params: ProblemParams = {
  prompts: { a: null, b: null},
  expectedResponseKeyValues: {
    set: {
      product: null
    }
  }
};
const MultiplicationWidget: WidgetSet = {
  widgetSetId: 'MultiplicationWidget',
  coach: MultiplicationWidgetCoach,
  student: MultiplicationWidgetStudent,
  defaultProgressionSetId,

};
export default MultiplicationWidget;
