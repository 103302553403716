import * as React from "react";
//
import { StyleSheet, View } from "react-native";
import type { Column } from "../../components/Lists/BaseTable";
import { makeTable } from "../../components/Lists/BaseTable";
import { navigateTo } from "../../NavigationAction";
import { queryAllCoaches } from "../../redux/queries/UserQueries";
import { NAV } from "../../redux/structs/navigationStruct";
import type { UserProfile } from "../../sharedDefs/userDefs";
import { PROFILE } from "../../sharedDefs/userDefs";
import { Styles } from "../../styles/Styles";
import { calculateAge, mapValueToLabel } from "../../utils/dataUtils";
const columns: Array<Column> = [{
  field: 'name',
  title: 'Name',
  render: (row: UserProfile) => row.firstName + ' ' + row.lastName
}, {
  field: 'age',
  title: 'Age',
  render: (row: UserProfile) => calculateAge(row.birthMonth, row.birthYear)
}, {
  field: 'occupation',
  title: 'Occupation',
  render: (row: UserProfile) => mapValueToLabel(row.occupation, PROFILE.OCCUPATION, PROFILE.OCCUPATION_LABEL)
}, {
  field: 'status',
  title: 'Status',
  render: (row: UserProfile) => mapValueToLabel(row.status || '', PROFILE.STATUS, PROFILE.STATUS_LABEL)
}];
const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 440
  }
});
type AdminCoachListProps = Record<string,never>;
export default function AdminCoachList(props: AdminCoachListProps): JSX.Element {
  const [coaches, setCoaches] = React.useState([]);
  React.useEffect(() => {
    queryAllCoaches(setCoaches);
  }, []);
  const gotoDetail = React.useCallback((rowData: any) => {
    navigateTo(NAV.COACH.HOME, {
      userId: rowData.userId
    });
  },[]);
  const table = makeTable(columns, coaches, Styles.Icons.classroom, gotoDetail);
  return <View testID="AdminCoachList" style={styles.root}>
    {table}
  </View>;
}
