
import { all, fork } from "redux-saga/effects";
import adminSagas from "./adminSaga";
import chatSagas from "./chatSaga";
import curriculumSagas from "./curriculumSaga";
import huddleSagas from "./huddleSaga";
import navigationSagas from "./navigationSaga";
import parentSagas from "./parentSaga";
import speechSagas from "./speechSaga";
import webSocketSagas from "./webSocketSaga";
import userSagas from "./userSagas";
import webconSagas from "./webconSaga";
export default function* rootSaga() {
  yield all([fork(userSagas),
    fork(adminSagas),
    fork(parentSagas),
    fork(navigationSagas),
    fork(huddleSagas),
    fork(curriculumSagas),
    fork(chatSagas),
    fork(speechSagas),
    fork(webSocketSagas),
    fork(webconSagas)]);
}

