import { all, delay, put, select, takeEvery } from "redux-saga/effects";
import { navigateTo } from "../../NavigationAction";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import type { UserProfile } from "../../sharedDefs/userDefs";
import { ROLE } from "../../sharedDefs/userDefs";
import { DELAY, NAV, NAV_ACTION } from "../structs/navigationStruct";
import {State} from "../reducer";
const debug = debugModule(DEBUG_MODULE.NAVIGATION.SAGA);
// eslint-disable-next-line import/no-cycle
export function* navGotoSaga(action: {
  type: string;
  screenName: string;
  screenParams?: Record<string, string>;
}): any {
  const isOff = yield select((state:State) => state.navigationReducer.isOff);

  if (isOff) {
    debug('Skip goto '+action.screenName);
    yield put({
      type: NAV_ACTION.SKIPPED
    });
  } else {
    navigateTo(action.screenName, action.screenParams);
    yield put({
      type: NAV_ACTION.SUCCESS
    });
  }
}
export function* navGoHomeSaga(action: {
  type: string;
  role: string;
  delayCount ?: number;
}): any {
  const isOff = yield select((state:State) => state.navigationReducer.isOff);
  debug('NAVIGATION ISOFF'+isOff);
  if (isOff) {

    debug('Skip go home');
    yield put({
      type: NAV_ACTION.SKIPPED
    });
  } else
  {
    try {
      const role = action.role ? action.role : yield select((state: State) => state.userReducer.role);
      const userProfile: UserProfile = yield select((state: State) => state.userReducer.userData?.userProfile);
      let screenName = '';

      switch (role) {
        case ROLE.COACH:
          if ( !userProfile?.firstName || !userProfile?.lastName) {
            screenName = NAV.COACH.APPLICATION;
          } else {
            screenName = NAV.COACH.HOME;
          }

          break;

        case ROLE.PARENT:
          if (!userProfile || !userProfile.firstName || !userProfile.lastName) {
            screenName = NAV.PARENT.APPLICATION;
          } else {
            screenName = NAV.PARENT.HOME;
          }

          break;

        case ROLE.ADMIN:
          screenName = NAV.ADMIN.ASSESSMENT_WORKBENCH;
          break;

        case ROLE.STUDENT:
          screenName = NAV.STUDENT.HOME;
          break;

        default:
          yield put({
            type: NAV_ACTION.SKIPPED
          });
          return;
      }

      debug('navigateTo', screenName, role);
      navigateTo(screenName);
      yield put({
        type: NAV_ACTION.SUCCESS,
        role,
        screenName
      });
    }
    catch (e) {
      let delayCount = action.delayCount;
      if (!delayCount){
        delayCount = 0;
      }
      if ( delayCount < 3){
        yield delay( 1000);
        delayCount +=1;
        yield put({...action, delayCount});
      }
      else {
        yield put({
          type: NAV_ACTION.SKIPPED,
        });}
    }
  }
}
export function* delaySaga(action: {
  type: string;
  delayTime?: number;
}): any {
  yield delay(action.delayTime || 1000);
  yield put({
    type: DELAY.DONE
  });
}
export default function* navigationSagas(): Generator<any, any, any> {
  yield all([
    takeEvery(NAV_ACTION.GOTO, navGotoSaga),
    takeEvery(NAV_ACTION.GOHOME, navGoHomeSaga),
    takeEvery(DELAY.REQUEST, delaySaga)]);
}
