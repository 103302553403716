import * as React from "react";
import {Image, StyleSheet, Text, View} from "react-native";
import {useSelector} from "react-redux";
import type {State} from "../../redux/reducer";
import type {HuddleUser} from "../../sharedDefs/huddleDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";

const debug = debugModule(DEBUG_MODULE.WEBCON.STUDENT);
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    height: 380
  },
  image: {
    width: 680,
    height: 320,
    resizeMode: 'center',
    alignSelf: 'center'
  },
  labelStyle: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'center',
    padding: 10,
    color: COLOR.BASE
  }
});

export default function StudentLobby(): JSX.Element {
  const coachUser: HuddleUser = useSelector((state: State) => state.huddleReducer.huddle && state.huddleReducer.huddle.coachUser);


  return <View testID="StudentLobby" style={styles.root}>
    <Image style={styles.image} source={require('../../assets/mocks/chatGraphic.png')} />
    <Text style={styles.labelStyle}>
        Coach
      {coachUser?.userProfile?.firstName}
      {' '}
        will start soon
    </Text>
  </View>;
}
