import * as React from "react";
import {StyleSheet, View} from "react-native";
import {COLOR} from "../utils/UiConstants";
import {gridStyles} from "./WidgetStyles";

export type DotNumberProps = {
  vertical?: number;
  horizontal?: number;
  isCoach?: boolean;
  widgetWidth: number;
};
const styles = StyleSheet.create({
  dot: {
    flex: 1,
    width: 10,
    height: 10,
    maxWidth: 10,
    maxHeight: 10,
    minWidth: 10,
    minHeight: 10,
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE
  },
  column: {
    marginLeft: 7
  },
  blank: {
    flex: 1,
    width: 5,
    height: 5
  }
});
const dotStyle = (widgetWidth: number) => {
  if (widgetWidth > 500) {
    return {
      flex: 1,
      width: 10,
      height: 10,
      maxWidth: 10,
      maxHeight: 10,
      minWidth: 10,
      minHeight: 10,
      borderRadius: 10,
      borderColor: COLOR.BASE,
      borderWidth: 1,
      backgroundColor: COLOR.WHITE
    };
  }
  return {
    flex: 1,
    width: 7,
    height: 7,
    maxWidth: 7,
    maxHeight: 7,
    minWidth: 7,
    minHeight: 7,
    borderRadius: 7,
    borderColor: COLOR.BASE,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE
  };
};
const stylesCoach = StyleSheet.create({
  dot: {
    flex: 1,
    width: 5,
    height: 5,

    borderRadius: 2.5,
    borderColor: COLOR.BASE,
    borderWidth: 0.5,
    backgroundColor: COLOR.WHITE
  },
  column: {
    marginLeft: 1
  },
  blank: {
    flex: 1,
    width: 5,
    height: 5
  }
});
export default function DotNumber(props: DotNumberProps): JSX.Element {
  // eslint-disable-next-line react/destructuring-assignment
  const {widgetWidth} = props;
  const v = props.vertical || 1;
  // eslint-disable-next-line react/destructuring-assignment
  const h = props.horizontal || 1;
  const styleToUse = props.isCoach ? stylesCoach : styles;
  const rows = [];
  for (let index = 0; index < 10; index += 1) {
    if (index < v) {
      rows.push(<View key={index.toString()} style={dotStyle(widgetWidth)}/>);
    } else {
      rows.push(<View key={index.toString()} style={styleToUse.blank}/>);
    }
  }
  const columns = [];
  for (let index = 0; index < 10; index += 1) {
    if (index < h) {
      columns.push(<View key={index.toString()} style={[gridStyles.gridColumnContainer, styleToUse.column]}>
        {rows}
      </View>);
    } else {
      columns.push(<View key={index.toString()} style={styleToUse.blank}/>);
    }
  }
  return <View testID="DotNumber" style={[gridStyles.gridRowContainer, {minHeight: 50}]}>
    {columns}
  </View>;
}

