import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import type {UserId} from "../../sharedDefs/userDefs";
import {COLOR} from "../../utils/UiConstants";
import {useSelector} from "react-redux";
import {State} from "../../redux/reducer";
import {CurriculumStudentProgressionSummary} from "../../sharedDefs/curriculumDefs";

const VIDEO_SIZE = 120;
const styles = StyleSheet.create({
  root: {
    marginTop:20,
    flexDirection: 'column',
    flex: 1,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 20
  },
  textLabel: {
    borderColor: COLOR.GREY,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderStyle: 'dashed',
    flex: 5,
    fontWeight: '600',
    fontSize: 10 // flexShrink: 2

  },
  textValue: {
    borderColor: COLOR.GREY,
    borderStyle: 'dashed',
    flex: 5,
    fontSize: 8,
    fontWeight: '400'
  },
  textLabelBottom: {
    borderWidth: 0,
    flex: 5 // flexShrink: 2

  },
  iconBox: {
    borderColor: COLOR.GREY,
    borderWidth: 0.5,
    borderStyle: 'dashed',
    flex: 2.5
  },
  iconItem: {
    width: 20,
    height: 20,
    flex: 2.5,
    backgroundColor: COLOR.LT_BLUE
  },
  skillBox: {
    flex: 5
  },
  skillText: {
    fontWeight: '400'
  },
  arrowColumn: {
    flex: 2.5,
    alignItems: 'flex-start',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderStyle: 'dashed'
  },
  arrowIcon: {
    width: 20,
    height: 20
  }
});
export type CoachStudentDashPropType = {
  studentId: UserId;
};
export default function StudentDash(props: CoachStudentDashPropType): JSX.Element {
  const {studentId} = props;

  const curriculumStudentProgressionSummary:CurriculumStudentProgressionSummary = useSelector((state: State) => state.curriculumReducer.curriculumStudentProgressionSummaryMap[studentId]);

  console.log('curriculumStudentProgressionSummary',curriculumStudentProgressionSummary);

  if (!curriculumStudentProgressionSummary || curriculumStudentProgressionSummary.attempts === 0){
    return <View style={styles.root} ><Text>No Data yet</Text></View>;
  }
  const accuracy = Math.round(100* curriculumStudentProgressionSummary.accurateCount/curriculumStudentProgressionSummary.attempts);
  const aveTime = Math.round(curriculumStudentProgressionSummary.timeTotal/curriculumStudentProgressionSummary.attempts/100000)*10;
  const maxTime = Math.round(curriculumStudentProgressionSummary.maxTime/100000)*10;
  const unsureness = Math.round((curriculumStudentProgressionSummary.confidenceVeryLow+curriculumStudentProgressionSummary.confidenceLow+curriculumStudentProgressionSummary.confidenceMedium)/curriculumStudentProgressionSummary.attempts);

  // console.log('StudentDash',accuracy, aveTime,maxTime,unsureness);

  return <View testID="StudentDash" style={styles.root}>
    <View style={styles.row}>
      <Text style={styles.textLabel}>Accuracy </Text>

      <Text style={styles.textValue}>{accuracy} % </Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.textLabel}>Ave Speed</Text>
      <Text style={styles.textValue}>{aveTime} Sec</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.textLabel}>Max Time </Text>
      <Text style={styles.textValue}>{maxTime} Sec</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.textLabel}>Unsure </Text>
      <Text style={styles.textValue}>{unsureness} %</Text>
    </View>
  </View>;
}
