import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import type {WebconAction, WebconState} from "../structs/webconStruct";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";
import {addReducerLogging} from "./common";
import {DeviceInfos, WEBCON_ACTION, WEBCON_SESSION_STATE} from "../../sharedDefs/webconDefs";
import type {CoachId, StudentId, UserId} from "../../sharedDefs/userDefs";
import {ActionType} from "../../sharedDefs/commonStruct";

const debug = debugModule(DEBUG_MODULE.WEBCON.REDUCER);


export const webconDeviceInfos = (deviceInfos: DeviceInfos): WebconAction => {
  invariant(deviceInfos, 'invalid-reducer webconDeviceInfos');
  return {
    type: WEBCON_ACTION.DEVICE_INFOS.ASSIGN,
    deviceInfos
  };
};

export const webconDeviceCamera = (webcamDeviceId: string): WebconAction => {
  invariant(webcamDeviceId, 'invalid-reducer webconDeviceCamera');
  return {
    type: WEBCON_ACTION.DEVICE_INFOS.ASSIGN_CAMERA,
    webcamDeviceId
  };
};

export const webconDeviceMic = (microphoneDeviceId: string): WebconAction => {
  invariant(microphoneDeviceId, 'invalid-reducer webconDeviceMic');
  return {
    type: WEBCON_ACTION.DEVICE_INFOS.ASSIGN_MIC,
    microphoneDeviceId
  };
};
export const webconDeviceSpeaker = (speakerDeviceId: string): WebconAction => {
  invariant(speakerDeviceId, 'invalid-reducer webconDeviceSpeaker');
  return {
    type: WEBCON_ACTION.DEVICE_INFOS.ASSIGN_SPEAKER,
    speakerDeviceId
  };
};

export const webconSessionInitialize = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer webconSessionInitialize');
  return {
    type: WEBCON_ACTION.SESSION.INITIALIZE.REQUEST,
    parent,
    origin: ACTION_ORIGIN.INSTIGATE,
    coachId,
    userId
  };
};

export const webconSessionEnter = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer webconSessionEnter');
  return {
    type: WEBCON_ACTION.SESSION.ENTER.REQUEST,
    parent,
    origin: ACTION_ORIGIN.INSTIGATE,
    coachId,
    userId
  };
};

export const webconSessionLeave = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer webconLeaveSession');
  return {
    type: WEBCON_ACTION.SESSION.LEAVE.REQUEST,
    parent,
    origin: ACTION_ORIGIN.INSTIGATE,
    coachId,
    userId
  };
};

export const webconWebconState = (): WebconAction => {
  return {
    type: WEBCON_ACTION.DEBUG.WEBCON_STATE,
    origin: ACTION_ORIGIN.INSTIGATE,
  };
};

export const webconDeviceSetup = (parent: ActionType, userId: UserId, coachId: CoachId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer webconDeviceSetup');
  return {
    type: WEBCON_ACTION.DEVICE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    userId,
    coachId
  };
};
export const webconEnableWebcam = (parent: ActionType, coachId: CoachId): WebconAction => {
  invariant(coachId, 'invalid-reducer webconDeviceSetup');
  return {
    type: WEBCON_ACTION.ENABLE_WEBCAM.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId
  };
};
export const webconEnableMicrophone = (parent: ActionType, coachId: CoachId): WebconAction => {
  invariant(coachId, 'invalid-reducer webconDeviceSetup');
  return {
    type: WEBCON_ACTION.ENABLE_MICROPHONE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId
  };
};
export const webconGetRouterRtpCapabilities = (parent: ActionType): WebconAction => ({
  type: WEBCON_ACTION.ROUTER_RTP_CAPABILITIES.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  parent
});
export const webconAnyoneSendTransport = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer coachId');
  return {
    type: WEBCON_ACTION.ANYONE_SEND_TRANSPORT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const webconStudentRecieveTransport = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer coachId');
  return {
    type: WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const webconCoachRecieveTransport = (parent: ActionType, coachId: CoachId, userId: UserId): WebconAction => {
  invariant(coachId && userId, 'invalid-reducer');
  return {
    type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const webconTransportConnect = (parent: ActionType, userId: UserId, coachId: CoachId, transportId, dtlsParameters, appData): WebconAction => {
  invariant(userId && coachId && transportId && dtlsParameters, 'invalid-reducer ');
  return {
    type: WEBCON_ACTION.TRANSPORT_CONNECT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    userId,
    coachId,
    transportId,
    dtlsParameters,
    appData
  };
};
export const webconTransportProduce = (parent: ActionType, userId: UserId, coachId: CoachId, transportId, kind, rtpParameters): WebconAction => {
  invariant(userId && coachId && transportId && kind && rtpParameters, 'invalid-reducer ');
  return {
    type: WEBCON_ACTION.TRANSPORT_PRODUCE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    userId,
    coachId,
    transportId,
    kind,
    rtpParameters
  };
};
export const webconChangeDevices = (): WebconAction => ({
  type: WEBCON_ACTION.CHANGE_DEVICES.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
});

export const webconWebcamUpdate = (parent: ActionType): WebconAction => ({
  type: WEBCON_ACTION.WEBCAM_UPDATE.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  parent
});
export const webconMicrophoneUpdate = (parent: ActionType): WebconAction => ({
  type: WEBCON_ACTION.MICROPHONE_UPDATE.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  parent
});

export const webconStudentConsumeCoachVideo = (parent: ActionType, coachId: CoachId, studentId: UserId): WebconAction => ({
  type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  coachId,
  studentId,
  parent
});
export const webconStudentConsumeCoachAudio = (parent: ActionType, coachId: CoachId, studentId: StudentId): WebconAction => ({
  type: WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  coachId,
  studentId,
  parent
});

export const webconCoachConsumeStudentVideo = (parent: ActionType, coachId: CoachId, studentId: StudentId): WebconAction => ({
  type: WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  parent,

  studentId,
  coachId
});

export const webconCoachConsumeStudentAudio = (parent: ActionType, coachId: CoachId, studentId: StudentId): WebconAction => ({
  type: WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE,
  parent,
  studentId,
  coachId
});
const initialState: WebconState = {
  mediasoupDevice: undefined,
  anyoneSendTransport: undefined,
  anyoneSendTransportDef: undefined,
  webcamDeviceId: undefined,
  localVideoTrack: undefined
};

function _webconReducer(state: WebconState = initialState, action: WebconAction): WebconState {
  switch (action.type) {
    case WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS: {
      return {
        ...state,
        sessionState: WEBCON_SESSION_STATE.INITIALIZED
      };
    }
    case WEBCON_ACTION.SESSION.INITIALIZE.REQUEST: {
      return {
        ...state,
        sessionState: WEBCON_SESSION_STATE.INITIALIZING
      };
    }
    case WEBCON_ACTION.SESSION.INITIALIZE.FAILURE: {
      return {
        ...state,
        sessionState: WEBCON_SESSION_STATE.INITIALIZED_FAILURE
      };
    }
    case WEBCON_ACTION.DEVICE_INFOS.ASSIGN: {
      const {
        deviceInfos,
      } = action;
      invariant(deviceInfos, WEBCON_ACTION.DEVICE_INFOS.ASSIGN);
      return {
        ...state,
        deviceInfos,
      };
    }
    case WEBCON_ACTION.DEVICE_INFOS.ASSIGN_CAMERA: {
      const {
        webcamDeviceId,
      } = action;
      invariant(webcamDeviceId, WEBCON_ACTION.DEVICE_INFOS.ASSIGN_CAMERA);
      return {
        ...state,
        webcamDeviceId,
      };
    }
    case WEBCON_ACTION.DEVICE_INFOS.ASSIGN_MIC: {
      const {
        microphoneDeviceId,
      } = action;
      invariant(microphoneDeviceId, WEBCON_ACTION.DEVICE_INFOS.ASSIGN_MIC);
      console.log(WEBCON_ACTION.DEVICE_INFOS.ASSIGN_MIC, microphoneDeviceId);
      return {
        ...state,
        microphoneDeviceId,
      };
    }
    case WEBCON_ACTION.DEVICE_INFOS.ASSIGN_SPEAKER: {
      const {
        speakerDeviceId,
      } = action;
      invariant(speakerDeviceId, WEBCON_ACTION.DEVICE_INFOS.ASSIGN_SPEAKER);
      return {
        ...state,
        speakerDeviceId,
      };
    }
    case WEBCON_ACTION.DEVICE.SUCCESS: {

      const {
        mediasoupDevice, routerRtpCapabilities
      } = action;
      invariant(mediasoupDevice && routerRtpCapabilities, WEBCON_ACTION.DEVICE.SUCCESS, ' ' + (!!mediasoupDevice) + ' ' + routerRtpCapabilities);
      return {
        ...state,
        mediasoupDevice, routerRtpCapabilities
      };
    }

    case WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS: {
      const {
        localVideoTrack
      } = action;
      invariant(localVideoTrack && localVideoTrack.kind === 'video', 'WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS' + localVideoTrack?.kind);
      return {
        ...state,
        localVideoTrack
      };
    }
    case WEBCON_ACTION.MICROPHONE_UPDATE.SUCCESS: {
      const {
        microphoneDeviceId,
        localAudioTrack
      } = action;
      invariant(localAudioTrack && localAudioTrack.kind === 'audio', 'WEBCON_ACTION.MICROPHONE_UPDATE.SUCCESS' + localAudioTrack);
      return {
        ...state,
        microphoneDeviceId,
        localAudioTrack
      };
    }
    case WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.SUCCESS: {
      const {studentVideoConsumer} = action;
      invariant(studentVideoConsumer && studentVideoConsumer.kind === 'video', 'invalid action WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.SUCCESS');
      return {
        ...state,
        studentVideoConsumer,
      };
    }
    case WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.CLOSE: {
      const newState = {
        ...state
      };
      delete newState.studentVideoConsumer;
      return newState;
    }
    case WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS: {
      const {studentAudioConsumer} = action;
      invariant(studentAudioConsumer && studentAudioConsumer.kind === 'audio', 'invalid action WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS');
      return {
        ...state,
        studentAudioConsumer,
      };
    }
    case WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS: {
      const {coachVideoConsumer, studentId} = action;
      debug('coachVideoConsumer', studentId);
      invariant(coachVideoConsumer && studentId && coachVideoConsumer.kind === 'video', 'invalid action WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS');
      const coachVideoConsumers = {...state.coachVideoConsumers};
      coachVideoConsumers[studentId] = coachVideoConsumer;


      return {
        ...state,
        coachVideoConsumers,
      };
    }
    case WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS: {
      const {coachAudioConsumer, studentId} = action;
      debug('coachAudioConsumer', studentId);
      invariant(coachAudioConsumer && studentId && coachAudioConsumer.kind === 'audio', 'invalid action WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS');
      const coachAudioConsumers = {...state.coachAudioConsumers};
      coachAudioConsumers[studentId] = coachAudioConsumer;


      return {
        ...state,
        coachAudioConsumers,
      };
    }
    case WEBCON_ACTION.COACH_STUDENT_CONSUMERS.CLOSE: {
      const {studentId} = action;
      debug('QQQ coachAudioConsumer reducer', studentId);
      invariant(studentId, 'invalid action WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS');
      const coachAudioConsumers = {...state.coachAudioConsumers};
      delete coachAudioConsumers[studentId];
      const coachVideoConsumers = {...state.coachVideoConsumers};
      delete coachVideoConsumers[studentId];
      debug('QQQ coachAudioConsumer reducer', studentId, Object.keys(coachAudioConsumers));
      return {
        ...state,
        coachAudioConsumers,
        coachVideoConsumers
      };
    }
    // case WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.CLOSE: {
    //   const { studentId} = action;
    //   debug('QQQ coachVideoConsumer reducer', studentId);
    //   invariant( studentId , 'invalid action WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS');
    //   const coachVideoConsumers = {...state.coachVideoConsumers};
    //   delete coachVideoConsumers[studentId] ;
    //   debug('QQQ coachVideoConsumers reducer', studentId, Object.keys(coachVideoConsumers));
    //   return {
    //     ...state,
    //     coachVideoConsumers,
    //   };
    // }
    case WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.SUCCESS: {
      const {studentReceiveTransport, studentReceiveTransportDef} = action;

      invariant(studentReceiveTransport && studentReceiveTransportDef, 'invalid action WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.SUCCESS');
      return {
        ...state,
        studentReceiveTransport,
        studentReceiveTransportDef
      };
    }
    case WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS: {
      const {coachReceiveStudentTransports, coachReceiveStudentTransportDefs} = action;


      invariant(coachReceiveStudentTransports && coachReceiveStudentTransportDefs, 'invalid action WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS');

      return {
        ...state,
        coachReceiveStudentTransports,
        coachReceiveStudentTransportDefs
      };

    }
    case WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.CLOSE: {
      const {studentId} = action;
      const st = {...state};
      if (st.coachReceiveStudentTransportDefs[studentId]) {
        const coachReceiveStudentTransportDefs = {
          ...st.coachReceiveStudentTransportDefs
        };
        delete coachReceiveStudentTransportDefs[studentId];
        st.coachReceiveStudentTransportDefs = coachReceiveStudentTransportDefs;
      }
      if (st.coachReceiveStudentTransports[studentId]) {
        const coachReceiveStudentTransports = {
          ...st.coachReceiveStudentTransports
        };
        delete coachReceiveStudentTransports[studentId];
        st.coachReceiveStudentTransports = coachReceiveStudentTransports;
      }

      console.log('webconCoachVideoConsumeSaga AFTER WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.CLOSE', studentId, st.coachReceiveStudentTransportDefs, st.coachReceiveStudentTransports);
      return st;
    }
    case WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.SUCCESS: {
      const st = {...state};
      delete st.studentAudioConsumer;
      delete st.studentVideoConsumer;
      delete st.microphoneProducer;
      delete st.webcamProducer;
      delete st.anyoneSendTransport;
      delete st.anyoneSendTransportDef;
      delete st.localAudioTrack;
      delete st.localAudioTrack;
      delete st.microphoneDeviceId;
      delete st.routerRtpCapabilities;
      delete st.studentReceiveTransport;
      delete st.studentReceiveTransportDef;
      delete st.webcamDeviceId;
      delete st.localVideoTrack;
      delete st.mediasoupDevice;
      return st;
    }
    case WEBCON_ACTION.COACH_DISCONNECT.SUCCESS: {
      const st = {...state};
      delete st.studentAudioConsumer;
      delete st.studentVideoConsumer;
      return st;
    }
    case WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS: {
      const {webcamProducer} = action;
      debug('WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS', action);
      // invariant(webcamProducer?.kind === 'video',
      //   'invalid action WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS ' + webcamProducer?.kind);
      return {
        ...state,
        webcamProducer
      };
    }
    case WEBCON_ACTION.ENABLE_WEBCAM.CLOSE: {

      debug('WEBCON_ACTION.ENABLE_WEBCAM.CLOSE', action);
      const st = {...state};
      delete st.webcamProducer;
      return st;
    }

    case WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS: {
      const {microphoneProducer} = action;
      debug('WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS', action);
      // invariant(microphoneProducer && microphoneProducer.kind === 'audio', 'invalid action WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS');
      return {
        ...state,
        microphoneProducer
      };
    }

    case WEBCON_ACTION.ANYONE_SEND_TRANSPORT.SUCCESS: {
      const {
        transportDef,
        anyoneSendTransport
      } = action;
      return {
        ...state,
        anyoneSendTransport,
        anyoneSendTransportDef: transportDef
      };
    }

    default:
      return state;
  }
}

function actionForLogger(action: WebconAction) {
  const {
    webconItem,
    parent,
    origin,
    type
  } = action;
  const parents = [];
  for (let p = parent; p; p = p.parent) {
    parents.push(p.type);
  }
  return {
    type,
    parents,
    origin,
    webconItem,
    error: action.error
  };
}

export default function webconReducer(state: WebconState = initialState, action: WebconAction): WebconState {
  const prefix = 'WEBCON_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _webconReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
