import {firestore} from "../rsf";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";
import {Assessment, ProgressionSet, Standard} from "../../sharedDefs/curriculumDefs";


const debug = debugModule(DEBUG_MODULE.UTILS.QUERIES);

export function queryStandards(levelId: string, domainId?: string): Promise< Array<Standard> > {
  console.log('queryStandards', levelId,domainId);

  if (domainId && levelId) {
    return firestore.collection(COLLECTION.STANDARD)
      .where('levelId', '==', levelId)
      .where('domainId', '==', domainId)
      .get()
      .then(standardRefs => {
        const stardards: Array<Standard> = standardRefs.docs.map(x => x.data() as Standard);
        debug('Standards',stardards);
        return stardards;
      });
  }

  if ( levelId) {
    return firestore.collection(COLLECTION.STANDARD).where('levelId', '==', levelId).get()
      .then(standardRefs => {
        const stardards: Array<Standard> = standardRefs.docs.map(x => x.data() as Standard);
        debug('Standards',stardards);
        return stardards;
      });
  }
  return Promise.resolve([]);
}



export function queryProgressions(levelId: string, domainId?: string,standardId?: string): Promise< Array<ProgressionSet> > {
  debug('queryProgressions', levelId,domainId,standardId);

  if (!levelId && !domainId && !standardId) {
    return Promise.resolve([]);
  }
  let q = firestore.collection(COLLECTION.PROGRESSION_SET);

  if (levelId) {
    q = q.where('levelId', '==', levelId);
  }
  if (domainId) {
    q = q.where('domainId', '==', domainId);
  }
  if (standardId) {
    q = q.where('standardId', '==', standardId);
  }

  return q.get()
    .then(progressSetRefs => {
      const progressSets: Array<ProgressionSet> = progressSetRefs.docs.map(x => x.data() as ProgressionSet);
      console.log('progressSets',progressSets);
      return progressSets;
    });
}

export async function queryAssessment(levelId: string): Promise< Assessment> {
  debug('queryAssessment', levelId);

  if (!levelId ) {
    return Promise.resolve(null);
  }

  const assessmentResult = await firestore.collection(COLLECTION.ASSESSMENTS).doc(levelId).get();
  if (assessmentResult.exists) {
    return assessmentResult.data() as Assessment;
  }
  return null;
}





