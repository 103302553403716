import * as React from "react";
import { StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { Styles } from "../../styles/Styles";
import {COLOR} from "../../utils/UiConstants";
const styles = StyleSheet.create({
  button: {
    maxWidth: 200,
    height: 36,
    minHeight:36,
    borderColor: '#000000',
    backgroundColor: COLOR.GREEN,
    borderWidth: 1,
    flex:1,


  }
});
export type CplButtonProps = {
  testID?: string;
  disabled?: boolean;
  children?: any;
  onPress?: () => void;
};
export default function CplButton(props: CplButtonProps): JSX.Element {
  const {
    testID,
    children,
    onPress,
    disabled
  } = props;

  const _testID = testID || 'SUBMIT';

  return <Button style={styles.button} disabled={disabled} mode="contained" testID={_testID} onPress={onPress}>
    {children}
  </Button>;
}
