import {Text, TextInput} from 'react-native-paper';
import {View} from "react-native";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import * as React from "react";
import {AdditionPartnerParams} from "../sharedDefs/ProgressionSetUtil";

type WorkbenchGeneratorProps = {
  problemGenerationParams:AdditionPartnerParams,
  onGeneratorChange: (generationParams:AdditionPartnerParams )=>void;
}
export default function AdditionPartnerWorkbench(props:WorkbenchGeneratorProps): JSX.Element {
  const {onGeneratorChange,problemGenerationParams} = props;


  React.useEffect( () => {


    if (!problemGenerationParams || Object.keys(problemGenerationParams).length != 2 || problemGenerationParams.min === undefined) {
      const params:AdditionPartnerParams = {
        min: 1,
        max: 10,
      };

      onGeneratorChange(params);
    }
  },[]);


  console.log('problemGenerationParams',problemGenerationParams);

  return (
    <View  style={gridStyles.gridColumnContainer} >
      <Text style={textStyles.titleBar}>AdditionPartner</Text>

      <View style={gridStyles.formRowContainer}>
        <TextInput
          style={textStyles.textInput}
          label="min"  value={problemGenerationParams?.min?.toString(10) ||''}
          onChangeText={min => {
            try {
              if (min.length === 0){
                onGeneratorChange({...problemGenerationParams,min: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, min: parseInt(min, 10)});
              }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,min: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput}
          label="max"
          value={problemGenerationParams?.max?.toString(10)||''}
          onChangeText={max => {
            try {if (max.length === 0){
              onGeneratorChange({...problemGenerationParams,max: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, max :parseInt(max, 10)});
            }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,max: null });
            }
          }}
        />
      </View>



      <Text style={textStyles.formText}>{JSON.stringify(problemGenerationParams)}</Text>

    </View>
  );
}
