import {firestore} from "../rsf";
import type {ProgressionSet} from "../../sharedDefs/curriculumDefs";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {HUDDLE, Huddle} from "../../sharedDefs/huddleDefs";

const debug = debugModule(DEBUG_MODULE.UTILS.QUERIES);

export function queryProgressionSets(setProgressionSetList: (progressionSets: Array<ProgressionSet>) => void): () => void {
  debug('queryProgressionSets queryProgressionSets');
  const unsubscribe = firestore.collection(COLLECTION.PROGRESSION_SET).onSnapshot(querySnapshot => {
    const progressionSets: Array<ProgressionSet> = [];
    querySnapshot.forEach(doc => {
      const progressionSet: ProgressionSet = doc.data();
      progressionSets.push(progressionSet);
    });
    debug('update queryProgressionSets', progressionSets.length);
    setProgressionSetList(progressionSets);
  });
  return () => {
    debug('unsubscribe');
    unsubscribe();
  };
}

export function queryHuddleNoCoach(setNoCoachHuddle: (huddle: Huddle) => void): () => void {
  debug('queryHuddleNoCoach ');
  const unsubscribe = firestore.collection(COLLECTION.HUDDLES)
    .doc(HUDDLE.NO_COACH)
    .onSnapshot(huddleRef => {
      if (huddleRef.exists){
        setNoCoachHuddle(huddleRef.data());
      }
      else {
        setNoCoachHuddle(null);
      }
    });
  return () => {
    debug('unsubscribe');
    unsubscribe();
  };
}
