import {Checkbox, Text, TextInput} from 'react-native-paper';
import {View} from "react-native";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import * as React from "react";

export type MultiplicationParams = {
  numberOfProblems: number;
  randomSeed: string;
  minA: number;
  maxA: number,
  minB: number,
  maxB: number,
  minProduct: number,
  maxProduct: number
}
type WorkbenchGeneratorProps = {
  generationParams: MultiplicationParams;
  onGeneratorChange: ( generationParams:MultiplicationParams )=>void;
}
export default function MultiplicationWorkbench(props:WorkbenchGeneratorProps): JSX.Element  {
  const {onGeneratorChange,generationParams} = props;



  const changeGeneration = (_generationParams:MultiplicationParams) => {
    onGeneratorChange(_generationParams);
  };

  React.useEffect( () => {

    if (!generationParams || Object.keys(generationParams).length != 8 || generationParams.minProduct === undefined) {
      console.log('Object.keys(generationParams).length', generationParams);
      const params:MultiplicationParams = {
        numberOfProblems: 10,
        randomSeed: null,
        minA: 1,
        maxA: 10,
        minB: 1,
        maxB: 10,
        minProduct: 1,
        maxProduct: 100
      };

      changeGeneration(params);
    }
  },[]);



  return (
    <View  style={gridStyles.gridColumnContainer} >
      <Text style={textStyles.titleBar}>Multiplication</Text>
      <TextInput style={[textStyles.textInput,gridStyles.formRowContainer]} label="number of problems"  value={generationParams?.numberOfProblems?.toString(10)||''}
        onChangeText={_numberOfProblems => {
          try {
            changeGeneration({...generationParams,numberOfProblems: parseInt(_numberOfProblems,10) });
          }
          catch (e){
            console.error('Use Number');
            changeGeneration({...generationParams,numberOfProblems: null });
          }
        }}
      />
      <View style={gridStyles.formRowContainer}>
        <Checkbox
          status={generationParams?.randomSeed ? 'checked' : 'unchecked'}
          onPress={() => {
            if (generationParams?.randomSeed) {
              changeGeneration({...generationParams,randomSeed: null });
            }
            else {
              changeGeneration({...generationParams,randomSeed: Date.now().toString(10) });
            }
          }}
        />
        <Text>Random Order</Text>
      </View>
      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minA"  value={generationParams?.minA?.toString(10) ||''}
          onChangeText={minA => {
            try {
              if (minA.length === 0){
                changeGeneration({...generationParams,minA: null });
              }
              else {
                changeGeneration({...generationParams, minA: parseInt(minA, 10)});
              }

            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,minA: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxA"  value={generationParams?.maxA?.toString(10)||''}
          onChangeText={maxA => {
            try {if (maxA.length === 0){
              changeGeneration({...generationParams,maxA: null });
            }
            else {
              changeGeneration({...generationParams, maxA: parseInt(maxA, 10)});
            }
            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,maxA: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minB"  value={generationParams?.minB?.toString(10)||''}
          onChangeText={minB => {
            try {if (minB.length === 0){
              changeGeneration({...generationParams,minB: null });
            }
            else {
              changeGeneration({...generationParams, minB: parseInt(minB, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,minB: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxB"  value={generationParams?.maxB?.toString(10)||''}
          onChangeText={maxB => {
            try {if (maxB.length === 0){
              changeGeneration({...generationParams,maxB: null });
            }
            else {
              changeGeneration({...generationParams, maxB: parseInt(maxB, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,maxB: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minProduct"  value={generationParams?.minProduct?.toString(10)||''}
          onChangeText={minProduct => {
            try {
              if (minProduct.length === 0){
                changeGeneration({...generationParams,minProduct: null });
              }
              else {
                changeGeneration({...generationParams, minProduct: parseInt(minProduct, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,minProduct: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxProduct"  value={generationParams?.maxProduct?.toString(10)||''}
          onChangeText={maxProduct => {
            try {
              if (maxProduct.length === 0){
                changeGeneration({...generationParams,maxProduct: null });
              }
              else {
                changeGeneration({...generationParams, maxProduct: parseInt(maxProduct, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              changeGeneration({...generationParams,maxProduct: null });
            }
          }}
        />
      </View>


    </View>
  );
}
