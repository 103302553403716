// noinspection ES6CheckImport



import {applyMiddleware, compose, createStore, Store} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
// eslint-disable-next-line import/no-cycle
import rootSaga from "./sagas";
// noinspection ES6CheckImport
const context = {
  dispatch: (action: unknown)=> {console.log('dispatch');}
};
const sagaMiddleware = createSagaMiddleware({
  context
});

export default function configureStore(): Store {
  // const enhancers = compose(applyMiddleware(sagaMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
  const enhancers = compose(applyMiddleware(sagaMiddleware));
  const store = createStore(rootReducer, enhancers);
  context.dispatch = store.dispatch;
  // window.store = store;
  sagaMiddleware.run(rootSaga);
  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducer', () => {
  //     const nextRootReducer = require('./reducer');
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }
  return store;
}
