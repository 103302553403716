import * as React from "react";
import {Image, StyleSheet, View} from "react-native";
import {useDispatch} from "react-redux";

import type {CurriculumStudentInteraction, CurriculumStudentResponse} from "../sharedDefs/curriculumDefs";
import {CURRICULUM} from "../sharedDefs/curriculumDefs";
import InstructionsWidget from "../widgets/InstructionsWidget";
import Lottie from "../components/Lottie";


const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    marginTop: 3 // flex: 1

  },
  child: {},
  image: {
    width: 30,
    height: 30
  },
  confidence: {
    marginLeft: 4,
    marginRight: 4,
    width: 30,
    height: 30,
    resizeMode: 'center'
  }
});
type CoachWidgetContainerPropType = {
  curriculumStudentInteraction: CurriculumStudentInteraction;
  curriculumStudentResponse: CurriculumStudentResponse;
  children: any;
};
export default function CoachWidgetContainer(props: CoachWidgetContainerPropType): JSX.Element {

  // const responseState = useSelector((state: State) => (state.curriculumReducer.responseState));
  const {
    children,
    curriculumStudentResponse,
    curriculumStudentInteraction
  } = props;

  //const rootStyle = styles.root;
  // const topClasses = {};
  // topClasses[styles.root] = true;
  // topClasses[styles.correct] = answerState === ANSWER_STATE.CORRECT;
  // topClasses[styles.incorrect] = answerState === ANSWER_STATE.INVALID || answerState === ANSWER_STATE.INCORRECT;
  return <View style={styles.root}>

    <View style={styles.child}>
      {children}
    </View>
    { function () {
      if (curriculumStudentResponse?.confidence) {
        return ( <View style={styles.confidence} ><Lottie    confidenceConstant={curriculumStudentResponse?.confidence}  /> </View> );
      }else {
        return (<> </>);}
    }()}
    { function () {
      if (curriculumStudentInteraction?.problem?.widgetSetId === InstructionsWidget.widgetSetId) {
        return (<Image source={require('../assets/icons/instructions.png')} style={styles.confidence}/>);
      }
      if (curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.CORRECT) {
        return (<Image source={require('../assets/icons/correct.png')} style={styles.confidence}/>);
      }
      if (curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.INCORRECT) {
        return (<Image source={require('../assets/icons/incorrect.png')} style={styles.confidence}/>);
      }
    }()}

  </View>;
}
