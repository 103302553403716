import React from "react";
import type {Huddle, HuddleUser} from "../sharedDefs/huddleDefs";
import type {UserId} from "../sharedDefs/userDefs";
import {DEBUG_MODULE, debugModule} from "./debugModule";
import * as _ from "lodash";

const debug = debugModule(DEBUG_MODULE.FIRESTORE.DATA);

export function getHuddleUser(huddle: Huddle, userId: UserId): HuddleUser | null | undefined {
  if (!huddle || !userId) {
    return null;
  }

  if (huddle.coachUser && huddle.coachUser.userProfile && huddle.coachId === userId) {
    return huddle.coachUser;
  }

  if (huddle.studentHuddleUsers && huddle.studentHuddleUsers[userId]) {
    return huddle.studentHuddleUsers[userId];
  }

  return null;
}
export function calculateAge(birthMonth: string, birthYear: string): string {
  try {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    if ( month >= parseInt(birthMonth)){
      return ''+(year - parseInt(birthYear));
    }
    return ''+(year - parseInt(birthYear)-1);
  } catch (err) {
    return 'birthday error' + birthMonth + '   ' + birthYear;
  }
}
export function mapValueToLabel(value: string, valueObj: Record<string, string>, labelObj: Record<string, string>): string {
  if (!value || !valueObj) return '';
  const key = Object.keys(valueObj).find(k => valueObj[k] === value);

  if (key) {
    return labelObj[key];
  }

  return '';
}
export function valueLabelArray(valueObj: Record<string, string>, labelObj: Record<string, string>): Array<{
  value: string;
  label: string;
}> {
  return Object.values(valueObj).map(v => ({
    value: ((v as any) as string),
    label: mapValueToLabel(((v as any) as string), valueObj, labelObj)
  }));
}


export function generateInteractionId(): string {
  const timeStamp = Date.now();
  return timeStamp + '-xxxxxxxx'.replace(/[x]/g, c => {
    // eslint-disable-next-line one-var,no-bitwise
    const r = Math.random() * 16 | 0,
      // eslint-disable-next-line no-bitwise,eqeqeq,no-mixed-operators
      v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}

export function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}
export function mergeDeep(target: any, source: any): any {
  if (isObject(target) && isObject(source)) {
    const r1 = {};
    const ret = _.merge(r1, target, source);
    return r1;

    // Object.keys(source).forEach(key => {
    //   if (isObject(source[key])) {
    //     if (!target[key]) Object.assign(target, {
    //       [key]: {}
    //     });
    //     mergeDeep(target[key], source[key]);
    //   } else {
    //     try {
    //       Object.assign(target, {
    //         [key]: source[key]
    //       });
    //     }
    //     catch(e){
    //       // ignore readonly failures
    //     }
    //   }
    // });
  }

  return target;
}
