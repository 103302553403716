import * as React from "react";
import {useRef} from "react";
import {Image, StyleSheet, Text, View} from "react-native";
import {Avatar} from "react-native-paper";
import {useDispatch, useSelector} from "react-redux";
import CoachChatView from "../../containers/Coach/CoachChatView";
import StudentDash from "../../containers/Student/StudentDash";
import type {State} from "../../redux/reducer";
import {huddleCoachConnectsToEnteredStudent} from "../../redux/reducer/huddleReducer";
import type {HuddleUser} from "../../sharedDefs/huddleDefs";
import {AUDIO_MODE, HUDDLE} from "../../sharedDefs/huddleDefs";
import type {UserId} from "../../sharedDefs/userDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";
import {Consumer} from "mediasoup-client/lib/Consumer";
import CoachsStudentWebConFooter from "./CoachStudentWebConFooter";

const debug = debugModule(DEBUG_MODULE.WEBCON.COACH);

const VIDEO_SIZE = 120;
const styles = StyleSheet.create({

  chatSizeImage: {
    width: 240,
    height: 170,
    resizeMode: 'center'
  },

  video: {
    borderRadius: 10,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    overflow: 'hidden',
    // backgroundColor: '#ff0000',
    alignItems: 'center',
    alignContent: 'center'
  },
  gridColumnContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flex: 1
  },
  gridColumnLeft: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 240,
    minWidth: 300,
    alignItems: 'center'
  },
  gridColumnRight: {
    //  backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    width: 160
  },
  gridRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'row',
    flex: 1
  },
  gridItemVideo: {
    flexDirection: 'row',
    justifyContent: 'center',
    // alignSelf:'center',
    // backgroundColor: COLOR.YELLOW,
    // width: 150,
    height: VIDEO_SIZE,
    flex: 1
  },
  gridItemDash: {
    flexDirection: 'row',
    justifyContent: 'center',
    maxHeight: 140,
    // alignSelf:'flex-end',
    // backgroundColor: COLOR.YELLOW,
    // width: 300,
    //  height: 140,
    flex: 2
  },
  videoWidth: {// width: 150,
    // height: 90,
  },
  placeholderInactive: {
    backgroundColor: COLOR.GREY
  },
  placeholderActive: {
    backgroundColor: COLOR.GREEN
  },
  placeholder: {
    borderRadius: 10,
    backgroundColor: COLOR.GREY,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    //    padding: 5,
    position: 'absolute',
    // top: 20,
    alignSelf: 'center',
    zIndex: 100
  },
  placeholderContent: {
    top: 20,
    alignSelf: 'center'
  },
  talkee: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    flex: 1
  },
  admitButton: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    backgroundColor: COLOR.RED,
    borderRadius: 5,
    borderColor: COLOR.RED,
    height: 40,
    width: 100,
    maxWidth: 100,
    flex: 1,
    shadowColor: COLOR.RED,
    shadowOffset: {
      width: 5,
      height: 5
    },
    shadowOpacity: 0.5,
    shadowRadius: 8
  },
  admitText: {
    fontFamily: 'ArialRoundedMTBold',
    justifyContent: 'center',
    color: COLOR.WHITE,
    fontSize: 20,
    textAlign: 'left',
    marginTop: 5,
    padding: 3
  },
  admitIcon: {
    justifyContent: 'center',
    height: 40,
    width: 40,
    resizeMode: 'center'
  },
  menuButton: {
    alignSelf: 'flex-end',
    marginRight: 10,
    height: 40,
    width: 40,
    flex: 1
  },
  footer: {
    opacity: 0.6,
    borderColor: COLOR.BASE,
    height: 60,
    maxHeight: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
    flex: 16
  },
  progressionButton: {
    width: 150,
    height: '100%'
  },
  footer_progression: {
    alignSelf: 'flex-start',
    flex: 1,
    maxWidth: 300,
    height: 30,
    justifyContent: 'center'
  },
  footer_HEY_COACH: {
    backgroundColor: COLOR.RED
  },
  footer_COMMUNICATING: {
    backgroundColor: COLOR.LT_BLUE
  },
  footer_OFF: {
    backgroundColor: COLOR.GREY
  },
  footer_CONNECTING: {
    backgroundColor: COLOR.YELLOW
  },
  buttonIcon: {
    width: 50,
    height: 40
  }
});
type CoachsStudentWebConPropType = {
  coachId: UserId;
  studentHuddleUser: HuddleUser;

};


export default function CoachsStudentWebCon(props: CoachsStudentWebConPropType): JSX.Element {

  const dispatch = useDispatch();
  const {

    studentHuddleUser,
    coachId
  } = props;
  const {
    userProfile,
    audioMode,
    heyCoach
  } = studentHuddleUser;
  const studentId = userProfile.userId;


  const videoElem = useRef<HTMLVideoElement>(null);
  const [attachedVideoTrack, setAttachedVideoTrack] = React.useState(null);
  const [attachedAudioTrack, setAttachedAudioTrack] = React.useState(null);


  const audioElem = useRef<HTMLAudioElement>(null);


  const coachVideoConsumers: Record<UserId, Consumer> = useSelector((state: State) => state.webconReducer.coachVideoConsumers);
  const coachAudioConsumers: Record<UserId, Consumer> = useSelector((state: State) => state.webconReducer.coachAudioConsumers);

  const coachVideoConsumer = coachVideoConsumers ? coachVideoConsumers[studentId] : null;
  const coachAudioConsumer = coachAudioConsumers ? coachAudioConsumers[studentId] : null;

  const studentVideoTrack = coachVideoConsumer?.track;


  const studentAudioTrack = coachAudioConsumer?.track;


  // const curriculumStudentInteraction: CurriculumStudentInteraction = useSelector((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
  // const curriculumStudentProgression: CurriculumStudentProgression = useSelector((state: State) => state.curriculumReducer.curriculumStudentProgressionMap[studentId]);

  React.useEffect(() => {
    if (studentHuddleUser?.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED && !studentVideoTrack) {
      debug('huddleCoachConnectsToEnteredStudent useEffect curriculumInteractionSetSyncStart');
      dispatch(huddleCoachConnectsToEnteredStudent(coachId, studentId));
    }
  }, [studentHuddleUser, coachId, studentId, studentVideoTrack]);
  // React.useEffect(() => {
  //   if (coachId && !curriculumStudentInteraction) {
  //     debug('webSocketInit useEffect curriculumInteractionSetSyncStart', coachId, curriculumStudentInteraction);
  //     dispatch(webSocketInit(coachId, coachId));
  //   }
  // }, [studentId, curriculumStudentInteraction]);
  // React.useEffect(() => {
  //   if (coachId && !curriculumStudentProgression) {
  //     debug('useEffect curriculumProgressionSetSyncStart', coachId, curriculumStudentProgression);
  //     dispatch(webSocketInit(coachId, coachId));
  //   }
  // }, [studentId, curriculumStudentProgression]);




  const imageSrc = function imageLocator(imageId) {
    switch (imageId) {
      case 'student1':
        return require('../../assets/student1.png');

      case 'student2':
        return require('../../assets/student2.png');

      case 'student3':
        return require('../../assets/student3.png');

      case 'student4':
        return require('../../assets/student4.png');

      case 'coach':
        return require('../../assets/coach.png');

      default:
        return require('../../assets/student1.png');
    }
  }(studentHuddleUser.userProfile.imageId);


  React.useEffect(() => {
    if (attachedVideoTrack && !studentVideoTrack && videoElem) {
      videoElem.current.srcObject = null;
      setAttachedVideoTrack(false);

    }
    if (!attachedVideoTrack && studentVideoTrack && videoElem && videoElem.current) {
      const stream = new MediaStream;
      setAttachedVideoTrack(true);
      stream.addTrack(studentVideoTrack);
      videoElem.current.srcObject = stream;

      videoElem.current.play()
        .catch((error) => console.error('videoElem.play() failed:%o', error));
    }

  }, [studentVideoTrack, videoElem]);

  React.useEffect(() => {
    debug('attachedAudioTrack', attachedAudioTrack, studentAudioTrack);
    if (attachedAudioTrack && !studentAudioTrack && audioElem) {
      audioElem.current.srcObject = null;
      setAttachedAudioTrack(false);

    }
    if (attachedAudioTrack && studentAudioTrack && audioElem) {
      if (audioMode === AUDIO_MODE.WALKIE_TALKIE) {
        audioElem.current.play()
          .catch((error) => console.error('audioElem.play() failed:%o', error));
      } else {
        audioElem.current.pause();
      }
    }
    if (audioElem && !attachedAudioTrack && studentAudioTrack) {
      debug('studentAudioTrack ', studentAudioTrack);
      const stream = new MediaStream;
      setAttachedAudioTrack(true);
      //console.log('studentAudioTrack',studentAudioTrack);
      stream.addTrack(studentAudioTrack);
      audioElem.current.srcObject = stream;

      if (audioMode === AUDIO_MODE.WALKIE_TALKIE) {
        audioElem.current.play()
          .catch((error) => console.error('audioElem.play() failed:%o', error));
      } else {
        audioElem.current.pause();
      }

    }

  }, [studentAudioTrack, audioMode, audioElem, attachedAudioTrack]);


  const audioMuted = false;

  return (
    <View testID="CoachsStudentWebCon" style={styles.gridColumnContainer}>
      <View testID="body" style={styles.gridRowContainer}>
        <View testID="LeftCol" style={[styles.gridColumnLeft, {backgroundColor: COLOR.WHITE}]}>
          {function () {
            if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVED) {
              return (<Image style={styles.chatSizeImage} source={require('../../assets/mocks/studentArrived.png')}/>);
            }
            if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.INACTIVE) {
              return (<Image style={styles.chatSizeImage} source={require('../../assets/mocks/studentArrived.png')}/>);
            }
            return (
              <CoachChatView studentId={studentId} coachId={coachId}/>);
          }()}
        </View>
        <View testID="RightCol" style={[styles.gridColumnRight, {paddingTop: 5}]}>

          <View style={[styles.gridItemVideo, styles.videoWidth]}>
            <View style={styles.video}>
              <video autoPlay
                ref={videoElem}
                muted
                controls={false}
                style={
                  {
                    width: '180%',
                    maxWidth: 400,
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
              />
              <audio
                ref={audioElem}
                autoPlay
                playsInline
                muted={audioMuted}
                controls={false}
              />
            </View>
            {function () {
              if (!attachedVideoTrack) {
                return <View
                  style={[styles.placeholder, studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.INACTIVE ? styles.placeholderInactive : styles.placeholderActive]}>
                  <View style={styles.placeholderContent}>
                    <Avatar.Image size={48} source={imageSrc}/>
                    <Text style={{
                      color: COLOR.WHITE
                    }}>{studentHuddleUser.userProfile.firstName}</Text>
                    {function () {
                      if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.INACTIVE) {
                        return <Text style={{
                          color: COLOR.WHITE
                        }}>Not Arrived</Text>;
                      }
                    }()}
                  </View>
                </View>;
              }
              if (studentVideoTrack) {
                return <View testID="hasRemoteTracks"/>;
              }
              if (!studentVideoTrack) {
                return <View testID="notHasRemoteTracks"/>;
              }
            }()}
            {function () {
              if (heyCoach) {
                return <Image style={{
                  height: 80,
                  width: 80,
                  zIndex: 400
                }} source={require('../../assets/icons/heyCoach.png')}/>;
              }
            }()}
          </View>
          <View style={styles.gridItemDash}>
            {function () {
              // if (curriculumStudentProgression?.progressionMode !== CURRICULUM.PROGRESSION_MODE.ASSIGNED) {
              //   return <Image style={styles.dashSizeImage} source={require('../../assets/mocks/interestDash.png')}/>;
              // }
              return (<StudentDash studentId={studentId}/>);
            }()}
          </View>

        </View>
      </View>
      <CoachsStudentWebConFooter coachId={coachId} studentHuddleUser={studentHuddleUser}/>
    </View>
  );

}
