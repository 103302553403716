import { all, call, put, takeEvery } from "redux-saga/effects";
import { PARENT } from "../reducer/parentReducer";

import type {CoachId, UserId} from "../../sharedDefs/userDefs";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import { parentCoachAssignStudentServerApi } from "../serverApi";
const debug = debugModule(DEBUG_MODULE.USER.SAGA);

function* parentCoachAssignToStudentSaga(action: {
  type: string;
  coachId: CoachId;
  studentId: UserId;
}): any {
  try {
    const {
      coachId,
      studentId
    } = action;
    debug('parentSessionAdd', coachId, studentId);
    yield call(parentCoachAssignStudentServerApi, {
      coachId,
      studentId
    });
    yield put({
      type: PARENT.COACH_ASSIGN.SUCCESS
    });
  } catch (error) {
    debug('catch an error', error);
    yield put({
      type: PARENT.COACH_ASSIGN.FAILURE,
      error
    });
  }
}

export default function* parentSagas(): Generator<any, any, any> {
  debug('parentSagas Start');
  yield all([takeEvery(PARENT.COACH_ASSIGN.ASSIGN, parentCoachAssignToStudentSaga)]);
}
