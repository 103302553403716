import * as React from "react";
import {View,Text} from "react-native";
import {Button} from "react-native-paper";
import {useDispatch} from "react-redux";
import {navGoto} from "../../redux/reducer/navigationReducer";
import {NAV} from "../../redux/structs/navigationStruct";
import CoachAvailableStudents from "./CoachAvailableStudents";


export default function CoachHome(): JSX.Element {
  const dispatch = useDispatch();
  const onPress = () => {
    dispatch(navGoto(NAV.COACH.CLASS_MODE));
  };
  return <View testID="CoachHome">
    <Text>COACH HOME </Text>
    <Button testID={'startClass'} onPress={onPress}>Start Class</Button>
    <CoachAvailableStudents allowAdmit={false}/>
  </View>;
}
