import invariant from "invariant";

import type {UserId} from "../../sharedDefs/userDefs";
import {addReducerLogging, loggerCopy} from "./common";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {ActionType} from "../../sharedDefs/commonStruct";

const debug = debugModule(DEBUG_MODULE.ADMIN.REDUCER);
export const ADMIN_ACTION = {

  // SESSION: {
  //   SAVE: 'ADMIN_ACTION.SESSION.SAVE',
  //   SUCCESS: 'ADMIN_ACTION.SESSION.SUCCESS',
  //   FAILURE: 'ADMIN_ACTION.SESSION.FAILURE',
  // },
  SESSION: {
    CREATE: {
      REQUEST: 'ADMIN_ACTION.SESSION.CREATE.REQUEST',
      SUCCESS: 'ADMIN_ACTION.SESSION.CREATE.SUCCESS',
      FAILURE: 'ADMIN_ACTION.SESSION.CREATE.FAILURE'
    }
  },
  COACH: {
    ASSESS: {
      APPROVE: 'ADMIN_ACTION.COACH.ASSESS.APPROVE',
      DECLINE: 'ADMIN_ACTION.COACH.ASSESS.DECLINE',
      SUCCESS: 'ADMIN_ACTION.COACH.ASSESS.SUCCESS',
      FAILURE: 'ADMIN_ACTION.COACH.ASSESS.FAILURE'
    }
  }
};
type AdminAction = ActionType & {
    coachId?: UserId;
};
//
// export const adminSessionSave = (session: SessionPreSave): AdminAction => {
//   if (!session) {
//     /* istanbul ignore next */
//     throw new ValidationError('invalid-reducer', session);
//   }
//   return {
//     type: ADMIN_ACTION.SESSION.SAVE,
//     session,
//   };
// };
//
// export const adminCourseSave = (course: CoursePreSave): AdminAction => {
//   if (!course) {
//     /* istanbul ignore next */
//     throw new ValidationError('invalid-reducer', course);
//   }
//   return {
//     type: ADMIN_ACTION.COURSE.SAVE,
//     course,
//   };
// };

export const adminCoachAssessApprove = (coachId: UserId): AdminAction => {
  invariant(coachId, 'invalid-reducer');
  return {
    type: ADMIN_ACTION.COACH.ASSESS.APPROVE,
    coachId
  };
};
export const adminCoachAssessDecline = (coachId: UserId): AdminAction => {
  invariant(coachId, 'invalid-reducer');
  return {
    type: ADMIN_ACTION.COACH.ASSESS.DECLINE,
    coachId
  };
};
export type AdminState = {

};
const initialState: AdminState = {

};

function _adminReducer(state: AdminState = initialState, action: ActionType = {type:''}): AdminState {
  switch (action.type) {
    default:
      return state;
  }
}

function actionForLogger(action: AdminAction) {
  return loggerCopy(action);
}

export default function adminReducer(state: AdminState = initialState, action: ActionType = {type:''}): AdminState {
  const prefix = 'ADMIN_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _adminReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
