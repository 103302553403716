import {ActionType} from "../sharedDefs/commonStruct";

export class ValidationError extends Error {
  values: any;

  constructor(message: string, ...values: any[]) {
    super(message);
    this.values = values;
  }

}
export function ValidateAction(action: ActionType, _params: Array<string>) : void{
  const params = (action.parent)? [..._params,'parent']: _params;
  if (!action || !action.type) {
    throw new ValidationError('null-action', action);
  }

  const keys = Object.keys(action).filter(k => k !== 'type' && k !== 'origin'  && k !== 'timestamp');

  if (keys.length !== params.length) {
    throw new ValidationError(`action-invalid ${JSON.stringify(keys)} should be ${JSON.stringify(params)}`);
  }

  keys.forEach(k => {
    const param = params.find(p => p === k);

    if (!param) {
      throw new ValidationError(`action-invalid ${JSON.stringify(keys)} should be ${JSON.stringify(params)}`);
    }
  });
  keys.forEach(key => {
    const value = action[key];

    if (value === undefined) {
      throw new ValidationError(`action-invalid ${JSON.stringify(action)} key should be defined ${JSON.stringify(key)}`);
    }
  });
}
const _consoleFailure = `padding: 20px; margin-left: 20px; background-color: white; color: red; font-style:  
         italic; border: 1px solid black; font-size: 3em;`;
const _consoleHighlight = `padding: 10px; margin-left: 60px; background-color: white; color: blue; font-style:  
         italic; border: 1px solid black; font-size: 2em;`;
const _consoleSecondary = `padding: 4px; margin-left: 120px; background-color: white; color: green; font-style:  
         italic; border: 1px solid black; font-size: 1.0em;`;
export function consoleFailure(str: string, ...moreArgs: any[]): void {
  console.log(`%c  -  ${str} `, _consoleFailure, ...moreArgs);
}
export function consoleHighlight(str: string, ...moreArgs: any[]) : void {
  console.log(`%c  -  ${str} `, _consoleHighlight, ...moreArgs);
}
export function consoleSecondary(str: string, ...moreArgs: any[]) : void {
  console.log(`%c  -  ${str} `, _consoleSecondary, ...moreArgs);
}



export function validEmail(email:string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validPassword(password:string): boolean {
  return !!password && password.length >=8;
}


// const isEmpty = value => value === undefined || value === null || value === '';
// const join = (rules) => (value, data) => rules.map(rule => rule(value, data))
//   .filter(error => !!error)[0];
//
// // eslint-disable-next-line consistent-return
// export function email(value: string): any {
//   if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
//     return 'Invalid email address';
//   }
// }
//
// export function required(value: any): any {
//   if (isEmpty(value)) {
//     return 'Required';
//   }
//   return undefined;
// }
//
// export function minLength(min: number): any {
//   return (value) => {
//     if (!isEmpty(value) && value.length < min) {
//       return `Must be at least ${min} characters`;
//     }
//     return undefined;
//   };
// }
//
// export function maxLength(max: number): any {
//   return value => {
//     if (!isEmpty(value) && value.length > max) {
//       return `Must be no more than ${max} characters`;
//     }
//     return undefined;
//   };
// }
//
// export function integer(value: any): any {
//   if (!Number.isInteger(Number(value))) {
//     return 'Must be an integer';
//   }
//   return undefined;
// }
//
// export function oneOf(enumeration: Array<any>): any {
//   return (value) => {
//     if (enumeration.indexOf(value) < 0) {
//       return `Must be one of: ${enumeration.join(', ')}`;
//     }
//     return undefined;
//   };
// }
//
// export function match(field: string): any {
//   return (value, data) => {
//     if (data) {
//       if (value !== data[field]) {
//         return 'Do not match';
//       }
//     }
//     return undefined;
//   };
// }
//
// export function phone(value: string): any {
//   if (!/^(\d|\+)[0-9+() -]+$/.test(value)) {
//     return 'Invalid phone format';
//   }
//   return undefined;
// }
//
// export function createValidator(rules: any, section: any, activate: any): any {
//   return (data = {}) => {
//     // eslint-disable-next-line no-param-reassign
//     data = data.toJS ? data.toJS() : data;
//     // eslint-disable-next-line no-param-reassign
//     data = section && data.section ? data[section] : data;
//     if (activate && typeof data[activate] !== 'undefined') {
//       if (!data[activate]) {
//         return {};
//       }
//     }
//     const errors = {};
//     Object.keys(rules)
//       .forEach((key) => {
//         const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
//         const error = rule(data[key], data);
//         if (error) {
//           errors[key] = error;
//         }
//       });
//     return errors;
//   };
// }
