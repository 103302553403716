import type {
  CurriculumStudentInteraction,
  CurriculumStudentProgression,
  CurriculumStudentResponse
} from "./curriculumDefs";
import type {UserId} from "./userDefs";
import {ActionType} from "./commonStruct";
import invariant from "invariant";


export const CHAT_ACTION = {
  ADD: {
    REQUEST: 'CHAT_ACTION.ADD.REQUEST',
    SUCCESS: 'CHAT_ACTION.ADD.SUCCESS',
    FAILURE: 'CHAT_ACTION.ADD.FAILURE'
  },
  INITIALIZE: {
    REQUEST: 'CHAT_ACTION.INITIALIZE.REQUEST',
    SUCCESS: 'CHAT_ACTION.INITIALIZE.SUCCESS',
    FAILURE: 'CHAT_ACTION.INITIALIZE.FAILURE'
  }
};
export const CHAT = {
  EVENT: {
    TEXT: 'TEXT',
    HEY_COACH: 'HEY_COACH',
    INTERACTION: 'INTERACTION',
    RESPONSE: 'RESPONSE',
    ASSIGN_PROGRESSION: 'ASSIGN_PROGRESSION',
    COMPLETE_PROGRESSION: 'COMPLETE_PROGRESSION'
  }
};
export type ChatItemEvent = string;
export type ChatItem = {
  chatItemEvent: ChatItemEvent;
  studentId: UserId;
  coachId: UserId;
  timeStamp?: number;
  dateBlock?: string;
  chatUserId: UserId;
  text?: string;
  confidence?: number;
  curriculumStudentResponse?: CurriculumStudentResponse;
  curriculumStudentInteraction?: CurriculumStudentInteraction;
  curriculumStudentProgression?: CurriculumStudentProgression;
  suggestion?: string;
};
export type ChatAction = ActionType & {
  studentId?: UserId;
  dateBlock?: string;
  chatItem?: ChatItem;
  chatItems?: Array<ChatItem>;
  chatSyncChannel?: any;
  error?: Error;
};



export const getDateBlock = (timeStamp: number): string => new Date(timeStamp).toISOString().substring(0, 10);


export function datedChatItem(chatItem: ChatItem): ChatItem {
  const offset = 0; // Server time Offset TODO

  const timeStamp = chatItem.timeStamp || new Date(Date.now() + offset).getMilliseconds();
  const dateBlock = chatItem.dateBlock || getDateBlock(timeStamp);
  return { ...chatItem,
    timeStamp,
    dateBlock
  };
}

export const chatResponseSuccess = (studentId: UserId, coachId: UserId, chatUserId: UserId, curriculumStudentResponse: CurriculumStudentResponse, curriculumStudentInteraction: CurriculumStudentInteraction): ChatAction => {
  invariant(studentId && coachId && chatUserId, 'invalid-reducer');

  let timeStamp = curriculumStudentResponse.timeStamp;
  if (!timeStamp) {
    timeStamp = Date.now();
  }
  const dateBlock = getDateBlock(timeStamp);
  return {
    type: CHAT_ACTION.ADD.SUCCESS,
    studentId,
    chatItem: datedChatItem({
      chatItemEvent: CHAT.EVENT.RESPONSE,
      studentId,
      coachId,
      chatUserId,
      timeStamp,
      curriculumStudentInteraction,
      curriculumStudentResponse,
      dateBlock
    })
  };
};
