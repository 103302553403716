import { Platform } from "react-native";
// import { useFonts } from 'expo-font';
//
// useFonts({
//   'GothamRounded-Bold': require('../assets/fonts/GothamRounded-Bold.otf'),
//   'GothamRounded-LightItalic': require('../assets/fonts/GothamRounded-LightItalic.otf'),
//   'Poppins-Medium': require('../assets/fonts/Poppins-Medium.ttf'),
//   'Poppins-Italic': require('../assets/fonts/Poppins-Italic.ttf'),
//   'Poppins-Bold': require('../assets/fonts/Poppins-Bold.ttf'),
// });
const typography = {
  web: {
    regular: {
      fontFamily: 'Poppins-Medium',
      fontWeight: 'normal'
    },
    studentBold: {
      fontFamily: 'GothamRounded-Bold',
      fontWeight: 'bold'
    },
    bold: {
      fontFamily: 'Poppins-Bold',
      fontWeight: 'normal'
    }
  }
};
export default Platform.select(typography);