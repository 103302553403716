//region Header
// noinspection DuplicatedCode

import * as React from "react";

import {Button, Checkbox, IconButton, List, Text} from 'react-native-paper';
import {StyleSheet, View} from "react-native";

import {queryProgressionSets} from "../redux/queries/SessionQueries";
import {Assessment, CURRICULUM, CurriculumStudentResponse, Problem, ProgressionSet} from "../sharedDefs/curriculumDefs";
import {queryAssessment} from "../redux/queries/CurriculumQueries";
import {ProgressionSetUtil} from "../sharedDefs/ProgressionSetUtil";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";

import WidgetInventory from "../widgets/WidgetInventory";
import ResponseDataUtil from "./ResponseDataUtil";
import {COLOR} from "../utils/UiConstants";
import DropDown from "../components/FormControls/DropDown";
import {adminAssessmentSaveApi} from "../redux/serverApi";


const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column'
  },
  gridColumnContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flex: 1
  },
  gridRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'row',
    flex: 1
  },
  coachView: {
    flex: 1,
    height: 250
  },
  studentView: {
    flex: 1,
    height: 250
  },
  problemList: {
    flex: 1,
    marginTop: 30,
    width: 200
  }
});
//endregion

export default function AssessmentWorkbench(): JSX.Element {
  // region state

  const [dev, setDev] = React.useState<boolean>(true);
  const [demo, setDemo] = React.useState<boolean>(false);
  const [staging, setStaging] = React.useState<boolean>(false);
  const [production, setProduction] = React.useState<boolean>(false);
  const [showLevelDropDown, setShowLevelDropDown] = React.useState<boolean>(false);

  const [progressionSetList, setProgressionSetList] = React.useState([]);

  const [problemList, setProblemList] = React.useState<Array<Problem>>([]);


  const [curriculumStudentResponse, setCurriculumStudentResponse] = React.useState<CurriculumStudentResponse>(null);


  //QQQ
  const [levelId, setLevelId] = React.useState<string>(null);
  const [selectedProgressionSet, setSelectedProgressionSet] = React.useState<ProgressionSet>(null);
  const [selectedProblem, setSelectedProblem] = React.useState<Problem>(null);
  const [problemChoices, setProblemChoices] = React.useState<Array<Problem>>(null);

  //endregion
  // region callBacks
  React.useEffect(() => {
    if (selectedProgressionSet) {
      const _problems: Array<Problem> = ProgressionSetUtil.createProblemList(selectedProgressionSet);
      setProblemChoices(_problems);
    } else {
      setProblemChoices([]);
    }
  }, [selectedProgressionSet]);

  React.useEffect(() => queryProgressionSets(setProgressionSetList), []);

  React.useEffect(() => {
    (async () => {
      console.log('queryAssessment ', levelId);
      const assessment = await queryAssessment(levelId);
      console.log('queryAssessment ', levelId, assessment);
      if (assessment) {
        setProblemList(assessment.problemList);
      } else {
        setProblemList([]);
      }
    })();
  }, [levelId]);

  const saveAssessment = async () => {
    const assessment: Assessment = {
      problemList,
      levelId,
      assessmentName: 'Assessment Level ' + levelId,
      timeStamp: Date.now()
    };
    await adminAssessmentSaveApi({
      assessment,
      dev,
      demo,
      production,
      staging
    });


  };


  // endregion

  console.log('selectedProblem', selectedProblem);
  return (
    <View style={styles.root} testID="CurriculumWorkbench">
      {/* region topBar */}
      <View style={[styles.gridRowContainer, {maxHeight: 80, backgroundColor: '#DDDDDD'}]}>
        <DropDown label="Level" value={levelId}
          mode={"outlined"}
          visible={showLevelDropDown}
          showDropDown={() => setShowLevelDropDown(true)}
          onDismiss={() => {
            setShowLevelDropDown(false);
          }}
          setValue={(_levelId) => {
            setLevelId(_levelId);

          }}
          list={Object.keys(CURRICULUM.LEVELS).map(levelId => ({
            label: CURRICULUM.LEVELS[levelId],
            value: levelId
          }))}/>

        <View style={gridStyles.gridColumnContainer}>
          <Text style={textStyles.titleBar}>Assessment Editor</Text>
        </View>
      </View>
      {/* endregion*/}
      <View style={styles.gridRowContainer}>


        <View style={styles.gridColumnContainer}>
          {/* region saveBox */}
          <View style={[gridStyles.gridRowContainer, {maxHeight: 200, backgroundColor: COLOR.GREY}]}>
            <View style={gridStyles.gridColumnContainer}>
              <View style={gridStyles.formRowContainer}>
                <Checkbox
                  status={dev ? 'checked' : 'unchecked'}
                  onPress={() => {
                    setDev(!dev);
                  }}
                />
                <Text>Dev</Text>
              </View>
              <View style={gridStyles.formRowContainer}>
                <Checkbox
                  status={demo ? 'checked' : 'unchecked'}
                  onPress={() => {
                    setDemo(!demo);
                  }}
                />
                <Text>Demo</Text>
              </View>
              <View style={gridStyles.formRowContainer}>
                <Checkbox
                  status={staging ? 'checked' : 'unchecked'}
                  onPress={() => {
                    setStaging(!staging);
                  }}
                />
                <Text>Staging</Text>
              </View>
              <View style={gridStyles.formRowContainer}>
                <Checkbox
                  status={production ? 'checked' : 'unchecked'}
                  onPress={() => {
                    setProduction(!production);
                  }}
                />
                <Text>Production</Text>
              </View>
            </View>
            <View style={gridStyles.gridColumnContainer}>
              <Button icon="content-save" mode="contained" onPress={async () => {
                await saveAssessment();
              }}>
                Save
              </Button>
            </View>
          </View>

          {/* endregion */}
          {/* region Progression List  Left Column */}

          <List.Section title="ProgressionSets">
            {progressionSetList.map((_progressionSet: ProgressionSet) => {
              return (
                <List.Accordion
                  key={_progressionSet.progressionSetName}
                  title={_progressionSet.progressionSetName}
                  left={props => <List.Icon {...props} icon="folder"/>}
                  expanded={selectedProgressionSet?.progressionSetId === _progressionSet.progressionSetId}

                  onPress={() => {
                    if (selectedProgressionSet?.progressionSetId === _progressionSet.progressionSetId) {
                      setSelectedProblem(null);

                      setSelectedProgressionSet(null);
                    } else {
                      setSelectedProblem(null);

                      setSelectedProgressionSet(_progressionSet);
                    }
                  }}>
                  {
                    (selectedProgressionSet?.progressionSetId === _progressionSet.progressionSetId) &&
                    problemChoices.map(_problem => {
                      return <List.Item
                        left={props => <IconButton
                          {...props}
                          icon="plus-thick"
                          color={COLOR.GREEN}
                          size={20}
                          onPress={() => {
                            let p;
                            if (problemList) {
                              p = [...problemList, _problem];
                            } else {
                              p = [_problem];
                            }
                            setSelectedProblem(_problem);
                            setProblemList(p);
                          }
                          }
                        />}
                        key={_problem.problemId}
                        style={{backgroundColor: (_problem === selectedProblem) ? COLOR.RED : COLOR.WHITE}}
                        title={JSON.stringify(_problem.params.prompts)}
                        onPress={
                          () => {
                            setSelectedProblem(_problem);
                          }
                        }/>;
                    })
                  }
                </List.Accordion>
              );
            })
            }
          </List.Section>
        </View>
        {/* endregion */}
        {/* region Widget Iphone*/}
        <View style={[styles.gridColumnContainer]}>
          <Text style={textStyles.labelText}>Problem Preview</Text>
          {
            selectedProblem?.widgetSetId && (
              <>
                <Button onPress={() => {
                  let p;
                  if (problemList) {
                    p = [...problemList, selectedProblem];
                  } else {
                    p = [selectedProblem];
                  }
                  setProblemList(p);
                }}>Add Problem to Assessment</Button>

                <View style={{width: 425, height: 425}}>
                  {
                    React.createElement(WidgetInventory(selectedProblem?.widgetSetId).student, {
                      key: 'student' + selectedProblem?.widgetSetId,
                      curriculumStudentInteraction: {
                        problem: selectedProblem,
                        position: 0,

                        interactionId: '', studentId: 'null', coachId: 'null',
                        widgetSetId: selectedProblem?.widgetSetId
                      },
                      inWorkbench: true,
                      curriculumStudentResponse: ResponseDataUtil.createCurriculumStudentResponse(selectedProblem, "IID"),
                      onResponse: (key: string, value: string, index?: number) => {
                        const _curriculumStudentResponse = ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);
                        console.log('Response', _curriculumStudentResponse);
                        setCurriculumStudentResponse(_curriculumStudentResponse);
                      }
                    })
                  }
                </View>

              </>
            )
          }

        </View>
        {/* endregion */}
        {/* region Widget Views*/}
        <View style={[styles.gridColumnContainer, {minWidth: 790, width: 790}]}>


          <View style={{maxWidth: 200}}>
            <Text style={textStyles.labelText}>Problem List</Text>
            {
              problemList?.map((_problem, index) => {
                return (
                  <View style={{flexDirection: 'row'}} key={'coach' + index + '_' + _problem.problemId}>
                    <IconButton
                      style={{marginRight: 0, marginLeft: 0}}
                      icon="minus-circle-outline"
                      color={COLOR.RED}
                      size={20}
                      onPress={() => {
                        const newProblemList = problemList.filter(p => p.problemId !== _problem.problemId);
                        console.log('newProblemList', newProblemList.length, problemList.length);
                        setSelectedProblem(null);
                        setProblemList(newProblemList);
                      }
                      }
                    />

                    {
                      React.createElement(WidgetInventory(_problem.widgetSetId).coach, {
                        key: 'coach' + _problem.problemId,
                        curriculumStudentInteraction: {
                          problem: _problem,
                          position: 0,
                          interactionId: '', studentId: 'null', coachId: 'null',
                          widgetSetId: _problem.widgetSetId
                        },
                        curriculumStudentResponse: ResponseDataUtil.createCurriculumStudentResponse(_problem, "IID"),
                      })
                    }
                    <View style={{flexDirection: 'column', width: 30}}>
                      {index !== 0 && <IconButton
                        style={{margin: 0}}
                        icon="arrow-up-circle-outline"
                        color={COLOR.GREEN}
                        size={20}
                        onPress={() => {


                          const newProblemList = [...problemList];
                          const p = newProblemList[index];
                          newProblemList[index] = newProblemList[index - 1];
                          newProblemList[index - 1] = p;
                          setProblemList(newProblemList);
                        }
                        }
                      />}

                      {index < problemList.length - 1 &&
                      <IconButton
                        style={{margin:0}}
                        icon="arrow-down-circle-outline"
                        color={COLOR.GREEN}
                        size={20}
                        onPress={() => {
                          const newProblemList = [...problemList];
                          const p = newProblemList[index];
                          newProblemList[index] = newProblemList[index + 1];
                          newProblemList[index + 1] = p;
                          setProblemList(newProblemList);
                        }
                        }
                      />}
                    </View>
                  </View>
                );
              })
            }

          </View>

        </View>
        {/* endregion */}

      </View>
    </View>
  );

}
