import * as React from "react";
import {Text, View} from "react-native";

import {useDispatch, useSelector} from "react-redux";
import CplButton from "../../components/FormControls/CplButton";
import CplRadioGroup from "../../components/FormControls/CplRadioGroup";
import CplTextField from "../../components/FormControls/CplTextField";
import {navigateTo} from "../../NavigationAction";
import type {State} from "../../redux/reducer";
import {userStudentCreateRequest} from "../../redux/reducer/userReducer";
import {NAV} from "../../redux/structs/navigationStruct";
import type {UserProfilePreSave} from "../../sharedDefs/userDefs";
import {PROFILE, ROLE} from "../../sharedDefs/userDefs";
import {valueLabelArray} from "../../utils/dataUtils";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";
import {CURRICULUM} from "../../sharedDefs/curriculumDefs";

const debug = debugModule(DEBUG_MODULE.USER.CONTAINER);


export default function ParentChildForm(): JSX.Element {
  const dispatch = useDispatch();
  const userId = useSelector((state: State) => state.userReducer.userId);
  const userData = useSelector((state: State) => state.userReducer.userData);

  const initialValues = {firstName:'', lastName:'',   levelId: CURRICULUM.LEVELS.G1,birthDate: undefined,goal:undefined, interests:undefined};
  const defaultValues = REACT_APP_PREFILL ? {
    firstName: REACT_APP_PREFILL,
    lastName: REACT_APP_PREFILL,
    levelId: CURRICULUM.LEVELS.G1,
    birthDate: new Date(2015, 1, 1),
    goal: PROFILE.GOAL.ACCELERATE,
    interests: 'prefill interests'
  } : initialValues;
  const [formObject, setFormObject] = React.useState(defaultValues);
  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    const newFormObject: any = { ...formObject,
      ...f
    };
    setFormObject(newFormObject);
    debug('NEW formObject', newFormObject);
  },[formObject]);
  const errors = {};

  const submit = () => {
    let parentId = userId;

    if (parentId === null) {
      parentId = userData && userData.userProfile && userData.userProfile.userId;
    }

    const userProfile: UserProfilePreSave = {
      email: '',
      parentIds: [parentId],
      levelId: formObject.levelId,
      role: ROLE.STUDENT,
      firstName: formObject.firstName,
      lastName: formObject.lastName,
      birthDateTimestamp: formObject.birthDate && formObject.birthDate.getTime(),
      goal: formObject.goal,
      essay: formObject.interests,
      occupation: 'Student',
      coachIds: [],
      studentIds:[]
    };
    dispatch(userStudentCreateRequest(userProfile));
    navigateTo(NAV.PARENT.HOME);
  };

  return <View testID="ParentChildForm">
    <Text>ParentChildForm Form</Text>
    <View>
      <View>
        <View>
          <View>


            <Text>
                    Your Child
            </Text>

            <View>
              <CplTextField name="firstName" label="First Name" formObject={formObject} onChange={onChange} errors={errors} />
              <CplTextField name="lastName" label="Last Name" formObject={formObject} onChange={onChange} errors={errors} />
            </View>
            <CplTextField name="birthDate" label="BirthDate" formObject={formObject} onChange={onChange} errors={errors} />

            <CplRadioGroup label="Goal of enrolling your child" name="goal" formObject={formObject} onChange={onChange} errors={errors} radioButtons={valueLabelArray(PROFILE.GOAL, PROFILE.GOAL_LABEL)} />

            <View>
              <CplTextField name="interests" label="What are some of your child's interests"  multiline formObject={formObject} onChange={onChange} errors={errors} />
            </View>

            <CplButton testID="submit" onPress={() => submit()}>
                    Submit
            </CplButton>

          </View>
        </View>
      </View>
    </View>
  </View>;
}
