import * as React from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { COLOR } from "../utils/UiConstants";
import { DEBUG_MODULE, debugModule } from "../utils/debugModule";
import {useTestPage} from "../utils/environment";
const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
const styles = StyleSheet.create({
  buttonRow: {
    flex: 1,
    flexDirection: 'row'
  },
  smallButton: {
    backgroundColor: COLOR.GREEN,
    alignContent: 'center',
    width: 45,
    borderRadius: 10,
    margin: 10,
    height: 45
  },
  buttonText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 35,
    alignSelf: 'center'
  },
  hidden: {
    width: 0,
    height: 0
  }
});
export type CompareKeypadProps = {
  inWorkbench:boolean,
  onKeyPress: (key: string) => void;
};
export default function CompareKeypad(props: CompareKeypadProps): JSX.Element {
  const {
    onKeyPress,
    inWorkbench
  } = props;
  const onTyped = React.useCallback((e: {
    readonly nativeEvent: {
      readonly key: string;
    };
  }) => {
    const {
      key
    } = e.nativeEvent;
    debug('onTyped', key);

    if (key === '<' || key === '>' || key === '=') {
      onKeyPress(key);
    }
  },[]);
  const textInputRef = React.useRef(null);
  return <View style={styles.buttonRow}>

    <Pressable style={styles.smallButton} testID="button_LT" onPress={() => {
      onKeyPress('<');
    }}>
      <Text style={styles.buttonText}>{'<'}</Text>
    </Pressable>
    <Pressable style={styles.smallButton} testID="button_EQ" onPress={() => {
      onKeyPress('=');
    }}>
      <Text style={styles.buttonText}>=</Text>
    </Pressable>
    <Pressable style={styles.smallButton} testID="button_GT" onPress={() => {
      onKeyPress('>');
    }}>
      <Text style={styles.buttonText}>{'>'}</Text>
    </Pressable>

    {!useTestPage && !inWorkbench && <TextInput testID="textInput" ref={textInputRef} style={styles.hidden} autoFocus onBlur={() => {
      textInputRef?.current?.focus();
    }} onKeyPress={onTyped} />}

  </View>;
}
