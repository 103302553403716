import type {StudentId, CoachId, UserId} from "./userDefs";

import {ActionType} from "./commonStruct";
import invariant from "invariant";


export const CURRICULUM_ACTION = {
  STUDENT_SUMMARY: {
    SYNC: 'CURRICULUM_ACTION.STUDENT_SUMMARY.SYNC',
    FETCH: 'CURRICULUM_ACTION.STUDENT_SUMMARY.FETCH',
  },
  STUDENT_RESPONSE: {
    KV_PERFORM: 'CURRICULUM_ACTION.STUDENT_RESPONSE.KV_PERFORM',
    PERFORM: 'CURRICULUM_ACTION.STUDENT_RESPONSE.PERFORM',
    UPDATE: 'CURRICULUM_ACTION.STUDENT_RESPONSE.UPDATE',
    SUCCESS: 'CURRICULUM_ACTION.STUDENT_RESPONSE.SUCCESS',
    FAILURE: 'CURRICULUM_ACTION.STUDENT_RESPONSE.FAILURE'
  },
  SUBMIT: {
    PERFORM: 'CURRICULUM_ACTION.SUBMIT.PERFORM',
    UPDATE: 'CURRICULUM_ACTION.SUBMIT.UPDATE',
    SUCCESS: 'CURRICULUM_ACTION.SUBMIT.SUCCESS',
    FAILURE: 'CURRICULUM_ACTION.SUBMIT.FAILURE'
  },

  PROGRESSION_SET: {
    ASSIGN: {
      REQUEST: 'CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.REQUEST',
      SEND: 'CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.SEND',
      SUCCESS: 'CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.FAILURE'
    },
    PROBLEM_ADVANCE: {
      REQUEST: 'CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.REQUEST',
      SUCCESS: 'CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.FAILURE'
    },
    COMPLETED: {
      REQUEST: 'CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.REQUEST',
      SUCCESS: 'CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.FAILURE'
    },
    ADVANCE_SET: {
      REQUEST: 'CURRICULUM_ACTION.PROGRESSION_SET.ADVANCE_SET.REQUEST',
      SUCCESS: 'CURRICULUM_ACTION.PROGRESSION_SET.ADVANCE_SET.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.PROGRESSION_SET.ADVANCE_SET.FAILURE'
    }
  },
  INTERACTION: {
    ASSIGN: {
      REQUEST: 'CURRICULUM_ACTION.INTERACTION.ASSIGN.REQUEST',
      SEND: 'CURRICULUM_ACTION.INTERACTION.ASSIGN.SEND',
      RECIEVE: 'CURRICULUM_ACTION.INTERACTION.ASSIGN.RECIEVE',
      SUCCESS: 'CURRICULUM_ACTION.INTERACTION.ASSIGN.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.INTERACTION.ASSIGN.FAILURE'
    },
    WIDGET_SET_CHANGE: {
      REQUEST: 'CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.REQUEST',
      SUCCESS: 'CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.SUCCESS',
      FAILURE: 'CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.FAILURE'
    },
  },
};
export const CURRICULUM = {
  LEVELS: {
    PK: 'PK',
    K: 'K',
    G1: 'G1',
    G2: 'G2',
    G3: 'G3',
    G4: 'G4' ,
    G5: 'G5' ,

  },
  DOMAINS: {
    "Math.CC": 'Counting & Cardinality',
    "Math.OA":'Operations and Algebraic Thinking',
    "Math.NBT":'Numbers and Operations Base 10',
    "Math.MD":'Measurement and Data',
    "Math.G":'Geometry',
    "Math.NFA":'Number & Operations—Fractions'
  },
  SUBJECTS: {
    MATH: 'MATH'
  },
  RESPONSE_STATE: {
    CORRECT: 'CORRECT',
    INCORRECT: 'INCORRECT',
    INVALID: 'INVALID',
    NO_RESPONSE: 'NO_RESPONSE',
    INCOMPLETE: 'INCOMPLETE'
  },
  CONFIDENCE: {
    VERY_HIGH: 'Very High',
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
    VERY_LOW: 'Very Low'
  },
  SAMPLE: {
    ALL: 'All',
    RANDOM: 'Random',
    FIRST: 'First',
    LAST: 'Last'
  },
  PROGRESSION_MODE: {
    ASSIGNED: 'PROGRESSION_MODE.ASSIGNED',
    COMPLETED: 'PROGRESSION_MODE.COMPLETED'
  }
};
export type InteractionId = string;
export type ProgressionMode = string;
export type ProgressionSetId = string;
export type ProblemId = string;
export type CourseId = string;
export type DomainId = string;
export type StandardId = string;

export type WidgetSetId = string;
export type ResponseState = string;
export type SubjectType = string;
export type LevelType = string;
export type ConfidenceType = string;
export type SampleType = string;
export type Domain = {
  domainId: DomainId;
  domainPostfix: string;
  domainLabel: string;
  domainDescription: string;
  subjectId: string;
};
export type Standard = {
  standardId: StandardId;
  standardNumber: number;
  standardName: string;
  standardDescription: string;
  subjectId: string;
  levelId: string;
  domainId: string;
  domainLabel: string;
  domainDescription: string;
};
export type ProblemGenerationParams =Record<string,any> | Array<Record<string,any>>;
export type ResponseKeyValues = {
  set?: Record<string, string>;
  setList?: Array<Record<string, string>>;
};
export type ProblemParams = {
  prompts: Record<string, string>;
  expectedResponseKeyValues: ResponseKeyValues;
};
export type Problem = {
  problemId: ProblemId;
  widgetSetId: WidgetSetId;
  widgetIdList: Array<WidgetSetId>;
  problemOrder: number;
  params: ProblemParams;
  progressionSetId: string;
  imageUrl?: string;
};
export type CurriculumStudentResponse = {
  interactionId: InteractionId;
  responseKeyValues: ResponseKeyValues;
  // responseItems: Array<string>,
  responseState: ResponseState;
  confidence?: ConfidenceType;
  timeStamp: number;
};

export type CurriculumStudentInteraction = {
  interactionId: InteractionId;
  studentId: UserId;
  coachId: UserId;
  widgetSetId: WidgetSetId;
  problem: Problem;
  position: number;
};

export type ProgressionSet = {
  progressionSetId: ProgressionSetId;
  progressionSetName: string;
  progressionSetOrder: number;
  standardId: string;
  standardNumber: number;
  standardName: string;
  standardDescription: string;
  widgetSetId: WidgetSetId;
  widgetIdList: Array<WidgetSetId>;
  subjectId: string;
  levelId: string;
  domainId: string;
  domainLabel: string;
  domainPostfix: string;
  domainDescription: string;
  instructions: string;
  problemGenerationFunction:string;
  problemGenerationParams:ProblemGenerationParams,
  numberOfProblems: number;
  problemRandomSeed:string;
  problemList: Array<Problem>;
  timeStamp:number;
};
export type Assessment = {
  levelId: string;
  assessmentName:string;
  problemList: Array<Problem>;
  timeStamp:number;
}

export type CurriculumStudentProgression = {
  studentId: UserId;
  progressionSet?: ProgressionSet;
  assessment?: Assessment;
  problemList: Array<Problem>;
  progressionMode: ProgressionMode;
};

export type CurriculumStudentProgressionSummary = {
  studentId: UserId;
  progressionSetId: ProgressionSetId;
  attempts: number;
  accurateCount: number;
  timeTotal: number;
  maxTime: number;
  confidenceVeryHigh:number;
  confidenceHigh:number;
  confidenceMedium:number;
  confidenceLow:number;
  confidenceVeryLow:number;
};

export type CurriculumResponseDetail = {
  studentId: StudentId;
  coachId: CoachId;
  problem: Problem;
  responseKeyValues: ResponseKeyValues;
  curriculumStudentResponseHistory: Array<CurriculumStudentResponse>;
  confidence?: ConfidenceType;
  elapsedTime: number;
  startTimestamp?: number;
  finishTimestamp?: number;
  responseState: ResponseState;
  widgetSetId: WidgetSetId;

  progressionSetId: ProgressionSetId;
};

export type CurriculumAction = ActionType & {
  studentId: StudentId;
  coachId: CoachId;
  interactionId?: InteractionId;
  curriculumStudentProgressionSummary?: CurriculumStudentProgressionSummary,
  progressionSetId?: ProgressionSetId;
  assessmentId?: string;
  // progressionSetId?: ProgressionSetId;
  curriculumStudentProgression?: CurriculumStudentProgression;
  curriculumStudentInteraction?: CurriculumStudentInteraction;
  problem?: Problem;
  problemList?: Array<Problem>;
  position?: number;
  curriculumStudentResponse?: CurriculumStudentResponse;
  widgetSetId?: WidgetSetId;
  key?:string;
  value?:string;
  index?:number;
  error?: Error;
};


export const  curriculumWidgetSetChangeRequest = (studentId: StudentId,  coachId:CoachId,
  curriculumStudentInteraction: CurriculumStudentInteraction,
  widgetSetId:WidgetSetId) : CurriculumAction =>{
  invariant(studentId && curriculumStudentInteraction && widgetSetId, 'invalid-reducer curriculumProgressionSetAssignRequest ' +
    !!studentId+' '+!!  curriculumStudentInteraction+' '+widgetSetId);


  return {
    type: CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.REQUEST,
    studentId,
    coachId,
    curriculumStudentInteraction,
    widgetSetId
  };
};


export const  curriculumWidgetSetChangeSuccess = (studentId: StudentId,  coachId:CoachId,
  curriculumStudentInteraction: CurriculumStudentInteraction,
  widgetSetId:WidgetSetId) : CurriculumAction =>{
  invariant(studentId && curriculumStudentInteraction && widgetSetId, 'invalid-reducer curriculumProgressionSetAssignRequest ' +
    !!studentId+' '+!!  curriculumStudentInteraction+' '+widgetSetId);


  return {
    type: CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.SUCCESS,
    studentId,
    coachId,
    curriculumStudentInteraction,
    widgetSetId
  };
};


export const  curriculumProgressionSetAssignSuccess = (studentId: StudentId,  coachId:CoachId,
  curriculumStudentProgression : CurriculumStudentProgression,
  curriculumStudentInteraction: CurriculumStudentInteraction,
  curriculumStudentResponse: CurriculumStudentResponse) : CurriculumAction =>{
  invariant(studentId && curriculumStudentProgression && curriculumStudentInteraction && curriculumStudentResponse, 'invalid-reducer curriculumSubmitPerform ' +
    !!studentId+' '+!! curriculumStudentResponse+' '+!! curriculumStudentInteraction+' '+!!curriculumStudentProgression);

  invariant(curriculumStudentResponse.interactionId === curriculumStudentInteraction.interactionId,
    'Interaction mismatch  Response= '+curriculumStudentResponse.interactionId +' Interaction='
    + curriculumStudentInteraction.interactionId);
  return {
    type: CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.SUCCESS,
    studentId,
    coachId,
    curriculumStudentProgression,
    curriculumStudentInteraction,
    curriculumStudentResponse
  };
};



export const curriculumProgressionSetProblemAdvanceSuccess = (studentId: StudentId, coachId: CoachId,
  curriculumStudentResponse: CurriculumStudentResponse, curriculumStudentInteraction: CurriculumStudentInteraction): CurriculumAction => {
  invariant(studentId && coachId && curriculumStudentResponse && curriculumStudentInteraction , 'invalid-reducer curriculumSubmitPerform ' +
    !!studentId+' '+!! coachId+' '+!! curriculumStudentResponse+' '+!! curriculumStudentInteraction);

  if (curriculumStudentInteraction){
    invariant(curriculumStudentResponse, 'curriculumProgressionSetProblemAdvanceSuccess ILLEGAL STATE '+studentId);
    if (curriculumStudentInteraction.problem) {



      invariant(curriculumStudentResponse?.responseKeyValues?.set
        || curriculumStudentResponse?.responseKeyValues?.setList, 'ILLEGAL STATE '+JSON.stringify(curriculumStudentResponse,null,2));
      invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction.interactionId, 'interaction mismatch1');
    }
  }

  return {
    type: CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.SUCCESS,
    studentId,
    coachId,
    curriculumStudentInteraction,
    curriculumStudentResponse
  };
};


export const curriculumInteractionAssignSuccess = (studentId: StudentId, coachId: CoachId,
  curriculumStudentResponse: CurriculumStudentResponse, curriculumStudentInteraction: CurriculumStudentInteraction): CurriculumAction => {
  invariant(studentId && coachId && curriculumStudentResponse && curriculumStudentInteraction , 'invalid-reducer curriculumSubmitPerform ' +
    !!studentId+' '+!! coachId+' '+!! curriculumStudentResponse+' '+!! curriculumStudentInteraction);
  if (curriculumStudentInteraction){
    invariant(curriculumStudentResponse, 'ILLEGAL STATE '+studentId);
    if (curriculumStudentInteraction.problem) {

      invariant(curriculumStudentResponse?.responseKeyValues?.set || curriculumStudentResponse?.responseKeyValues?.setList,
        'ILLEGAL STATE '+JSON.stringify(curriculumStudentResponse,null,2));

      invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction.interactionId, 'interaction mismatch2');
    }
  }
  return {
    type: CURRICULUM_ACTION.INTERACTION.ASSIGN.SUCCESS,
    studentId,
    coachId,
    curriculumStudentInteraction,
    curriculumStudentResponse
  };
};


export type WidgetKeyResponseType = (key: string, value: string, index?: number) => void;

export type WidgetCoachPropsType = {
  curriculumStudentInteraction: CurriculumStudentInteraction;
  curriculumStudentResponse: CurriculumStudentResponse;
};
