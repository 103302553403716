import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import {Button, Colors, ProgressBar} from "react-native-paper";
// import styles from './user-jss';


const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
  progressWrapper: {
    alignSelf: 'center',
    maxWidth: 500,
    minWidth: 300,
    minHeight: 30,
    maxHeight: 30,
    marginBottom: 20
  },
  progress: {
    minHeight: 20,
    borderRadius: 10
  },

  actionButton: {
    maxWidth: 200,
    margin: 10,
    marginRight: 40,
    alignSelf: 'flex-end'
  },
  backButton: {
    maxWidth: 200,
    alignSelf: 'flex-start',
    margin: 10
  },
});


export default function RegistrationProgress(props: { page: number, steps: number }): JSX.Element {
  const {page, steps} = props;

  return <View style={styles.progressWrapper}>
    <ProgressBar style={styles.progress} progress={(page + 1) / steps} color={Colors.red800}/>
    <Text>Step {(page + 1)} of {steps}</Text>
  </View>;

}


export function RegistrationProgressButtons(props: { valid:boolean, page: number, steps: number, setPage: (p: number) => void , submitForm?:()=> void}): JSX.Element {
  const {valid,page, steps, setPage,submitForm} = props;

  return <View style={{flexDirection:"row", justifyContent:'space-between'}}>

    {
      (page>0) && <Button style={styles.backButton} icon="arrow-left" onPress={() => {
        setPage(page - 1);
      }}>
        Back
      </Button>}
    {(page < (steps - 1) ) && <Button disabled={!valid} style={styles.actionButton} mode="contained" testID="continue" onPress={() => {
      setPage(page + 1);
    }}>
      Continue
    </Button>
    }
    {(page == (steps - 1)) &&
      <Button disabled={!valid}  style={styles.actionButton} mode="contained" testID="continue" onPress={() => {
        submitForm();
      }}>
        Submit
      </Button>
    }
  </View>;

}
