
import { combineReducers } from "redux";
import type { ChatState } from "../structs/chatStruct";
import type { CurriculumState } from "../structs/curriculumStruct";
import type { HuddleState } from "../structs/huddleStruct";

import type { SpeechState } from "../structs/speechStruct";
import type { UserState } from "../structs/userStruct";
import type { WsState } from "../structs/webSocketStruct";
import type { AdminState } from "./adminReducer";
import adminReducer from "./adminReducer";
import chatReducer from "./chatReducer";
import curriculumReducer from "./curriculumReducer";
import huddleReducer from "./huddleReducer";

import type { LoggingState } from "./loggingReducer";
import loggingReducer from "./loggingReducer";
import type { NavigationState } from "./navigationReducer";
import navigationReducer from "./navigationReducer";
import speechReducer from "./speechReducer";
import wsReducer from "./wsReducer";
import userReducer from "./userReducer";
import webconReducer from "./webconReducer";
import type { WebconState } from "../structs/webconStruct";

export type State = {
  loggingReducer: LoggingState;
  userReducer: UserState;

  navigationReducer: NavigationState;
  huddleReducer: HuddleState;
  adminReducer: AdminState;
  curriculumReducer: CurriculumState;
  chatReducer: ChatState;
  speechReducer: SpeechState;
  wsReducer: WsState;
  webconReducer: WebconState;
};
export default (combineReducers({
  loggingReducer,
  userReducer,

  navigationReducer,
  huddleReducer,
  adminReducer,
  curriculumReducer,
  chatReducer,
  speechReducer,
  wsReducer,
  webconReducer
}) );
