import * as React from "react";
import {SafeAreaView, StyleSheet, Text, View} from "react-native";
import {Avatar, Button, Colors, ProgressBar, Surface, TextInput} from "react-native-paper";
import {useDispatch} from "react-redux";
import {parentRegisterRequest, ParentRegistrationObject} from "../../redux/reducer/userReducer";
import {PROFILE} from "../../sharedDefs/userDefs";

// import styles from './user-jss';
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";
import {gridStyles, textStyles} from "../../widgets/WidgetStyles";


import type {TextStyleProp, ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {COLOR} from "../../utils/UiConstants";
import DropDown from "../../components/FormControls/DropDown";

import Dropzone from 'react-dropzone';
import RegistrationProgress, {RegistrationProgressButtons} from "./RegistrationProgress";
import {validEmail, validPassword} from "../../utils/validation";

const debug = debugModule(DEBUG_MODULE.USER.AUTH);


const styles = StyleSheet.create({
  root: {
    width: '100%'
  },
  progressWrapper: {
    alignSelf: 'center',
    maxWidth: 500,
    minWidth: 300,
    minHeight: 30,
    maxHeight: 30,
    marginBottom: 20
  },
  progress: {
    minHeight: 20,
    borderRadius: 10
  },

  actionButton: {
    maxWidth: 200,
    margin: 10,
    marginRight: 40,
    alignSelf: 'flex-end'
  },
  backButton: {
    maxWidth: 200,
    alignSelf: 'flex-start',
    margin: 10
  },
});
const defaultValues: ParentRegistrationObject = REACT_APP_PREFILL ? {
  email: REACT_APP_PREFILL + '@gmail.com',
  password: '12345678',
  passwordConfirm: '12345678',
  birthMonth: null,
  birthYear: null,
  studentEssay: '',
  zipcode: '',
  reason: null,
  studentFirstName: '',
  parentFirstName: '',
  parentLastName: '',
  levelId:null
} : {
  email: '',
  password: '',
  passwordConfirm: '',
  zipcode: '', reason: null,
  birthMonth: null,
  birthYear: null,
  studentEssay: '',
  studentFirstName: '',
  parentFirstName: '',
  parentLastName: '',
  levelId:null
};
export default function RegisterParent(): JSX.Element {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = React.useState(null);

  const [birthMonth, setBirthMonth] = React.useState<string>(null);
  const [birthYear, setBirthYear] = React.useState<string>(null);
  const [showMonthDropDown, setShowMonthDropDown] = React.useState<boolean>(false);
  const [showYearDropDown, setShowYearDropDown] = React.useState<boolean>(false);
  const [rootWidth, setRootWidth] = React.useState<number>(0);


  const [page, setPage] = React.useState<number>(0);
  const [parentRegistrationObject, setParentRegistrationObject] = React.useState<ParentRegistrationObject>(defaultValues);


  const pageWidth = Math.min(800, rootWidth);
  const sentenceWidth = Math.min(600, Math.round(3 * rootWidth / 4));
  const registrationStyles: (widgetWidth: number) => {
    page: ViewStyleProp;
    buttonWord: ViewStyleProp;
    buttonSentence: ViewStyleProp;
    textInputWord: ViewStyleProp;
    textInputSentence: ViewStyleProp;
    buttonLabelSentence: TextStyleProp;
    buttonLabel: TextStyleProp;
  } = (rootWidth: number) => {
    return StyleSheet.create({
      page: {
        backgroundColor: COLOR.PAPER.grey50,
        flex: 1,
        maxWidth: pageWidth,
        minWidth: pageWidth,
        alignSelf: 'center'
      },
      buttonWord: {

        maxWidth: Math.max(200, Math.min(300, Math.round(rootWidth / 4))),
        minWidth: Math.max(200, Math.min(300, Math.round(rootWidth / 4))),
        margin: 5,
        alignSelf: 'center'
      },
      buttonLabelSentence: {
        fontFamily: 'ArialRoundedMTBold',
        fontSize: Math.round(Math.min(600, Math.round(3 * rootWidth / 4)) / 25),
        margin: 5,
        textAlign: 'center',
      },
      buttonLabel: {
        fontSize: Math.round(Math.min(600, Math.round(3 * rootWidth / 4)) / 25),
      },
      buttonSentence: {
        maxWidth: sentenceWidth,
        minWidth: sentenceWidth,

        margin: 5,
        alignSelf: 'center'
      },
      textInputWord: {
        maxWidth: Math.max(300, Math.min(400, Math.round(rootWidth / 2))),
        minWidth: Math.max(300, Math.min(400, Math.round(rootWidth / 2))),
        margin: 5,
        alignSelf: 'center'
      },
      textInputSentence: {
        maxWidth: sentenceWidth,
        minWidth: sentenceWidth,
        margin: 5,
        alignSelf: 'center'
      },
    });
  };

  const onChange = (field: string, value: any, _page?: number) => {
    const newFormObject = {
      ...parentRegistrationObject,
    };
    newFormObject[field] = value;
    setParentRegistrationObject(newFormObject);
    if (typeof _page !== 'undefined') {
      setPage(_page);
    }
    debug('NEW formObject', newFormObject, parentRegistrationObject);
  };


  function submitForm() {
    debug('Register ', parentRegistrationObject);
    parentRegistrationObject.birthYear = birthYear;
    parentRegistrationObject.birthMonth = birthMonth;
    if (parentRegistrationObject.email && parentRegistrationObject.password) {
      dispatch(parentRegisterRequest(parentRegistrationObject));
    }
  }

  const steps = 6;

  return <Surface style={styles.root} testID="Register" onLayout={(event) => {
    setRootWidth(event?.nativeEvent?.layout?.width);

  }}>
    {page == 0 &&
    <View style={registrationStyles(rootWidth).page}>
      <RegistrationProgress page={page} steps={steps}/>


      <Text style={textStyles.labelText}>What is your child's grade level?</Text>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.PK, 1)}>Pre-K </Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.K, 1)}>Kindergarten</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.G1, 1)}>1st</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.G2, 1)}>2nd</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.G3, 1)}>3rd</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.G4, 1)}>4th</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonWord}
        mode="outlined"
        onPress={() => onChange('levelId', PROFILE.LEVELS.G5, 1)}>5th or older</Button>
      <Text style={textStyles.smallText}>Note: if you have more than one child to enroll, you can add additional
        children to you account after
        registration.</Text>
    </View>}
    {
      page == 1 &&
      <View style={registrationStyles(rootWidth).page}>
        <RegistrationProgress page={page} steps={steps}/>


        <Text style={textStyles.labelText}>What best describes you goal with CoachPlus</Text>
        <Button labelStyle={registrationStyles(rootWidth).buttonLabelSentence}
          style={registrationStyles(rootWidth).buttonSentence} mode="outlined"
          onPress={() => onChange('reason', PROFILE.GOAL.ACCELERATE, 2)}>
          Math Acceleration
        </Button>
        <Button labelStyle={registrationStyles(rootWidth).buttonLabelSentence}
          style={registrationStyles(rootWidth).buttonSentence} mode="outlined"
          onPress={() => onChange('reason', PROFILE.GOAL.STRUGGLING, 2)}>
          Alleviate Math Struggle
        </Button>
        <Button labelStyle={registrationStyles(rootWidth).buttonLabelSentence}
          style={registrationStyles(rootWidth).buttonSentence} mode="outlined"
          onPress={() => onChange('reason', PROFILE.GOAL.CONFIDENCE, 2)}>
          Confidence in Math
        </Button>
        <Button labelStyle={registrationStyles(rootWidth).buttonLabelSentence}
          style={registrationStyles(rootWidth).buttonSentence} mode="outlined"
          onPress={() => onChange('reason', PROFILE.GOAL.ENJOYMENT, 2)}>
          Math Enjoyment
        </Button>
        <RegistrationProgressButtons valid={!!parentRegistrationObject.reason} page={page} setPage={setPage}
          steps={steps}/>

      </View>
    }
    {
      page == 2 &&
      <View style={registrationStyles(rootWidth).page}>
        <RegistrationProgress page={page} steps={steps}/>


        <Text style={textStyles.labelText}>Parent Information</Text>
        <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={parentRegistrationObject.parentFirstName} label="First Name (of parent)"
          onChangeText={(value) => {
            onChange('parentFirstName', value);
          }}/>

        <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={parentRegistrationObject.parentLastName} label="Last Name"
          onChangeText={(value) => {
            onChange('parentLastName', value);
          }}/>
        <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={parentRegistrationObject.zipcode} label="ZipCode"
          onChangeText={(value) => {
            onChange('zipcode', value);
          }}/>
        <Text style={textStyles.smallText}>To Locate Coaches closest to you</Text>

        <RegistrationProgressButtons
          valid={!!parentRegistrationObject.parentFirstName && !!parentRegistrationObject.parentLastName && !!parentRegistrationObject.zipcode}
          page={page} setPage={setPage} steps={steps}/>
      </View>
    }
    {page == 3 &&
    <View style={registrationStyles(rootWidth).page}>
      <RegistrationProgress page={page} steps={steps}/>


      <Text style={textStyles.labelText}>Create Parent Account</Text>

      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
        value={parentRegistrationObject.email} label="Email"
        onChangeText={(value) => {
          onChange('email', value);
        }}/>
      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord} secureTextEntry
        value={parentRegistrationObject.password} label="Password"
        onChangeText={(value) => {
          onChange('password', value);
        }}/>
      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord} secureTextEntry
        value={parentRegistrationObject.passwordConfirm} label="PasswordConfirm"
        onChangeText={(value) => {
          onChange('passwordConfirm', value);
        }}/>

      <RegistrationProgressButtons
        valid={validEmail(parentRegistrationObject.email) && validPassword(parentRegistrationObject.password) && parentRegistrationObject.password === parentRegistrationObject.passwordConfirm}
        page={page} setPage={setPage} steps={steps}/>

    </View>
    }
    {page == 4 &&
    <View style={registrationStyles(rootWidth).page}>
      <RegistrationProgress page={page} steps={steps}/>


      <Dropzone
        accept="image/*"
        maxFiles={1}
        multiple={false}
        onDrop={acceptedFiles => {
          if (acceptedFiles.length > 0) {

            const preview = URL.createObjectURL(acceptedFiles[0]);
            setImageSrc(preview);
            // const newFormObject = {
            //   ...parentRegistrationObject,
            // };
            // console.log(typeof  preview);
            // newFormObject.studentImage = preview;
            // setParentRegistrationObject(newFormObject);


            const fr = new FileReader();
            fr.onload = function () {
              // setImageSrc(fr.result);
              const newFormObject = {
                ...parentRegistrationObject,
              };
              console.log(typeof fr.result);
              newFormObject.studentImage = fr.result as ArrayBuffer;
              setParentRegistrationObject(newFormObject);

            };
            fr.readAsArrayBuffer(acceptedFiles[0]);
          }
        }}>
        {({getRootProps, getInputProps}) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Text>Upload and image for your child</Text>
              {imageSrc && <img style={{width: 100, height: 100}} src={imageSrc} alt={'preview'}/>}
              {imageSrc && <Text>PREVIEW</Text>}
              {!imageSrc && < Avatar.Icon size={100} icon="cloud-upload"> </Avatar.Icon>}
            </div>
          </section>
        )}
      </Dropzone>


      <RegistrationProgressButtons valid={true} page={page} setPage={setPage} steps={steps}/>

    </View>
    }
    {
      page == 5 &&
      <SafeAreaView style={[registrationStyles(rootWidth).page, {height: 600}]}>
        <View style={styles.progressWrapper}>
          <ProgressBar style={styles.progress} progress={5 / steps} color={Colors.red800}/>
          <Text>Step 6 of {steps}</Text>
        </View>

        <Text style={textStyles.labelText}>Student Information</Text>
        <TextInput style={registrationStyles(rootWidth).textInputSentence} mode="outlined"
          value={parentRegistrationObject.studentFirstName} label="First Name (of student)"
          onChangeText={(value) => {
            onChange('studentFirstName', value);
          }}/>
        <View style={[gridStyles.gridRowContainer, {
          justifyContent: 'space-between',
          alignSelf: "center",
          minWidth: sentenceWidth,
          maxWidth: sentenceWidth
        }]}>
          <View style={{maxWidth: sentenceWidth / 2}}>
            <DropDown label={'Birth Month'} value={birthMonth}
              setValue={(_birthMonth) => {
                setBirthMonth(_birthMonth);
              }}
              visible={showMonthDropDown}
              mode={"outlined"}
              showDropDown={() => setShowMonthDropDown(true)}
              onDismiss={() => {
                setShowMonthDropDown(false);
              }}
              list={[{label: 'Jan', value: '01'},
                {label: 'Feb', value: '02'},
                {label: 'Mar', value: '03'},
                {label: 'Apr', value: '04'},
                {label: 'May', value: '05'},
                {label: 'Jun', value: '06'},
                {label: 'Jul', value: '07'},
                {label: 'Aug', value: '08'},
                {label: 'Sep', value: '09'},
                {label: 'Oct', value: '10'},
                {label: 'Nov', value: '11'},
                {label: 'Dec', value: '12'},

              ]}
            />
          </View>
          <View style={{maxWidth: sentenceWidth / 2}}>
            <DropDown dropDownStyle={{maxWidth: sentenceWidth / 2}} label={'Birth Year'}
              value={birthYear}
              setValue={(_birthYear) => {
                setBirthYear(_birthYear);
              }}
              visible={showYearDropDown}
              mode={"outlined"}
              showDropDown={() => setShowYearDropDown(true)}
              onDismiss={() => setShowYearDropDown(false)}
              list={[{label: '2010', value: '2010'},
                {label: '2011', value: '2011'},
                {label: '2012', value: '2012'},
                {label: '2013', value: '2013'},
                {label: '2014', value: '2014'},
                {label: '2015', value: '2015'},
                {label: '2016', value: '2016'},
              ]}
            />
          </View>
        </View>

        <TextInput style={registrationStyles(rootWidth).textInputSentence} mode="outlined" multiline
          numberOfLines={5}
          value={parentRegistrationObject.studentEssay}
          label="Describe some things your child is interested in"
          placeholder={"My son likes playing Minecraft. He has a dog named Sandy. He also likes to talk about gross science stuff."}
          onChangeText={(value) => {
            onChange('studentEssay', value);
          }}/>
        <RegistrationProgressButtons
          valid={!!parentRegistrationObject.studentFirstName && !!parentRegistrationObject.birthMonth && !!parentRegistrationObject.birthYear}
          page={page} setPage={setPage} steps={steps} submitForm={submitForm}/>
      </SafeAreaView>
    }
  </Surface>;
}
