
/* eslint-disable no-empty */
import invariant from "invariant";
import { eventChannel } from "redux-saga";
import { all, put, select, take, takeEvery } from "redux-saga/effects";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import { consoleHighlight } from "../../utils/validation";
import type { State } from "../reducer";
import { chatAddHeyCoach, chatAddText } from "../reducer/chatReducer";
import {huddleHeyCoachMode, huddleAudioMode} from "../reducer/huddleReducer";
import { setRecognizerRunning, setSpeechChannel, setSpeechMode } from "../reducer/speechReducer";
import type {Huddle, HuddleUser} from "../../sharedDefs/huddleDefs";
import { SPEECH_MODE } from "../../sharedDefs/speechDefs";
import type {SpeechAction, SpeechEvent, SpeechRecognizer} from "../structs/speechStruct";
import { SPEECH_ACTION } from "../structs/speechStruct";
import {ActionType} from "../../sharedDefs/commonStruct";
import {ROLE, UserId} from "../../sharedDefs/userDefs";


export interface IWindow extends Window {
  webkitSpeechRecognition: any;
  webkitSpeechGrammarList: any;
}

const debug =  debugModule(DEBUG_MODULE.WEBCON.SPEECH);

const SPEECH_EVENT = {
  RECOGNIZER: 'RECOGNIZER',
  ON_START: 'OnStart',
  ON_NO_MATCH: 'ON_NO_MATCH',
  ON_END: 'ON_END',
  ON_ERROR: 'ON_ERROR',
  ON_RESULT: 'ON_RESULT'
};
type SpeechChannelAction =  ActionType & {
  recognizer: SpeechRecognizer;
  event: any;
};


function createSpeechChannel() {
  debug('createSpeechChannel');
  let recognizer: SpeechRecognizer | null | undefined;
  return eventChannel(emit => {




    if (process.env.USE_SPEECH_RECOGNITION === 'true' && (typeof SpeechRecognition !== 'undefined' || typeof (<IWindow><any>window).webkitSpeechRecognition !== 'undefined')) {

      const SpeechRecognitionImpl = (typeof (<IWindow><any>window).webkitSpeechRecognition !== 'undefined') ? (<IWindow><any>window).webkitSpeechRecognition : SpeechRecognition;

      const SpeechGrammarListImpl = (typeof (<IWindow><any>window).webkitSpeechGrammarList !== 'undefined') ? (<IWindow><any>window).webkitSpeechGrammarList : SpeechGrammarList;


      recognizer = new SpeechRecognitionImpl();
      recognizer.continuous = true;
      recognizer.lang = 'en-US';
      recognizer.interimResults = false;
      recognizer.maxAlternatives = 1;


      const onStart = event => {
        debug('onStart.');
        emit({
          type: SPEECH_EVENT.ON_START,
          recognizer,
          event
        });
      };

      const onNoMatch = (event: SpeechEvent) => {
        debug('onNoMatch', event);
        emit({
          type: SPEECH_EVENT.ON_NO_MATCH,
          recognizer,
          event
        });
      };

      const onEnd = (event: SpeechEvent) => {
        debug('onEnd with event', event.message, event.error);
        emit({
          type: SPEECH_EVENT.ON_END,
          recognizer,
          event
        });
      };

      const onError = (event: SpeechEvent) => {
        console.error('Speech Error ', event.error);
        debug('onError with event', event.message, event.error);
        emit({
          type: SPEECH_EVENT.ON_ERROR,
          recognizer,
          event
        });
      };

      const onResult = (event: SpeechEvent) => {
        debug('onresult', event);
        emit({
          type: SPEECH_EVENT.ON_RESULT,
          recognizer,
          event
        });
      };

      recognizer.onresult = onResult;
      recognizer.onnomatch = onNoMatch;
      recognizer.onerror = onError;
      recognizer.onend = onEnd;
      recognizer.onStart = onStart;

      debug('recognizer init', onResult);

      const grammar = '#JSGF V1.0; grammar words; public <hey> =hey coach | coach | six ;';

      const speechRecognitionList = new SpeechGrammarListImpl();
      speechRecognitionList.addFromString(grammar, 1);
      recognizer.grammars = speechRecognitionList;
      recognizer.start();
      debug('EMIT RECOGNIZER');
      emit({
        type: SPEECH_EVENT.RECOGNIZER,
        recognizer
      });
    } else {
      debug('SpeechRecognition NO EXISTS');

    }

    // setup the subscription
    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    return () => {
      debug('unsubscribe');
      recognizer?.stop();
    };
  });
}

function* speechStateSetSaga(action: SpeechAction) {
  debug('speechStateSetSaga Start', action.localSpeechMode);
  const role = yield select((state: State) => state.userReducer.role);
  const userId = yield select((state: State) => state.userReducer.userId);
  const huddle: Huddle = yield select((state: State) => state.huddleReducer.huddle);
  let coachId: UserId;
  if (role === ROLE.COACH) {
    coachId = userId;
  }
  else {
    coachId = huddle?.coachId;
  }


  if (action.localSpeechMode === SPEECH_MODE.OFF) {
    console.log('STOP SPEECH RECOGNITION');
    const recognizer = yield select((state: State) => state.speechReducer.recognizer);
    const recognizerRunning = yield select((state: State) => state.speechReducer.recognizerRunning);

    if (recognizer && recognizerRunning) {
      yield put(setRecognizerRunning(recognizer, false));
      recognizer?.stop();
    }
  }
  else {
    let speechChannel = yield select((state: State) => state.speechReducer.speechChannel);
    debug('speechStateSetSaga speechChannel', speechChannel);

    if (!speechChannel) {
      debug('speechStateSetSaga call startRecognizerChannel');
      debug('startRecognizerChannel Start');
      const {
        studentId,
      } = action;
      invariant(studentId, 'invalid-action');
      speechChannel = createSpeechChannel();
      yield put(setSpeechChannel(speechChannel));

      while (true) {
        try {
          // An error from socketChannel will cause the saga jump to the catch block
          const speechChannelAction: SpeechChannelAction = yield take(speechChannel);
          debug('Channel take ', speechChannelAction);
          const {
            type,
            event
          } = speechChannelAction;
          let recognizer;
          const recognizerRunning = yield select((state: State) => state.speechReducer.recognizerRunning);

          if (speechChannelAction.recognizer) {
            recognizer = speechChannelAction.recognizer;
          } else {
            recognizer = yield select((state: State) => state.speechReducer.recognizer);
          }

          const localSpeechMode = yield select((state: State) => state.speechReducer.localSpeechMode);

          switch (type) {
            case SPEECH_EVENT.RECOGNIZER:
              {
                yield put(setRecognizerRunning(speechChannelAction.recognizer, true));
              }
              break;

            case SPEECH_EVENT.ON_START:
              {
                yield put(setRecognizerRunning(recognizer, true));
              }
              break;

            case SPEECH_EVENT.ON_NO_MATCH:
              {}
              break;

            case SPEECH_EVENT.ON_END:
              {
                if (localSpeechMode === SPEECH_MODE.DICTATING || localSpeechMode === SPEECH_MODE.LISTEN_HEY_COACH) {
                  yield put(setRecognizerRunning(recognizer, true));

                  try {
                    recognizer?.start();

                  } catch (e) {}
                }
              }
              break;

            case SPEECH_EVENT.ON_ERROR:
              {
                if (localSpeechMode === SPEECH_MODE.DICTATING || localSpeechMode === SPEECH_MODE.LISTEN_HEY_COACH ) {
                  const _recognizerRunning = yield select((state: State) => state.speechReducer.recognizerRunning);

                  if (!_recognizerRunning) {
                    yield put(setRecognizerRunning(recognizer, true));

                    try {
                      recognizer?.start();
                    } catch (e) {}
                  }
                }
              }
              break;

            case SPEECH_EVENT.ON_RESULT:
              {
                for (let index = 0; index < event.results.length; index += 1) {
                  const result = event.results[index];

                  if (result.isFinal) {
                    const {
                      transcript,
                      confidence
                    } = result[0];
                    debug('onResult' + localSpeechMode + '  ' + transcript + ' ' + confidence);
                    consoleHighlight('SPEECH RESULT: ' + localSpeechMode + '   ' + transcript + ' ' + confidence);

                    if (localSpeechMode === SPEECH_MODE.DICTATING ) {
                      debug('dispatch chatAddText', transcript);
                      yield put(chatAddText(studentId, coachId, userId, transcript, confidence));
                      yield put(setRecognizerRunning(recognizer, false));
                      recognizer?.stop();
                    }
                    else if (localSpeechMode === SPEECH_MODE.LISTEN_HEY_COACH) {
                      const heyCoach = 'hey coach';
                      const heyCoachIndex = transcript.indexOf(heyCoach);
                      debug('heyCoachIndex', heyCoachIndex);

                      if (heyCoachIndex >= 0) {
                        yield put(setSpeechMode(SPEECH_MODE.DICTATING, studentId));
                        yield put(chatAddHeyCoach(studentId, coachId, studentId, confidence));
                        yield put(huddleHeyCoachMode(action,userId,coachId, true));
                        const remainder = transcript.substring(heyCoachIndex + heyCoach.length);

                        if (remainder.length > 3) {
                          debug('dispatch chatAddText', remainder);
                          yield put(chatAddText(studentId, coachId, studentId, remainder, confidence));
                        }

                        debug('Start transcribe HeyCoach');
                      }

                      yield put(setRecognizerRunning(recognizer, false));
                      recognizer?.stop();
                    } else {
                      console.log('!!!!!! No result when off ' + localSpeechMode);
                    }
                  }
                }
              }
              break;

            default:
            {}
          }
        } catch (error) {
          yield put({
            type: SPEECH_ACTION.SPEECH_CHANNEL.FAILURE,
            error
          });
        }
      }
    }
    else {
      const recognizer = yield select((state: State) => state.speechReducer.recognizer);
      const recognizerRunning = yield select((state: State) => state.speechReducer.recognizerRunning);
      debug('speechChannel Put', recognizer, recognizerRunning);

      if (recognizer && !recognizerRunning) {
        yield put(setRecognizerRunning(recognizer, true));

        try {
          recognizer?.start();
        } catch (e) {}
      }
      // speechChannel.put({ type: SPEECH_EVENT.START_RECOGNITION });
      // yield put(speechChannel, { type: SPEECH_EVENT.START_RECOGNITION });

    }
  }
}

type Saga<T> = Generator<any, T, any>;
export default function* speechSagas(): Saga<void> {
  debug('speechSagas Start');
  yield all([takeEvery(SPEECH_ACTION.SPEECH_MODE.SET, speechStateSetSaga)]);
}
