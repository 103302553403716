import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
});
type MaintenanceProps = Record<string,never>;
export default function Maintenance(props: MaintenanceProps): JSX.Element {
  return <View testID="Maintenance" style={styles.root}>
    <View>
      <Text>Website under maintenance</Text>
      <Text>
          Our website is under maintenance. We
        {'\''}
          ll be back shortly
      </Text>
    </View>
  </View>;
}
