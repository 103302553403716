import * as React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import type {State} from "../../redux/reducer";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {
  webconChangeDevices,
  webconDeviceCamera,
  webconDeviceMic,
  webconDeviceSpeaker
} from "../../redux/reducer/webconReducer";

const debug = debugModule(DEBUG_MODULE.WEBCON.DEVICE_CHOOSER);

const styles = StyleSheet.create({

  labelText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 14,
    textAlign: 'left',
    padding: 10
  },
  selectedText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 16,
    textAlign: 'left',
    padding: 5
  },
  modalRoot: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },

});
export default function DeviceChooser(): JSX.Element {
  const dispatch = useDispatch();

  const deviceInfos = useSelector((state: State) => state.webconReducer.deviceInfos);
  const webcamDeviceId = useSelector((state: State) => state.webconReducer.webcamDeviceId);
  const microphoneDeviceId = useSelector((state: State) => state.webconReducer.microphoneDeviceId);
  const speakerDeviceId = useSelector((state: State) => state.webconReducer.speakerDeviceId);


  function cleanLabel(label:string): string{
    return label.replace(/ *\([^)]*\) */g, "");
  }
  const cameraChoice = React.useCallback(_cameraDeviceInfo => () => {
    debug('Choose camera', _cameraDeviceInfo);
    dispatch(webconDeviceCamera(_cameraDeviceInfo.deviceId));

    dispatch(webconChangeDevices());
  },[]);
  const microphoneChoice = React.useCallback(_microphoneDeviceInfo => () => {
    dispatch(webconDeviceMic(_microphoneDeviceInfo.deviceId));
  },[]);
  const speakerChoice = React.useCallback(_speakerDeviceInfo => () => {
    dispatch(webconDeviceSpeaker(_speakerDeviceInfo.deviceId));
  },[]);
  const cameraRenders = deviceInfos && deviceInfos.filter(dev => dev.kind === 'videoinput').map(dev => {
    const active =  webcamDeviceId && dev.deviceId === webcamDeviceId;

    if (active) {
      return <View key={dev.deviceId}><Text style={styles.selectedText}>{cleanLabel(dev.label)}</Text></View>;
    }

    return <View key={dev.deviceId}>
      <TouchableOpacity onPress={cameraChoice(dev)}>
        <Text> {cleanLabel(dev.label)}</Text>
      </TouchableOpacity>
    </View>;
  });
  const micRenders = deviceInfos && deviceInfos.filter(dev => dev.kind === 'audioinput').map(dev => {
    const active = microphoneDeviceId && dev.deviceId === microphoneDeviceId;

    if (active) {
      return <View key={dev.deviceId}><Text style={styles.selectedText}>{cleanLabel(dev.label)}</Text></View>;
    }

    return <View key={dev.deviceId}>
      <TouchableOpacity onPress={microphoneChoice(dev)}>
        <Text>{cleanLabel(dev.label)}</Text>
      </TouchableOpacity>
    </View>;
  });
  const speakerRenders = deviceInfos && deviceInfos.filter(dev => dev.kind === 'audiooutput').map(dev => {
    const active = speakerDeviceId && dev.deviceId === speakerDeviceId;
    if (active) {
      return <View key={dev.deviceId}><Text style={styles.selectedText}>{cleanLabel(dev.label)}</Text></View>;
    }

    return <View key={dev.deviceId}>
      <TouchableOpacity onPress={speakerChoice(dev)}>
        <Text> {cleanLabel(dev.label)}</Text>
      </TouchableOpacity>
    </View>;
  });

  return (
    <View testID="DeviceChooser">
      <View testID="camera">
        <Text key="camera" style={styles.labelText}>Camera</Text>
        {cameraRenders}
      </View>
      <View testID="mic">
        <Text key="Mic" style={styles.labelText}>Mic</Text>
        {micRenders}
      </View>
      <View testID="speaker">
        <Text key="Speaker" style={styles.labelText}>Speaker</Text>
        {speakerRenders}
      </View>

    </View>);
}
