import * as React from "react";
import {Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import NumberKeypad from "./NumberKeypad";
import {gridStyles, rootStyles, textStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import AnswerBlock from "./AnswerBlock";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
export function SubtractionWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  const {
    difference
  } = curriculumStudentResponse?.responseKeyValues?.set || {};return <View testID="SubtractionWidgetCoach" style={rootStyles.coachRoot}>
    <View style={gridStyles.gridRowContainer}>
      <Text style={textStyles.coachProblemTextLeft}>
        {curriculumStudentInteraction?.problem?.params?.prompts?.a}
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>
        -
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>{curriculumStudentInteraction?.problem?.params?.prompts?.b}</Text>
      <Text style={textStyles.coachProblemTextLeft}>=</Text>
      <Text style={textStyles.coachProblemTextLeft}>{difference}</Text>

    </View>
  </View>;
}
export function SubtractionWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    onResponse,
    inWorkbench
  } = props;
  // problemValidate(problemValues, valueTypeSet);
  //
  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params.prompts || {};
  const {
    difference
  } = curriculumStudentResponse?.responseKeyValues?.set || {};


  const [widgetWidth, setWidgetWidth] = React.useState(0);
  const [widgetHeight, setWidgetHeight] = React.useState(0);


  const onKeyPress = React.useCallback((_answer: string) => {

    debug('onKeyPress', _answer, difference, JSON.stringify(curriculumStudentResponse.responseKeyValues));
    let newDifference = _answer;

    if (difference && parseInt(difference,10) <10) {
      newDifference = difference + _answer;
      debug('onKeyPress append '+difference , _answer,'=',newDifference);
    }

    if (parseInt(newDifference, 10) >= 100) {
      newDifference = '';
    }
    debug('onKeyPress newDifference', newDifference);
    onResponse('difference', newDifference);
  },[curriculumStudentResponse, curriculumStudentInteraction]);

  debug('SUbtraction INTERACTION ID  ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
  return <View key="1"  testID="SubtractionWidgetStudent" style={[rootStyles.studentRoot]} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    <View style={gridStyles.gridColumnCenteredContainer}>
      <View  style={gridStyles.gridRowContainer}>
        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {a}
          {' '}
          -
          {b}
          {' '}
          =
        </Text>

        <AnswerBlock  widgetWidth={widgetWidth} actual={difference} expected={problem?.params?.expectedResponseKeyValues?.set?.difference} ></AnswerBlock>

      </View>
      <View  style={gridStyles.gridRowContainer}>
        <NumberKeypad  widgetWidth={widgetWidth} inWorkbench={inWorkbench} onKeyPress={onKeyPress} />
      </View>
    </View>
  </View>;
}

const defaultProgressionSetId = 'Math.K.OA.A.2.Add1Within10.1';
const SubtractionWidget: WidgetSet = {
  widgetSetId: 'SubtractionWidget',
  coach: SubtractionWidgetCoach,
  student: SubtractionWidgetStudent,
  defaultProgressionSetId};
export default SubtractionWidget;
