import invariant from "invariant";
import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {CHAT_ACTION, ChatAction} from "../../sharedDefs/chatDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";

import {Saga} from "./curriculumSaga";
import {emitNoResponse} from "./sagaUtils";
import {Socket} from "socket.io-client";
import {State} from "../reducer";

const debug = debugModule(DEBUG_MODULE.CHAT.SAGA);

function* chatAddRequestSaga(chatAction: ChatAction) {
  debug(' chatAddPerformSaga start');

  try {
    const socketClient: Socket = yield select((state:State) => state.wsReducer.socketClient);
    if (socketClient) {
      debug('chatAddRequestSaga socketClient', socketClient, chatAction);
      invariant(socketClient, 'socketClient not initialized');
      yield call(emitNoResponse, socketClient, chatAction);
    }

  } catch (error) {
    console.error(error);
    yield put({
      type: CHAT_ACTION.ADD.FAILURE,
      error
    });
  }
}

export default function* chatSagas(): Saga<void> {
  debug('chatSaga Start');
  yield all([
    takeEvery(CHAT_ACTION.ADD.REQUEST, chatAddRequestSaga),
  ]);
}
