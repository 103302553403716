import * as React from "react";
import {useRef} from "react";
import {StyleSheet, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import type {State} from "../../redux/reducer";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";


const debug = debugModule(DEBUG_MODULE.WEBCON.SELF);
type SelfWebConProps = {VIDEO_CONTAINER_SIZE : number};
export const VIDEO_SIZE = 160;
const styles = StyleSheet.create({
  self: {
    marginTop: 5,
    width: VIDEO_SIZE,
    height: VIDEO_SIZE,
    top: 0,
    position: 'absolute',
    backgroundColor: COLOR.GREEN,
  },
  selfIos: {
    marginTop: 5,
    width: VIDEO_SIZE,
    height: VIDEO_SIZE,
    top: 0,
    position: 'absolute',
    backgroundColor: COLOR.RED,
  },
  video: {
    borderRadius: 10,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    overflow: 'hidden',
    backgroundColor: COLOR.GREY,
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute'
  },
  audio: {
    height: 0,
    width: 0
  },
  placeholder: {
    borderRadius: 10,
    backgroundColor: COLOR.GREY,
    height: 90,
    width: '100%',
    padding: 5,
    position: 'absolute',
    top: 20,
    alignSelf: 'center'
  },
  placeholderContent: {
    top: 20,
    alignSelf: 'center'
  }
});
export default function SelfWebCon(props: SelfWebConProps): JSX.Element {

  const {VIDEO_CONTAINER_SIZE} = props;
  const videoElem = useRef<HTMLVideoElement>(null);
  const [videoStream, setVideoStream] = React.useState(null);
  const [attachedVideoTrack, setAttachedVideoTrack] = React.useState(null);
  const [attachedAudioTrack, setAttachedAudioTrack] = React.useState(null);
  const dispatch = useDispatch();


  const localVideoTrack = useSelector((state: State) => state.webconReducer.localVideoTrack);

  debug('localVideoTrack', !!localVideoTrack, localVideoTrack);
  debug('attachedVideoTrack', !!attachedVideoTrack);
  debug('videoElem.current.srcObject', !!videoElem, !!videoElem?.current, !!videoElem?.current?.srcObject);
  React.useEffect(() => {

    if (!attachedVideoTrack && localVideoTrack) {

      debug('attach VideoTrack');
      const stream = new MediaStream;
      setAttachedVideoTrack(localVideoTrack);
      setVideoStream(stream);

      stream.addTrack(localVideoTrack);
      videoElem.current.srcObject = stream;

      if (videoElem.current && videoElem.current.play) {
        videoElem.current.play()
          .catch((error) => console.error('videoElem.play() failed:%o', error));
      }
    }
    if (attachedVideoTrack && localVideoTrack) {
      videoStream.removeTrack(attachedVideoTrack);
      videoStream.addTrack(localVideoTrack);
      setAttachedVideoTrack(localVideoTrack);


    }

  }, [localVideoTrack,attachedVideoTrack]);

  console.log('VIDEO_CONTAINER_SIZE',VIDEO_CONTAINER_SIZE, VIDEO_SIZE);

  return <View testID="SelfWebCon" style={[styles.self,{marginTop:(VIDEO_CONTAINER_SIZE - VIDEO_SIZE)/2}]}>
    <View testID="localVideoContainer" nativeID="localVideoContainer" style={styles.video}>
      <video id="localVideo"
        autoPlay
        ref={videoElem}
        muted
        controls={false}
        style={
          {
            width: '180%',
            maxWidth: 400,
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}/>
    </View>
    <View style={{
      position: 'absolute',
      top: VIDEO_SIZE - 40,
      right: 5
    }}>
    </View>
  </View>;
}
