process.env.DEBUG_COLORS = 'yes';
process.env.TERM = 'dumb';
process.env.DEBUG_HIDE_DATE = 'true';
import debugModuleLocal from "debug";
export const DEBUG_MODULE = {
  STAR: 'CPL:*',
  WEBCON: {
    SAGA_WEBCAM_ENABLE: 'CPL:WEBCON:SAGA:WEBCAM_ENABLE',
    SAGA_SEND_TRANSPORT: 'CPL:WEBCON:SAGA:SEND_TRANSPORT',
    SAGA: 'CPL:WEBCON:SAGA',
    REDUCER: 'CPL:WEBCON:REDUCER',
    NODE: 'CPL:WEBCON:NODE',
    STAR: 'CPL:WEBCON:*',
    COACH: 'CPL:WEBCON:COACH',
    SELF: 'CPL:WEBCON:SELF',
    STUDENT: 'CPL:WEBCON:STUDENT',
    SPEECH: 'CPL:WEBCON:SPEECH',
    MOCK: 'CPL:WEBCON:MOCK',
    DEVICE_CHOOSER: 'CPL:WEBCON:DEVICE_CHOOSER'
  },
  MEDIASOUP: {
    NODE: 'CPL:MEDIASOUP:NODE',
    STAR: 'CPL:MEDIASOUP:*'
  },
  WS: {
    SAGA: 'CPL:WS:SAGA',
    REDUCER: 'CPL:WS:REDUCER',
    NODE: 'CPL:WS:NODE',
    STAR: 'CPL:WS:*'
  },
  HUDDLE: {
    SAGA: 'CPL:HUDDLE:SAGA',
    REDUCER: 'CPL:HUDDLE:REDUCER',
    NODE: 'CPL:HUDDLE:NODE',
    STAR: 'CPL:HUDDLE:*'
  },
  JITSI: {
    SAGA: 'CPL:JITSI:SAGA',
    REDUCER: 'CPL:JITSI:REDUCER',
    CONNECTION: 'CPL:JITSI:SAGA:CONNECTION',
    NODE: 'CPL:JITSI:NODE',
    STAR: 'CPL:JITSI:*'
  },
  PARENT: {
    NODE: 'CPL:PARENT:NODE',
    STAR: 'CPL:PARENT:*'
  },
  ADMIN: {
    SAGA: 'CPL:ADMIN:SAGA',
    REDUCER: 'CPL:ADMIN:REDUCER',
    UI: 'CPL:ADMIN:UI',
    NODE: 'CPL:ADMIN:NODE',
    STAR: 'CPL:ADMIN:*'
  },
  CURRICULUM: {
    SAGA: 'CPL:CURRICULUM:SAGA',
    REDUCER: 'CPL:CURRICULUM:REDUCER',
    UTIL: 'CPL:CURRICULUM:REDUCER',
    WIDGETS: 'CPL:CURRICULUM:WIDGETS',
    NODE: 'CPL:CURRICULUM:NODE',
    STAR: 'CPL:CURRICULUM:*'
  },
  NAVIGATION: {
    SAGA: 'CPL:NAVIGATION:SAGA',
    REDUCER: 'CPL:NAVIGATION:REDUCER',
    STAR: 'CPL:NAVIGATION:*'
  },
  USER: {
    SAGA: 'CPL:USER:SAGA',
    REDUCER: 'CPL:USER:REDUCER',
    CONTAINER: 'CPL:USER:CONTAINER',
    AUTH: 'CPL:USER:AUTH',
    NODE: 'CPL:USER:NODE',
    STAR: 'CPL:USER:*'
  },
  CHAT: {
    SAGA: 'CPL:CHAT:SAGA',
    REDUCER: 'CPL:CHAT:REDUCER',
    UI: 'CPL:CHAT:UI',
    NODE: 'CPL:CHAT:NODE',
    STAR: 'CPL:CHAT:*'
  },
  FIRESTORE: {
    RFS: 'CPL:FIRESTORE:RFS',
    DATA: 'CPL:FIRESTORE:DATA',
    NODE: 'CPL:FIRESTORE:NODE',
    STAR: 'CPL:FIRESTORE:*'
  },
  UTILS: {
    QUERIES: 'CPL:UTILS:QUERIES',
    STORE: 'CPL:UTILS:STORE',
    STAR: 'CPL:UTILS:*'
  },
  COMPONENTS: {
    TABLE: 'CPL:COMPONENTS:TABLE',
    STAR: 'CPL:COMPONENTS:*'
  },
  JEST: {
    SETUP: 'CPL:JEST:SETUP',
    TESTS: 'CPL:JEST:TESTS',
    COACH: 'CPL:JEST:COACH',
    STUDENT: 'CPL:JEST:STUDENT',
    UTILS: 'CPL:JEST:UTILS',
    STAR: 'CPL:JEST:*'
  },
  SOCKETIO: {
    BASE: 'CPL:SOCKETIO:BASE',
    CONSOLE: 'CPL:SOCKETIO:CONSOLE',
    NODE: 'CPL:SOCKETIO:NODE',
    STAR: 'CPL:SOCKETIO:*'
  }
};
debugModuleLocal.disable();
const debugEnabled = [DEBUG_MODULE.STAR, '-' + DEBUG_MODULE.COMPONENTS.STAR].join();
debugModuleLocal.enable(debugEnabled);
export type DebugLogger = (...args: Array<any>) => void;

const safeStringify = (obj, indent = 0) => {
  let cache = [];
  const retVal = JSON.stringify(obj, (key, value) => typeof value === 'object' && value !== null ? cache.includes(value) ? undefined // Duplicate reference found, discard key
    : cache.push(value) && value // Store value in our collection
    : value, indent);
  cache = null;
  return retVal;
};

const debugModuleWrapper = (logKey: string): DebugLogger => {
  const localDebug: DebugLogger = debugModuleLocal(logKey);
  return (...args: Array<any>): void => {
    const formattedArgs = args.map(arg => {
      if (arg instanceof Error) {
        console.error(arg);
      }

      // if (process.env && (process.env.NODE_ENV === 'test' || process.env.USE_EMULATOR !== false) && typeof arg === 'object') {
      //   return  safeStringify(arg);
      // }
      return arg;
    });

    if (process.env.DEBUG_CONSOLE) {
      console.log(logKey, safeStringify(formattedArgs)); // localDebug(safeStringify(formattedArgs));
    } else {
      localDebug(safeStringify(formattedArgs));
    }
  };
};

export const debugModule: (logKey: string) => DebugLogger = debugModuleWrapper;
