import * as React from "react";
import {StyleSheet, View} from "react-native";
import {Card} from "react-native-paper";
import {useDispatch} from "react-redux";
import {userStudentLoginRequest} from "../../redux/reducer/userReducer";

import type {CoachId, UserData, UserProfile} from "../../sharedDefs/userDefs";
import {COLOR} from "../../utils/UiConstants";
import {ValidationError} from "../../utils/validation";
import StudentDash from "../Student/StudentDash";
import CplButton from "../../components/FormControls/CplButton";
import CplAvatar from "../../components/General/CplAvatar";

type ParentStudentCardProps = {
  studentUserProfile: UserProfile;
  parentUserData: UserData;
  width?: string;
};
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row'
  },
  avatar: {},
  card: {
    backgroundColor: COLOR.LT_BLUE,
    color: COLOR.BLACK
  },
  titleStyle: {
    color: COLOR.BLACK
  }
});


export default function ParentStudentCard(props: ParentStudentCardProps): JSX.Element {
  const dispatch = useDispatch();
  const {
    studentUserProfile,
    //    parentUserData,
  } = props;

  if (!studentUserProfile || !studentUserProfile.userId) {
    throw new ValidationError('invalid-property', studentUserProfile, studentUserProfile.userId);
  }

  let coach : UserProfile;
  let coachId: CoachId;

  // if (studentUserProfile.coachIds) {
  //   studentUserProfile.coachIds.forEach(coachId => {
  //     // if (parentUserData.childCoaches) {
  //     //   coach= parentUserData.childCoaches.find(s => s.userId === coachId);
  //     //   coachId = coach?.userId;
  //     // }
  //   });
  // }

  function studentSignin() {
    console.log('studentSignin',studentUserProfile);
    dispatch(userStudentLoginRequest(studentUserProfile.userId, coachId));
  }

  const LeftContent = () => studentUserProfile && <CplAvatar userProfile={studentUserProfile} showName={true}/>;
  const RightContent = () => coach &&  <CplAvatar userProfile={coach} showName={true}/>;

  return <Card testID="ParentStudentCard" style={styles.card}>
    <Card.Title titleStyle={styles.titleStyle}  style={{backgroundColor: COLOR.LT_BLUE}} title={coach?.name} left={LeftContent} right={RightContent}/>
    <Card.Content style={{ minHeight:80, backgroundColor: COLOR.LT_BLUE}}>
      <View style={styles.container}>
        <StudentDash studentId={studentUserProfile.userId}/>
        {/*<StudentAchievements studentId={studentUserProfile.userId}/>*/}
      </View>
    </Card.Content>
    <Card.Actions  style={{backgroundColor: COLOR.LT_BLUE, height:70}} >
      <CplButton testID="studentSignIn" onPress={studentSignin}>{'Sign In  ' + studentUserProfile.firstName}</CplButton>
    </Card.Actions>
  </Card>;
}
