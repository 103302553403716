export const COLLECTION = {
  USERS: 'users',
  USER_DATA: 'userData',
  HUDDLES: 'huddles',
  PROBLEMS: 'problems',
  PROGRESSION_SET: 'progressionSet',
  ASSESSMENTS: 'assessments',
  // META_PROBLEM: 'metaProblem',
  STANDARD: 'standard',
  DOMAINS: 'domains',
  LEVEL: 'level',
  SUBJECT: 'subject',
  CURRICULUM_STUDENT_RESPONSE: 'curriculumStudentResponse',
  CURRICULUM_STUDENT_INTERACTION: 'curriculumStudentInteraction',
  CURRICULUM_STUDENT_PROGRESSION: 'curriculumStudentProgression',
  CURRICULUM_STUDENT_PROGRESSION_SET_SUMMARY: 'curriculumStudentProgressionSetSummary',
  CURRICULUM_RESPONSE_DETAILS: 'curriculumResponseDetails',
  CHAT: 'chat',
  RESPONSES: 'response',
  ROOM_SUMMARY: 'room-summary',
  WEBCON_EVENTS: 'webcon_events'
};
