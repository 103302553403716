
import {  debugModule } from "../utils/debugModule";
const debug = debugModule('CPL:ENV');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Constants = process.env.JEST_WORKER_ID? null:  require( "expo-constants");
//
// debug(JSON.stringify(process.env, null,2));
//
// debug('CONSTANTS', JSON.stringify(Constants, null,2));

debug('process.env.USE_EMULATOR '+process.env.USE_EMULATOR );


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export let USE_EMULATOR = process?.env?.APP_MANIFEST?.env ?  process?.env?.APP_MANIFEST?.env.USE_EMULATOR :
  process.env.USE_EMULATOR;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export let USE_TESTPAGE = process?.env?.APP_MANIFEST?.env ?  process?.env?.APP_MANIFEST?.env.USE_TESTPAGE :
  process.env.USE_TESTPAGE;

const APP_MANIFEST :any = Constants?.default?.manifest? Constants.default.manifest:  process?.env?.APP_MANIFEST;
debug('process?.env?.APP_MANIFEST?.env', !!APP_MANIFEST?.env,  APP_MANIFEST?.env?.USE_EMULATOR);
debug('Manifest',  Constants?.default?.manifest);
export let REACT_APP_PREFILL= APP_MANIFEST?.env ?  APP_MANIFEST?.env.REACT_APP_PREFILL :
  process.env.REACT_APP_PREFILL;

export let REACT_APP_ADMIN= APP_MANIFEST?.env ?  APP_MANIFEST?.env.REACT_APP_ADMIN :
  process.env.REACT_APP_ADMIN;

export const JEST_WORKER_ID=   process.env.JEST_WORKER_ID;

export let SOCKET_IO_URL= APP_MANIFEST?.env ?  APP_MANIFEST?.env.SOCKET_IO_URL :
  process.env.SOCKET_IO_URL;


export let  NODE_URL= APP_MANIFEST?.env ?  APP_MANIFEST?.env.NODE_URL :
  process.env.NODE_URL;

export let WEB_URL= APP_MANIFEST?.env ?  APP_MANIFEST?.env.WEB_URL :
  process.env.WEB_URL;

const manifest = Constants?.default?.manifest || Constants?.manifest;

if (manifest?.env) {
  debug('Constants?.default?.manifest?.env',manifest?.env);
  Object.keys(manifest?.env).forEach(key => {
    debug('process env', key, manifest.env[key]);
    if (key === 'USE_EMULATOR'){
      USE_EMULATOR = manifest.env[key];
    }
    if (key === 'USE_TESTPAGE'){
      USE_TESTPAGE = manifest.env[key];
    }
    if (key === 'SOCKET_IO_URL'){
      SOCKET_IO_URL =  manifest.env[key];
    }
    if (key === 'NODE_URL'){
      NODE_URL =  manifest.env[key];
    }
    if (key === 'WEB_URL'){
      WEB_URL =  manifest.env[key];
    }
    if (key === 'REACT_APP_ADMIN'){
      REACT_APP_ADMIN =  manifest.env[key];
    }
    if (key === 'REACT_APP_PREFILL'){
      REACT_APP_PREFILL =  manifest.env[key];
    }
  });
}



export let useTestPage =   USE_TESTPAGE === 'true' ;

export function enableTestPage(){
  useTestPage = true;
}
export const useEmulator =   USE_EMULATOR === 'true' ;

debug('USE_EMULATOR',USE_EMULATOR, useEmulator);
