import * as React from "react";
import {Text, View} from "react-native";
import {queryAllCoaches} from "../../redux/queries/UserQueries";
import AdminCoachCard from "./AdminCoachCard";

const styles = theme => ({
  result: {
    margin: theme.spacing(1)
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  button: {
    fontSize: 12,
    '& svg': {
      marginRight: 10
    }
  },
  appBar: {
    position: 'relative'
  },
  flex: {
    flex: 1
  }
});

type CoachGalleryProps = Record<string,never>;
export default function AdminCoachGallery(props: CoachGalleryProps): JSX.Element {
  const [coachIndex, setCoachIndex] = React.useState(0);
  const [coaches, setCoaches] = React.useState([]);
  React.useEffect(() => {
    queryAllCoaches(setCoaches);
  }, []);

  // eslint-disable-next-line react/prop-types
  if (coaches) {
    return <View testID="CoachGallery">
      <View>
        {coaches.map((coach, index) => <View key={index.toString()}>
          <AdminCoachCard user={coach} width={300} />
        </View>)}
      </View>
    </View>;
  }

  return <View>
    <Text>No Coaches</Text>
  </View>;
}
