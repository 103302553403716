import {ActionType} from "../../sharedDefs/commonStruct";

export type LoggingState = {
  actions: Array<ActionType>;
  logs: Array<any>;
};
const initialState: LoggingState = {
  actions: [],
  logs: []
};
export const LOGGING = {
  WARN: 'LOGGING.WARN',
  ERROR: 'LOGGING.ERROR',
  INFO: 'LOGGING.INFO',
  CLEAR_ACTIONS: 'LOGGING.CLEAR_ACTIONS'
};
type LoggingAction = ActionType & {
  str?: string;
  obj?: any;
};
export function info(str: string, obj: any): LoggingAction {
  return {
    type: LOGGING.INFO,
    str,
    obj
  };
}
export const loggingClearActions = (): LoggingAction => ({
  type: LOGGING.CLEAR_ACTIONS
});
export default function loggingReducer(state: LoggingState = initialState, action: ActionType = {type:''}): LoggingState {
  switch (action.type) {
    case LOGGING.CLEAR_ACTIONS:
      return { ...state,
        actions: []
      };

    default:
      if (action && action.type) {
        const actions = [action, ...state.actions];
        // debug('ACTIONS ', action, actions);
        return { ...state,
          actions
        };
      }

      return state;
  }
}
