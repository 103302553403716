import * as React from "react";
import {Text, View} from "react-native";
import {widgetBlockStyles, widgetTextStyles} from "./WidgetStyles";


export type AnswerBlockProps = {
  actual: string;
  expected: string;
  widgetWidth: number;
};
export default function AnswerBlock(props: AnswerBlockProps): JSX.Element {
  const {
    actual, expected,
    widgetWidth
  } = props;


  if (expected?.length === 4) {
    let actualPrint = actual;
    if (actual?.length === 0) {
      actualPrint = '_ _ _ _';
    }
    if (actual?.length === 1) {
      actualPrint = actual + '_ _ _';
    }
    if (actual?.length === 2) {
      actualPrint = actual + '_ _';
    }
    if (actual?.length === 3) {
      actualPrint = actual + '_';
    }
    return <View style={widgetBlockStyles(widgetWidth).problemBlock4}>
      <Text style={widgetTextStyles(widgetWidth).problemText}>
        {actualPrint}
      </Text>
    </View>;
  }

  if (expected?.length === 3) {
    let actualPrint = actual;
    if (actual?.length === 0) {
      actualPrint = '_ _ _ _';
    }
    if (actual?.length === 1) {
      actualPrint = actual + '_ _ _';
    }
    if (actual?.length === 2) {
      actualPrint = actual + '_ _';
      return <View style={widgetBlockStyles(widgetWidth).problemBlock3}>
        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {actualPrint}
        </Text>
      </View>;
    }
  }

  if (expected?.length === 2) {
    let actualPrint = actual;
    if (actual?.length === 0) {
      actualPrint = '_ _';
    }
    if (actual?.length === 1) {
      actualPrint = actual + ' _';
    }
    return <View style={widgetBlockStyles(widgetWidth).problemBlock2}>
      <Text style={widgetTextStyles(widgetWidth).problemText}>
        {actualPrint}
      </Text>
    </View>;
  }

  let actualPrint = actual;
  if (actual?.length === 0) {
    actualPrint = '_';
  }

  return <View style={widgetBlockStyles(widgetWidth).problemBlock}>
    <Text style={widgetTextStyles(widgetWidth).problemText}>
      {actualPrint}
    </Text>
  </View>;

}
