import * as React from "react";
import {useRef} from "react";
import {Image, StyleSheet, Text, View} from "react-native";
import {Avatar} from "react-native-paper";
import {useDispatch, useSelector} from "react-redux";
import type {State} from "../../redux/reducer";
import {chatAddHeyCoach} from "../../redux/reducer/chatReducer";
import {huddleHeyCoachMode} from "../../redux/reducer/huddleReducer";
import type {Huddle, HuddleUser} from "../../sharedDefs/huddleDefs";
import {AUDIO_MODE, HUDDLE} from "../../sharedDefs/huddleDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";
import invariant from "invariant";

const debug = debugModule(DEBUG_MODULE.WEBCON.COACH);
const VIDEO_SIZE = 160;

const videoStyle = {
  width: '180%',
  maxWidth: 400,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
//  backgroundColor: 'orange',
};
const audioStyle = {
  //backgroundColor: 'blue',
};
const styles = StyleSheet.create({
  root: {
    marginTop: 5,
    display: 'flex',
    width: VIDEO_SIZE,
    zIndex: 1,
    position: 'relative',
  },
  videoContainer: {
    borderRadius: 10,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    overflow: 'hidden',
    backgroundColor: COLOR.GREY,
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute'
  },
  videoPrefix: {
    borderRadius: 10,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    overflow: 'hidden',
    // backgroundColor: '#ff0000',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    //  backgroundColor: 'red',
  },
  placeholder: {
    borderRadius: 10,
    height: VIDEO_SIZE,
    width: VIDEO_SIZE,
    // padding: 5,
    position: 'absolute',

    alignSelf: 'center'
  },
  placeholderInactive: {
    backgroundColor: COLOR.GREY
  },
  placeholderActive: {
    backgroundColor: COLOR.GREEN
  },
  placeholderContent: {
    top: 20,
    alignSelf: 'center'
  },
  heyCoach: {
    height: 80,
    width: 80,
    zIndex: 400,
    position: 'absolute',
    right: -30
  }
});

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
//type CoachViewWebConPropType = Record<string, never>;
export default function CoachViewWebCon(/*props: CoachViewWebConPropType */): JSX.Element {
  const dispatch = useDispatch();
  const videoElem = useRef<HTMLVideoElement>(null);
  const audioElem = useRef<HTMLAudioElement>(null);
  const [attachedAudioTrack, setAttachedAudioTrack] = React.useState(null);
  const [attachedVideoTrack, setAttachedVideoTrack] = React.useState(null);
  const [packetsSent, setPacketsSent] = React.useState(0);
  const [packetsReceived, setPacketsReceived] = React.useState(0);

  const coachUser: HuddleUser = useSelector((state: State) => state.huddleReducer.huddle && state.huddleReducer.huddle.coachUser);
  const huddle: Huddle = useSelector((state: State) => state.huddleReducer.huddle && state.huddleReducer.huddle);

  const studentVideoConsumer = useSelector((state: State) => state.webconReducer.studentVideoConsumer);
  const studentAudioConsumer = useSelector((state: State) => state.webconReducer.studentAudioConsumer);
  const localSpeechMode = useSelector((state: State) => state.speechReducer.localSpeechMode);
  const studentId = useSelector((state: State) => state.userReducer.userId);

  const coachUserId = coachUser && coachUser.userProfile && coachUser.userProfile.userId;

  const studentHuddleUser = huddle?.studentHuddleUsers[studentId];
  const audioMode = studentHuddleUser?.audioMode;
  const heyCoach = studentHuddleUser?.heyCoach;

  React.useEffect(() => {
    if (!attachedVideoTrack && studentVideoConsumer ) {

      debug('ZZZZZZ studentVideoConsumer',!!studentVideoConsumer, attachedVideoTrack);

      const stream = new MediaStream;
      const coachVideoTrack = studentVideoConsumer.track;
      setAttachedVideoTrack(coachVideoTrack);
      invariant(coachVideoTrack?.kind ==='video', 'studentVideoConsumer.track is not auvideodio'+JSON.stringify(studentVideoConsumer.track));
      stream.addTrack(coachVideoTrack);
      videoElem.current.srcObject = stream;
      debug('ZZZZZZ studentVideoConsumer',!!studentVideoConsumer, coachVideoTrack);
      if (videoElem.current && videoElem.current.play) {
        videoElem.current.play()
          .catch((error) => console.error('videoElem.play() failed:%o', error));
      }
    }

  }, [studentVideoConsumer,videoElem]);



  React.useEffect(() => {
    debug(attachedAudioTrack, studentAudioConsumer?.track);

    if (attachedAudioTrack && !studentAudioConsumer?.track && audioElem){
      audioElem.current.srcObject = null;
      setAttachedAudioTrack(false);

    }
    if (attachedAudioTrack && studentAudioConsumer?.track && audioElem){
      if (audioMode === AUDIO_MODE.WALKIE_TALKIE) {
        console.log('play');
        audioElem.current.play()
          .catch((error) => console.error('audioElem.play() failed:%o', error));
      }
      else {
        console.log('pause');
        audioElem.current.pause();
      }
    }
    if (!attachedAudioTrack && studentAudioConsumer && audioElem&& audioElem.current) {
      debug(studentAudioConsumer.track.getSettings());
      debug(studentAudioConsumer.track.getCapabilities());
      debug(studentAudioConsumer.track.getConstraints());
      const stream = new MediaStream;
      const coachAudioTrack = studentAudioConsumer.track;
      invariant(coachAudioTrack?.kind ==='audio', 'studentAudioConsumer.track is not audio'+JSON.stringify(studentAudioConsumer.track));
      console.log('AUDIO TRACK ',coachAudioTrack);
      setAttachedAudioTrack(true);
      stream.addTrack(coachAudioTrack);
      audioElem.current.srcObject = stream;

      if (audioMode === AUDIO_MODE.WALKIE_TALKIE) {
        console.log('play1');
        audioElem.current.play()
          .catch((error) => console.error('audioElem.play() failed:%o', error));
      }
      else {
        console.log('pause1');
        audioElem.current.pause();
      }

    }

  }, [attachedAudioTrack,studentAudioConsumer,audioElem ,audioMode]);


  const sendHeyCoach = React.useCallback(() => {
    dispatch(chatAddHeyCoach(studentId, coachUserId, studentId, 1));
    dispatch(huddleHeyCoachMode({type: 'CoachViewWebCon'}, studentId, coachUserId, true));
  }, []);
  const audioMuted = false;

  const coachNotConnected = !studentVideoConsumer || coachUser?.huddleUserStatus === HUDDLE.USER_STATUS.INACTIVE || studentHuddleUser?.huddleUserStatus !== HUDDLE.USER_STATUS.ENTERED;



  return (
    <View testID="CoachViewWebCon" style={styles.root}>

      <View  style={styles.videoPrefix}/>
      <View testID="coachVideoContainer" nativeID="coachVideoContainer" style={styles.videoContainer}>

        <video  ref={videoElem} autoPlay muted controls={false} style={{
          width: '180%',
          maxWidth: 400,
          height: '100%',
          display: 'flex',
          top: -VIDEO_SIZE,
          justifyContent: 'center',
          backgroundColor: 'orange',
        }}
        />


      </View>
      <audio
        style={audioStyle}
        ref={audioElem}
        autoPlay
        playsInline
        muted={audioMuted}
        controls={false}
      />
      {
        coachNotConnected &&
        <View
          style={[styles.placeholder, coachUser?.huddleUserStatus === HUDDLE.USER_STATUS.INACTIVE ? styles.placeholderInactive : styles.placeholderActive]}>
          <View style={styles.placeholderContent}>
            <Avatar.Image size={48} source={require('../../assets/coach.png')}/>
            <Text style={{
              color: COLOR.WHITE
            }}>Coach</Text>
          </View>
        </View>
      }
      {
        (heyCoach) &&
        <Image style={styles.heyCoach} source={require('../../assets/icons/heyCoach.png')}/>
      }
      {
        // attachedVideoTrack &&
        // (<TouchableOpacity onPress={sendHeyCoach} style={styles.heyCoach} activeOpacity={0.2}>
        //   <Image source={require('../../assets/icons/heyCoach.png')} style={{
        //     height: 80,
        //     width: 80,
        //     opacity: 0.2
        //   }}/>
        // </TouchableOpacity>)
      }
    </View>);
}
