import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import * as React from "react";
import {Image, Modal, StyleSheet, TouchableOpacity, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import AdminCoachDetail from "./containers/Admin/AdminCoachDetail";
import AdminCoachList from "./containers/Admin/AdminCoachList";

import AdminHome from "./containers/Admin/AdminHome";


import Login from "./containers/Auth/Login";
import Maintenance from "./containers/Auth/Maintenance";
import TestPageEnable from "./containers/Auth/TestPageEnable";
import RegisterParent from "./containers/Auth/RegisterParent";
import ResetPassword from "./containers/Auth/ResetPassword";
import CoachApplication from "./containers/Coach/CoachApplication";
import CoachClassMode from "./containers/Coach/CoachClassMode";
import CoachHome from "./containers/Coach/CoachHome";
import NotFound from "./containers/ErrorPages/NotFoundDedicated";
import Home from "./containers/Home";
import ParentApplication from "./containers/Parent/ParentApplication";
import ParentChildForm from "./containers/Parent/ParentChildForm";
import ParentHome from "./containers/Parent/ParentHome";

import StudentClassMode from "./containers/Student/StudentClassMode";
import StudentHome from "./containers/Student/StudentHome";
import ProgressionSetWorkbench from "./curriculum/ProgressionSetWorkbench";
import {navigationRef} from "./NavigationAction";
import type {State} from "./redux/reducer";
import {NAV} from "./redux/structs/navigationStruct";
import {ROLE} from "./sharedDefs/userDefs";

import {WEB_URL} from "./utils/environment";
import CplAvatar from "./components/General/CplAvatar";
import CoachMenuContents from "./containers/Coach/CoachMenuContents";
import ParentMenuContents from "./containers/Parent/ParentMenuContents";
import {navGoHome, navGoto} from "./redux/reducer/navigationReducer";
import RegisterCoach from "./containers/Auth/RegisterCoach";
import AssessmentWorkbench from "./curriculum/AssessmentWorkbench";


import {Button} from "react-native-paper";
import {userLogoutRequest, userStudentLogoutRequest} from "./redux/reducer/userReducer";

const CoachStack = createStackNavigator();
const ParentStack = createStackNavigator();
const StudentStack = createStackNavigator();
const AdminStack = createStackNavigator();
const AuthStack = createStackNavigator();

const linking = {
  prefixes: [WEB_URL],
  config: {
    screens: {
      Simple: 'simple',
      Home: 'home',
      Coach: 'c',
      CoachClassMode: 'c/classmode',
      CoachApplication: 'c/application',
      ParentChildForm: '/p/addChild',
      ParentApplication: '/p/profile',
      ParentHome: '/p/home',
      ParentSelectSession: '/p/selectSession/:studentId',
      StudentHome: 's/',
      StudentClassMode: 's/classMode',
      CurriculumWorkbench: 'a/cw',
      AssessmentWorkbench: 'a/aw',
      AdminCoachDetail: 'a/coach/:userId',
      AdminCoachList: 'a/coaches',
      AdminSessionDetail: 'a/session/:coachId',
      AdminSessionList: 'a/sessions',
      AdminHome: 'a/home',
      Login: 'login',
      Register: 'register/:role',
      ResetPassword: 'reset-password',
      RegisterParent:'RegisterParent',
      RegisterCoach:'RegisterCoach',
      Maintenance: 'maintenance',
      TestPage: 'TestPage',
      Parent: 'p',
      Auth: 'a'
    }
  }
};
const styles = StyleSheet.create({
  modalOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    backgroundColor:  'rgba(0,0,0,0.5)'
  },
  model:{

    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    zIndex: 100,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  }
});

function LogoTitle() {
  const [logoSource, setLogoSource] = React.useState(undefined);
  React.useEffect(() => {
    let isActive = true;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const logo = require('./assets/logoLandscape.png');
    if (isActive) {
      setLogoSource(logo);
    }
    return () => {
      isActive = false;
    };
  }, []);
  return <Image style={{
    width: 150,
    height: 60
  }} source={logoSource}/>;
}

export default function Navigation(): JSX.Element {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const dispatch = useDispatch();
  const role = useSelector((state: State) => state.userReducer.role);
  // const admin = 'no'; // process.env.REACT_APP_ADMIN;

  const loggedIn = useSelector((state: State) => state.userReducer.loggedIn);
  const userProfile = useSelector((state: State) => state.userReducer.userData?.userProfile);



  const menuPress = React.useCallback(() => {

    setMenuVisible(!menuVisible);
  }, []);

  const hideMenu= React.useCallback(() => {
    setMenuVisible(false);
  }, []);

  const goHome = () => {
    dispatch(navGoHome(role));
  };

  if (loggedIn && role === ROLE.COACH) {
    const options = {
      headerLeft: () =>  <TouchableOpacity onPress={goHome}><LogoTitle/></TouchableOpacity>,
      title: 'Coach',
      headerRight: () => {
        return (<CplAvatar userProfile={userProfile} onPress={menuPress}/>);
      },
    };


    return(
      <NavigationContainer ref={navigationRef} linking={linking} fallback={<CoachHome/>}>

        <Modal
          transparent={true}
          style={styles.model}
          visible={menuVisible}
          onDismiss={() => {

            hideMenu();
          }}
        >
          <CoachMenuContents  hideMenu={hideMenu}/>
        </Modal>
        <CoachStack.Navigator screenOptions={options}>
          <CoachStack.Screen name={NAV.COACH.HOME} component={CoachHome}/>
          <CoachStack.Screen name={NAV.COACH.CLASS_MODE} component={CoachClassMode}/>
          <CoachStack.Screen name={NAV.COACH.APPLICATION} component={CoachApplication}/>
        </CoachStack.Navigator>

      </NavigationContainer>);
  }

  if (loggedIn && role === ROLE.PARENT) {
    const options = {
      headerLeft: () =>  <TouchableOpacity onPress={goHome}><LogoTitle/></TouchableOpacity>,
      title: '',
      headerRight: () =>  <Button  testID="logout" onPress={()=>{ dispatch(userLogoutRequest());}}>Logout</Button>,
    };

    return <NavigationContainer ref={navigationRef} linking={linking} fallback={<ParentHome/>}>

      <Modal
        transparent={true}
        style={styles.model}
        visible={menuVisible}
        onDismiss={() => {

          hideMenu();
        }}
      >
        <ParentMenuContents  hideMenu={hideMenu}/>
      </Modal>

      <ParentStack.Navigator screenOptions={options}>
        <ParentStack.Screen name={NAV.PARENT.HOME} component={ParentHome} />
        <ParentStack.Screen name={NAV.PARENT.CHILD_FORM} component={ParentChildForm} />
        <ParentStack.Screen name={NAV.PARENT.APPLICATION} component={ParentApplication}  />
        <ParentStack.Screen name="TestPage" component={TestPageEnable} />
      </ParentStack.Navigator>
    </NavigationContainer>;
  }

  if (loggedIn && role === ROLE.STUDENT) {
    const options = {
      headerLeft: () => <TouchableOpacity onPress={goHome}><LogoTitle/></TouchableOpacity>,
      headerRight: () =>  <Button  testID="logout" onPress={()=>{ dispatch(userStudentLogoutRequest());}}>Logout</Button>,
      title: ''
    };
    return <>

      <NavigationContainer ref={navigationRef} linking={linking} fallback={<StudentHome/>}>
        <StudentStack.Navigator screenOptions={options}>
          <StudentStack.Screen name={NAV.STUDENT.HOME} component={StudentHome}/>
          <StudentStack.Screen name={NAV.STUDENT.CLASS_MODE} component={StudentClassMode} />
          <StudentStack.Screen name="TestPage" component={TestPageEnable} />
        </StudentStack.Navigator>
      </NavigationContainer>
    </>;
  }

  if (loggedIn && role === ROLE.ADMIN) {
    console.log('nav admin 1');
    const options = {
      headerLeft: () => <LogoTitle/>,
      title: 'Admin'
    };

    return <NavigationContainer ref={navigationRef} linking={linking} fallback={<NotFound/>}>
      <View style={{
        flexDirection: 'row',
        flex: 1
      }} >

        <View style={{flex:1}}>
          <Button onPress={() => {dispatch(navGoto(NAV.ADMIN.CURRICULUM_WORKBENCH));}}>Progression Set</Button>
          <Button onPress={() => {dispatch(navGoto(NAV.ADMIN.ASSESSMENT_WORKBENCH));}}>Assessments</Button>
          <Button onPress={() => {dispatch(navGoto(NAV.ADMIN.COACH_LIST));}}>Coach List</Button>
        </View>
        <View style={{flex:6}}>
          <AdminStack.Navigator screenOptions={options}>
            <AdminStack.Screen name={NAV.ADMIN.CURRICULUM_WORKBENCH} component={ProgressionSetWorkbench} />
            <AdminStack.Screen name={NAV.ADMIN.ASSESSMENT_WORKBENCH} component={AssessmentWorkbench} />
            <AdminStack.Screen name={NAV.ADMIN.COACH_DETAIL} component={AdminCoachDetail} />
            <AdminStack.Screen name={NAV.ADMIN.COACH_LIST} component={AdminCoachList} />
            <AdminStack.Screen name={NAV.ADMIN.HOME} component={AdminHome} />
          </AdminStack.Navigator>
        </View>
      </View>
    </NavigationContainer>

    ;

  }

  const options = {
    headerLeft: () => <LogoTitle/>,
    title: ''
  };


  return <NavigationContainer ref={navigationRef} linking={linking} fallback={<Home/>}>
    <AuthStack.Navigator screenOptions={options}>
      <AuthStack.Screen name={NAV.AUTH.HOME} component={Home}/>
      <AuthStack.Screen name={NAV.AUTH.LOGIN} component={Login}/>
      <AuthStack.Screen name={NAV.AUTH.REGISTER_PARENT} component={RegisterParent}/>
      <AuthStack.Screen name={NAV.AUTH.REGISTER_COACH} component={RegisterCoach}/>
      <AuthStack.Screen name={NAV.AUTH.RESET_PASSWORD} component={ResetPassword}/>
      <AuthStack.Screen name={NAV.AUTH.MAINTENANCE} component={Maintenance}/>
      <AuthStack.Screen name={NAV.AUTH.TESTPAGE} component={TestPageEnable}/>
    </AuthStack.Navigator>
  </NavigationContainer>;
}


