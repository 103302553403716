import * as React from "react";
import {StyleSheet, Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import DotNumber from "./DotNumber";
import NumberKeypad from "./NumberKeypad";
import {gridStyles, rootStyles, textStyles, widgetTextStyles} from "./WidgetStyles";
import {DataTable} from "react-native-paper";
import AnswerBlock from "./AnswerBlock";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
const styles = StyleSheet.create({
  tableRow :{
    borderBottomWidth: 0,
    margin:5
  },
  coachTableRow:{
    minHeight:25
  }
});
export function DotDivisionWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  return <View testID="DotDivisionWidgetCoach" style={rootStyles.coachRoot}>
    <View style={[gridStyles.gridColumnContainer, {
      width:'100%',
      alignSelf: 'center'
    }]}>
      <DataTable style={{ width:'100%'}}>
        <DataTable.Row key={'top'} style={styles.coachTableRow}>
          <DataTable.Cell> </DataTable.Cell>
          <DataTable.Cell>
            <Text style={textStyles.coachProblemText}>
              {curriculumStudentResponse?.responseKeyValues.set?.quotient}
            </Text>
          </DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row key={'bottom'} style={styles.coachTableRow}>
          <DataTable.Cell  style={{justifyContent:'flex-end'}}>
            <Text style={[textStyles.coachProblemText]}>
              {curriculumStudentInteraction?.problem?.params?.prompts?.divisor}
            </Text>
          </DataTable.Cell>
          <DataTable.Cell style={{borderTopWidth:4, borderLeftWidth:4}}>
            <Text style={textStyles.coachProblemText}>
              {curriculumStudentInteraction?.problem?.params.prompts.dividend}
            </Text>
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>

    </View>
  </View>;
}
export function DotDivisionWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    onResponse,
    inWorkbench
  } = props;
  const {
    problem,

  } = curriculumStudentInteraction;
  const {
    divisor,
    dividend
  } = problem?.params?.prompts|| {};
  const {
    quotient
  } = curriculumStudentResponse?.responseKeyValues?.set || {};
  const onKeyPress = React.useCallback((_answer: string) => {
    debug('onKeyPress', _answer);
    onResponse('quotient', _answer);
  },[]);
  const [widgetWidth, setWidgetWidth] = React.useState(0);
  const [widgetHeight, setWidgetHeight] = React.useState(0);

  const dotWidth =(widgetWidth > 500) ? parseInt(quotient, 10)*18: parseInt(quotient, 10)*16;
  debug(' DotDivisionWidgetStudent Create Element ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
  return <View testID="DotDivisionWidgetStudent" style={rootStyles.studentRoot} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    <View style={gridStyles.gridColumnCenteredContainer}>
      <View  style={[gridStyles.gridRowContainer, {width:'100%'}]}>
        <DataTable>
          <DataTable.Row key={'top'} style={styles.tableRow}>
            <DataTable.Cell> </DataTable.Cell>
            <DataTable.Cell style={{maxWidth:dotWidth, minWidth:dotWidth}}> </DataTable.Cell>
            <DataTable.Cell>
              <Text style={widgetTextStyles(widgetWidth).problemText}>
                <AnswerBlock  widgetWidth={widgetWidth} actual={quotient} expected={problem?.params?.expectedResponseKeyValues?.set?.quotient} ></AnswerBlock>
              </Text>
            </DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row key={'bottom'} style={styles.tableRow}>
            <DataTable.Cell  style={{justifyContent:'flex-end'}}>
              <Text style={[widgetTextStyles(widgetWidth).problemText]}>
                {divisor}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={{maxWidth:dotWidth, minWidth:dotWidth}}>
              <DotNumber widgetWidth = {widgetWidth} vertical={parseInt(divisor, 10)} horizontal={parseInt(quotient, 10)} />
            </DataTable.Cell>
            <DataTable.Cell style={{borderTopWidth:4, borderLeftWidth:4}}>
              <Text style={widgetTextStyles(widgetWidth).problemText}>
                {dividend}
              </Text>
            </DataTable.Cell>
          </DataTable.Row>
        </DataTable>
      </View>
      <View  style={gridStyles.gridRowContainer}>
        <NumberKeypad  widgetWidth={widgetWidth} inWorkbench={inWorkbench} onKeyPress={onKeyPress} />
      </View>
    </View>
  </View>;
}
const defaultProgressionSetId = 'Math.G3.OA.B.6.DivideSingleDigit.1';
const DotDivisionWidget: WidgetSet = {
  widgetSetId: 'DotDivisionWidget',
  coach: DotDivisionWidgetCoach,
  student: DotDivisionWidgetStudent,
  defaultProgressionSetId,

};
export default DotDivisionWidget;
