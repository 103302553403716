import invariant from "invariant";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import { ValidateAction } from "../../utils/validation";
import type { SpeechMode } from "../../sharedDefs/speechDefs";
import { SPEECH_MODE } from "../../sharedDefs/speechDefs";
import type { UserId } from "../../sharedDefs/userDefs";
import { addReducerLogging, loggerCopy } from "./common";
import { ACTION_ORIGIN } from "../structs/firestoreStruct";
import type { SpeechAction, SpeechState } from "../structs/speechStruct";
import { SPEECH_ACTION } from "../structs/speechStruct";
const debug = debugModule(DEBUG_MODULE.WEBCON.SPEECH);
export const setRecognizerRunning = (recognizer: any, recognizerRunning: boolean): SpeechAction => {
  invariant(recognizer, 'invalid-action');
  debug(SPEECH_ACTION.RECOGNIZER.SET, recognizerRunning);
  return {
    type: SPEECH_ACTION.RECOGNIZER.SET,
    origin: ACTION_ORIGIN.INSTIGATE,
    recognizer,
    recognizerRunning
  };
};
export const setSpeechMode = (localSpeechMode: SpeechMode, studentId: UserId): SpeechAction => {
  invariant(localSpeechMode  , 'invalid-action');
  debug(SPEECH_ACTION.SPEECH_MODE.SET, localSpeechMode);
  return {
    type: SPEECH_ACTION.SPEECH_MODE.SET,
    origin: ACTION_ORIGIN.INSTIGATE,
    localSpeechMode,
    studentId,
  };
};
export const setSpeechChannel = (speechChannel?: any): SpeechAction => {
  debug(SPEECH_ACTION.SPEECH_CHANNEL.SET, speechChannel);
  return {
    type: SPEECH_ACTION.SPEECH_CHANNEL.SET,
    origin: ACTION_ORIGIN.INSTIGATE,
    speechChannel
  };
};
const initialState: SpeechState = {
  localSpeechMode: SPEECH_MODE.OFF,
  recognizerRunning: false,

};

function _speechReducer(state: SpeechState = initialState, action: SpeechAction): SpeechState {
  switch (action.type) {
    case SPEECH_ACTION.RECOGNIZER.SET:
    {
      debug('SetRecognizer ', action.recognizerRunning, action.recognizer);
      invariant(action.recognizer, 'invalid-action');
      return { ...state,
        recognizer: action.recognizer,
        recognizerRunning: action.recognizerRunning || false
      };
    }

    case SPEECH_ACTION.SPEECH_CHANNEL.SET:
    {
      ValidateAction(action, ['speechChannel']);
      invariant(action.speechChannel, 'invalid-action');
      return { ...state,
        speechChannel: action.speechChannel
      };
    }

    case SPEECH_ACTION.SPEECH_MODE.SET:
    {
      const {
        localSpeechMode,
        studentId,
      } = action;
      invariant(localSpeechMode , 'invalid-action');
      return { ...state,
        localSpeechMode,
        studentId,
      };
    }

    default:
      return state;
  }
}

function actionForLogger(action: SpeechAction) {
  const {
    type,
    origin
  } = action;
  return {
    type,
    origin,
    error: action.error,
    action: loggerCopy(action)
  };
}

export default function speechReducer(state: SpeechState = initialState, action: SpeechAction): SpeechState {
  const prefix = 'SPEECH_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _speechReducer(state, action);

  debug('SPEECH_MODE', newState.localSpeechMode);
  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
