import { detailedDiff } from "deep-object-diff";
import produce from "immer";
import type { DebugLogger } from "../../utils/debugModule";
import { ACTION_ORIGIN } from "../structs/firestoreStruct";
export type ReducerLog = {
  timeStamp: number;
  type?: string;
  actionSimplified: any;
  actionSimplifiedStr: string;
  stateDiff: any;
  stateDiffStr?: string;
};
export function loggerCopy(obj: any): any {
  const retObj = {};

  if (!obj) {
    return 'null';
  }

  Object.entries(obj).forEach(([key, value]) => {
    if (key === 'error' || key === 'origin') {
      retObj[key] = value;
    } else {
      const t = typeof value;

      if (!value) {
        retObj[key] = 'null';
      } else if (t === 'string' || t === 'number' || t === 'boolean') {
        retObj[key] = value;
      } else if (Array.isArray(value)) {
        retObj[key] = 'Array len ' + value.length;
      } else if (value instanceof Date) {
        retObj[key] = value.toDateString();
      } else {
        retObj[key] = 'Object';
      }
    }
  });
  return retObj;
}
export function loggerState(obj: any): any {
  const retObj = {};

  if (!obj) {
    return 'null';
  }

  Object.entries(obj).forEach(([key, value]) => {
    const t = typeof value;

    if (!value) {
      retObj[key] = 'null';
    } else if (t === 'string' || t === 'number' || t === 'boolean') {
      retObj[key] = value;
    } else if (Array.isArray(value)) {
      retObj[key] = 'Array len ' + value.length;
    } else if (value instanceof Date) {
      retObj[key] = value.toDateString();
    } else {
      retObj[key] = loggerCopy(value);
    }
  });
  return retObj;
}
export function addReducerLogging(debug: DebugLogger, state: any, newState: any, actionSimplified: any): any {
  const circularCache = [];

  function circularReplacer(key, value) {
    if (typeof value === 'object' && value !== null) {
      // Duplicate reference found, discard key
      if (circularCache.includes(value)) return;
      // Store value in our collection
      circularCache.push(value);
    }

    // eslint-disable-next-line consistent-return
    return value;
  }

  const stateDiff = detailedDiff(loggerState(state), loggerState(newState));
  const timeStamp = Date.now();
  const actionSimplifiedStr = JSON.stringify(actionSimplified, circularReplacer, 2);
  const logObject: ReducerLog = {
    timeStamp,
    actionSimplifiedStr,
    actionSimplified,
    stateDiff
  };

  if (actionSimplified && actionSimplified.type && actionSimplified.type.endsWith('.FAILURE')) {
    console.error(actionSimplified.type, actionSimplified.error, logObject);
    debug(actionSimplified.type, actionSimplified.error, logObject);
  }

  const finalState = produce(newState, draftState => {
    // eslint-disable-next-line no-param-reassign
    //draftState.logs[timeStamp] = logObject;
  });

  if (actionSimplified.origin === ACTION_ORIGIN.INSTIGATE) {
    debug(actionSimplified.type);
  }

  if (actionSimplified.origin === ACTION_ORIGIN.SECONDARY || actionSimplified.origin === ACTION_ORIGIN.REMOTE) {
    debug(actionSimplified.type);
  }

  debug(actionSimplified.type, actionSimplified);
  return finalState;
}
