import * as React from "react";
import { RadioButton } from "react-native-paper";
import { StyleSheet, Text, View } from "react-native";
const styles = StyleSheet.create({
  formControl: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  radioLabel: {},
  errors: {}
});
type CplRadioGroupProps = {
  label: string;
  name: string;
  formObject: Record<string, any>;
  errors: Record<string, any>;
  required?: boolean;
  onChange: (name: string, newValue: string) => void;
  radioButtons: Array<{
    label: string;
    value: string;
  }>;
};
export default function CplRadioGroup(props: CplRadioGroupProps): JSX.Element {
  const {
    label,
    name,
    formObject,
    onChange,
    errors,
    radioButtons
  } = props;
  const value = formObject[name];
  const buttonDivs = radioButtons.map(radioButton => <View key={radioButton.value + '_radiokey'}>
    <Text style={styles.radioLabel}>{radioButton.label}</Text>
    <RadioButton testID={radioButton.value + '_radio'} value={radioButton.value} />
  </View>);
  return <RadioButton.Group onValueChange={newValue => onChange(name, newValue)} value={value}>
    {buttonDivs}
  </RadioButton.Group>;
}
