import * as React from "react";
import {Text, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";


import type {State} from "../redux/reducer";
import {curriculumStudentResponseKVPerform, curriculumSubmitPerform} from "../redux/reducer/curriculumReducer";
import type {
  ConfidenceType,
  CurriculumStudentInteraction,
  CurriculumStudentResponse
} from "../sharedDefs/curriculumDefs";
import {CURRICULUM} from "../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import ConfidenceSelect from "../components/ConfidenceSelect";
import ResponseDataUtil from "./ResponseDataUtil";
import WidgetInventory from "../widgets/WidgetInventory";
import invariant from "invariant";

const debug = debugModule(DEBUG_MODULE.WEBCON.STUDENT);


export default function StudentWidgetContainer(): JSX.Element {
  const dispatch = useDispatch();
  const studentId = useSelector((state: State) => state.userReducer.userId);
  const curriculumStudentResponse:CurriculumStudentResponse  = useSelector((state: State) => state.curriculumReducer.curriculumStudentResponseMap[studentId]);
  const curriculumStudentInteraction:CurriculumStudentInteraction= useSelector((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
  const [widgetWidth, setWidgetWidth] = React.useState<number>(0);
  const [widgetHeight, setWidgetHeight] = React.useState<number>(0);

  // const curriculumStudentResponse2:CurriculumStudentResponse  = useSelector((state: State) => state.curriculumReducer.curriculumStudentResponseMap[studentId],(l,r)=> {
  //   debug('Use selector 2 ',l?.interactionId, r?.interactionId);
  //   return l?.interactionId=== r?.interactionId;
  // });
  useSelector((state: State) => state.curriculumReducer.curriculumStudentResponseMap,(l,r)=> {
    debug('Use selector mapp check');
    return l == r;
  });

  debug('Inteaction Id in container ',curriculumStudentResponse?.interactionId,curriculumStudentInteraction?.interactionId );

  const {
    problem,
    coachId,
    widgetSetId
  } = curriculumStudentInteraction || {};
  // const {
  //
  // } = problem || {};

  debug('INTERACTION ID  ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
  debug('RESPONSE '+JSON.stringify( curriculumStudentResponse?.responseKeyValues?.set,null,2));
  const widgetSet = WidgetInventory(widgetSetId);
  const onResponse =(key: string, value: string, index?: number) => {

    // debug('onResponse INTERACTION ID  ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
    //
    // invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch');
    //
    // const _responseData = key === 'instruction' ? curriculumStudentResponse : ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);
    //
    // const curriculumStudentResponseUpdated = ResponseDataUtil.responseStatusSet(_responseData, problem);
    // // curriculumStudentResponseUpdated.interactionId = interactionId;
    //
    // invariant(curriculumStudentResponseUpdated?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch');

    dispatch(curriculumStudentResponseKVPerform(studentId, coachId, key,value,index));
  };

  const onConfidenceKeyPress = React.useCallback((_confidence: ConfidenceType) => {
    debug('onConfidenceKeyPress', _confidence);

    invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch 2');

    const curriculumStudentResponseUpdated = ResponseDataUtil.responseAddConfidence(curriculumStudentResponse, _confidence);
    debug('Confidence ',JSON.stringify( curriculumStudentResponseUpdated,null,2));
    invariant(curriculumStudentResponseUpdated?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch 2');

    dispatch(curriculumSubmitPerform(studentId, coachId,curriculumStudentResponseUpdated));
    // setTimeout(() => {
    //   debug('onConfidenceKeyPress wait curriculumStudentResponse NULL', _confidence);
    //   dispatch(curriculumProgressionSetProblemAdvanceRequest(studentId,  coachId));
    // }, 2000);
  }, [curriculumStudentResponse, curriculumStudentInteraction]);

  invariant(curriculumStudentInteraction?.interactionId== curriculumStudentResponse?.interactionId, 'interactionId Mismatch');

  // console.log('QQQ curriculumStudentInteraction.problem',curriculumStudentInteraction.problem);
  // console.log('curriculumStudentResponse',curriculumStudentResponse);
  debug('DIM', widgetWidth, widgetHeight);
  return <View testID="StudentWidgetContainer" style={{
    flex: 1,
    flexDirection: 'column',
    padding: 20,
    alignItems: 'center',
  }}  onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    {widgetSet && curriculumStudentResponse && curriculumStudentResponse.responseKeyValues &&
         React.createElement(widgetSet.student, {
           curriculumStudentInteraction,
           curriculumStudentResponse,
           onResponse: (key: string, value: string, index?: number) => {
             // invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch 3');
             //
             // debug('call on response ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);
             onResponse(key, value, index);
           }
         })}
    { !(widgetSet && curriculumStudentResponse && curriculumStudentResponse.responseKeyValues) && <Text>Student</Text>}

    {function () {
      if (!curriculumStudentResponse?.confidence && (curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.CORRECT || curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.INCORRECT)) {
        return (<ConfidenceSelect onKeyPress={onConfidenceKeyPress}/>);
      }
      if (curriculumStudentResponse?.confidence && curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.CORRECT) {
        return (<Text>Correct</Text>);
      }
      if (curriculumStudentResponse?.confidence && curriculumStudentResponse?.responseState === CURRICULUM.RESPONSE_STATE.INCORRECT) {
        return (<Text>Incorrect</Text>);
      }
      return (<View/>);
    }()}
  </View>;
}
