import type {CoachId, StudentId, UserId} from "./userDefs";

import {ActionType} from "./commonStruct";



export const WEBCON_SESSION_STATE = {
  INITIALIZING:'INITIALIZING',
  INITIALIZED:'INITIALIZED',
  INITIALIZED_FAILURE:'INITIALIZED_FAILURE',

};
export const WEBCON_ACTION = {
  SESSION: {
    INITIALIZE: {
      REQUEST: 'WEBCON_ACTION.SESSION.INITIALIZE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.SESSION.INITIALIZE.FAILURE',
    },
    ENTER: {
      REQUEST: 'WEBCON_ACTION.SESSION.ENTER.REQUEST',
      SUCCESS: 'WEBCON_ACTION.SESSION.ENTER.SUCCESS',
      FAILURE: 'WEBCON_ACTION.SESSION.ENTER.FAILURE',
    },
    LEAVE: {
      REQUEST: 'WEBCON_ACTION.SESSION.LEAVE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.SESSION.LEAVE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.SESSION.LEAVE.FAILURE',
    },
  },
  COACH_DISCONNECT: {
    REQUEST: 'WEBCON_ACTION.COACH_DISCONNECT.REQUEST',
    SUCCESS: 'WEBCON_ACTION.COACH_DISCONNECT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.COACH_DISCONNECT.FAILURE',
  },
  COACH_DISCONNECT_TO_STUDENT: {
    REQUEST: 'WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST',
    SUCCESS: 'WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.FAILURE',
  },
  DEVICE_INFOS: {
    ASSIGN: 'WEBCON_ACTION.DEVICE_INFOS.ASSIGN',
    ASSIGN_CAMERA: 'WEBCON_ACTION.DEVICE_INFOS.ASSIGN_CAMERA',
    ASSIGN_MIC: 'WEBCON_ACTION.DEVICE_INFOS.ASSIGN_MIC',
    ASSIGN_SPEAKER: 'WEBCON_ACTION.DEVICE_INFOS.ASSIGN_SPEAKER',

  },
  CHANGE_DEVICES: {
    REQUEST: 'WEBCON_ACTION.CHANGE_DEVICES.REQUEST',
    SUCCESS: 'WEBCON_ACTION.CHANGE_DEVICES.SUCCESS',
    FAILURE: 'WEBCON_ACTION.CHANGE_DEVICES.FAILURE',
  },
  DEBUG: {
    WEBCON_STATE: 'WEBCON_ACTION.DEBUG.WEBCON_STATE',
    WEBCON_STATE_SUMMARY: 'WEBCON_ACTION.DEBUG.WEBCON_STATE_SUMMARY',
    ROOM_SUMMARY: 'WEBCON_ACTION.DEBUG.ROOM_SUMMARY',
    FAILURE: 'WEBCON_ACTION.DEBUG.FAILURE',
  },
  WEBCAM_UPDATE: {
    REQUEST: 'WEBCON_ACTION.WEBCAM_UPDATE.REQUEST',
    SUCCESS: 'WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS',
    FAILURE: 'WEBCON_ACTION.WEBCAM_UPDATE.FAILURE'
  },
  MICROPHONE_UPDATE: {
    REQUEST: 'WEBCON_ACTION.MICROPHONE_UPDATE.REQUEST',
    SUCCESS: 'WEBCON_ACTION.MICROPHONE_UPDATE.SUCCESS',
    FAILURE: 'WEBCON_ACTION.MICROPHONE_UPDATE.FAILURE',
  },
  COACH_CONSUME_STUDENT_VIDEO: {
    REQUEST: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.REQUEST',
    SUCCESS: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS',
    FAILURE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE',
    //CLOSE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.CLOSE',
    NODE: {
      REQUEST: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.FAILURE',
    }
  },
  COACH_STUDENT_CONSUMERS:{
    CLOSE:'WEBCON_ACTION.COACH_STUDENT_CONSUMERS.CLOSE'
  },
  COACH_CONSUME_STUDENT_AUDIO: {
    REQUEST: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.REQUEST',
    SUCCESS: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS',
    FAILURE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.FAILURE',
    //CLOSE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.CLOSE',
    NODE: {
      REQUEST: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.NODE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.NODE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.NODE.FAILURE',
    }
  },

  STUDENT_CONSUME_COACH_VIDEO: {
    REQUEST: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.REQUEST',
    CLOSE: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.CLOSE',
    SUCCESS: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.SUCCESS',
    FAILURE: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.FAILURE',
    NODE: {
      REQUEST: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.NODE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.NODE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.NODE.FAILURE',
    }
  },
  STUDENT_CONSUME_COACH_AUDIO: {
    REQUEST: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.REQUEST',
    SUCCESS: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS',
    FAILURE: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.FAILURE',
    NODE: {
      REQUEST: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.NODE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.NODE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.NODE.FAILURE',
    }
  },

  ANYONE_SEND_TRANSPORT: {
    REQUEST: 'WEBCON_ACTION.ANYONE_SEND_TRANSPORT.REQUEST',
    SUCCESS: 'WEBCON_ACTION.ANYONE_SEND_TRANSPORT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.ANYONE_SEND_TRANSPORT.FAILURE'
  },
  TRANSPORT_CONNECT: {
    REQUEST: 'WEBCON_ACTION.TRANSPORT_CONNECT.REQUEST',
    SUCCESS: 'WEBCON_ACTION.TRANSPORT_CONNECT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.TRANSPORT_CONNECT.FAILURE',
  },
  TRANSPORT_PRODUCE: {
    REQUEST: 'WEBCON_ACTION.TRANSPORT_PRODUCE.REQUEST',
    SUCCESS: 'WEBCON_ACTION.TRANSPORT_PRODUCE.SUCCESS',
    FAILURE: 'WEBCON_ACTION.TRANSPORT_PRODUCE.FAILURE'
  },
  COACH_RECIEVE_TRANSPORT: {
    REQUEST: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.REQUEST',
    CLOSE: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.CLOSE',
    SUCCESS: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.FAILURE',
    NODE: {
      REQUEST: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.REQUEST',
      SUCCESS: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.SUCCESS',
      FAILURE: 'WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.FAILURE',
    }
  },
  STUDENT_RECIEVE_TRANSPORT: {
    REQUEST: 'WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.REQUEST',
    SUCCESS: 'WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.SUCCESS',
    FAILURE: 'WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.FAILURE'
  },
  ROUTER_RTP_CAPABILITIES: {
    REQUEST: 'WEBCON_ACTION.ROUTER_RTP_CAPABILITIES.REQUEST',
    SUCCESS: 'WEBCON_ACTION.ROUTER_RTP_CAPABILITIES.SUCCESS',
    FAILURE: 'WEBCON_ACTION.ROUTER_RTP_CAPABILITIES.FAILURE'
  },
  ENABLE_WEBCAM: {
    REQUEST: 'WEBCON_ACTION.ENABLE_WEBCAM.REQUEST',
    SUCCESS: 'WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS',
    FAILURE: 'WEBCON_ACTION.ENABLE_WEBCAM.FAILURE',
    CLOSE: 'WEBCON_ACTION.ENABLE_WEBCAM.CLOSE'
  },
  ENABLE_MICROPHONE: {
    REQUEST: 'WEBCON_ACTION.ENABLE_MICROPHONE.REQUEST',
    SUCCESS: 'WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS',
    FAILURE: 'WEBCON_ACTION.ENABLE_MICROPHONE.FAILURE'
  },
  DEVICE: {
    REQUEST: 'WEBCON_ACTION.DEVICE.REQUEST',
    SUCCESS: 'WEBCON_ACTION.DEVICE.SUCCESS',
    FAILURE: 'WEBCON_ACTION.DEVICE.FAILURE'
  }
};
export type IosMediaDeviceInfo =  {groupId: string,facing: string,deviceId: string,label: string,kind:string };
export type DeviceInfos = Array<MediaDeviceInfo | IosMediaDeviceInfo>;

export type ProducerSummary = {
  appData?: Record<string, any>,
  producerId?: boolean,
  kind?: string,
}

export type ConsumerSummary = {
  appData?: Record<string, any>,
  producerId?: boolean,
  consumerId?: boolean,
  kind?: string,
}
export type RouterSummary = {
  appData?: Record<string, any>,
  transports?:number,
  producers?: number,
}
export type StudentBroadcasterSummary = {
  userId?: string,
  device?: Record<string, string>,
  rtpCapabilities?: { codecLength: number },
  anyClientSendTransport?: string,
  studentReceiveTransport?: string,
  studentReceiveVideoConsumer?: ConsumerSummary,
  studentReceiveAudioConsumer?: ConsumerSummary
  videoProducer?: ProducerSummary;
  audioProducer?: ProducerSummary;
}


export type CoachBroadcasterSummary = {
  userId?: string,
  device?: Record<string, string>,
  rtpCapabilities?: { codecLength: number },
  anyClientSendTransport?: string,
  coachReceiveStudentTransports?: Record<UserId, string>,
  coachReceiveVideoConsumers?: Record<UserId, ConsumerSummary>,
  coachReceiveAudioConsumers?: Record<UserId, ConsumerSummary>,
  videoProducer?: ProducerSummary;
  audioProducer?: ProducerSummary;
}
// export type TransportSummary = {
//   iceRole?: boolean,
//   iceState?: boolean,
//   dtlsParameters?: boolean,
//   dtlsState?: boolean
// }
export type RoomSummary =
  {
    coachId?: CoachId,
    closed?: boolean,
    coachBroadcaster?: CoachBroadcasterSummary,
    mediasoupRouter?: RouterSummary;
    studentBroadcasterMap?:Record<UserId,StudentBroadcasterSummary>;
  }

export type WebsocketSummary = {
     rooms: Record<string, Array<string>>,
     sids: Record<string, Array<string>>,
  };

export type TransportDef = {
  id: string;
  userId: UserId;
  iceParameters: unknown;
  iceCandidates: unknown;
  dtlsParameters: Record<string, any>;
  sctpParameters: unknown;
};
export type WebconActionNode = ActionType & {
  transportDef?: TransportDef;
  studentTransportDefs?: Record<string, TransportDef>;
  coachReceiveStudentTransportDefs?: Record<string, TransportDef>;
  routerRtpCapabilities?: unknown;
  producerId?: string;
  consumerId?: string;
  coachId?: CoachId;
  transportId?: string;
  dtlsParameters?: unknown;
  rtpParameters?: unknown;
  appData?: unknown;
  kind?: unknown;
  userId?: UserId;

  studentId?: StudentId;
  deviceInfos?: DeviceInfos;
  webconItem?: unknown;
  roomSummary?: RoomSummary;
  websocketSummary?: WebsocketSummary;
  consumerType?: string;
  error?: Error;
};
