import {DefaultTheme, Provider as PaperProvider} from "react-native-paper";
import * as React from "react";
import {COLOR} from "../utils/UiConstants";


type CplThemeProviderProps = {
  children: any;
};
const theme = {
  dark: false,
  roundness: 4,
  colors: {
    primary: COLOR.LT_BLUE,
    accent: COLOR.GREEN,
    background: COLOR.NEUTRAL.WHITE,
    surface: COLOR.NEUTRAL.WHITE,
    error: COLOR.RED,
    text: COLOR.NEUTRAL.BLACK,
    onBackground: COLOR.NEUTRAL.WHITE,
    onSurface: COLOR.NEUTRAL.WHITE,
    disabled: COLOR.NEUTRAL.DISABLED,
    placeholder: COLOR.NEUTRAL.BASE,
    backdrop: COLOR.NEUTRAL.BASE,
    notification: COLOR.RED
  },
  fonts: DefaultTheme.fonts,
  animation: {
    scale: 1.0,
    useNativeDriver: false
  }
};
export default function CplThemeProvider(props: CplThemeProviderProps): JSX.Element{
  const {
    children
  } = props;
  return <PaperProvider theme={theme}>
    {children}
  </PaperProvider>;
}
