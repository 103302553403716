import {all, call, put, select, take, takeEvery} from "redux-saga/effects";
import invariant from "invariant";
import {detectDevice, Device} from "mediasoup-client";
import {getContext} from "redux-saga-test-plan/matchers.js";
import type {WebconAction, WebconState, WebconSummaryAction} from "../structs/webconStruct";
import {enumerateMockDevices, getUserMedia, MockMediasoupDevice} from "../structs/webconStruct";
import type {State} from "../reducer";
import {emitWithResponse} from "./sagaUtils";
import {DeviceInfos, WEBCON_ACTION, WEBCON_SESSION_STATE} from "../../sharedDefs/webconDefs";
import {
  webconAnyoneSendTransport,
  webconCoachConsumeStudentAudio,
  webconCoachConsumeStudentVideo,
  webconCoachRecieveTransport,
  webconDeviceCamera,
  webconDeviceInfos,
  webconDeviceMic,
  webconDeviceSetup,
  webconDeviceSpeaker,
  webconEnableMicrophone,
  webconEnableWebcam,
  webconMicrophoneUpdate,
  webconSessionInitialize,
  webconStudentConsumeCoachAudio,
  webconStudentConsumeCoachVideo,
  webconStudentRecieveTransport,
  webconTransportConnect,
  webconTransportProduce,
  webconWebcamUpdate
} from "../reducer/webconReducer";
import {Transport} from "mediasoup-client/lib/Transport.js";
import {HUDDLE, Huddle} from "../../sharedDefs/huddleDefs";

import {webSocketInit} from "../reducer/wsReducer";
import {WS_ACTION} from "../../sharedDefs/webSocketDefs";
import {CoachId, ROLE, StudentId, UserId} from "../../sharedDefs/userDefs";
import './webconSagaInit';
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {JEST_WORKER_ID} from "../../utils/environment";
import {Platform} from "react-native";
import {firestore} from "../rsf";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";




const debug = debugModule(DEBUG_MODULE.WEBCON.SAGA);
const debugSendTransport = debugModule(DEBUG_MODULE.WEBCON.SAGA_SEND_TRANSPORT);
const debugWebcamEnable = debugModule(DEBUG_MODULE.WEBCON.SAGA_WEBCAM_ENABLE);

export type Saga<T> = Generator<any, T, any>;
const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{
    googDscp: true
  }]
};
const VIDEO_CONSTRAINS = {
  qvga: {
    width: {
      ideal: 320
    },
    height: {
      ideal: 240
    }
  },
  vga: {
    width: {
      ideal: 640
    },
    height: {
      ideal: 480
    }
  },
  hd: {
    width: {
      ideal: 1280
    },
    height: {
      ideal: 720
    }
  }
};
const WEBCAM_KSVC_ENCODINGS = [{
  scalabilityMode: 'S3T3_KEY'
}];

export function webConEvent(studentId:StudentId, coachId: CoachId, role:string, event: string): Promise<unknown>{
  const data = {
    studentId: studentId,

    role: role,
    event
  };
  invariant(studentId &&  role && event,JSON.stringify(data));
  const d = new Date();
  const id =String(d.getMinutes()).padStart(2,'0')+'-'+String(d.getSeconds()).padStart(2,'0')+'-'+String(d.getMilliseconds()).padStart(3,'0')+'-'+role+'Client-'+ studentId;


  return firestore.collection(COLLECTION.WEBCON_EVENTS).doc(id).set(data);

}


function* webconCoachDisconnectToStudentSaga(){
  try {
    debug('webconCoachDisconnectToStudentSaga-1');
    const role = yield select((state: State) => state.userReducer.role);
    const userId = yield select((state: State) => state.userReducer.userId);
    const studentAudioConsumer = yield select((state: State) => state.webconReducer.studentAudioConsumer);
    const studentVideoConsumer = yield select((state: State) => state.webconReducer.studentVideoConsumer);

    const microphoneProducer = yield select((state: State) => state.webconReducer.microphoneProducer);
    const webcamProducer = yield select((state: State) => state.webconReducer.webcamProducer);

    webConEvent(userId,'',role,'studentAudio and Video Consumer close ').then();

    debug('webconCoachDisconnectToStudentSaga-1',!!studentAudioConsumer,!!studentVideoConsumer,!!microphoneProducer, !!webcamProducer);
    if (studentAudioConsumer){
      studentAudioConsumer.close();
    }
    if (studentVideoConsumer){
      studentVideoConsumer.close();
    }

    if (microphoneProducer){
      microphoneProducer.close();
    }

    if (webcamProducer){
      webcamProducer.close();
    }

    yield put(webSocketInit(userId,HUDDLE.NO_COACH));

    yield put({
      type: WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.SUCCESS,

    });
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.FAILURE,
      error
    });
  }
}

function* webconCoachDisconnectSaga(){
  try {
    debug('webconCoachDisconnectSaga');
    const role = yield select((state: State) => state.userReducer.role);
    const userId = yield select((state: State) => state.userReducer.userId);
    const studentAudioConsumer = yield select((state: State) => state.webconReducer.studentAudioConsumer);
    const studentVideoConsumer = yield select((state: State) => state.webconReducer.studentVideoConsumer);
    if (role === ROLE.STUDENT){
      webConEvent(userId,'',role,'studentAudio and Video Consumer close ').then();
      if (studentAudioConsumer){
        studentAudioConsumer.close();
      }
      if (studentVideoConsumer){
        studentVideoConsumer.close();
      }

      yield put(webSocketInit(userId,HUDDLE.NO_COACH));
    }

    yield put({
      type: WEBCON_ACTION.COACH_DISCONNECT.SUCCESS,

    });
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_DISCONNECT.FAILURE,
      error
    });
  }
}
function* webconSessionInitializeSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;

    debug('webconSessionInitializeSaga-1', userId, coachId);
    const huddle: Huddle = yield select((state: State) => state.huddleReducer.huddle);
    const socketClient = yield select((state:State) => state.wsReducer.socketClient);

    debug('webconSessionInitializeSaga-2', userId, coachId);
    if (!socketClient) {

      yield put(webSocketInit(userId, coachId));
      yield take(WS_ACTION.SYNC.CONNECTED);
    }
    debug('webconSessionInitializeSaga-3');
    yield put(webconDeviceSetup(action, userId, coachId));
    const deviceAction = yield take(WEBCON_ACTION.DEVICE.SUCCESS);
    debug('webconSessionInitializeSaga-4',deviceAction);
    yield put(webconWebcamUpdate(action));
    const webcamAction = yield take(WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS);
    debug('webconSessionInitializeSaga-5',webcamAction);
    yield put( webconMicrophoneUpdate(action));
    const microphoneAction = yield take(WEBCON_ACTION.MICROPHONE_UPDATE.SUCCESS);
    debug('webconSessionInitializeSaga-6',microphoneAction);
    yield put(webconAnyoneSendTransport(action, coachId, userId));
    const sendTransportAction = yield take(WEBCON_ACTION.ANYONE_SEND_TRANSPORT.SUCCESS);
    debug('webconSessionInitializeSaga-7',sendTransportAction);

    if (userId === huddle.coachId) {
      debug('webconSessionInitializeSaga-8 coachId');
      yield put(webconCoachRecieveTransport(action, coachId, userId));
      yield take(WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS);
    } else {
      if (coachId !== HUDDLE.NO_COACH) {
        debug('webconSessionInitializeSaga-8 student',userId);
        yield put(webconStudentRecieveTransport(action, coachId, userId));
        yield take(WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.SUCCESS);
      }
    }
    debug('webconSessionInitializeSaga-9');
    yield put({
      type: WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS,
    });

  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.SESSION.INITIALIZE.FAILURE,
      error
    });
  }
}

function* webconSessionEnterSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;

    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    const sessionState = yield select((state: State) => state.webconReducer.sessionState);

    debug('webconSessionEnterSaga 1', sessionState);
    if (!sessionState || sessionState === WEBCON_SESSION_STATE.INITIALIZED_FAILURE) {

      debug('webconSessionEnterSaga Need Initialize', sessionState);
      yield put(webconSessionInitialize(action, coachId, userId));
      const initAction = yield take([WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS, WEBCON_ACTION.SESSION.INITIALIZE.FAILURE]) as WebconAction;
      if (initAction.type === WEBCON_ACTION.SESSION.INITIALIZE.FAILURE) {
        console.error(initAction.error);
        throw initAction.error;
      }
      debug('webconSessionEnterSaga Done Initialize', sessionState);

    }
    else if (sessionState ===  WEBCON_SESSION_STATE.INITIALIZING){
      console.log('Wait for initializing',WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS);
      const initAction = yield take([WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS, WEBCON_ACTION.SESSION.INITIALIZE.FAILURE]) as WebconAction;
      if (initAction.type === WEBCON_ACTION.SESSION.INITIALIZE.FAILURE) {
        console.error(initAction.error);
        throw initAction.error;
      }
      debug('webconSessionEnterSaga Done Initialize', sessionState);
    }
    debug('webconSessionEnterSaga 1.5', sessionState);
    yield put(webconEnableWebcam(action, coachId));
    debug('webconSessionEnterSaga 2V');
    yield take(WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS);

    yield put(webconEnableMicrophone(action, coachId));
    debug('webconSessionEnterSaga 2A');
    yield take(WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS);

    debug('webconSessionEnterSaga 3');
    const huddle: Huddle = yield select((state: State) => state.huddleReducer.huddle);

    if (userId === huddle.coachId) {
      const studentIds: Array<UserId> = Object.keys(huddle.studentHuddleUsers);
      for (let index = 0; index < studentIds.length; index += 1) {
        const studentId = studentIds[index];
        const studentHuddleUser = huddle.studentHuddleUsers[studentId];
        if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ADMITTED) {
          debug('webconSessionEnterSaga 4 coachVideoConsume Student start ', index, studentId);
          yield put(webconCoachConsumeStudentVideo(action, coachId, studentId));
          const takeAction : WebconAction = yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE]);

          yield put(webconCoachConsumeStudentAudio(action, coachId, studentId));
          const takeAudioAction:WebconAction = yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE]);
          if (takeAction.type ===WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE || takeAudioAction.type === WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE){
            yield put({
              type: WEBCON_ACTION.SESSION.ENTER.FAILURE,
              error: takeAction.error || takeAudioAction.error
            });
            return;
          }
        }
      }
    } else {
      debug('webconSessionEnterSaga 4 webconStudentVideoConsume', coachId, userId);
      invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
      yield put(webconStudentConsumeCoachVideo(action, coachId, userId));
      yield take(WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.SUCCESS);
      debug('webconSessionEnterSaga 5');
      yield put(webconStudentConsumeCoachAudio(action, coachId, userId));
      yield take(WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS);
      debug('webconSessionEnterSaga 6');
    }
    debug('webconSessionEnterSaga 7 WEBCON_ACTION.SESSION.ENTER.SUCCESS');
    yield put({type: WEBCON_ACTION.SESSION.ENTER.SUCCESS});

  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.SESSION.ENTER.FAILURE,
      error
    });
  }
}

function* webconSessionLeaveSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.SESSION.LEAVE.FAILURE,
      error
    });
  }
}

function* webconStudentConsumeCoachVideoSaga(action: WebconAction) {
  try {
    const {
      coachId,
      studentId
    } = action;
    const nodeAction = {
      type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.NODE.REQUEST,
      coachId,
      studentId
    };
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    const dispatch = yield getContext('dispatch');

    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {
      debug('webSocketInit w4 ', studentId);
      yield put(webSocketInit(studentId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }
    const studentReceiveTransport: Transport = yield select((state: State) => state.webconReducer.studentReceiveTransport);
    const responseAction = yield call(emitWithResponse, socketClient, nodeAction) as WebconAction;
    debug('webconStudentVideoConsumeSaga responseAction', responseAction);
    if (responseAction.type === WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.NODE.FAILURE) {
      yield put({
        type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.FAILURE,
        error: responseAction.error
      });
    } else {
      debug(responseAction.type);

      const {kind, producerId, consumerId, rtpParameters, consumerType} = responseAction;
      const appData = {};
      //const peerId = undefined;
      debug('studentReceiveTransport consume', studentReceiveTransport, consumerType);

      webConEvent(studentId,coachId,ROLE.STUDENT,'studentReceiveTransport consume video '+consumerType).then();

      const studentVideoConsumer = yield call([studentReceiveTransport, 'consume'],
        {
          id: consumerId,
          producerId,
          kind,
          rtpParameters: {...rtpParameters},
          appData: {...appData} // Trick.
        });
      debug('studentReceiveTransport consume Called', studentVideoConsumer);
      studentVideoConsumer.on('transportclose', () => {
        debug('QQQ studentReceiveTransport close');
        dispatch({
          type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.CLOSE,
          studentVideoConsumer
        });
      });
      studentVideoConsumer.on('trackended', () => {
        debug('QQQ studentReceiveTransport close');
        dispatch({
          type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.CLOSE,
          studentVideoConsumer
        });
      });



      debug('studentReceiveTransport PUT WEBCON_ACTION.STUDENT_VIDEO_CONSUME.SUCCESS');
      yield put({
        type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.SUCCESS,
        studentVideoConsumer
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.FAILURE,
      error
    });
  }
}


function* webconStudentConsumeCoachAudioSaga(action: WebconAction) {
  try {
    const {
      coachId,
      studentId
    } = action;
    const nodeAction = {
      type: WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.NODE.REQUEST,
      coachId,
      studentId
    };
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {
      debug('webSocketInit w3', studentId);
      yield put(webSocketInit(studentId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }

    const studentReceiveTransport: Transport = yield select((state: State) => state.webconReducer.studentReceiveTransport);
    const responseAction = yield call(emitWithResponse, socketClient, nodeAction) as WebconAction;
    debug('webconStudentConsumeCoachAudioSaga responseAction', responseAction);
    if (responseAction.type === WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.NODE.FAILURE) {
      yield put({
        type: WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.FAILURE,
        error: responseAction.error
      });
    } else {
      debug(responseAction.type);

      const {kind, producerId, consumerId, rtpParameters, consumerType} = responseAction;
      const appData = {};
      //const peerId = undefined;
      debug('webconStudentConsumeCoachAudioSaga consume', studentReceiveTransport, consumerType);
      webConEvent(studentId,coachId,ROLE.STUDENT,'studentReceiveTransport consume Audio '+consumerType).then();

      const studentAudioConsumer = yield call([studentReceiveTransport, 'consume'],
        {
          id: consumerId,
          producerId,
          kind,
          rtpParameters: {...rtpParameters},
          appData: {...appData} // Trick.
        });
      debug('webconStudentConsumeCoachAudioSaga consume Called', studentAudioConsumer);
      studentAudioConsumer.on('transportclose', () => {
        debug('webconStudentConsumeCoachAudioSaga close');
        //this._consumers.delete(consumer.id);
      });

      debug('webconStudentConsumeCoachAudioSaga PUT WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS');
      yield put({
        type: WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.SUCCESS,
        studentAudioConsumer
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.FAILURE,
      error
    });
  }
}

function* webconCoachConsumeStudentVideoSaga(action: WebconAction) {
  try {
    const {

      coachId,
      studentId
    } = action;
    debug('webconCoachConsumeStudentVideoSaga-1 START');
    const debugFunction = debugModule(DEBUG_MODULE.WEBCON.SAGA + ':webconCoachConsumeStudentVideoSaga');
    const nodeAction = {
      type: WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.REQUEST,
      studentId,
      coachId
    };
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {
      debug('webSocketInit w2', coachId);
      yield put(webSocketInit(coachId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }

    const dispatch = yield getContext('dispatch');
    const coachReceiveStudentTransports: Transport = yield select((state: State) => state.webconReducer.coachReceiveStudentTransports);
    debug('webconCoachConsumeStudentVideoSaga-2 SENDING  WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.REQUEST ',nodeAction);
    const responseAction = yield call(emitWithResponse, socketClient, nodeAction) as WebconAction;
    debug('webconCoachConsumeStudentVideoSaga-3 SENDING  WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.RESPONSE ',responseAction);
    const coachReceiveStudentTransport = coachReceiveStudentTransports[studentId];
    debugFunction('responseAction', responseAction);
    if (responseAction.type === WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.NODE.FAILURE) {

      yield put({
        type: WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE,
        error: responseAction.error
      });
    } else {
      debug(responseAction.type);

      const {kind, producerId, consumerId, rtpParameters, consumerType} = responseAction;
      const appData = {};
      const peerId = undefined;
      debugFunction('consume', coachReceiveStudentTransport);

      webConEvent(studentId,coachId,ROLE.COACH,'coachReceiveStudentTransport consume video '+consumerType).then();


      const coachVideoConsumer = yield call([coachReceiveStudentTransport, 'consume'],
        {
          id: consumerId,
          producerId,
          kind,
          rtpParameters: {...rtpParameters},
          appData: {...appData} // Trick.
        });
      debugFunction('consume Called', coachVideoConsumer);
      coachVideoConsumer.on('transportclose', () => {
        debug('QQQQ webconCoachVideoConsumeSaga transport close');

        dispatch({
          type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.CLOSE,
          studentId
        });
        //this._consumers.delete(consumer.id);
      });


      debugFunction('PUT WEBCON_ACTION.COACH_VIDEO_CONSUME.SUCCESS');
      yield put({
        type: WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS,
        coachVideoConsumer, studentId
      });
    }

  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE,
      error
    });
  }
}

function* webconCoachConsumeStudentAudioSaga(action: WebconAction) {
  try {
    const {
      coachId,
      studentId
    } = action;


    const debugFunction = debugModule(DEBUG_MODULE.WEBCON.SAGA + ':webconCoachConsumeStudentAudioSaga');
    const nodeAction = {
      type: WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.NODE.REQUEST,
      studentId,
      coachId
    };
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {
      debug('webSocketInit w1 ', coachId);
      yield put(webSocketInit(coachId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }

    const coachReceiveStudentTransports: Transport = yield select((state: State) => state.webconReducer.coachReceiveStudentTransports);
    const responseAction = yield call(emitWithResponse, socketClient, nodeAction) as WebconAction;
    const coachReceiveStudentTransport = coachReceiveStudentTransports[studentId];
    debugFunction('responseAction', responseAction);
    if (responseAction.type === WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.NODE.FAILURE) {

      yield put({
        type: WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.FAILURE,
        error: responseAction.error
      });
    } else {
      debug(responseAction.type);

      const {kind, producerId, consumerId, rtpParameters, consumerType} = responseAction;
      const appData = {};

      debugFunction('consume', coachReceiveStudentTransport);
      webConEvent(studentId,coachId,ROLE.COACH,'coachReceiveStudentTransport consume audio '+consumerType).then();

      const coachAudioConsumer = yield call([coachReceiveStudentTransport, 'consume'],
        {
          id: consumerId,
          producerId,
          kind,
          rtpParameters: {...rtpParameters},
          appData: {...appData} // Trick.
        });
      debugFunction('consume Called', coachAudioConsumer);


      debugFunction('PUT WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS');
      yield put({
        type: WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS,
        coachAudioConsumer, studentId
      });
    }

  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.FAILURE,
      error
    });
  }
}

function* webconDeviceSaga(action: WebconAction) {
  try {



    const dispatch = yield getContext('dispatch');
    const {userId, coachId} = action;
    if (! JEST_WORKER_ID ){
      debug('webconDeviceSaga 0 ', navigator.mediaDevices);
      // const devices1 = yield call([navigator.mediaDevices, 'getUserMedia'],
      //   {
      //     audio: true,
      //     video: {
      //       width: 640,
      //       height: 480,
      //       frameRate: 30,
      //       facingMode: ("user"),
      //     }
      //   });
      // debug('webconDeviceSaga 0.1 ', devices1);

      const deviceInfos: DeviceInfos = yield call([navigator.mediaDevices, 'enumerateDevices']);

      yield put(webconDeviceInfos(deviceInfos));
      const role = yield select((state:State) => state.userReducer.role);
      let webcamDeviceId = yield select((state:State) => state.webconReducer.webcamDeviceId);
      let microphoneDeviceId = yield select((state:State) => state.webconReducer.microphoneDeviceId);
      let speakerDeviceId = yield select((state:State) => state.webconReducer.speakerDeviceId);

      debug('webconDeviceSaga 1.5 ', webcamDeviceId, microphoneDeviceId,speakerDeviceId);

      if (Platform.OS !== 'ios') {
        deviceInfos?.forEach(d => {
          if (d instanceof InputDeviceInfo) {
            debug(d.getCapabilities());
          }
        });
      }
      //console.log('webconDeviceSaga orig ', webcamDeviceId);
      if (!webcamDeviceId){
        debug('webconDeviceSaga find  videoinput ', deviceInfos);

        const deviceInfoCamera = deviceInfos.find((d: any) => d.kind === 'videoinput' && d.facing ==='front');
        if (deviceInfoCamera) {
          webcamDeviceId =  deviceInfoCamera.deviceId;

          yield put(webconDeviceCamera(webcamDeviceId));
        }
        else {
          const deviceInfoCamera = deviceInfos.find((d: any) => d.kind === 'videoinput' );
          if (deviceInfoCamera) {
            webcamDeviceId =  deviceInfoCamera.deviceId;

            yield put(webconDeviceCamera(webcamDeviceId));
          }
          else {
            console.log('webcamDeviceId NOT FOUND', deviceInfos);
          }
        }
      }


      if (!microphoneDeviceId){
        let deviceInfoMic;
        if (role ===  ROLE.COACH){
          deviceInfoMic = deviceInfos.find(d => d.kind === 'audioinput' && d.label.toLowerCase().indexOf('fifine') >= 0);

        }
        if (role ===  ROLE.STUDENT){
          deviceInfoMic = deviceInfos.find(d => d.kind === 'audioinput' && d.label.toLowerCase().indexOf('yxc') >= 0);

        }
        if (!deviceInfoMic) {
          deviceInfoMic = deviceInfos.find(d => d.kind === 'audioinput');
        }
        if (deviceInfoMic) {

          microphoneDeviceId  = deviceInfoMic.deviceId;
          yield put(webconDeviceMic(microphoneDeviceId));
        }
      }

      debug('webconDeviceSaga microphoneDeviceId ', microphoneDeviceId, role);


      if (!speakerDeviceId){
        const deviceInfoSpeaker = deviceInfos.find(d => d.kind === 'audiooutput');
        if (deviceInfoSpeaker) {
          speakerDeviceId = deviceInfoSpeaker.deviceId;
          yield put(webconDeviceSpeaker(speakerDeviceId));
        }
      }

      const handlerName = detectDevice();
      debug('webconDeviceSaga 2 ', webcamDeviceId, microphoneDeviceId,speakerDeviceId);

      if (handlerName) {
        debug("detected handler: %s", handlerName);
      } else {
        console.error("no suitable handler found for current browser/device");
      }
      debug('webconDeviceSaga 3 ');

    }
    const mediasoupDevice = JEST_WORKER_ID ? new MockMediasoupDevice() : new Device();
    debug('webconDeviceSaga 4 ');
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {
      debug('webconDeviceSaga 4.2 ', userId, coachId);
      yield put(webSocketInit(userId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }
    const responseAction = yield call(emitWithResponse, socketClient, action);
    const {
      routerRtpCapabilities
    } = responseAction;
    debug('routerRtpCapabilities', routerRtpCapabilities);
    debug('webconDeviceSaga before load');

    mediasoupDevice
      .load({
        routerRtpCapabilities
      })
      .then ( () => {

        if (dispatch) {
          debug('webconDeviceSaga after load PROMISE DISPATCH');
          dispatch({
            type: WEBCON_ACTION.DEVICE.SUCCESS,
            mediasoupDevice,
            routerRtpCapabilities
          });
        }
        else {

          debug('webconDeviceSaga after load PROMISE No DISPATCH');
        }
      });
    // yield call([mediasoupDevice, 'load'], {
    //   routerRtpCapabilities
    // });
    // debug('webconDeviceSaga after load');
    //
    // yield put({
    //   type: WEBCON_ACTION.DEVICE.SUCCESS,
    //   mediasoupDevice,
    //   routerRtpCapabilities
    // });
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.DEVICE.FAILURE,
      error
    });
  }
}
function* webconWebcamChangeDevices(action: WebconAction){
  yield put(webconWebcamUpdate(action));
  yield take(WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS);
  const localVideoTrack = yield select((state: State) => state.webconReducer.localVideoTrack);
  const webcamProducer = yield select((state: State) => state.webconReducer.webcamProducer);
  debug('webconWebcamChangeDevices',localVideoTrack, webcamProducer );
  yield call( [webcamProducer, 'replaceTrack'], { track: localVideoTrack });



}
function* webconWebcamUpdateSaga(action: WebconAction) {
  try {

    debug('webconWebcamUpdateSaga start',JEST_WORKER_ID);
    const webcamDeviceId = yield select((state: State) => state.webconReducer.webcamDeviceId);
    JEST_WORKER_ID ? new MockMediasoupDevice() : new Device();

    const resolution = 'hd';
    const args = {
      video: {
        deviceId: {
          ideal: webcamDeviceId
        },
        ...VIDEO_CONSTRAINS[resolution]
      }
    };

    const mediaStream = JEST_WORKER_ID ? getUserMedia(args) : yield call([navigator.mediaDevices, 'getUserMedia'], args);
    const videoTracks =  mediaStream.getVideoTracks();
    const localVideoTrack = mediaStream.getVideoTracks()[0];

    debug('mediaStream', webcamDeviceId, videoTracks?.length , mediaStream, localVideoTrack);

    yield put({
      type: WEBCON_ACTION.WEBCAM_UPDATE.SUCCESS,
      localVideoTrack,
    });
  } catch (error) {
    debug('ERROR GETTING WEBCAM');
    debug(error);
    yield put({
      type: WEBCON_ACTION.WEBCAM_UPDATE.FAILURE,
      error
    });
  }
}

function* webconMicrophoneUpdateSaga(action: WebconAction) {
  try {

    debug('webconMicrophoneUpdateSaga start');
    const microphones = {};
    let microphoneDeviceId = yield select((state: State) => state.webconReducer.microphoneDeviceId);


    debug('webconMicrophoneUpdateSaga mediaStreams before');
    JEST_WORKER_ID ? new MockMediasoupDevice() : new Device();
    const devices = JEST_WORKER_ID ? enumerateMockDevices() : yield call([navigator.mediaDevices, 'enumerateDevices']);
    let microphone;
    debug('webconMicrophoneUpdateSaga mediaStreams after');

    // eslint-disable-next-line no-restricted-syntax
    for (const device of devices) {
      debug('webconMicrophoneUpdateSaga DEVICE', device?.kind, 'deviceId', device?.deviceId, 'label', device?.label);

      if (device.kind === 'audioinput') {
        if (device.deviceId) {
          microphones[device.deviceId] = device;
        }
        if (device.deviceId === microphoneDeviceId){
          microphone = device;
        }
      }
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!microphone && Object.keys(microphones).length > 0 && microphones.hasOwnProperty('default')) {
      microphone = microphones['default'];
      microphoneDeviceId = 'default';
    }

    if (!microphone && Object.keys(microphones).length > 0) {
      microphone = microphones[Object.keys(microphones)[0]];
      microphoneDeviceId = microphone?.deviceId;
    }

    debug('webconMicrophoneUpdateSaga DEVICE', microphone?.kind, 'microphoneDeviceId', microphoneDeviceId, 'label', microphone?.label);


    const resolution = 'hd';
    const args = {
      audio: {
        deviceId: {
          ideal: microphoneDeviceId
        }
      }
    };
    const mediaStream = JEST_WORKER_ID ? getUserMedia(args) : yield call([navigator.mediaDevices, 'getUserMedia'], args);
    const localAudioTrack = mediaStream.getAudioTracks()[0];

    debug('mediaStream', mediaStream);
    debug('localAudioTrack', localAudioTrack);


    yield put({
      type: WEBCON_ACTION.MICROPHONE_UPDATE.SUCCESS,
      localAudioTrack,
      microphoneDeviceId
    });
  } catch (error) {
    debug('ERROR GETTING MICROPHONE_UPDATE');
    debug(error);
    yield put({
      type: WEBCON_ACTION.MICROPHONE_UPDATE.FAILURE,
      error
    });
  }
}

function* webconAnyoneSendTransportSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;

    debug('anyoneSendTransport START ',coachId,userId);
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    const dispatch = yield getContext('dispatch');
    const mediasoupDevice = yield select((state: State) => state.webconReducer.mediasoupDevice);
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {

      debug('webconDeviceSaga 4.3 ', userId);
      yield put(webSocketInit(userId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }
    const responseAction = yield call(emitWithResponse, socketClient, action);
    const {
      transportDef
    } = responseAction;


    debugSendTransport(`responseAction in saga ${JSON.stringify(responseAction)}`);
    invariant(responseAction.type === WEBCON_ACTION.ANYONE_SEND_TRANSPORT.SUCCESS || responseAction.type === WEBCON_ACTION.ANYONE_SEND_TRANSPORT.FAILURE, 'webconAnyoneSendTransportSaga not initialized');

    if (responseAction.type === WEBCON_ACTION.ANYONE_SEND_TRANSPORT.FAILURE) {
      debugSendTransport(responseAction.error);
      yield put(responseAction);
    } else {
      debugSendTransport(`put  ${JSON.stringify(responseAction)}`);
      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters
      } = transportDef;
      webConEvent(userId === coachId?'ANY STUDENT': userId,coachId,userId === coachId?ROLE.COACH: ROLE.STUDENT,'createSendTransport->anyoneSendTransport ').then();

      const anyoneSendTransport = mediasoupDevice.createSendTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters: {
          ...dtlsParameters
        },
        // dtlsParameters was readonly
        sctpParameters,
        iceServers: [],
        proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS
      });
      responseAction.anyoneSendTransport = anyoneSendTransport;
      // eslint-disable-next-line no-shadow
      anyoneSendTransport.on('connect', ({
        dtlsParameters
      }, callback, errback) => {
        webConEvent(userId === coachId?'ANY STUDENT': userId,coachId,userId === coachId?ROLE.COACH: ROLE.STUDENT,'createSendTransport->anyoneSendTransport SEND-webconTransportConnect OnCONNECT').then();

        debugSendTransport('anyoneSendTransport connect');
        const transportId = id;
        debugSendTransport('webconTransportConnect emit', userId, coachId, transportId, dtlsParameters);
        const appData = {};
        emitWithResponse(socketClient, webconTransportConnect(action, userId, coachId, transportId, dtlsParameters, appData))
          .then(_responseAction => {
            debugSendTransport('anyoneSendTransport responseKeyValues', _responseAction);
            dispatch(_responseAction);
            callback();
          }).catch(error => {
            debugSendTransport(error);
            errback(error);
          });
      });
      anyoneSendTransport.on('produce', async (args, callback, errorBack) => {
        const {
          kind,
          rtpParameters,
          appData
        } = args;

        webConEvent(userId === coachId?'ANY STUDENT': userId,coachId,userId === coachId?ROLE.COACH: ROLE.STUDENT,'createSendTransport->anyoneSendTransport OnPRODUCE '+kind).then();


        debugSendTransport('anyoneSendTransport args', callback, errorBack);
        const transportId = anyoneSendTransport.id;
        debugSendTransport('anyoneSendTransport produce', transportId, callback);

        emitWithResponse(socketClient, webconTransportProduce(action, userId, coachId, transportId, kind, rtpParameters))
          .then(_responseAction => {
            const {transportId, producerId} = _responseAction as WebconAction;
            debugSendTransport('anyoneSendTransport Produce responseKeyValues', _responseAction);
            dispatch(_responseAction);
            debugSendTransport('call  anyoneSendTransport.produce()');
            // anyoneSendTransport.produce();
            debugSendTransport('call  anyoneSendTransport.callback', callback);

            callback({id: producerId});
          })
          .catch(error => {
            debugSendTransport(error);
            errorBack(error);
          });
      });
      yield put(responseAction);
    }
  } catch (error) {
    debugSendTransport(error);
    yield put({
      type: WEBCON_ACTION.ANYONE_SEND_TRANSPORT.FAILURE,
      error
    });
  }
}

function* webconEnableWebcamSaga(action: WebconAction) {
  try {
    const {
      coachId,
      parent
    } = action;

    const dispatch = yield getContext('dispatch');
    debugWebcamEnable('webconEnableWebcamSaga ', coachId, parent?.type);
    const userId = yield select((state: State) => state.userReducer.userId);
    const mediasoupDevice = yield select((state: State) => state.webconReducer.mediasoupDevice);
    const anyoneSendTransport = yield select((state: State) => state.webconReducer.anyoneSendTransport);
    const webcamDeviceId = yield select((state: State) => state.webconReducer.webcamDeviceId);
    const localVideoTrack = yield select((state: State) => state.webconReducer.localVideoTrack);
    const webcamProducerOriginal = yield select((state: State) => state.webconReducer.webcamProducer);

    if (webcamProducerOriginal){
      yield put({
        type: WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS,
        webcamProducer:webcamProducerOriginal
      });
      return;
    }
    const codecOptions = {
      videoGoogleStartBitrate: 1000
    };
    debugWebcamEnable('webconEnableWebcamSaga:webcamDeviceId', webcamDeviceId);
    debugWebcamEnable('webconEnableWebcamSaga:mediasoupDevice.rtpCapabilities.codecs', mediasoupDevice.rtpCapabilities.codecs);
    debug(mediasoupDevice.rtpCapabilities.codecs);
    const codec = mediasoupDevice.rtpCapabilities.codecs.find(c => c.mimeType.toLowerCase() === 'video/vp8');

    const firstVideoCodec = mediasoupDevice.rtpCapabilities.codecs.find(c => c.kind === 'video');
    const encodings = WEBCAM_KSVC_ENCODINGS;

    webConEvent(userId === coachId?'ANY STUDENT': userId,coachId,userId === coachId?ROLE.COACH: ROLE.STUDENT,'anyoneSendTransport call PRODUCE Video').then();


    const webcamProducer = yield call([anyoneSendTransport, 'produce'], {
      track: localVideoTrack,
      codecOptions,
    });

    debugWebcamEnable('webconEnableWebcamSaga:webcamProducer', webcamProducer);
    webcamProducer.on('transportclose', () => {
      debugWebcamEnable('webconEnableWebcamSaga:webcamProducer transportclose');
      dispatch({type:WEBCON_ACTION.ENABLE_WEBCAM.CLOSE });
    });
    webcamProducer.on('trackended', () => {
      debugWebcamEnable('webconEnableWebcamSaga:webcamProducer trackended');
    });
    debugWebcamEnable('webconEnableWebcamSaga:SENDING SUCCESS', webcamProducer?.kind);
    yield put({
      type: WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS,
      webcamProducer
    });
  } catch (error) {
    console.error(error);
    debugWebcamEnable(error);
    yield put({
      type: WEBCON_ACTION.ENABLE_WEBCAM.FAILURE,
      error
    });
  }
}

function* webconMicrophoneEnableSaga(action) {
  try {
    const {
      coachId
    } = action;

    const userId = yield select((state: State) => state.userReducer.userId);

    const deviceInfos = yield select((state: State) => state.webconReducer.deviceInfos);
    const mediasoupDevice = yield select((state: State) => state.webconReducer.mediasoupDevice);
    const anyoneSendTransport = yield select((state: State) => state.webconReducer.anyoneSendTransport);
    const microphoneDeviceId = yield select((state: State) => state.webconReducer.microphoneDeviceId);
    const localAudioTrack = yield select((state: State) => state.webconReducer.localAudioTrack);
    const microphoneProducerOriginal = yield select((state: State) => state.webconReducer.microphoneProducer);

    //console.log('webconMicrophoneEnableSaga microphoneDeviceId', microphoneDeviceId);
    if (microphoneProducerOriginal){
      yield put({
        type: WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS,
        microphoneProducer:microphoneProducerOriginal
      });
      return;
    }
    const codecOptions = {
      videoGoogleStartBitrate: 1000
    };
    debugWebcamEnable('webconMicrophoneEnableSaga:webcamDeviceId', microphoneDeviceId, localAudioTrack);



    webConEvent(userId === coachId?'ANY STUDENT': userId,coachId,userId === coachId?ROLE.COACH: ROLE.STUDENT,'anyoneSendTransport call PRODUCE Mic').then();


    const microphoneProducer = yield call([anyoneSendTransport, 'produce'], {
      track: localAudioTrack,
      codecOptions :
        {
          opusStereo : 1,
          opusDtx    : 1
        }
    });

    debugWebcamEnable('webconMicrophoneEnableSaga:webcamProducer', microphoneProducer);
    microphoneProducer.on('transportclose', () => {
      debug('webconMicrophoneEnableSaga:webcamProducer transportclose');
    });
    microphoneProducer.on('trackended', () => {
      debug('webconMicrophoneEnableSaga:webcamProducer trackended');
    });
    //console.log('webconMicrophoneEnableSaga:SENDING SUCCESS');
    yield put({
      type: WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS,
      microphoneProducer
    });
  } catch (error) {
    console.error(error);
    debug(error);
    yield put({
      type: WEBCON_ACTION.ENABLE_MICROPHONE.FAILURE,
      error
    });
  }
}

function* webconStudentRecieveTransportSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;


    debug('webconStudentRecieveTransportSaga ', coachId);
    const mediasoupDevice = yield select((state: State) => state.webconReducer.mediasoupDevice);
    const _wsReducer = yield select((state: State) => state.wsReducer);
    const dispatch = yield getContext('dispatch');
    debug('webconStudentRecieveTransportSaga _wsReducer', _wsReducer);
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {

      debug('webconDeviceSaga 4.4 ', userId);
      yield put(webSocketInit(userId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }

    debug('webconStudentRecieveTransportSaga Action', action.type);
    const responseAction: WebconAction = yield call(emitWithResponse, socketClient, action);
    const {
      transportDef
    } = responseAction;
    debug(`webconStudentRecieveTransportSaga in saga ${JSON.stringify(responseAction)}`);
    invariant(responseAction.type === WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.SUCCESS || responseAction.type === WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.FAILURE, 'webconAnyoneSendTransportSaga not initialized');

    if (responseAction.type === WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.FAILURE) {
      console.error(responseAction.error);
      yield put(responseAction);
    } else {
      debug(`webconStudentRecieveTransportSaga Put  ${JSON.stringify(responseAction)}`);
      const {
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters,
        sctpParameters
      } = transportDef;
      webConEvent(userId,coachId, ROLE.STUDENT,'webconStudentRecieveTransportSaga createRecvTransport ').then();

      const studentReceiveTransport = mediasoupDevice.createRecvTransport({
        id,
        iceParameters,
        iceCandidates,
        dtlsParameters: {...dtlsParameters},
        sctpParameters,
        iceServers: []
      });
      studentReceiveTransport?.on('connectionstatechange', (connectionState) => {
        debug('studentReceiveTransport connectionstatechange',connectionState);
      });

      responseAction.studentReceiveTransport = studentReceiveTransport;
      responseAction.studentReceiveTransportDef = transportDef;

      // eslint-disable-next-line no-shadow
      studentReceiveTransport.on('connect', async ({
        dtlsParameters
      }, callback, errback) => {
        webConEvent(userId,coachId, ROLE.STUDENT,'webconStudentRecieveTransportSaga createRecvTransport studentReceiveTransport SEND-webconTransportConnect OnCONNECT').then();

        debug('studentReceiveTransport connect call connectWebRtcTransport');
        const transportId = studentReceiveTransport.id;
        const appData = {};

        emitWithResponse(socketClient, webconTransportConnect(action, userId, coachId, transportId, dtlsParameters, appData)).then(_responseAction => {
          debugSendTransport('studentReceive responseKeyValues', _responseAction);
          dispatch(_responseAction);
          callback();
        }).catch(error => {
          debugSendTransport(error);
          errback(error);
        });
      });
      yield put(responseAction);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.FAILURE,
      error
    });
  }
}

function* webconCoachRecieveTransportSaga(action: WebconAction) {
  try {
    const {
      coachId,
      userId
    } = action;

    debug('webconCoachRecieveTransportSaga ', userId, coachId);

    const mediasoupDevice = yield select((state: State) => state.webconReducer.mediasoupDevice);
    let socketClient = yield select((state:State) => state.wsReducer.socketClient);
    if (!socketClient) {

      debug('webconDeviceSaga 4.5 ', userId);
      yield put(webSocketInit(userId,coachId));
      const response = yield take(WS_ACTION.SYNC.CONNECTED);
      socketClient = response.socketClient;
    }
    const dispatch = yield getContext('dispatch');
    const nodeAction = {
      ...action,
      type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.REQUEST,
    };

    const responseAction: WebconAction = yield call(emitWithResponse, socketClient, nodeAction);
    const {
      coachReceiveStudentTransportDefs
    } = responseAction;
    debug(`responseAction in saga ${JSON.stringify(responseAction)}`);
    invariant(responseAction.type === WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.SUCCESS ||
      responseAction.type === WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.FAILURE, 'webconAnyoneSendTransportSaga not initialized');

    if (responseAction.type === WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.NODE.FAILURE) {
      console.error(responseAction.error);

      yield put({...responseAction, type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.FAILURE});
    } else {
      const successAction = {
        type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS,
        coachReceiveStudentTransports: {},
        coachReceiveStudentTransportDefs: {}
      };
      debug(`put  ${JSON.stringify(responseAction)}`);
      Object.entries(coachReceiveStudentTransportDefs).forEach(([studentId, transportDef]) => {
        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters
        } = transportDef;

        webConEvent(studentId,coachId, ROLE.COACH,'webconCoachRecieveTransportSaga createRecvTransport ').then();


        const coachReceiveTransport = mediasoupDevice.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters: {...dtlsParameters},
          sctpParameters,
          iceServers: []
        });
        successAction.coachReceiveStudentTransports[studentId] = coachReceiveTransport;
        successAction.coachReceiveStudentTransportDefs[studentId] = transportDef;

        // eslint-disable-next-line no-shadow
        coachReceiveTransport.on('connect', ({
          dtlsParameters
        }, callback, errback) => {
          webConEvent(studentId,coachId, ROLE.COACH,'webconCoachRecieveTransportSaga createRecvTransport coachReceiveTransport SEND-webconTransportConnect OnCONNECT').then();

          debug('webconCoachRecieveTransportSaga connect call connectWebRtcTransport');
          const transportId = coachReceiveTransport.id;
          const appData = {};

          emitWithResponse(socketClient, webconTransportConnect(action, coachId, coachId, transportId, dtlsParameters, appData)).then(_responseAction => {
            debugSendTransport('CoachRecieve responseKeyValues', _responseAction);
            dispatch(_responseAction);
            callback();
          }).catch(error => {
            debugSendTransport(error);
            errback(error);
          });
        });
        coachReceiveTransport.on('close',() =>{
          debug('coachReceiveTransport close ',studentId);
        });
        coachReceiveTransport.on('connectionstatechange', (connectionState, callback, errback) => {
          debug('coachReceiveTransport connectionstatechange ',connectionState);
          if (connectionState === 'disconnected'){
            coachReceiveTransport.close();
          }

        });

      });
      yield put(successAction);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.FAILURE,
      error
    });
  }
}




function* webconDebugWebconState() {
  try {
    debug('webconDebugWebconState ');
    const webconReducer: WebconState = yield select((state: State) => state.webconReducer);

    const responseAction: WebconSummaryAction = {
      type: WEBCON_ACTION.DEBUG.WEBCON_STATE_SUMMARY,
      webconSummary: {
        mediasoupDevice: !!webconReducer.mediasoupDevice,
        anyoneSendTransport: webconReducer.anyoneSendTransport?(webconReducer.anyoneSendTransport.closed? 'closes': 'open'):'null',
        anyoneSendTransportDef: !!webconReducer.anyoneSendTransportDef,
        studentReceiveTransport: webconReducer.studentReceiveTransport?(webconReducer.studentReceiveTransport.closed?'closes':'open'):'null',
        studentReceiveTransportDef: !!webconReducer.studentReceiveTransportDef,
        studentVideoConsumer: !!webconReducer.studentVideoConsumer,
        studentAudioConsumer: !!webconReducer.studentAudioConsumer,
        coachReceiveStudentTransports: Object.keys(webconReducer.coachReceiveStudentTransports || {}).length,
        coachReceiveStudentTransportDefs: Object.keys(webconReducer.coachReceiveStudentTransportDefs || {}).length,
        coachVideoConsumers: Object.keys(webconReducer.coachVideoConsumers || {}).length,
        coachAudioConsumers: Object.keys(webconReducer.coachVideoConsumers || {}).length,
        webcamDeviceId: !!webconReducer.webcamDeviceId,
        webcamProducer: !!webconReducer.webcamProducer,
        microphoneDeviceId: !!webconReducer.microphoneDeviceId,
        microphoneProducer: !!webconReducer.microphoneProducer,
        routerRtpCapabilities: !!webconReducer.routerRtpCapabilities,
        localVideoTrack: !!webconReducer.localVideoTrack
      }

    };
    yield put(responseAction);


  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.FAILURE,
      error
    });
  }
}


export default function* webconSagas(): Saga<void> {

  yield all([
    takeEvery(WEBCON_ACTION.SESSION.INITIALIZE.REQUEST, webconSessionInitializeSaga),
    takeEvery(WEBCON_ACTION.COACH_DISCONNECT.REQUEST,webconCoachDisconnectSaga ),
    takeEvery(WEBCON_ACTION.SESSION.ENTER.REQUEST, webconSessionEnterSaga),
    takeEvery(WEBCON_ACTION.SESSION.LEAVE.REQUEST, webconSessionLeaveSaga),

    takeEvery(WEBCON_ACTION.WEBCAM_UPDATE.REQUEST, webconWebcamUpdateSaga),
    takeEvery(WEBCON_ACTION.CHANGE_DEVICES.REQUEST, webconWebcamChangeDevices),
    takeEvery(WEBCON_ACTION.MICROPHONE_UPDATE.REQUEST, webconMicrophoneUpdateSaga),

    takeEvery(WEBCON_ACTION.DEBUG.WEBCON_STATE, webconDebugWebconState),
    takeEvery(WEBCON_ACTION.ANYONE_SEND_TRANSPORT.REQUEST, webconAnyoneSendTransportSaga),
    takeEvery(WEBCON_ACTION.DEVICE.REQUEST, webconDeviceSaga),
    takeEvery(WEBCON_ACTION.ENABLE_WEBCAM.REQUEST, webconEnableWebcamSaga),
    takeEvery(WEBCON_ACTION.ENABLE_MICROPHONE.REQUEST, webconMicrophoneEnableSaga),
    takeEvery(WEBCON_ACTION.STUDENT_RECIEVE_TRANSPORT.REQUEST, webconStudentRecieveTransportSaga),
    takeEvery(WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.REQUEST, webconCoachRecieveTransportSaga),
    takeEvery(WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.REQUEST, webconCoachConsumeStudentVideoSaga),
    takeEvery(WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.REQUEST, webconCoachConsumeStudentAudioSaga),
    takeEvery(WEBCON_ACTION.STUDENT_CONSUME_COACH_VIDEO.REQUEST, webconStudentConsumeCoachVideoSaga),
    takeEvery(WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST, webconCoachDisconnectToStudentSaga),
    takeEvery(WEBCON_ACTION.STUDENT_CONSUME_COACH_AUDIO.REQUEST, webconStudentConsumeCoachAudioSaga)]);

}

