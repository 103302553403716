import type {
  CurriculumStudentInteraction,
  CurriculumStudentProgression,
  CurriculumStudentResponse,
  Problem,
  ProgressionSet,
  ProgressionSetId,

  WidgetSetId
} from "./curriculumDefs";
import {Assessment, CurriculumStudentProgressionSummary} from "./curriculumDefs";
import type {CoachId, RoleType, StudentId, UserData, UserId, UserProfile} from "./userDefs";
import type {ChatItem} from "./chatDefs";
import type {AudioMode, Huddle} from "./huddleDefs";


export function uuidv4(prefix: string): string {
  return prefix + '-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line one-var,no-bitwise
    const r = Math.random() * 16 | 0,
      // eslint-disable-next-line no-bitwise,eqeqeq,no-mixed-operators
      v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
export const API_URI = {
  TEST: 'test',
  ADMIN_PROGRESSION_SET_SAVE: 'admin-progression-set-save',
  ADMIN_ASSESSMENT_SAVE: 'admin-assessment-save',
  ADMIN_IMPORT_FROM_AIRTABLE: 'admin-import-from-airtable',
  ADMIN_APPLICATION_ASSESS: 'admin-application-assess',
  ADMIN_SESSION_CREATE: 'admin-session-create',
  CURRICULUM_INTERACTION_ADVANCE: 'curriculum-curriculumStudentInteraction-advance',
  CURRICULUM_PROGRESSION_SET_ASSIGN: 'curriculum-curriculumStudentProgression-set-assign',
  CURRICULUM_PROGRESSION_SET_COMPLETED: 'curriculum-curriculumStudentProgression-set-completed',
  CURRICULUM_STUDENT_PROGRESSION_SET_SUMMARY: 'curriculum-student-curriculumStudentProgression-set-summary',
  CURRICULUM_RESPONSE_PERFORM: 'curriculum-response-perform',
  CURRICULUM_SUBMIT_PERFORM: 'curriculum-submit-perform',
  CURRICULUM_RESPONSE_SUMMARY: 'curriculum-responseKeyValues-summary',
  CURRICULUM_WIDGET_SET_ID_CHANGE: 'curriculum-widget-set-id-change',
  HUDDLE_STUDENT_ARRIVE: 'huddle-stduent-arrive',
  HUDDLE_COACH_ADMIT_STUDENT: 'huddle-coach-admit-student',
  HUDDLE_COACH_RELEASE_STUDENT: 'huddle-coach-release-student',
  HUDDLE_DISMISS: 'huddle-dismiss',
  HUDDLE_STUDENT_ENTER: 'huddle-student-enter',
  HUDDLE_COACH_PRE_ENTER: 'huddle-coach-pre-enter',
  HUDDLE_COACH_ENTER: 'huddle-coach-enter',
  HUDDLE_LEAVE: 'huddle-leave',
  HUDDLE_AUDIO_MODE: 'huddle-audio-mode',
  HUDDLE_HEY_COACH_MODE: 'huddle-hey-coach-mode',
  PARENT_COACH_ASSIGN_TO_STUDENT: 'parent-coach-assign-student',
  USER_PROFILE_UPDATE: 'user-profile-update',
  USER_REGISTER_ROLE: 'user-register-role',
  USER_STUDENT_CREATE: 'user-student-create',
  DEBUG_ROOM_SUMMARY: 'debug-room-summary',
  DEBUG_CLEAR_CACHE: 'debug-clear-cache'
};
export type TestBody = {
  testString: string;
};
export type TestResult = {
  testString: string;
  retObject: {
    retValue: string;
    retDate: Date;
    retNumber: number;
  };
};

export type AdminProgressionSetSaveBody = {
  progressionSet: ProgressionSet;
  dev:boolean; // only running DB
  demo:boolean; // demo DB
  staging:boolean; // staged DB
  production:boolean; // production DB
};
export type AdminProgressionSetSaveResult = {
  status: string;
};


export type AdminAssessmentSaveBody = {
  assessment: Assessment;
  dev:boolean; // only running DB
  demo:boolean; // demo DB
  staging:boolean; // staged DB
  production:boolean; // production DB
};
export type AdminAssessmentSaveResult = {
  status: string;
};
export type AdminImportFromAirtableBody = {
  prefix: string;
};
export type AdminImportFromAirtableResult = {
  status: string;
};

export type AdminApplicationAssessBody = {
  type: string;
  userId: UserId;
};
export type AdminApplicationAssessResult = {
  userData: UserData;
};


export type CurriculumWidgetSetChangeRequestBody = {
  widgetSetId: WidgetSetId;
  studentId: UserId;
  coachId: CoachId
  curriculumStudentInteraction: CurriculumStudentInteraction

};
export type CurriculumWidgetSetChangeRequestResult = {
resultAction: {
  type:string,
  widgetSetId: WidgetSetId;
  studentId: UserId;
  coachId: CoachId
  curriculumStudentInteraction: CurriculumStudentInteraction
}
}

export type CurriculumInteractionAdvanceBody = {
  widgetSetId: WidgetSetId;
  studentId: StudentId;
  problem: Problem;
  coachId: CoachId;

  position: number;
};

export type CurriculumInteractionAdvanceResult = {
  curriculumStudentResponse: CurriculumStudentResponse;
  curriculumStudentInteraction: CurriculumStudentInteraction;
}
export type CurriculumResponseSummaryBody = {
  studentId: UserId;
}
export type CurriculumSubmitPerformBody = {
  studentId: StudentId;
  coachId: CoachId;
  problem: Problem;
  curriculumStudentResponse: CurriculumStudentResponse;
};
export type CurriculumResponsePerformBody = {
  studentId: UserId;
  coachId: CoachId;
  problem: Problem;
  curriculumStudentResponse: CurriculumStudentResponse;
};
export type CurriculumResponsePerformResult = {
  chatItem: ChatItem;
};
export type CurriculumProgressionSetAssignBody = {
  studentId: StudentId;
  coachId: CoachId;

  progressionSetId?: ProgressionSetId;
  assessmentId?: string;
};
export type CurriculumProgressionSetAssignResult = {
  curriculumStudentProgression: CurriculumStudentProgression;
  curriculumStudentInteraction: CurriculumStudentInteraction;
  curriculumStudentResponse: CurriculumStudentResponse;
};
export type CurriculumProgressionSetCompletedBody = {
  studentId: UserId;
};
export type CurriculumProgressionSetCompletedResult = Record<string,any>;


export type CurriculumStudentProgressionSetSummaryBody = {
  studentId: UserId;
  progressionSetId: ProgressionSetId;
};
export type CurriculumStudentProgressionSetSummaryResult = CurriculumStudentProgressionSummary;


export type ParentCoachAssignStudentBody = {
  coachId: CoachId;
  studentId: UserId;
};
export type ParentCoachAssignStudentResult = Record<string,any>;
export type UserRegisterRoleBody = {
  customClaims: {
    role: RoleType;
  };
  userId: UserId;
};
export type UserRegisterRoleResult = Record<string,any>;
export type UserStudentCreateBody = {
  studentProfile: UserProfile;
};
export type UserStudentCreateResult = {
  parentUserData: UserData;
  studentUserData: UserData;
};
export type UserProfileUpdateBody = {
  userProfile: UserProfile;
};
export type UserProfileUpdateResult = {
  userData: UserData;
};
export type HuddleStudentArriveBody = {
  coachId: CoachId;
  userId: UserId;
};
export type HuddleStudentArriveResult = {
  huddle: Huddle;
};
export type HuddleCoachAdmitStudentBody = {
  coachId: CoachId;
  studentId: UserId;
};
export type HuddleCoachAdmitStudentResult = {
  huddle: Huddle;
  //coachReceiveStudentTransportDefs:Record<UserId, TransportDef>;
};
export type HuddleCoachReleaseStudentBody = {
  coachId: CoachId;
  studentId: StudentId;
};
export type HuddleCoachReleaseStudentResult = {
  huddle: Huddle;
};
export type HuddleStudentEnterBody = {
  coachId: CoachId;
  userId: UserId;
};
export type HuddleStudentEnterResult = {
  huddle: Huddle;
};

export type HuddleCoachPreEnterBody = {
  coachId: CoachId;
};
export type HuddleCoachPreEnterResult = {
  huddle: Huddle;
};

export type HuddleCoachEnterBody = {
  coachId: CoachId;
};
export type HuddleCoachEnterResult = {
  huddle: Huddle;
};
export type HuddleAudioModeBody = {
  coachId: CoachId;
  userId: UserId;
  audioMode: AudioMode;
};
export type HuddleAudioModeResult = {
  huddle: Huddle;
};


export type HuddleHeyCoachModeBody = {
  coachId: CoachId;
  userId: UserId;
  heyCoach: boolean;
};
export type HuddleHeyCoachModeResult = {
  huddle: Huddle;
};
export type HuddleLeaveBody = {
  coachId: CoachId;
  userId: UserId;
};
export type HuddleLeaveResult = {
  huddle: Huddle;
};
export type HuddleDismissBody = {
  coachId: CoachId;
  studentId: StudentId;
};
export type HuddleDismissResult = {
  huddle: Huddle;
};
