import * as React from "react";
import {Image, StyleSheet, Text, useWindowDimensions, View} from "react-native";
import {Button, Surface} from "react-native-paper";
import {useDispatch, useSelector} from "react-redux";
import {navigateTo} from "../NavigationAction";
import {userLoginRequest} from "../redux/reducer/userReducer";
import {NAV} from "../redux/structs/navigationStruct";
import {ROLE} from "../sharedDefs/userDefs";
import {State} from "../redux/reducer";

import CplTextField from "../components/FormControls/CplTextField";
import {navGoHome} from "../redux/reducer/navigationReducer";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {REACT_APP_PREFILL} from "../utils/environment";

const debug = debugModule(DEBUG_MODULE.USER.AUTH);


const defaultValues = REACT_APP_PREFILL ? {
  email: REACT_APP_PREFILL + '@gmail.com',
  password: '12345678'
} : {};

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state: State) => state.userReducer.loggedIn);
  const role = useSelector((state: State) => state.userReducer.role);
  const [formObject, setFormObject] = React.useState(defaultValues);


  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    const newFormObject: any = {
      ...formObject,
      ...f
    };
    setFormObject(newFormObject);
  }, [formObject]);
  const submitLoginForm = React.useCallback(() => {
    debug('Login', formObject);
    dispatch(userLoginRequest(formObject.email, formObject.password));
  }, [formObject]);
  React.useEffect(() => {
    if (loggedIn && role) {
      dispatch(navGoHome(role));
    }
  }, [loggedIn, role]);
  const errors = {};
  const window = useWindowDimensions();
  const phone = window.width< 415 || window.height< 415;
  const landscape = window.width > window.height;

  const styles = StyleSheet.create({
    top:{
      flex: 1,
      flexDirection:  'column',
      justifyContent: 'center',
      alignItems: 'center',

    },
    container: {
      width: '100%',

      flex: 1,
      flexDirection:  landscape ? 'row': 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonBox: {
      margin: 0,
      flex: 1,
      flexDirection: 'column',

      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      //height: 200,
      padding: 20
    },

    headline: {
      padding: 10,
      fontFamily: 'Poppins-SemiBold',
      fontSize: phone?20:30
    },
    registerSurface: {

      margin:10,
      flex: 1,
      flexDirection: 'column',

      // width: 300,
      // maxWidth: 300,
      justifyContent: 'center',
      alignItems: 'center',
      //height: 300,

    },
    loginSurface: {

      margin:10,
      flex: 1,
      flexDirection: 'column',

      width: 280,
      maxWidth: 300,
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: 230,

    },
    image: {
      height: phone?150:200,
      width: phone?150:200,
      margin: 10
    },
    registerButton: {
      margin: 10
    },
    loginFormView: {
      // height:300
    },
    btnArea: {margin: 20}
  });


  return (
    <View testID="Home" style={styles.top} >
      <View style={styles.container}>

        <Surface style={styles.loginSurface}>

          <Text style={styles.headline}>Login</Text>
          <View style={styles.loginFormView}>

            <CplTextField textContentType={'emailAddress'} label="Email" errors={errors} name="email" onChange={onChange} formObject={formObject}/>

            <CplTextField textContentType={'password'} label="Password" errors={errors} name="password" onChange={onChange}
              formObject={formObject}/>
          </View>
          <View style={styles.btnArea}>
            <Button testID="submit_login" mode="contained" onPress={() => submitLoginForm()}>
                Login
              {
                /* <ArrowForward style={classNames(styles.rightIcon, styles.iconSmall)} disabled={submitting || pristine} /> */
              }
            </Button>
          </View>
        </Surface>




        <Surface style={styles.registerSurface}>
          {!phone && <Text style={styles.headline}>
            Enroll your child
          </Text>
          }
          <Image style={styles.image}
            source={require('../assets/student1.png')}
          />
          <Button style={styles.registerButton} mode="contained" testID="parent_register"
            onPress={() => navigateTo(NAV.AUTH.REGISTER_PARENT )}>
              Start your child
          </Button>
        </Surface>
        <Surface style={styles.registerSurface}>
          {!phone && <Text style={styles.headline}>
              Become a Coach
          </Text> }
          <Image style={styles.image}
            source={require('../assets/coach.png')}
          />
          <Button style={styles.registerButton} mode="contained" testID="coach_register"
            onPress={() => navigateTo(NAV.AUTH.REGISTER_COACH)}>
              See if you qualify
          </Button>
        </Surface>

      </View>
    </View>);

}
