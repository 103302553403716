
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Provider, Surface } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import CplRadioGroup from "../../components/FormControls/CplRadioGroup";
import CplTextField from "../../components/FormControls/CplTextField";
import type { State } from "../../redux/reducer";
import { navGoHome } from "../../redux/reducer/navigationReducer";
import { userProfileUpdate } from "../../redux/reducer/userReducer";
import type {UserProfile, UserProfileUpdates} from "../../sharedDefs/userDefs";
import { PROFILE, ROLE } from "../../sharedDefs/userDefs";
import { valueLabelArray } from "../../utils/dataUtils";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";
const debug = debugModule(DEBUG_MODULE.ADMIN.UI);
const styles = StyleSheet.create({
  root: {
    flexGrow: 1
  },
  radio: {
    marginRight: 10,
    width: 30
  },
  radioLabel: {
    width: 300
  }
});
type CoachApplicationProps = Record<string,never>;
export default function CoachApplication(props: CoachApplicationProps): JSX.Element {
  const dispatch = useDispatch();
  const initialValues: Partial< UserProfile> = useSelector((state: State) => state.userReducer.userData && state.userReducer.userData.userProfile || {});
  const userId = useSelector((state: State) => state.userReducer.userId);
  const userData = useSelector((state: State) => state.userReducer.userData);
  const defaultValues : Partial< UserProfile>= !(userData && userData.userProfile) && REACT_APP_PREFILL ? {
    firstName: REACT_APP_PREFILL,
    lastName: REACT_APP_PREFILL,

    occupation: PROFILE.OCCUPATION.COLLEGE,
    essay: 'prefill essay'
  } : initialValues;
  const [formObject, setFormObject] = React.useState(defaultValues);
  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    const newFormObject: any = { ...formObject,
      ...f
    };
    setFormObject(newFormObject);
    debug('NEW formObject', newFormObject);
  },[formObject]);
  const errors = {};
  React.useEffect(() => {
    if (initialValues.firstName && initialValues.lastName) {
      debug('go home Coach');
      dispatch(navGoHome(ROLE.COACH));
    }
  }, []);

  function updateProfileSubmit() {
    debug('updateProfileSubmit');
    const userProfileUpdates: UserProfileUpdates = {
      firstName: formObject.firstName,
      lastName: formObject.lastName,
      status: PROFILE.STATUS.APPLIED,

      occupation: formObject.occupation,
      essay: formObject.essay
    };
    dispatch(userProfileUpdate(userProfileUpdates));
  }

  return <View testID="coachApplication">
    <Surface>
      <Provider>
        <Text>Coach Application Form</Text>
        <Text>Fill this out to be considered for the awesome job as a coach</Text>
        <View style={{
          flexDirection: 'row'
        }}>
          <View>
            <View style={styles.root}>

              <View>
                <CplTextField name="firstName" label="First Name" formObject={formObject} onChange={onChange} errors={errors} />
                <CplTextField name="lastName" label="Last Name" formObject={formObject} onChange={onChange} errors={errors} />
              </View>
              <View>
                <CplTextField testID="birthDate" formObject={formObject} onChange={onChange} label="BirthDate" name="birthDate" errors={errors} required />
              </View>
              <CplRadioGroup label="Current status" name="occupation" formObject={formObject} onChange={onChange} errors={errors} radioButtons={valueLabelArray(PROFILE.OCCUPATION, PROFILE.OCCUPATION_LABEL)} />

              <CplTextField label="Why would you be a good after school coach" name="essay" formObject={formObject} onChange={onChange} numberOfLines={4} multiline errors={errors} required />

              <View>
                <Button mode="contained" testID="submit" onPress={() => updateProfileSubmit()}>
                    Submit
                </Button>
              </View>
            </View>
          </View>
        </View>
      </Provider>
    </Surface>
  </View>;
}
