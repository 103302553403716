import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import {useEmulator} from '../utils/environment';
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";

const debug = debugModule(DEBUG_MODULE.FIRESTORE.RFS);

const FB_REACT_CONFIG = {
  REACT_APP_API_KEY: 'AIzaSyBuYGsrojCkWFd51pvZk2I6FBobDgeRZX0',
  REACT_APP_AUTH_DOMAIN: 'coachplusdev.firebaseapp.com',
  REACT_APP_PROJECT_ID: 'coachplusdev',
  REACT_APP_STORAGE_BUCKET: 'coachplusdev.appspot.com',
  REACT_APP_MESSAGING_SENDER_ID: '36886243564',
  REACT_APP_ID: '1:36886243564:web:518620b326d08e235d5dc9',
  REACT_SERVICE_ACCOUNT: 'secrets/coachplusdev-firebase-adminsdk-7kw5p-37283aac76.json'
};
const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_ID

} = FB_REACT_CONFIG;


//
// if (!REACT_APP_API_KEY) {
//   REACT_APP_API_KEY = FB_REACT_APP_API_KEY;
//   REACT_APP_AUTH_DOMAIN = FB_REACT_APP_AUTH_DOMAIN;
//   REACT_APP_PROJECT_ID = FB_REACT_APP_PROJECT_ID;
//   REACT_APP_STORAGE_BUCKET = FB_REACT_APP_STORAGE_BUCKET;
//   REACT_APP_MESSAGING_SENDER_ID = FB_REACT_APP_MESSAGING_SENDER_ID;
//   REACT_APP_ID = FB_REACT_APP_ID;
// }
debug('REACT_APP_PROJECT_ID', useEmulator, REACT_APP_PROJECT_ID, REACT_APP_API_KEY);
export const firebaseConfig: any = useEmulator ? {
  apiKey: REACT_APP_API_KEY,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: 'coachplusdev.appspot.com'
} : {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_ID
};
//
// if (process.env.NODE_ENV !== 'production') {
//   firebaseConfig.databaseURL = 'http://localhost:5999';
// }
debug('INITIALIZE FIRESTORE', firebaseConfig);
let firebaseAppInitialized;
// let functionsInitialized;
let firestoreInitialized;
let authInitialized;

if (firebase.apps.length === 0) {
  if (useEmulator) {
    process.env.FIRESTORE_EMULATOR_HOST = 'localhost:5999';
    process.env.FIREBASE_AUTH_EMULATOR_HOST = 'localhost:9099';
  }

  firebaseAppInitialized = firebase.initializeApp(firebaseConfig);
  // functionsInitialized = firebaseAppInitialized.functions();
  firestoreInitialized = firebaseAppInitialized.firestore();
  authInitialized = firebaseAppInitialized.auth();

  if (useEmulator) {
    debug('auth use emulator');
    firestoreInitialized.settings({
      host: 'localhost:5999',
      ssl: false,
      merge: true
    });
    authInitialized.useEmulator('http://localhost:9099'); // functionsInitialized.useEmulator('Mac-mini.local', 5001);
  }
}

debug('firebase.INITIALIZED.');
type AuthType = {
  createUserWithEmailAndPassword: (arg0: string, arg1: string) => any;
  currentUser: {
    getIdTokenResult: () => any;
  };
  signInWithEmailAndPassword: (arg0: string, arg1: string) => any;
  signOut: () => any;
  onAuthStateChanged: (arg0: any) => any;
  setEmulatorRole: (uid: string, role: string) => void;
}; //
// const p = new Promise((resolve) => {
//   resolve({
//     role: 'MOCK_COACH'
//   });
// });
//
// const mockAuth: AuthType = {
//   createUserWithEmailAndPassword: (name, password): Promise<any> => new Promise((resolve) => {
//     resolve('mockUser');
//   }),
//   currentUser: () => ({ getIdTokenResult: () => new Promise((resolve) => {
//     resolve({
//       role: 'MOCK_COACH'
//     });
//   }) }),
//   signInWithEmailAndPassword: (name, password): Promise<any> => new Promise((resolve) => {
//     resolve('mockUser');
//   }),
//   signOut: (): Promise<any> => new Promise((resolve) => {
//     resolve('signOut');
//   }),
//   onAuthStateChanged: (name): Promise<any> => new Promise((resolve) => {
//     resolve('mockUser');
//   }),
// };
//
// class MockAuth {
//   userRole = {};
//   currentRole = 'MOCK_COACH';
//   currentUser = {
//     getIdTokenResult: () => new Promise(resolve => {
//       resolve({
//         claims: {
//           role: this.currentRole
//         }
//       });
//     })
//   };
//
//   setEmulatorRole(uid: string, role: string) {
//     this.userRole[uid] = role;
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   createUserWithEmailAndPassword(name, password): Promise<any> {
//     const uid = 'mockUid' + name.split('@')[0];
//     return new Promise(resolve => {
//       resolve({
//         user: {
//           uid
//         }
//       });
//     });
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   signInWithEmailAndPassword(name, password): Promise<any> {
//     const uid = 'mockUid' + name.split('@')[0];
//     return new Promise(resolve => {
//       resolve({
//         user: {
//           uid
//         }
//       });
//     });
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   signOut(): Promise<any> {
//     return new Promise(resolve => {
//       resolve('mockUser');
//     });
//   }
//
//   // eslint-disable-next-line class-methods-use-this
//   onAuthStateChanged(name): () => any {
//     return () => {
//       debug('onAuthStateChanged unsubscribe');
//     };
//   }
//
// }

type FirestoreType = {
  collection: (arg0: string) => any;
  useEmulator: (arg0: string, arg1: number) => any;
  doc: (arg0: string) => any;
};

// export const firebaseApp: Record<string,any> = firebase.app();
export const functions: {
  httpsCallable: (arg0: string) => any;
} = firebase.app().functions();
export const firestore: FirestoreType = firebase.app().firestore();
export const storage: firebase.storage.Storage = firebase.app().storage('gs://coachplusdev.appspot.com');
if (useEmulator){
  storage.useEmulator("localhost", 9199);
}
// export const auth: AuthType = process.env.USE_EMULATOR === 'true' ? (new MockAuth(): AuthType) : firebase.app().auth();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const auth: AuthType = authInitialized;
export async function createTestUser(name: string): Promise<void> {
  const d = Date.now() % 1000;
  return auth.createUserWithEmailAndPassword(`${name}${d}@gmail.com`, '12345678').then(() => {
    debug('createTestUser', name, d);
  }).catch(e => {
    console.error(`Create User Error ${name}${d}`, e);
  });
}

if (useEmulator) {
  debug('TRY CREATE USER in Emulator', firebase.apps.length); // createTestUser('RSFInitialization')
  //   .then(() => {});
}
