import { StyleSheet, Text, View } from "react-native";
import * as React from "react";
import { Switch } from "react-native-paper";
const styles = StyleSheet.create({
  label: {},
  errors: {}
});
export type CplSwitchProps = {
  label: string;
  name: string;
  testID?: string;
  formObject: Record<string, any>;
  onChange: (name: string, newValue: boolean) => void;
};
export default function CplSwitch(props: CplSwitchProps): JSX.Element {
  const {
    label,
    name,
    testID,
    formObject,
    onChange
  } = props;
  const value = formObject[name];
  return <View testID={testID}>
    <Text style={styles.label}>{label}</Text>
    <Switch value={value} onValueChange={v => onChange(name, v)} />
  </View>;
}
