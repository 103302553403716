import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {ValidateAction, ValidationError} from "../../utils/validation";
import type {CoachId, StudentId, UserId} from "../../sharedDefs/userDefs";
import {addReducerLogging, loggerCopy} from "./common";
import type {HuddleState} from "../structs/huddleStruct";
import type {AudioMode, HuddleAction} from "../../sharedDefs/huddleDefs";
import {HUDDLE, HUDDLE_ACTION} from "../../sharedDefs/huddleDefs";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";
import {WebconAction} from "../structs/webconStruct";
import {ActionType} from "../../sharedDefs/commonStruct";

const debug = debugModule(DEBUG_MODULE.HUDDLE.REDUCER);


export const huddleCoachConnectsToEnteredStudent = (coachId: CoachId, studentId: StudentId): WebconAction => {
  return {
    type: HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    coachId,
    studentId
  };
};


export const huddleDebugHuddleState = (): WebconAction => {
  return {
    type: HUDDLE_ACTION.DEBUG.HUDDLE_STATE,
    origin: ACTION_ORIGIN.INSTIGATE,
  };
};


export const huddleHeyCoachMode = (parent:ActionType,userId: UserId,coachId: CoachId, heyCoach:boolean): HuddleAction => {
  invariant( userId && coachId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.HEY_COACH.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    heyCoach,
    coachId,
    userId
  };
};

export const huddleAudioMode = (parent:ActionType,userId: UserId, audioMode: AudioMode): HuddleAction => {
  invariant(audioMode && userId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.AUDIO_MODE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    audioMode: audioMode,
    userId
  };
};
export const huddleStudentArrive = (parent:ActionType,coachId: CoachId, userId: UserId): HuddleAction => {
  invariant(coachId && userId, 'invalid-reducer');
  return {
    type: HUDDLE_ACTION.STUDENT_ARRIVE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const huddleCoachAdmitStudent = (parent:ActionType,coachId: CoachId, studentId: StudentId): HuddleAction => {
  invariant(coachId  && studentId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.COACH_ADMIT_STUDENT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    studentId
  };
};
export const huddleCoachReleaseStudent = (parent:ActionType,coachId: CoachId, studentId: UserId): HuddleAction => {
  invariant(coachId  && studentId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.COACH_RELEASE_STUDENT.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    studentId
  };
};
export const huddleCoachEnter = (parent:ActionType,coachId: CoachId): HuddleAction => {
  invariant(coachId, 'invalid-reducer '+coachId);

  return {
    type: HUDDLE_ACTION.COACH_ENTER.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
  };
};

export const huddleStudentEnter = (parent:ActionType,coachId: CoachId, userId: UserId): HuddleAction => {
  invariant(coachId && userId, 'invalid-reducer '+coachId+' '+userId);

  return {
    type: HUDDLE_ACTION.STUDENT_ENTER.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const huddleLeave = (parent:ActionType,coachId: CoachId, userId: UserId): HuddleAction => {
  invariant(coachId && userId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.LEAVE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    userId
  };
};
export const huddleDismiss = (parent:ActionType,coachId: CoachId, studentId: StudentId): HuddleAction => {
  invariant(coachId && studentId, 'invalid-reducer');

  return {
    type: HUDDLE_ACTION.DISMISS.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parent,
    coachId,
    studentId
  };
};

const initialState: HuddleState = {
  entering: false,
  coachId: HUDDLE.NO_COACH,
  huddle: undefined,
};

function _huddleReducer(state: HuddleState = initialState, action: HuddleAction): HuddleState {
  switch (action.type) {
    case HUDDLE_ACTION.STUDENT_ARRIVE.REQUEST:
    {
      ValidateAction(action, ['coachId', 'userId']);
      return { ...state,
        coachId: action.coachId
      };
    }
    case HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.REQUEST: {
      ValidateAction(action, ['huddle']);
      return { ...state,
        huddle: action.huddle,
        coachId: action.huddle.coachId
      };
    }

    case HUDDLE_ACTION.STUDENT_ARRIVE.INITIALIZE:
    {
      ValidateAction(action, ['huddle', 'coachId']);
      invariant(action.huddle.coachId === action.coachId, 'Coach Mismatch '+action.huddle.coachId+' '+action.coachId );
      if (state.coachId !== action.coachId) {
        throw new ValidationError('invalid-action', action);
      }
      //const sids1 = Object.keys(action.huddle.studentHuddleUsers);
      //debug("ARRIVE INIT", action.huddle.coachUser.huddleUserStatus, action.huddle.studentHuddleUsers[sids1[0]].huddleUserStatus);

      return { ...state,
        huddle: action.huddle,
        coachId: action.coachId
      };
    }
    case HUDDLE_ACTION.STUDENT_ENTER.FAILURE:
    case HUDDLE_ACTION.COACH_ENTER.FAILURE:
      debug('enter Failure action session');
      return { ...state,
        entering: false
      };
    case HUDDLE_ACTION.COACH_ENTER.PERFORM:
      debug('enter currentSession action session', state.coachId, action.coachId);
      invariant(action.coachId, 'invalid-action');
      ValidateAction(action, ['coachId']);
      return { ...state,
        coachId: action.coachId,
        entering: true
      };
    case HUDDLE_ACTION.STUDENT_ENTER.REQUEST:
      debug('enter currentSession action session', state.coachId, action.coachId);
      invariant(action.coachId, 'invalid-action');
      ValidateAction(action, ['coachId', 'userId']);
      return { ...state,
        coachId: action.coachId,
        entering: false
      };
    case HUDDLE_ACTION.COACH_ENTER.PRE_ENTER:
      debug('enter PRE_ENTER action session');
      invariant(action.huddle, 'invalid-action');

      ValidateAction(action, ['huddle']);
      return { ...state,
        huddle: action.huddle,
        coachId: action.huddle.coachId
      };
    case HUDDLE_ACTION.COACH_ENTER.REQUEST:
      debug('enter currentSession action session', state.coachId, action.coachId);
      invariant(action.coachId, 'invalid-action');
      ValidateAction(action, ['coachId']);
      return { ...state,
        coachId: action.coachId,
        entering: false
      };
    case HUDDLE_ACTION.STUDENT_ENTER.SUCCESS:
    case HUDDLE_ACTION.COACH_ENTER.SUCCESS: {
      ValidateAction(action, ['huddle']);

      debug("HUDDLE_ENTER SUCCESS", action.huddle.coachUser.huddleUserStatus);
      return {
        ...state,
        huddle: action.huddle,
        coachId: action.huddle.coachId,
        entering: false
      };
    }
    case HUDDLE_ACTION.HEY_COACH.SUCCESS: {
      ValidateAction(action, ['huddle']);

      debug("HUDDLE_ENTER SUCCESS", action.huddle.coachUser.huddleUserStatus);
      return {
        ...state,
        huddle: action.huddle,
      };
    }
    case HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST: {
      ValidateAction(action, [ 'huddle']);
      return { ...state,
        coachId: HUDDLE.NO_COACH,
        huddle:action.huddle
      };
    }
    case HUDDLE_ACTION.LEAVE.SUCCESS:
      ValidateAction(action, ['huddle', 'coachId']);
      return { ...state,
        huddle: undefined,
        coachId: undefined
      };

    case HUDDLE_ACTION.UPDATE.SUCCESS:
      ValidateAction(action, ['huddle']);

      return { ...state,
        huddle: action.huddle,
        coachId: action.huddle.coachId
      };

    case HUDDLE_ACTION.AUDIO_MODE.SUCCESS:
      ValidateAction(action, ['huddle']);
      return { ...state,
        huddle: action.huddle,
      };
    default:
      return state;
  }
}

function actionForLogger(action: HuddleAction) {
  const {
    coachId,
    parent,
    huddle,
    type,
    origin,
  } = action;
  const parents = [];
  for (let p = parent; p; p = p.parent){
    parents.push(p.type);
  }
  return {
    type,
    parents,
    origin,
    coachId,
    huddle: loggerCopy(huddle),
    huddle_studentHuddleUsers: loggerCopy(huddle && huddle.studentHuddleUsers),
    studentHuddleUsers: huddle && huddle.studentHuddleUsers,
    hasHuddleChannel: 'huddleSyncChannel' in action,
    error: action.error
  };
}

export default function huddleReducer(state: HuddleState = initialState, action: HuddleAction): HuddleState {
  const prefix = 'HUDDLE_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _huddleReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
