import {CoachId, UserId} from "./userDefs";

export const WS_ACTION = {

  HUDDLE_DOCUMENT: {
    START: 'WS_ACTION.HUDDLE_DOCUMENT.START',
    SYNC: 'WS_ACTION.HUDDLE_DOCUMENT.SYNC',
    STOP: 'WS_ACTION.HUDDLE_DOCUMENT.STOP'
  },
  ADD: {
    REQUEST: 'WS_ACTION.ADD.REQUEST',
    SUCCESS: 'WS_ACTION.ADD.SUCCESS',
    FAILURE: 'WS_ACTION.ADD.FAILURE'
  },
  INITIALIZE: {
    REQUEST: 'WS_ACTION.INITIALIZE.REQUEST',
    SUCCESS: 'WS_ACTION.INITIALIZE.SUCCESS',
    FAILURE: 'WS_ACTION.INITIALIZE.FAILURE'
  },
  CHECK_ROOMS: {
    REQUEST: 'WS_ACTION.CHECK_ROOMS.REQUEST',
    SUCCESS: 'WS_ACTION.CHECK_ROOMS.SUCCESS',
    FAILURE: 'WS_ACTION.CHECK_ROOMS.FAILURE'
  },
  SYNC: {
    START: 'WS_ACTION.SYNC.START',
    VERIFIED_START: 'WS_ACTION.SYNC.VERIFIED_START',
    INITIALIZE: 'WS_ACTION.SYNC.INITIALIZE',
    CONNECTING: 'WS_ACTION.SYNC.CONNECTING',
    CONNECTED: 'WS_ACTION.SYNC.CONNECTED',
    CLOSED: 'WS_ACTION.SYNC.CLOSED',
    DISCONNECTED: 'WS_ACTION.SYNC.DISCONNECTED',
    CHANNEL_STARTED: 'WS_ACTION.SYNC.STARTED',
    STOP: 'WS_ACTION.SYNC.STOP',
    ADD: 'WS_ACTION.SYNC.ADD',
    STOPPED: 'WS_ACTION.SYNC.STOPPED',
    ERROR: 'WS_ACTION.SYNC.ERROR'
  }
};

export type  ServerWsAction = { readonly type: string;
  coachId?: CoachId;
  userId: UserId;

  readonly error?: Error; }


export const  webSocketCheckRooms = (userId:UserId,coachId: CoachId) :ServerWsAction =>{
  return {
    type: WS_ACTION.CHECK_ROOMS.REQUEST,
    userId,
    coachId,

  };
};


export const  webSocketCheckRoomsSuccess = (userId:UserId) : ServerWsAction =>{
  return {
    type: WS_ACTION.CHECK_ROOMS.SUCCESS,
    userId,
  };
};


export const  webSocketCheckRoomsFail = (userId:UserId,error:Error) : ServerWsAction =>{
  return {
    type: WS_ACTION.CHECK_ROOMS.FAILURE,
    userId,
    error,

  };
};
