import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import {enableTestPage} from "../../utils/environment";
import {navGoHomeSaga} from "../../redux/sagas/navigationSaga";
import {useDispatch} from "react-redux";
import {navGoHome} from "../../redux/reducer/navigationReducer";
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
});

export default function TestPageEnable(): JSX.Element {
  const dispatch = useDispatch();
  enableTestPage();
  React.useEffect( () => {
    dispatch(navGoHome(null));
  } , []);
  return <View testID="Maintenance" style={styles.root}>

    <Text>PageEnable</Text>

  </View>;
}
