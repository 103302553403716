//
import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button } from "react-native-paper";
import { useDispatch } from "react-redux";
import { queryUserProfile } from "../../redux/queries/UserQueries";
import { adminCoachAssessApprove, adminCoachAssessDecline } from "../../redux/reducer/adminReducer";
import type { UserId, UserProfile } from "../../sharedDefs/userDefs";
type TabPanelProps = {
  children: any;
  value: any;
  index: number;
};

function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index
  } = props;
  return <View testID={`simple-tabpanel-${index}`}>
    {value === index && <View>
      <Text>{children}</Text>
    </View>}
  </View>;
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative'
  }
});
type AdminCoachDetailPropType = {
  route: {
    params: {
      userId: UserId;
    };
  };
  mockCoach?: UserProfile;
};
export default function AdminCoachDetail(props: AdminCoachDetailPropType): JSX.Element {
  const dispatch = useDispatch();
  const {
    route,
    mockCoach
  } = props;
  const {
    userId
  } = route.params;
  const [tabNum, setTabNum] = React.useState(0);
  const [coach, setCoach]: [UserProfile | null | undefined, (arg0: ((arg0: UserProfile | null | undefined) => UserProfile | null | undefined) | (UserProfile | null | undefined)) => void] = React.useState(mockCoach);
  const updateCoach = React.useCallback((_coach: UserProfile) => {
    setCoach(_coach);
  },[coach]);
  React.useEffect(() => {
    if (userId) {
      return queryUserProfile(userId, updateCoach);
    }

    return () => {};
  }, [userId]);

  function deny() {
    dispatch(adminCoachAssessDecline(userId));
  }

  function approve() {
    dispatch(adminCoachAssessApprove(userId));
  }

  return <View testID="AdminCoachDetail" style={styles.root}>
    <View>
      <View>
        <Text>
            Current State
          {' '}
          {coach?.firstName}
          {' '}

        </Text>
        <Button testID="deny" mode="contained" onPress={deny}>

            Deny
        </Button>
        <Button testID="approve" mode="contained" onPress={approve}>
            Approve
        </Button>
      </View>
      <View>
        {
          /* <AppBar position="static"> */
        }
        {
          /*  <Tabs value={tabNum} onChange={handleChange} aria-label="simple tabs example"> */
        }
        {
          /*    <Tab label="Application Form" {...a11yProps(0)} /> */
        }
        {
          /*    <Tab label="Documents" {...a11yProps(1)} /> */
        }
        {
          /*    <Tab label="References" {...a11yProps(2)} /> */
        }
        {
          /*  </Tabs> */
        }
        {
          /* </AppBar> */
        }
        <TabPanel value={tabNum} index={0}>
            Item One
        </TabPanel>
        <TabPanel value={tabNum} index={1}>
            Item Two
        </TabPanel>
        <TabPanel value={tabNum} index={2}>
            Item Three
        </TabPanel>
      </View>
    </View>
  </View>;
}
