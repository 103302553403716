import {Text, TextInput} from 'react-native-paper';
import {View} from "react-native";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import * as React from "react";

export type AdditionParams = {
  minA: number;
  maxA: number,
  minB: number,
  maxB: number,
  minSum: number,
  maxSum: number
}
type WorkbenchGeneratorProps = {
  problemGenerationParams: AdditionParams;
  onGeneratorChange: (problemGenerationParams:AdditionParams )=>void;
}
export default function AdditionWorkbench(props:WorkbenchGeneratorProps): JSX.Element {
  const {onGeneratorChange,problemGenerationParams} = props;


  React.useEffect( () => {


    if (!problemGenerationParams || Object.keys(problemGenerationParams).length != 6 || problemGenerationParams.minSum === undefined) {
      console.log('Object.keys(problemGenerationParams).length', problemGenerationParams);
      const params:AdditionParams = {
        minA: 1,
        maxA: 10,
        minB: 1,
        maxB: 10,
        minSum: 1,
        maxSum: 100
      };

      console.log('Object.keys(problemGenerationParams).length', params);
      onGeneratorChange(params);
    }
  },[]);



  return (
    <View  style={gridStyles.gridColumnContainer} >
      <Text style={textStyles.titleBar}>Addition</Text>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minA"  value={problemGenerationParams?.minA?.toString(10) ||''}
          onChangeText={minA => {
            try {
              if (minA.length === 0){
                onGeneratorChange({...problemGenerationParams,minA: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, minA: parseInt(minA, 10)});
              }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minA: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxA"  value={problemGenerationParams?.maxA?.toString(10)||''}
          onChangeText={maxA => {
            try {if (maxA.length === 0){
              onGeneratorChange({...problemGenerationParams,maxA: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, maxA: parseInt(maxA, 10)});
            }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxA: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minB"  value={problemGenerationParams?.minB?.toString(10)||''}
          onChangeText={minB => {
            try {if (minB.length === 0){
              onGeneratorChange({...problemGenerationParams,minB: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, minB: parseInt(minB, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minB: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxB"  value={problemGenerationParams?.maxB?.toString(10)||''}
          onChangeText={maxB => {
            try {if (maxB.length === 0){
              onGeneratorChange({...problemGenerationParams,maxB: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, maxB: parseInt(maxB, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxB: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minProduct"  value={problemGenerationParams?.minSum?.toString(10)||''}
          onChangeText={minSum => {
            try {
              if (minSum.length === 0){
                onGeneratorChange({...problemGenerationParams,minSum: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, minSum: parseInt(minSum, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minSum: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxSum"  value={problemGenerationParams?.maxSum?.toString(10)||''}
          onChangeText={maxSum => {
            try {
              if (maxSum.length === 0){
                onGeneratorChange({...problemGenerationParams,maxSum: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, maxSum: parseInt(maxSum, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxSum: null });
            }
          }}
        />
      </View>

    </View>
  );
}
