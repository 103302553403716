import type {UserId} from "../../sharedDefs/userDefs";
import type {SpeechMode} from "../../sharedDefs/speechDefs";
import {ActionType} from "../../sharedDefs/commonStruct";

export type SpeechEvent = {
  message: string;
  error: Error;
};

export type SpeechRecognizer = {
  lang: string;
  grammars: SpeechGrammarList;
  continuous: boolean;
  interimResults: boolean;
  maxAlternatives: number;
  start: () => void;
  stop: () => void;
  onresult: (event: SpeechEvent) => void;
  onnomatch: (event: SpeechEvent) => void;
  onerror: (event: SpeechEvent) => void;
  onend: (event: SpeechEvent) => void;
  onStart: (event: SpeechEvent) => void;
};
export const SPEECH_ACTION = {
  SPEECH_MODE: {
    SET: 'SPEECH_ACTION.SPEECH_MODE.SET'
  },
  RECOGNIZER: {
    SET: 'SPEECH_ACTION.RECOGNIZER.SET'
  },
  SPEECH_CHANNEL: {
    SET: 'SPEECH_ACTION.SPEECH_CHANNEL.SET',
    FAILURE: 'SPEECH_ACTION.SPEECH_CHANNEL.FAILURE'
  }
};
export type SpeechState = {
  readonly localSpeechMode: SpeechMode;
  readonly speechChannel?: any;
  studentId?: UserId;
  recognizer?: any;
  recognizerRunning: boolean;

};
export type SpeechAction = ActionType & {
  localSpeechMode?: SpeechMode;
  speechChannel?: any;
  studentId?: UserId;
  recognizer?: any;
  recognizerRunning?: boolean;
  error?: Error;
};
