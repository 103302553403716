import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Button, Surface } from "react-native-paper";
import { useDispatch } from "react-redux";
import CplTextField from "../../components/FormControls/CplTextField";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
const debug = debugModule(DEBUG_MODULE.USER.AUTH);
// import styles from './user-jss';
type ResetPasswordProps = Record<string,never>;
const styles = StyleSheet.create({});
export default function ResetPassword(props: ResetPasswordProps): JSX.Element {
  const dispatch = useDispatch();
  const [formObject, setFormObject] = React.useState({});
  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    setFormObject({ ...formObject,
      ...f
    });
    debug('NEW REG', formObject);
  },[formObject]);

  function submitResetForm() {// dispatch(userResetRequest(reg.email));
  }

  const errors = {};
  return <View >
    <View >
      <View style={{
        flex: 1,
        flexDirection: 'row'
      }}>
        <View>
          <View>
            <View >
              <Surface>
                <View>
                  <CplTextField label="Email" errors={errors} name="email"  onChange={onChange} formObject={formObject} />
                </View>
                <View >
                  <Button testID="submit_reset" mode="contained" onPress={() => submitResetForm()}>
                      Reset Password
                  </Button>
                </View>

              </Surface>
            </View>
          </View>
        </View>
      </View>
    </View>
  </View>;
}
