import React from "react";
import {Provider, ReactReduxContext} from "react-redux";
import Navigation from "./Navigation";
import configureStore from "./redux/store";
import CplThemeProvider from "./styles/CplThemeProvider";

import sms from 'source-map-support';
import TestPage from "./containers/TestPage";

sms.install();

const store = configureStore();
export default function App(): JSX.Element{
  return <CplThemeProvider>
    <Provider store={store} context={ReactReduxContext}>
      <TestPage/>
      <Navigation />
    </Provider>
  </CplThemeProvider>;
}
