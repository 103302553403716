import * as React from "react";
import {CommonActions} from '@react-navigation/native';
import {NAV} from "./redux/structs/navigationStruct";


export const navigationRef: any = React.createRef();
export function navigateTo(screenName: string, screenParams?: Record<string, string>): void {
  console.log('NAVIGATE TO ' + screenName);
  if (screenName === NAV.COACH.HOME) {
    navigationRef.current?.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          { name: screenName },
        ],
      })
    );
  } else {
    if (screenParams) {
      navigationRef.current?.navigate(screenName, screenParams);
    } else {
      navigationRef.current?.navigate(screenName);
    }
  }
}
