import * as React from "react";
import {View} from "react-native";
import {useSelector} from "react-redux";
import type {State} from "../../redux/reducer";
import ParentStudentGallery from "./ParentStudentGallery";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";

const debug = debugModule(DEBUG_MODULE.USER.CONTAINER);

// type ParentProps = Record<string,never>;
export default function ParentHome(/* props: ParentProps */): JSX.Element {

  const userData = useSelector((state: State) => state.userReducer.userData);
  debug('userData', userData);
  const children = useSelector((state: State) => state.userReducer.userData && state.userReducer.userData.children);

  return <View testID="ParentHome">
    <View testID="parent_home">
      <ParentStudentGallery students={children} parentUserData={userData} />
    </View>

  </View>;
}
