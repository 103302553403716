import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {ValidateAction} from "../../utils/validation";
import type {
  CurriculumAction,
  CurriculumStudentInteraction,
  CurriculumStudentProgressionSummary,
  CurriculumStudentResponse,
  ProgressionSetId,
  WidgetSetId
} from "../../sharedDefs/curriculumDefs";
import {CURRICULUM_ACTION} from "../../sharedDefs/curriculumDefs";
import type {CoachId, StudentId, UserId} from "../../sharedDefs/userDefs";
import {addReducerLogging} from "./common";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";
import {CurriculumState} from "../structs/curriculumStruct";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.REDUCER);



export const curriculumProgressionSetProblemAdvanceRequest = (studentId: StudentId, coachId: CoachId): CurriculumAction => ({
  type: CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.REQUEST,
  coachId,
  studentId,

});
export const curriculumSubmitPerform = (studentId: StudentId, coachId: CoachId, curriculumStudentResponse: CurriculumStudentResponse): CurriculumAction => {
  invariant(curriculumStudentResponse && studentId && curriculumStudentResponse.confidence, 'invalid-reducer curriculumSubmitPerform');
  return {
    type: CURRICULUM_ACTION.SUBMIT.PERFORM,
    origin: ACTION_ORIGIN.INSTIGATE,
    studentId,
    coachId,
    curriculumStudentResponse
  };
};

export const curriculumStudentResponseKVPerform = (studentId: StudentId, coachId: CoachId,key: string, value:string, index?:number  ): CurriculumAction => {
  invariant(key &&   coachId && studentId, 'invalid-reducer curriculumStudentResponse');

  debug('curriculumStudentResponseKVPerform interactionId ', key, value);
  return {
    type: CURRICULUM_ACTION.STUDENT_RESPONSE.KV_PERFORM,
    origin: ACTION_ORIGIN.INSTIGATE,
    studentId,
    coachId,
    key,
    value, index
  };
};
export const curriculumStudentResponsePerform = (studentId: StudentId, coachId: CoachId,  curriculumStudentResponse: CurriculumStudentResponse): CurriculumAction => {
  invariant(curriculumStudentResponse &&   coachId && studentId, 'invalid-reducer curriculumStudentResponse');

  debug('curriculumStudentResponsePerform interactionId '+curriculumStudentResponse.interactionId);
  return {
    type: CURRICULUM_ACTION.STUDENT_RESPONSE.PERFORM,
    origin: ACTION_ORIGIN.INSTIGATE,
    studentId,
    coachId,
    curriculumStudentResponse
  };
};

export const curriculumProgressionSetAssignRequest = (studentId: StudentId, coachId: CoachId, progressionSetId: ProgressionSetId, assessmentId?: string): CurriculumAction => {
  debug('curriculumProgressionSetAssignRequest ACTION');
  invariant((progressionSetId || assessmentId) && studentId && coachId, 'invalid-reducer curriculumProgressionSetAssignRequest');
  return {
    type: CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    studentId,
    coachId,

    progressionSetId,
    assessmentId
  };
};

export const curriculumProgressionSetCompletedRequest = (studentId: StudentId, coachId: CoachId): CurriculumAction => {
  invariant(studentId, 'invalid-reducer curriculumProgressionSetCompletedRequest');
  return {
    type: CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.REQUEST,
    studentId,
    coachId,
  };
};
const initialState: CurriculumState = {
  curriculumStudentResponseMap: {},
  curriculumStudentInteractionMap: {},
  curriculumStudentProgressionMap: {},
  curriculumStudentProgressionSummaryMap: {}
};

function _curriculumReducer(state: CurriculumState = initialState, action: CurriculumAction): CurriculumState {
  switch (action.type) {
    case CURRICULUM_ACTION.STUDENT_SUMMARY.SYNC: {
      const { studentId, curriculumStudentProgressionSummary } = action;
      invariant(curriculumStudentProgressionSummary && studentId, 'action_format ' + action.type+!!curriculumStudentProgressionSummary +' '+!!studentId);
      const curriculumStudentProgressionSummaryMap: Record<UserId, CurriculumStudentProgressionSummary> = { ...state.curriculumStudentProgressionSummaryMap};
      curriculumStudentProgressionSummaryMap[studentId] = curriculumStudentProgressionSummary;

      return { ...state,
        curriculumStudentProgressionSummaryMap
      };

    }
    case CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.SUCCESS: {
      const {
        widgetSetId,
        studentId,
        coachId,
        curriculumStudentInteraction
      } = action;
      const curriculumStudentInteractionMap: Record<UserId, CurriculumStudentInteraction> = { ...state.curriculumStudentInteractionMap};
      curriculumStudentInteractionMap[studentId] = curriculumStudentInteraction;
      return { ...state,
        curriculumStudentInteractionMap
      };
    }





    case CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.SUCCESS:
    {

      const {
        curriculumStudentProgression,
        curriculumStudentInteraction,
        curriculumStudentResponse,
        studentId
      } = action;
      invariant(curriculumStudentProgression && curriculumStudentInteraction && curriculumStudentResponse && studentId, 'action_format ' + action.type);
      const curriculumStudentProgressionMap = { ...state.curriculumStudentProgressionMap
      };
      curriculumStudentProgressionMap[studentId] = curriculumStudentProgression;
      const curriculumStudentInteractionMap = { ...state.curriculumStudentInteractionMap
      };
      curriculumStudentInteractionMap[studentId] = curriculumStudentInteraction;

      const curriculumStudentResponseMap = { ...state.curriculumStudentResponseMap
      };
      curriculumStudentResponseMap[studentId] = curriculumStudentResponse;


      return { ...state,
        curriculumStudentProgressionMap,
        curriculumStudentInteractionMap,
        curriculumStudentResponseMap
      };
    }

    case CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.REQUEST:
    {
      invariant(action.studentId && action.coachId && (action.progressionSetId || action.assessmentId), 'invalid-action');

      return state;
    }

    case CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.FAILURE:
    {
      ValidateAction(action, ['error']);
      return state;
    }

    case     CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.REQUEST: {
      return state;

      // TODO const {
      //   studentId
      // } = action;
      // const curriculumStudentResponseMap: Record<UserId, CurriculumStudentResponse> = { ...state.curriculumStudentResponseMap,
      // };
      // curriculumStudentResponseMap[studentId] = null;
      // return { ...state,
      //   curriculumStudentResponseMap
      // };
    }

    case CURRICULUM_ACTION.STUDENT_RESPONSE.PERFORM:
    {
      ValidateAction(action, ['studentId', 'coachId','curriculumStudentResponse']);
      const {
        curriculumStudentResponse,
        studentId
      } = action;
      const curriculumStudentInteraction: CurriculumStudentInteraction = state.curriculumStudentInteractionMap[studentId];


      invariant(curriculumStudentResponse && studentId && curriculumStudentResponse.responseKeyValues, 'action_format ' + action.type);
      invariant(  curriculumStudentResponse.interactionId === curriculumStudentInteraction.interactionId, 'curriculumStudentResponse interactionId mismatch response='+ curriculumStudentResponse.interactionId +' interaction'+ curriculumStudentInteraction.interactionId);

      const curriculumStudentResponseMap: Record<UserId, CurriculumStudentResponse> = { ...state.curriculumStudentResponseMap,
      };
      curriculumStudentResponseMap[studentId] = curriculumStudentResponse;

      // debug('CURRICULUM_ACTION.STUDENT_RESPONSE.PERFORM Reducer '+JSON.stringify(curriculumStudentResponse,null,2));
      return { ...state,
        curriculumStudentResponseMap
      };
    }

    case CURRICULUM_ACTION.STUDENT_RESPONSE.SUCCESS:
    case CURRICULUM_ACTION.STUDENT_RESPONSE.UPDATE:
    case CURRICULUM_ACTION.SUBMIT.UPDATE:
    {
      // ValidateAction(action, ['studentId', 'curriculumStudentResponse']);
      const {
        studentId,
        curriculumStudentResponse
      } = action;
      const curriculumStudentResponseMap: Record<UserId, CurriculumStudentResponse> = { ...state.curriculumStudentResponseMap,
      };
      curriculumStudentResponseMap[studentId] = curriculumStudentResponse;
      // const curriculumStudentInteraction: CurriculumStudentInteraction = state.curriculumStudentInteractionMap[studentId];
      // invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction?.interactionId, 'curriculumStudentResponse interactionId mismatch ' +!!curriculumStudentResponse+' ' +!!curriculumStudentInteraction+' ' +curriculumStudentResponse?.interactionId +' '+ curriculumStudentInteraction?.interactionId +'  '+JSON.stringify(action,null,2));


      return { ...state,
        curriculumStudentResponseMap
      };
    }
    case  CURRICULUM_ACTION.INTERACTION.ASSIGN.SUCCESS:{
      const {
        curriculumStudentInteraction,
        curriculumStudentResponse,
        studentId,
      } = action;
      invariant(studentId && curriculumStudentResponse && curriculumStudentInteraction, 'action_format ' + action.type+' '+studentId+' '+(!!curriculumStudentResponse)+' '+(!!curriculumStudentInteraction));


      const curriculumStudentInteractionMap: Record<UserId, CurriculumStudentInteraction> = { ...state.curriculumStudentInteractionMap};
      curriculumStudentInteractionMap[studentId] = curriculumStudentInteraction;

      const curriculumStudentResponseMap: Record<UserId, CurriculumStudentResponse> = { ...state.curriculumStudentResponseMap};
      curriculumStudentResponseMap[studentId] = curriculumStudentResponse;

      invariant(curriculumStudentResponse.interactionId === curriculumStudentInteraction.interactionId, 'curriculumStudentResponse interactionId mismatch ' );

      return { ...state,
        curriculumStudentInteractionMap,
        curriculumStudentResponseMap
      };

    }

    case CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.SUCCESS:
    {
      //ValidateAction(action, ['studentId','coachId', 'curriculumStudentInteraction', 'problem', 'position','interactionId', 'curriculumStudentResponse']);
      const {
        studentId,
        curriculumStudentResponse,
        curriculumStudentInteraction,

      } = action;
      invariant(studentId && curriculumStudentResponse && curriculumStudentInteraction, 'action_format ' + JSON.stringify(action,null,2));
      invariant(curriculumStudentResponse.interactionId === curriculumStudentInteraction.interactionId, 'curriculumStudentResponse interactionId mismatch ' );


      const curriculumStudentInteractionMap: Record<UserId, CurriculumStudentInteraction> = { ...state.curriculumStudentInteractionMap};
      curriculumStudentInteractionMap[studentId] = curriculumStudentInteraction;



      const curriculumStudentResponseMap: Record<UserId, CurriculumStudentResponse> = { ...state.curriculumStudentResponseMap};
      curriculumStudentResponseMap[studentId] = curriculumStudentResponse;

      return { ...state,
        curriculumStudentInteractionMap,
        curriculumStudentResponseMap
      };
    }

    default:
      return state;
  }
}

function actionForLogger(action: CurriculumAction) {
  const {
    curriculumStudentResponse,
    origin,
    type
  } = action;
  return {
    type,
    origin,
    curriculumStudentResponse,
    error: action.error
  };
}

export default function curriculumReducer(state: CurriculumState = initialState, action: CurriculumAction): CurriculumState {
  const prefix = 'CURRICULUM_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _curriculumReducer(state, action);

  if (newState.curriculumStudentInteractionMap){

    Object.keys(newState.curriculumStudentInteractionMap).forEach( studentId => {
      const curriculumStudentInteraction=  newState.curriculumStudentInteractionMap[studentId];
      if (curriculumStudentInteraction){

        const curriculumStudentResponse = newState.curriculumStudentResponseMap[studentId];
        invariant(curriculumStudentResponse, 'ILLEGAL STATE '+studentId);



        if (curriculumStudentInteraction.problem) {
          // invariant(curriculumStudentResponse.interactionId === curriculumStudentInteraction.interactionId,
          //   'Interaction mismatch  Response= '+curriculumStudentResponse.interactionId +' Interaction='
          //   + curriculumStudentInteraction.interactionId+' '+ JSON.stringify(action,null,2));


          invariant(curriculumStudentResponse.responseKeyValues && (curriculumStudentResponse.responseKeyValues.set
            || curriculumStudentResponse.responseKeyValues.setList), 'ILLEGAL STATE '+JSON.stringify(action,null,2)+'\n');
        }
      }
    });
  }
  //debug('BEFORE ADD LOGGING '+JSON.stringify(action,null,2));
  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
