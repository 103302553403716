import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import CplButton from "../../components/FormControls/CplButton";
import CplSwitch from "../../components/FormControls/CplSwitch";
import CplTextField from "../../components/FormControls/CplTextField";
import type {State} from "../../redux/reducer";
import {navGoHome} from "../../redux/reducer/navigationReducer";
import {userProfileUpdate} from "../../redux/reducer/userReducer";
import type {UserProfileUpdates} from "../../sharedDefs/userDefs";
import {ROLE} from "../../sharedDefs/userDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";

const debug = debugModule(DEBUG_MODULE.USER.CONTAINER);
const styles = StyleSheet.create({
  root: {
    flexGrow: 1
  }
});
type ParentApplicationProps = Record<string, never>;
export default function ParentApplication(props: ParentApplicationProps): JSX.Element {
  debug('ParentApplication');
  const dispatch = useDispatch();
  const [forwardHome, setForwardHome] = React.useState(false);
  const userId = useSelector((state: State) => state.userReducer.userId);
  const userData = useSelector((state: State) => state.userReducer.userData);
  const initialValues = userData && userData.userProfile || {
    firstName: undefined,
    lastName: undefined,
    mobileNumber: undefined,
    textReminders: undefined
  };
  const defaultValues = !(userData && userData.userProfile) && REACT_APP_PREFILL ? {
    firstName: REACT_APP_PREFILL,
    lastName: REACT_APP_PREFILL,
    mobileNumber: '555-555-1212',
    textReminders: true
  } : initialValues;
  const [formObject, setFormObject] = React.useState(defaultValues);
  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    const newFormObject: any = {
      ...formObject,
      ...f
    };
    setFormObject(newFormObject);
    debug('NEW formObject', newFormObject);
  }, [formObject]);
  const errors = {};
  debug('initialValues', initialValues);

  const updateProfileSubmit = () => {
    // this.setState({ valueForm: values });
    debug('values', JSON.stringify(formObject));
    const userProfile: UserProfileUpdates = {
      userId,
      firstName: formObject.firstName,
      lastName: formObject.lastName,
      mobileNumber: formObject.mobileNumber,
      textReminders: formObject.textReminders
    };
    dispatch(userProfileUpdate(userProfile));
    dispatch(navGoHome(ROLE.PARENT));
  };

  debug('ParentApplication', formObject);
  return <View testID="ParentApplication">

    <Text> Parent Application Form Fill this out to be considered for the awesome job as a coach"</Text>
    <View>
      <View>
        <View>
          <View>

            <Text>
                Please fill in the application
            </Text>

            <Text>
                Parent Information
            </Text>
            <View>
              <CplTextField name="firstName" label="First Name" formObject={formObject} onChange={onChange}
                errors={errors}/>
              <CplTextField name="lastName" label="Last Name" formObject={formObject} onChange={onChange}
                errors={errors}/>
            </View>
            <View>
              <CplTextField name="mobileNumber" label="Mobile Number" formObject={formObject} onChange={onChange}
                errors={errors}/>
              <CplSwitch testID="textReminders" name="textReminders" label="Text Reminders" formObject={formObject}
                onChange={onChange}/>
            </View>
            <View>
              <CplButton testID="submit" onPress={() => updateProfileSubmit()}>
                  Submit
              </CplButton>
            </View>

          </View>
        </View>
      </View>
    </View>
  </View>
  ;
}
