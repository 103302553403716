import invariant from "invariant";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import {WS_ACTION} from "../../sharedDefs/webSocketDefs";
import { ValidateAction } from "../../utils/validation";
import type {WsAction, WsState} from "../structs/webSocketStruct";
import { addReducerLogging } from "./common";
import {CoachId, UserId} from "../../sharedDefs/userDefs";

const debug = debugModule(DEBUG_MODULE.WS.REDUCER);
export const webSocketInit = (userId: UserId,coachId: CoachId): WsAction => {
  invariant(userId && coachId, 'Only Sync in session');
  debug('webSocketInit',userId );
  return {
    type: WS_ACTION.SYNC.START,
    userId,
    coachId
  };
};

export const webSocketStop = () : WsAction => {

  return {
    type: WS_ACTION.SYNC.STOP,
    userId: null,
    coachId: null
  };
};


const initialState: WsState = {
  wsSyncChannel: undefined,
  socketClient: undefined,
  userId: undefined,
  coachId: undefined,
  connecting: false,
};

function _wsReducer(state: WsState = initialState, action: WsAction): WsState {
  switch (action.type) {
    case WS_ACTION.SYNC.START: {
      const {userId, coachId} = action;
      invariant(userId && coachId , 'invalid-action');
      return {
        ...state,

      };
    }
    case WS_ACTION.SYNC.VERIFIED_START: {
      const {userId, coachId} = action;
      invariant(userId && coachId , 'invalid-action');
      // invariant(!state.userId && !state.coachId, 'One socket ber user and session Check state.wsReducer.userId = '+ state.userId+' && state.wsReducer.coachId '+ state.coachId);
      return {
        ...state,
        userId,
        coachId
      };
    }

    case WS_ACTION.SYNC.CONNECTING:

      return { ...state,
        connecting: true,

      };
    case WS_ACTION.SYNC.CONNECTED:

      debug('CONNECT ', action.socketClient);
      invariant(action.socketClient &&  action.coachId && action.userId, 'invalid-action');

      return { ...state,
        connecting: false,
        socketClient: action.socketClient,
        userId:action.userId,
        coachId:action.coachId
      };

    case WS_ACTION.SYNC.CHANNEL_STARTED:
      debug('CHANNEL_STARTED ', action.wsSyncChannel);
      invariant(action.wsSyncChannel&&  action.coachId && action.userId, 'invalid-action');
      return { ...state,
        connecting: false,
        wsSyncChannel: action.wsSyncChannel,
        userId:action.userId,
        coachId:action.coachId
      };

    case WS_ACTION.SYNC.DISCONNECTED:
      debug('DISCONNECTED ');
      ValidateAction(action, []);
      return { ...state,
        socketClient: null,
        userId:null,
        coachId:null
      };

    default:
      return state;
  }
}

function actionForLogger(action: WsAction) {
  const {
    type
  } = action;
  return {
    type,
    error: action.error
  };
}

export default function wsReducer(state: WsState = initialState, action: WsAction): WsState {
  const prefix = 'WS_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _wsReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
