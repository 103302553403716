import invariant from "invariant";

import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import {Socket} from "socket.io-client";
const debug = debugModule(DEBUG_MODULE.WS.SAGA);
export function emitWithResponse(socketClient: Socket, action: {
  type: string;
}): Promise<{
  type: string;
}> {
  return new Promise((resolve, reject) => {
    try {
      invariant(action.type, 'not an action' + action);
      debug(`EMIT ACTION ${action.type}  action ${action}`);
      socketClient.emit(action.type, action, responseAction => {
        invariant(responseAction.type, 'not an action responseKeyValues');
        debug(`ResponseAction ${responseAction}`);
        resolve(responseAction);
      });
    } catch (error) {
      reject(error);
    }
  });
}
export function emitNoResponse(socketClient: Socket, action: {
  type: string;
}): void {
  invariant(action.type, 'not an action' + action);
  socketClient.emit(action.type, action);
}
