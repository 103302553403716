import invariant from "invariant";
import type {ChatAction} from "../../sharedDefs/chatDefs";
import {CHAT, CHAT_ACTION, datedChatItem, getDateBlock} from "../../sharedDefs/chatDefs";
import type {
  CurriculumStudentInteraction,
  CurriculumStudentProgression,
  CurriculumStudentResponse
} from "../../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import type {UserId} from "../../sharedDefs/userDefs";
import type {ChatState} from "../structs/chatStruct";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";
import {addReducerLogging} from "./common";

const debug = debugModule(DEBUG_MODULE.CHAT.REDUCER);


export const chatAddHeyCoach = (studentId: UserId, coachId: UserId, chatUserId: UserId, confidence?: number): ChatAction => {
  invariant(studentId && coachId && chatUserId, 'invalid-reducer');
  return {
    type: CHAT_ACTION.ADD.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    chatItem: datedChatItem({
      chatItemEvent: CHAT.EVENT.HEY_COACH,
      studentId,
      coachId,
      chatUserId,
      confidence
    })
  };
};
export const chatAddText = (studentId: UserId, coachId: UserId, chatUserId: UserId, text: string, confidence?: number): ChatAction => {
  invariant(studentId && coachId && chatUserId && text, 'invalid-reducer');
  return {
    type: CHAT_ACTION.ADD.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    chatItem: datedChatItem({
      chatItemEvent: CHAT.EVENT.TEXT,
      studentId,
      coachId,
      chatUserId,
      text,
      confidence
    })
  };
};
export const chatAddInteraction = (studentId: UserId, coachId: UserId, chatUserId: UserId, interaction: CurriculumStudentInteraction, response: CurriculumStudentResponse): ChatAction => {
  debug('chatAddInteraction', studentId, coachId, chatUserId, interaction);
  invariant(studentId && chatUserId && interaction, 'invalid-reducer');
  const offset = 0; // Server time Offset TODO

  let timeStamp : number = new Date(Date.now() + offset).getMilliseconds();

  if (response?.timeStamp) {
    timeStamp = new Date(response.timeStamp).getMilliseconds();
  }

  const dateBlock = getDateBlock(timeStamp);
  return {
    type: CHAT_ACTION.ADD.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    chatItem: datedChatItem({
      chatItemEvent: CHAT.EVENT.INTERACTION,
      studentId,
      coachId,
      chatUserId,
      curriculumStudentInteraction: interaction,
      timeStamp,
      curriculumStudentResponse: response,
      dateBlock
    })
  };
};
export const chatAssignProgression = (studentId: UserId, coachId: UserId, chatUserId: UserId, progression: CurriculumStudentProgression): ChatAction => {
  invariant(studentId && coachId && chatUserId && progression, 'invalid-reducer ' + studentId + chatUserId + JSON.stringify(progression));
  return {
    type: CHAT_ACTION.ADD.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    chatItem: datedChatItem({
      studentId,
      coachId,
      chatUserId,
      chatItemEvent: CHAT.EVENT.ASSIGN_PROGRESSION,
      curriculumStudentProgression: progression
    })
  };
};
export const chatCompleteProgression = (studentId: UserId, coachId: UserId, chatUserId: UserId): ChatAction => {
  invariant(studentId && coachId && chatUserId, 'invalid-reducer');
  return {
    type: CHAT_ACTION.ADD.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    chatItem: datedChatItem({
      studentId,
      coachId,
      chatUserId,
      chatItemEvent: CHAT.EVENT.COMPLETE_PROGRESSION
    })
  };
};
// export const chatAddSuccess = (chatItem: ChatItem): ChatAction => {
//   invariant(chatItem && chatItem.chatItemEvent, 'invalid-reducer');
//   return {
//     type: CHAT_ACTION.ADD.SUCCESS,
//     origin: ACTION_ORIGIN.INSTIGATE,
//     chatItem
//   };
// };


const initialState: ChatState = {
  chatMap: {},
};

function _chatReducer(state: ChatState = initialState, action: ChatAction): ChatState {
  switch (action.type) {
    case 'CHAT_ACTION.TestPage':
    {
      return { ...state};
    }
    case CHAT_ACTION.INITIALIZE.SUCCESS: {
      const {
        studentId,
        chatItems
      } = action;
      const chatMap = { ...state.chatMap
      };
      chatMap[studentId]  = [...chatItems];
      return { ...state,
        chatMap
      };
    }
    case CHAT_ACTION.ADD.SUCCESS:
    {
      const {
        studentId,
        chatItems,
        chatItem
      } = action;

      invariant(studentId &&  (chatItems || chatItem), 'action_format ' + JSON.stringify(action));
      chatItems?.forEach(_chatItem => invariant(_chatItem.chatItemEvent, 'action_format chatItemEvent' + JSON.stringify(_chatItem)));
      const chatMap = { ...state.chatMap
      };

      if (chatItem) {
        if (chatMap[studentId]) {
          chatMap[studentId] = [...chatMap[studentId], chatItem];
        }
        else {
          chatMap[studentId] = [ chatItem];
        }
      } else {
        if (chatMap[studentId]) {
          chatMap[studentId] = [...chatMap[studentId], ...chatItems];
        }
        else {
          chatMap[studentId] = [ ...chatItems];
        }

      }

      return { ...state,
        chatMap
      };
    }

    default:
      return state;
  }
}

function actionForLogger(action: ChatAction) {
  const {
    chatItem,
    origin,
    type
  } = action;
  return {
    type,
    origin,
    chatItem,
    error: action.error
  };
}

export default function chatReducer(state: ChatState = initialState, action: ChatAction): ChatState {
  const prefix = 'CHAT_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _chatReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
