import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import {ProgressionViewPropsType} from "./widgetStructs";

const styles = StyleSheet.create({
  problemList: {
    flex: 1,
    marginTop: 30,
    width: 200
  }
});
export default function NotFoundProgressionView(props: ProgressionViewPropsType): JSX.Element {
  const {
    curriculumStudentProgression
  } = props;
  return <View testID="NotFoundProgressionView" style={styles.problemList}>

    <Text> NOT FOUND PROGRESSION VIEW </Text>
  </View>;
}
