import * as React from "react";
import {StyleSheet, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import CoachsStudentWebCon from "../../components/WebCon/CoachsStudentWebCon";
import type {State} from "../../redux/reducer";
import {huddleCoachEnter} from "../../redux/reducer/huddleReducer";

import type {Huddle, HuddleUser} from "../../sharedDefs/huddleDefs";
import {HUDDLE} from "../../sharedDefs/huddleDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";
import {Text} from "react-native-paper";
import CoachAvailableStudents from "./CoachAvailableStudents";

const debug = debugModule(DEBUG_MODULE.WEBCON.COACH);
const styles = StyleSheet.create({
  root: {
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20
  },
  student: {
    width: '50%',
    zIndex: 1,
    margin: 4,
    borderWidth: 2,
    borderColor: COLOR.BASE,
    borderRadius: 10
  },
  gridColumnContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flex: 1
  },
  gridRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'row',
    flex: 1
  },
  footerRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    marginTop:300,
    flexDirection: 'row',
    // flex: 1,
    height: 100
  }
});

export default function CoachClassMode(): JSX.Element {
  debug('CoachClassMode');

  const dispatch = useDispatch();

  const userId = useSelector((state: State) => state.userReducer.userId);
  const huddle: Huddle = useSelector((state: State) => state.huddleReducer.huddle);

  const  coachId = huddle?.coachId;

  const studentHuddleUsers = huddle && huddle.studentHuddleUsers;
  React.useEffect(() => {
    if (userId && huddle?.coachUser?.huddleUserStatus !== HUDDLE.USER_STATUS.ENTERING && huddle?.coachUser?.huddleUserStatus !== HUDDLE.USER_STATUS.ENTERED) {
      debug('huddleEnter', huddle?.coachUser?.huddleUserStatus);
      dispatch(huddleCoachEnter({type: 'CoachClassMode'}, userId));
    }
  }, [coachId,userId,huddle]);




  // navigation?.setOptions({ title: 'Math with Coach Caroline' });
  const ViewItems = [];
  debug('studentHuddleUsers ' + (studentHuddleUsers && Object.entries(studentHuddleUsers).length));
  debug('studentHuddleUsers ',studentHuddleUsers);


  if (studentHuddleUsers) {
    const sortedHuddleUsers = Object.entries(studentHuddleUsers).sort(([, _a], [, _b]) => {
      const a = ((_a as any) as HuddleUser);
      const b = ((_b as any) as HuddleUser);
      const statusSortOrder = [HUDDLE.USER_STATUS.DISMISSING, HUDDLE.USER_STATUS.LEAVING, HUDDLE.USER_STATUS.INACTIVE, HUDDLE.USER_STATUS.ARRIVING, HUDDLE.USER_STATUS.ARRIVED, HUDDLE.USER_STATUS.ADMITTED, HUDDLE.USER_STATUS.ENTERING, HUDDLE.USER_STATUS.ENTERED];
      const aStatus = statusSortOrder.indexOf(a.huddleUserStatus);
      const bStatus = statusSortOrder.indexOf(b.huddleUserStatus);

      if (aStatus !== bStatus) {
        return bStatus - aStatus;
      }

      return a.userProfile.firstName.localeCompare(b.userProfile.firstName);
    });
    sortedHuddleUsers.forEach(([, value]) => {
      // const { userId } = studentHuddleUser.userProfile;
      const studentHuddleUser = ((value as any) as HuddleUser);
      const studentId = studentHuddleUser.userProfile.userId;

      debug(' STUDENT ', studentHuddleUser.huddleUserStatus, studentHuddleUser.userProfile.firstName);
      ViewItems.push(<View style={styles.student} key={studentId}>
        <CoachsStudentWebCon coachId={userId} studentHuddleUser={studentHuddleUser}/>
      </View>);
    });
  }



  let gridOfWebcons;
  if (ViewItems.length === 0) {
    gridOfWebcons = <Text style={{margin:50}}>No student arrived yet</Text>;
  }
  else if (ViewItems.length === 1 || ViewItems.length === 2) {
    gridOfWebcons = <View style={styles.gridRowContainer} key="webcons">
      {ViewItems}
    </View>;
  } else if (ViewItems.length === 3) {
    gridOfWebcons = <View style={styles.gridColumnContainer} key="webcons">
      <View style={styles.gridRowContainer} key="webconsR1">
        {ViewItems[0]}
        {ViewItems[1]}
      </View>
      <View style={styles.gridRowContainer} key="webconsR2">
        {ViewItems[2]}
      </View>
    </View>;
  } else if (ViewItems.length >= 4) {
    gridOfWebcons = <View style={styles.gridColumnContainer} key="webcons">
      <View style={styles.gridRowContainer} key="webconsR1">
        {ViewItems[0]}
        {ViewItems[1]}
      </View>
      <View style={styles.gridRowContainer} key="webconsR2">
        {ViewItems[2]}
        {ViewItems[3]}
      </View>
    </View>;
  }

  debug('CoachClassMode');
  return <View style={styles.root} nativeID="CoachClassMode" testID="CoachClassMode">

    <View style={styles.gridColumnContainer}>
      {gridOfWebcons}
    </View>
    <CoachAvailableStudents allowAdmit={true}/>
  </View>;
}
