import { ValidationError } from "../../utils/validation";

import type {CoachId, UserId} from "../../sharedDefs/userDefs";
export const PARENT = {
  COACH_ASSIGN: {
    ASSIGN: 'PARENT.COACH_ASSIGN.ASSIGN',
    SUCCESS: 'PARENT.COACH_ASSIGN.SUCCESS',
    FAILURE: 'PARENT.COACH_ASSIGN.FAILURE'
  }
};
type ParentAction = {
  type: string;
  coachId: CoachId;
  studentId: UserId;
};
export const parentCoachAssignToStudent = (coachId: CoachId, studentId: UserId): ParentAction => {
  if (!coachId || !studentId) {
    throw new ValidationError('invalid-reducer', coachId, studentId);
  }

  return {
    type: PARENT.COACH_ASSIGN.ASSIGN,
    coachId,
    studentId
  };
};
