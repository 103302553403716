//
import * as React from "react";
import {StyleSheet, View} from "react-native";
import StudentClassMode from "./StudentClassMode";
import {COLOR} from "../../utils/UiConstants";


const styles = StyleSheet.create({
  root: {
    //marginTop:20,
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 1,
    position: 'relative'
  },
  subView: {
    maxWidth: 200,
    backgroundColor:COLOR.RED,
    minHeight:5,

    flex:1,


  },

});

export default function StudentHome(): JSX.Element {


  return <View testID="StudentHome" style={styles.root}>
    <View style={styles.subView}/>
    <StudentClassMode />
  </View>;
}
