import type {CoachId, StudentId, UserId, UserProfile} from "./userDefs";
import {ActionType} from "./commonStruct";

export type HuddleUserStatus = ["HUDDLE.USER_STATUS.DISMISSING", "HUDDLE.USER_STATUS.LEAVING", "HUDDLE.USER_STATUS.ABSENT" | "HUDDLE.USER_STATUS.ARRIVING" | "HUDDLE.USER_STATUS.ARRIVED" | "HUDDLE.USER_STATUS.ADMITTING" | "HUDDLE.USER_STATUS.ADMITTED"];

export const HUDDLE = {
  NO_COACH: 'HUDDLE.NO_COACH',
  USER_STATUS: {
    INACTIVE: 'HUDDLE.USER_STATUS.INACTIVE',
    ARRIVING: 'HUDDLE.USER_STATUS.ARRIVING',
    ARRIVED: 'HUDDLE.USER_STATUS.ARRIVED',
    ADMITTED: 'HUDDLE.USER_STATUS.ADMITTED',
    ENTERING: 'HUDDLE.USER_STATUS.ENTERING',
    ENTERED: 'HUDDLE.USER_STATUS.ENTERED',
    DISMISSING: 'HUDDLE.USER_STATUS.DISMISSING',
    LEAVING: 'HUDDLE.USER_STATUS.LEAVING'
  }
};
export const HUDDLE_ACTION = {
  DEBUG: {
    HUDDLE_STATE: 'HUDDLE_ACTION.DEBUG.HUDDLE_STATE',
    HUDDLE_STATE_SUMMARY: 'HUDDLE_ACTION.DEBUG.HUDDLE_STATE_SUMMARY'
  },
  STUDENT_ARRIVE: {
    REQUEST: 'HUDDLE_ACTION.STUDENT_ARRIVE.REQUEST',
    PERFORM: 'HUDDLE_ACTION.STUDENT_ARRIVE.PERFORM',
    INITIALIZE: 'HUDDLE_ACTION.STUDENT_ARRIVE.INITIALIZE',
    SUCCESS: 'HUDDLE_ACTION.STUDENT_ARRIVE.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.STUDENT_ARRIVE.FAILURE'
  },
  HEY_COACH: {
    REQUEST: 'HUDDLE_ACTION.HEY_COACH.REQUEST',
    PERFORM: 'HUDDLE_ACTION.HEY_COACH.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.HEY_COACH.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.HEY_COACH.FAILURE'
  },
  AUDIO_MODE: {
    REQUEST: 'HUDDLE_ACTION.AUDIO_MODE.REQUEST',
    PERFORM: 'HUDDLE_ACTION.AUDIO_MODE.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.AUDIO_MODE.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.AUDIO_MODE.FAILURE'
  },
  COACH_CONNECTS_TO_ENTERED_STUDENT: {
    REQUEST: 'HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.REQUEST',
    PERFORM: 'HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.FAILURE'
  },
  STUDENT_ADMIT_FROM_COACH: {
    REQUEST: 'HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.REQUEST',
    PERFORM: 'HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.FAILURE'
  },
  COACH_ADMIT_STUDENT: {
    REQUEST: 'HUDDLE_ACTION.COACH_ADMIT_STUDENT.REQUEST',
    PERFORM: 'HUDDLE_ACTION.COACH_ADMIT_STUDENT.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.COACH_ADMIT_STUDENT.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.COACH_ADMIT_STUDENT.FAILURE'
  },
  COACH_RELEASE_STUDENT: {
    REQUEST: 'HUDDLE_ACTION.COACH_RELEASE_STUDENT.REQUEST',
    PERFORM: 'HUDDLE_ACTION.COACH_RELEASE_STUDENT.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.COACH_RELEASE_STUDENT.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.COACH_RELEASE_STUDENT.FAILURE'
  },
  STUDENT_ENTER: {
    REQUEST: 'HUDDLE_ACTION.STUDENT_ENTER.REQUEST',
    PERFORM: 'HUDDLE_ACTION.STUDENT_ENTER.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.STUDENT_ENTER.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.STUDENT_ENTER.FAILURE'
  },
  COACH_ENTER: {
    REQUEST: 'HUDDLE_ACTION.COACH_ENTER.REQUEST',
    PERFORM: 'HUDDLE_ACTION.COACH_ENTER.PERFORM',
    PRE_ENTER: 'HUDDLE_ACTION.COACH_ENTER.PRE_ENTER',
    SUCCESS: 'HUDDLE_ACTION.COACH_ENTER.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.COACH_ENTER.FAILURE'
  },
  COACH_DISCONNECT_TO_STUDENT: {
    REQUEST: 'HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST',
    SUCCESS: 'HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.FAILURE'
  },
  LEAVE: {
    REQUEST: 'HUDDLE_ACTION.LEAVE.REQUEST',
    PERFORM: 'HUDDLE_ACTION.LEAVE.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.LEAVE.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.LEAVE.FAILURE'
  },
  DISMISS: {
    REQUEST: 'HUDDLE_ACTION.DISMISS.REQUEST',
    PERFORM: 'HUDDLE_ACTION.DISMISS.PERFORM',
    SUCCESS: 'HUDDLE_ACTION.DISMISS.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.DISMISS.FAILURE'
  },
  UPDATE: {
    REQUEST: 'HUDDLE_ACTION.UPDATE.REQUEST',
    SEND: 'HUDDLE_ACTION.UPDATE.SEND',
    RECIEVE: 'HUDDLE_ACTION.UPDATE.RECIEVE',
    SUCCESS: 'HUDDLE_ACTION.UPDATE.SUCCESS',
    FAILURE: 'HUDDLE_ACTION.UPDATE.FAILURE'
  },
};

export type AudioMode = 'AUDIO_MODE.OFF' | 'AUDIO_MODE.WALKIE_TALKIE';
export const AUDIO_MODE = {
  OFF: 'AUDIO_MODE.OFF' as AudioMode,
  WALKIE_TALKIE: 'AUDIO_MODE.WALKIE_TALKIE' as AudioMode
};

export type HuddleUser = {
  userProfile: UserProfile;
  huddleUserStatus: string;
  audioMode: AudioMode;
  heyCoach: boolean;
};

export type Huddle = {
  coachId: CoachId;
  coachUser: HuddleUser | null | undefined;
  studentHuddleUsers: Record<UserId, HuddleUser>;
};

export type HuddleAction = ActionType & {
  userId?: UserId;
  studentId?: StudentId;
  coachId?: CoachId;
  huddle?: Huddle;
  audioMode?: AudioMode;
  heyCoach?: boolean;
  error?: Error;
};

export type HuddleUserSummary = {
  userProfile?: boolean;
  userId?: UserId;
  huddleUserStatus?: string;
  audioMode?: AudioMode;
  heyCoach?: boolean;
}


export type HuddleSummary = {
  coachId: CoachId;
  coachUser: HuddleUserSummary
  studentHuddleUsers: Record<UserId, HuddleUserSummary>;
}


export type HuddleSummaryAction = {
  type: string;
  huddleSummary: HuddleSummary;
};


export const huddleUpdateSuccess = (huddle:Huddle) : HuddleAction =>{
  return {
    type: HUDDLE_ACTION.UPDATE.SUCCESS,
    huddle
  };
};

export const  huddleStudentAdmitFromCoach = (huddle:Huddle) : HuddleAction =>{

  return {
    type: HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.REQUEST,
    huddle
  };
};


export const  huddleCoachDisconnectToStudent = ( huddle:Huddle) : HuddleAction =>{
  return {
    type: HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST,
    huddle
  };
};


