import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {firestore} from "../rsf";
import type {ChatAction, ChatItem} from "../../sharedDefs/chatDefs";
import {CHAT_ACTION, getDateBlock} from "../../sharedDefs/chatDefs";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";
import {StudentId} from "../../sharedDefs/userDefs";

const debug = debugModule(DEBUG_MODULE.UTILS.QUERIES);


export async function  getChatInitializeActionForStudent(studentId: StudentId): Promise<ChatAction>{
  const dateBlock = getDateBlock( Date.now());
  const chatRef = await firestore.collection(COLLECTION.CHAT).doc(studentId).get();
  if (!chatRef.exists){
    return {
      type: CHAT_ACTION.INITIALIZE.SUCCESS,
      studentId,
      dateBlock,
      chatItems: []
    };

  }

  console.log('getChatInitializeActionForStudent ',studentId,dateBlock);

  const chatListRef = await firestore.collection(COLLECTION.CHAT).doc(studentId).collection(dateBlock);

  if (!chatListRef.empty) {

    const chatItems: Array<ChatItem> = chatListRef.docs.map(ref => ref.data() as ChatItem);
    const sortedChatItems = chatItems.sort((a, b) => {
      if (a.timeStamp) {
        if (a.timeStamp > b.timeStamp) {
          return 1;
        }

        if (a.timeStamp < b.timeStamp) {
          return -1;
        }
      }

      return 0;
    });


    return {
      type: CHAT_ACTION.INITIALIZE.SUCCESS,
      studentId,
      dateBlock,
      chatItems: sortedChatItems
    };
  }


}
export function addChatItem(chatItem: ChatItem): Promise<string> {
  debug('addChatItem', chatItem);
  invariant(chatItem && chatItem.studentId && chatItem.dateBlock && chatItem.chatItemEvent, 'invalid-query');
  return firestore.collection(COLLECTION.CHAT).doc(chatItem.studentId).get().then(chatRef => {
    if (!chatRef.exists) {
      return firestore.collection(COLLECTION.CHAT).doc(chatItem.studentId).set({
        studentId: chatItem.studentId
      });
    }

    return 'ok';
  }).then(() => firestore.collection(COLLECTION.CHAT).doc(chatItem.studentId).collection(chatItem.dateBlock).add(chatItem)).then(chatItemRef => {
    debug('chatItemRef.id', chatItemRef.id);
    return chatItemRef.id;
  });
} // export function queryAddedChatItems(studentId: UserId, dateBlock: string, actionEmitter: (ChatAction) => void): () => void {
//   debug('queryChatItems', studentId, dateBlock);
//   invariant(studentId && dateBlock && actionEmitter, 'invalid-query');
//   let initialSent = false;
//   const unsubscribe = firestore.collection(COLLECTION.CHAT)
//     .doc(studentId)
//     .collection(dateBlock)
//     .onSnapshot((querySnapshot) => {
//       debug('queryChatItems querySnapshot', initialSent, studentId, dateBlock);
//       if (!initialSent) {
//         initialSent = true;
//         const chatItems: Array<ChatItem> = [];
//         querySnapshot.forEach((doc) => {
//           const chatItem: ChatItem = doc.data();
//           chatItems.push(chatItem);
//         });
//         const action: ChatAction = {
//           type: CHAT_ACTION.SYNC.INITIALIZE,
//           chatItems
//         };
//         actionEmitter(action);
//       }
//       const chatItems: Array<ChatItem> = [];
//       querySnapshot.docChanges()
//         .forEach((change) => {
//           if (change.type === 'added') {
//             const chatItem: ChatItem = change.doc.data();
//             chatItems.push(chatItem);
//           }
//           if (change.type === 'modified') {
//             console.error('CHAT modified');
//           }
//           if (change.type === 'removed') {
//             console.error('CHAT Removed');
//           }
//           const action: ChatAction = {
//             type: CHAT_ACTION.SYNC.ADD,
//             chatItems
//           };
//           actionEmitter(action);
//         });
//     });
//   return () => unsubscribe();
// }
