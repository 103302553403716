import { firestore } from "../rsf";
import { COLLECTION } from "../../sharedDefs/firestoreDefs";
import type { UserId, UserProfile } from "../../sharedDefs/userDefs";
import { ROLE } from "../../sharedDefs/userDefs";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
const debug = debugModule(DEBUG_MODULE.UTILS.QUERIES);
export function queryAllCoaches(setCoaches: (coaches: Array<UserProfile>) => void): () => void {
  debug('queryAllCoaches');
  const unsubscribe = firestore.collection(COLLECTION.USERS).where('role', '==', ROLE.COACH).onSnapshot(querySnapshot => {
    const list = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      list.push(data);
    });
    setCoaches(list);
  });
  return () => unsubscribe();
}
export function queryUserProfile(userId: UserId, setUser: (userProfile: UserProfile) => void): () => void {
  const unsubscribe = firestore.collection(COLLECTION.USERS).doc(userId).onSnapshot(doc => {
    setUser(doc.data());
  });
  return () => unsubscribe();
}
