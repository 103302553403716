//
import { View, StyleSheet } from "react-native";
import * as React from "react";
import { Title } from "react-native-paper";
import { COLOR } from "../../utils/UiConstants";
import ParentStudentCard from "./ParentStudentCard";
import type { UserData, UserProfile } from "../../sharedDefs/userDefs";
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative'
  },
  gridContainer: {
    flexDirection: 'column',
    flex: 1
  },
  gridItem: {
    padding: 20
  }
});
type ParentStudentGalleryPropType = {
  students: Array<UserProfile>;
  parentUserData: UserData;
};
export default function ParentStudentGallery(props: ParentStudentGalleryPropType): JSX.Element {
  const {
    students
  } = props;
  const ViewItems = [];

  if (students) {
    students.forEach(studentUserProfile => {
      ViewItems.push(<View style={styles.gridItem} key={studentUserProfile.userId}>
        <ParentStudentCard key={studentUserProfile.userId} studentUserProfile={studentUserProfile} parentUserData={props.parentUserData}  />
      </View>);
    });
  }

  return <View testID="ParentStudentGallery" style={styles.root}>
    <View>
      <View>
        <Title style={{
          color: COLOR.BLACK
        }}>
            Login child for class or assign them a session
        </Title>
      </View>
      <View style={styles.gridContainer}>
        {ViewItems}
      </View>
    </View>
  </View>;
}
