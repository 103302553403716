import * as React from "react";
import {Dimensions, StyleSheet, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import CoachViewWebConWeb from "../../components/WebCon/CoachViewWebCon";
import SelfWebCon from "../../components/WebCon/SelfWebCon";
import StudentWidgetContainer from "../../curriculum/StudentWidgetContainer";

import invariant from 'invariant';
import type {State} from "../../redux/reducer";

import {huddleStudentArrive, huddleStudentEnter} from "../../redux/reducer/huddleReducer";
import {setSpeechMode} from "../../redux/reducer/speechReducer";
import type {CurriculumStudentProgression} from "../../sharedDefs/curriculumDefs";
import {CURRICULUM} from "../../sharedDefs/curriculumDefs";
import type {Huddle} from "../../sharedDefs/huddleDefs";
import {HUDDLE} from "../../sharedDefs/huddleDefs";
import {SPEECH_MODE} from "../../sharedDefs/speechDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import StudentLobby from "./StudentLobby";
import {webSocketInit} from "../../redux/reducer/wsReducer";
import {CoachId} from "../../sharedDefs/userDefs";


const debug = debugModule(DEBUG_MODULE.WEBCON.STUDENT);
const VIDEO_CONTAINER_SIZE=200;
const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100%',
    zIndex: 1,
    alignContent: 'center',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    flex: 1
  },

  gridWebcon: {
    flexDirection: 'row',
    alignContent: 'space-around',
    alignItems:'center',
    height:VIDEO_CONTAINER_SIZE

  },
  coachContainer: {
    flex:1,
    height:VIDEO_CONTAINER_SIZE,
    width:VIDEO_CONTAINER_SIZE,
    alignContent: 'center',
    alignItems:'center',
    justifyContent:'center',

  },
  selfContainer: {

    flex:1,
    height:VIDEO_CONTAINER_SIZE,
    width:VIDEO_CONTAINER_SIZE,
    justifyContent:'center',
    alignContent: 'center',
    alignItems:'center',
    paddingTop: 100
  }

});

export default function StudentClassMode(): JSX.Element {
  const dispatch = useDispatch();

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const [screenHeight, setScreenHeight] = React.useState<number>(100);
  const [screenWidth, setScreenWidth] = React.useState<number>(100);

  const [joining, setJoining] = React.useState(false);
  const studentId = useSelector((state: State) => state.userReducer.userId);
  const huddle: Huddle | null = useSelector((state: State) => state.huddleReducer.huddle);

  const coachId: CoachId  = useSelector((state: State) => state.huddleReducer.coachId);



  const studentStatus = huddle?.studentHuddleUsers[studentId]?.huddleUserStatus ;

  //const heyCoach = huddle ? huddle.studentHuddleUsers[studentId].heyCoach : null;

  const curriculumStudentInteraction = useSelector((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
  const curriculumStudentResponse = useSelector((state: State) => state.curriculumReducer.curriculumStudentResponseMap[studentId]);
  const curriculumStudentProgression: CurriculumStudentProgression = useSelector((state: State) => state.curriculumReducer.curriculumStudentProgressionMap[studentId]);
  const progressionSet = curriculumStudentProgression?.progressionSet;

  if (progressionSet?.progressionSetName) {// navigation?.setOptions({ title: 'Math with Coach Caroline' });
  }



  React.useEffect(() => {
    if (studentStatus === HUDDLE.USER_STATUS.ENTERED) {
      dispatch(setSpeechMode(SPEECH_MODE.LISTEN_HEY_COACH, studentId));
    } else {
      dispatch(setSpeechMode(SPEECH_MODE.OFF, studentId));
    }
  }, []);
  React.useEffect(() => {
    if (studentStatus === HUDDLE.USER_STATUS.ENTERED) {
      debug('useEffect recognition start');
      dispatch(setSpeechMode(SPEECH_MODE.LISTEN_HEY_COACH, studentId));
    }
  }, [studentStatus]);
  React.useEffect(() => {
    if (studentId && !curriculumStudentInteraction) {
      debug('useEffect curriculumStudentInteraction');
      debug('Init4', studentId, coachId);
      dispatch(webSocketInit(studentId, coachId));
    }
  }, [studentId, curriculumStudentInteraction]);
  React.useEffect(() => {
    if (studentId && !curriculumStudentProgression) {
      debug('useEffect curriculumProgressionSetSyncStart');
      debug('Init5', studentId, coachId);
      dispatch(webSocketInit(studentId, coachId));
    }
  }, [studentId, curriculumStudentProgression]);

  const userId = useSelector((state: State) => state.userReducer.userId);
  debug('NotUseEffect Join Room', studentStatus, coachId, userId);

  React.useEffect(() => {
    debug('StudentClassMode Effect ',studentStatus, coachId,userId);
    if (studentStatus === HUDDLE.USER_STATUS.ENTERED ) {
      setJoining(false);
    }


    if (coachId  && userId && studentStatus === HUDDLE.USER_STATUS.ADMITTED && !joining) {
      debug('useEffect Join Room', studentStatus, coachId, userId);
      invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
      setJoining(true);
      dispatch(huddleStudentEnter({type:'StudentClassMode'},coachId, userId));
    }

    if (coachId && userId && !joining && (!studentStatus || studentStatus === HUDDLE.USER_STATUS.INACTIVE)) {
      debug('useEffect huddleStudentArrive', coachId, userId, studentStatus , joining, huddle?.coachId);
      setJoining(false);
      dispatch(huddleStudentArrive({type:'StudentClassMode'},coachId, userId));
    }
  }, [studentStatus, userId, joining, coachId]);

  invariant(curriculumStudentInteraction?.interactionId== curriculumStudentResponse?.interactionId, 'interactionId Mismatch');
  debug('curriculumStudentInteraction', curriculumStudentInteraction);
  const showWidget = curriculumStudentInteraction?.problem?.widgetSetId && curriculumStudentProgression?.progressionMode !== CURRICULUM.PROGRESSION_MODE.COMPLETED;


  // console.log('showWidget',  curriculumStudentInteraction?.problem,  curriculumStudentInteraction?.problem?.widgetSetId,curriculumStudentProgression?.progressionMode );
  const problemSize = Math.min(screenHeight-30, screenWidth,windowWidth-30, windowHeight-30);

  return <View testID="StudentClassMode" style={styles.root} onLayout={(event) => {
    setScreenWidth(event?.nativeEvent?.layout?.width);
    setScreenHeight(event?.nativeEvent?.layout?.height);
  }}>

    {coachId !== HUDDLE.NO_COACH && <View style={styles.gridWebcon}>
      <View key="remote" style={styles.coachContainer}>
        <CoachViewWebConWeb />
      </View>
      <View key="self" style={styles.selfContainer}>
        <SelfWebCon VIDEO_CONTAINER_SIZE={VIDEO_CONTAINER_SIZE}/>
      </View>
    </View>}
    <View style={{ height:problemSize, width:problemSize, maxHeight:problemSize, maxWidth:problemSize, justifyContent:'center'}}>
      { showWidget &&  <StudentWidgetContainer />}
      { !showWidget && <StudentLobby />}
    </View>

  </View>;
}
