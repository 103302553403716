import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {ValidateAction, ValidationError} from "../../utils/validation";
import type {
  CoachId,
  StudentId,
  UserData,
  UserProfile,
  UserProfilePreSave,
  UserProfileUpdates
} from "../../sharedDefs/userDefs";
import {ROLE, USER_ACTION} from "../../sharedDefs/userDefs";
import {addReducerLogging, loggerCopy} from "./common";
import type {UserAction, UserState} from "../structs/userStruct";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";

const debug = debugModule(DEBUG_MODULE.USER.REDUCER);

export type CoachRegistrationObject = {
  email: string,

  password: string,
  passwordConfirm: string,
  birthDay:string,
  birthMonth:string,
  birthYear:string,

  coachEssay: string;
  firstName: string,
  lastName: string,
  occupation:string,
  zipcode:string;
  coachImage?: ArrayBuffer;

}


export type ParentRegistrationObject = {
  email: string,
  password: string,
  passwordConfirm: string,

  birthMonth:string,
  birthYear:string,
  levelId: string;

  reason:string;
  studentEssay: string;
  studentFirstName: string,
  zipcode:string;
  parentFirstName: string;
  parentLastName: string;
  studentImage?: ArrayBuffer;
}
export const userStudentCreateRequest = (userProfile: UserProfilePreSave): UserAction => {
  invariant(userProfile, 'invalid-reducer');
  return {
    type: USER_ACTION.STUDENT.CREATE.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    userProfile
  };
};

export const parentRegisterRequest = (parentRegistrationObject:ParentRegistrationObject): UserAction => {
  invariant(parentRegistrationObject?.email && parentRegistrationObject?.password ,'invalid-reducer');
  return {
    type: USER_ACTION.PARENT_REGISTER.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    parentRegistrationObject
  };
};

export const coachRegisterRequest = (coachRegistrationObject:CoachRegistrationObject): UserAction => {
  invariant(coachRegistrationObject?.email && coachRegistrationObject?.password ,'invalid-reducer');
  return {
    type: USER_ACTION.COACH_REGISTER.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    coachRegistrationObject
  };
};
export const adminRegisterRequest = (email: string, password: string): UserAction => {
  invariant(email && password , 'invalid-reducer');
  return {
    type: USER_ACTION.ADMIN_REGISTER.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    email,
    password,
    role:ROLE.ADMIN
  };
};
export const loginUserDataSync = (userData: UserData): UserAction => {
  invariant(userData, 'invalid-reducer');
  return {
    type: USER_ACTION.USER_DATA_SYNC.SYNC_EVENT,
    userData
  };
};
export const userLoginRequest = (email: string, password: string): UserAction => {
  invariant(email && password, 'invalid-reducer');
  return {
    type: USER_ACTION.LOGIN.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    email,
    password
  };
};
export const userLogoutRequest = (): UserAction => ({
  type: USER_ACTION.LOGOUT.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE
});
export const userProfileUpdate = (userProfile: UserProfileUpdates | UserProfile): UserAction => {
  invariant(userProfile, 'invalid-reducer');
  return {
    type: USER_ACTION.PROFILE.UPDATE,
    origin: ACTION_ORIGIN.SECONDARY,
    userProfile
  };
};
export const userStudentLoginRequest = (studentId: StudentId, coachId?: CoachId): UserAction => {
  invariant(studentId, 'invalid-reducer');
  return {
    type: USER_ACTION.STUDENT.LOGIN.REQUEST,
    origin: ACTION_ORIGIN.INSTIGATE,
    studentId,
    coachId
  };
};
export const userStudentLogoutRequest = (): UserAction => ({
  type: USER_ACTION.STUDENT.LOGOUT.REQUEST,
  origin: ACTION_ORIGIN.INSTIGATE
});
const initialState: UserState = {
  loading: false,
  loggedIn: false,
  userData: undefined,
  userId: undefined,
  role: undefined,
  userSyncChannel: undefined,

};

function _userReducer(state: UserState = initialState, action: UserAction = {type:''}): UserState {
  switch (action.type) {
    case USER_ACTION.USER_DATA_SYNC.STARTED:
    {
      ValidateAction(action, ['userSyncChannel', 'userId']);
      return { ...state,
        userSyncChannel: action.userSyncChannel,
        userId: action.userId
      };
    }

    case USER_ACTION.ADMIN_REGISTER.REQUEST:
    case USER_ACTION.COACH_REGISTER.REQUEST:
    case USER_ACTION.PARENT_REGISTER.REQUEST:
    {
      return { ...state,
        loading: true,
        userId: null,
        userData: null,
        role: null
      };
    }

    case USER_ACTION.PARENT_REGISTER.SUCCESS:
    {
      ValidateAction(action, ['role','userData', 'studentId','userId']);
      return { ...state,
        loading: false,
        loggedIn: true,
        userId: action.userId,
        role: action.role,
        userData: action.userData
      };
    }
    case USER_ACTION.ADMIN_REGISTER.SUCCESS:
    case USER_ACTION.COACH_REGISTER.SUCCESS:
    {
      ValidateAction(action, ['role', 'userId', 'userData']);
      return { ...state,
        loading: false,
        loggedIn: true,
        userId: action.userId,
        role: action.role,
        userData: action.userData
      };
    }
    case USER_ACTION.ADMIN_REGISTER.FAILURE:
    case USER_ACTION.PARENT_REGISTER.FAILURE:
    case USER_ACTION.COACH_REGISTER.FAILURE:
    {
      ValidateAction(action, ['error']);
      return { ...state,
        loading: false,
        loggedIn: false,
        userId: null,
        userData: null,
        role: null
      };
    }

    case USER_ACTION.USER_DATA_SYNC.SYNC_EVENT:
    {
      ValidateAction(action, ['userData']);
      if (state.userId !== (action.userData && action.userData.userProfile.userId)) {
        return state;
      }

      return { ...state,
        userData: action.userData,
        userId: action.userData && action.userData.userProfile.userId,
        role: action.userData && action.userData.userProfile && action.userData.userProfile.role
      };
    }

    case USER_ACTION.PROFILE.SUCCESS:
    {
      ValidateAction(action, ['userData']);
      if (state.userId === (action.userData && action.userData.userProfile.userId)) {
        return {
          ...state,
          userData: action.userData,
          userId: action.userData && action.userData.userProfile.userId,
          role: action.userData && action.userData.userProfile && action.userData.userProfile.role || state.role
        };
      }
      else {
        return state;
      }
    }

    case USER_ACTION.STUDENT.CREATE.SUCCESS:
    {
      ValidateAction(action, ['userData', 'studentId']);
      return { ...state,
        userData: action.userData,
        userId: action.userData && action.userData.userProfile.userId
      };
    }

    case USER_ACTION.LOGIN.REQUEST:
    {
      ValidateAction(action, ['email', 'password']);
      return { ...state,
        loading: true,
        loggedIn: false,
        userId: null,
        userData: null,
        role: null
      };
    }

    case USER_ACTION.LOGIN.SUCCESS:
    {
      if (!action.userId) {
        throw new ValidationError('action-invalid', action);
      }

      return { ...state,
        loading: false,
        loggedIn: true,
        userId: action.userId,
        role: action.role
      };
    }

    case USER_ACTION.LOGIN.FAILURE:
    {
      ValidateAction(action, ['error']);
      return { ...state,
        loading: false,
        role: null
      };
    }

    case USER_ACTION.LOGOUT.REQUEST:
    {
      ValidateAction(action, []);
      return { ...state,
        loading: true
      };
    }

    case USER_ACTION.LOGOUT.SUCCESS:
    {
      ValidateAction(action, []);

      return initialState;
    }

    case USER_ACTION.STUDENT.LOGIN.SUCCESS:
    {
      ValidateAction(action, ['userId', 'userData', 'role']);
      return { ...state,
        userId: action.userId,
        userData: action.userData,
        role: action.role
      };
    }

    case USER_ACTION.STUDENT.LOGOUT.SUCCESS:
    {
      ValidateAction(action, []);
      return { ...state,
        userId: null,
        userData: null,
        role: null,
        loggedIn: false
      };
    }

    case USER_ACTION.LOGOUT.FAILURE:
    {
      ValidateAction(action, ['error']);
      return { ...state,
        loading: false
      };
    }

    default:
      return state;
  }
}

function actionForLogger(action: UserAction) {
  const {
    type,
    loading,
    loggedIn,
    userData,
    userId,
    role,
    userSyncChannel
  } = action;
  return {
    type,
    role,
    loading,
    loggedIn,
    userId,
    userData: loggerCopy(userData),
    hasUserSyncChannel: !!userSyncChannel,
    error: action.error
  };
}

export default function userReducer(state: UserState = initialState, action: UserAction = {type:''}): UserState {
  const prefix = 'USER_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  const newState = _userReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
