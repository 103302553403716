import invariant from "invariant";
import {all, call, put, select, take, takeEvery} from "redux-saga/effects";
import {getHuddleUser} from "../../utils/dataUtils";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import type {State} from "../reducer";
import {huddleHeyCoachMode, huddleStudentArrive} from "../reducer/huddleReducer";

import type {HuddleAction, HuddleSummaryAction, HuddleUserSummary} from "../../sharedDefs/huddleDefs";
import {AUDIO_MODE, Huddle, HUDDLE, HUDDLE_ACTION, HuddleUser} from "../../sharedDefs/huddleDefs";

import {
  huddleAudioModeServerApi,
  huddleCoachAdmitStudentServerApi,
  huddleCoachEnterServerApi,
  huddleCoachPreEnterServerApi,
  huddleCoachReleaseStudentServerApi,
  huddleDismissServerApi, huddleHeyCoachModeServerApi,
  huddleLeaveServerApi,
  huddleStudentArriveServerApi,
  huddleStudentEnterServerApi,
} from "../serverApi";
import {
  webconCoachConsumeStudentAudio,
  webconCoachConsumeStudentVideo,
  webconCoachRecieveTransport,
  webconEnableMicrophone,
  webconEnableWebcam,
  webconSessionEnter,
  webconSessionInitialize,
  webconSessionLeave
} from "../reducer/webconReducer";
import {WEBCON_ACTION} from "../../sharedDefs/webconDefs";
import {CoachId, ROLE, UserId} from "../../sharedDefs/userDefs";
import {webSocketInit} from "../reducer/wsReducer";
import {WS_ACTION} from "../../sharedDefs/webSocketDefs";
import {setSpeechMode} from "../reducer/speechReducer";
import {SPEECH_MODE} from "../../sharedDefs/speechDefs";
import {webConEvent} from "./webconSaga";
import {ACTION_ORIGIN} from "../structs/firestoreStruct";

const debug = debugModule(DEBUG_MODULE.HUDDLE.SAGA);
export type Saga<T> = Generator<any, T, any>;


function* huddleWalkieTalkieModeSaga(action: HuddleAction) {
  try {
    const {
      audioMode,
      userId
    } = action;
    invariant(audioMode && userId, 'invalid-action');
    debug('huddleWalkieTalkieModeSaga  ->  huddleSpeechModeFunction ', userId);
    yield put({
      type: HUDDLE_ACTION.AUDIO_MODE.PERFORM,
      audioMode: audioMode,
      userId
    });
    const coachId: CoachId = yield select((state: State) => state.huddleReducer.huddle && state.huddleReducer.coachId);
    const audioReturn = yield call(huddleAudioModeServerApi, {
      coachId,
      userId,
      audioMode: audioMode
    });

    const data = audioReturn?.data;
    const huddle2 = data?.huddle;
    debug('huddleWalkieTalkieModeSaga-2 audioReturn', audioReturn);

    debug('huddleWalkieTalkieModeSaga-3 data', data);

    debug('huddleWalkieTalkieModeSaga-4 huddle2',  huddle2);
    const {
      huddle
    } = audioReturn.data;
    debug('huddleWalkieTalkieModeSaga-5 huddle',  huddle);
    yield put({
      type: HUDDLE_ACTION.AUDIO_MODE.SUCCESS,
      huddle,
    });
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.AUDIO_MODE.FAILURE,
      error
    });
  }
}

// Only Used for Testing, a event timer should open the huddle based on schedule
function* huddleStudentArriveSaga(action: HuddleAction) {
  try {
    const {
      coachId,
      userId
    } = action;
    invariant(coachId && userId, 'invalid-action');
    const huddleStart: Huddle = yield select((state: State) => state.huddleReducer.huddle);

    debug('HUDDLE START', huddleStart?.studentHuddleUsers);
    debug('huddleArriveSaga-1  -> webconInitialize, huddleArriveFunction, huddleSyncStart ', userId, userId);

    yield put(webSocketInit(userId, coachId));
    const huddleStartFunctionResult = yield call(huddleStudentArriveServerApi, {
      coachId,
      userId
    });

    debug('huddleArriveSaga-3  ->  webconSessionInitialize', coachId, userId);


    const {
      huddle
    } = huddleStartFunctionResult.data;
    const h : Huddle= huddle;


    debug('huddleArriveSaga-4  ->  webconSessionInitialize', h.studentHuddleUsers);

    yield put({
      type: HUDDLE_ACTION.STUDENT_ARRIVE.INITIALIZE,
      coachId,
      huddle
    });


    yield put({
      type: HUDDLE_ACTION.STUDENT_ARRIVE.SUCCESS,
      coachId,
      huddle
    });

  } catch (error) {
    console.error(error);
    yield put({
      type: HUDDLE_ACTION.STUDENT_ARRIVE.FAILURE,
      error
    });
  }

}

function* huddleCoachAdmitStudentSaga(action: HuddleAction) {
  try {
    const {
      coachId,

      studentId
    } = action;

    webConEvent(studentId,coachId,ROLE.COACH,'ADMIT STUDENT ').then();
    debug('huddleCoachAdmitStudentSaga 1');
    invariant(coachId &&  studentId, 'invalid-action' + action);
    yield put({
      type: HUDDLE_ACTION.COACH_ADMIT_STUDENT.PERFORM,
      coachId,

      studentId
    });


    yield call(huddleCoachAdmitStudentServerApi, {
      coachId,
      studentId
    });


    yield put(webconCoachRecieveTransport(action, coachId, studentId));
    yield take(WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.SUCCESS);

    // yield put(webconCoachConsumeStudentVideo(action, coachId, studentId));
    //
    // yield put(webconCoachConsumeStudentAudio(action, coachId, studentId));

    const consumeVideoAction = yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE]);
    console.log('huddleCoachAdmitStudentSaga 4');
    const consumeAudioAction = yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.FAILURE]);
    console.log('huddleCoachAdmitStudentSaga 5');
    if (consumeVideoAction.type === WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS && consumeAudioAction.type === WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS) {
      yield put({
        type: HUDDLE_ACTION.COACH_ADMIT_STUDENT.SUCCESS
      });
    } else {
      yield put({
        type: HUDDLE_ACTION.COACH_ADMIT_STUDENT.FAILURE,
        error: consumeVideoAction.error || consumeAudioAction.error
      });
    }
    console.log('huddleCoachAdmitStudentSaga 6');

  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.COACH_ADMIT_STUDENT.FAILURE,
      error
    });
  }
}

function* huddleCoachReleaseStudentSaga(action: HuddleAction) {
  try {
    const {
      coachId,
      studentId
    } = action;
    invariant(coachId &&  studentId, 'invalid-action' + action);
    yield put({
      type: HUDDLE_ACTION.COACH_RELEASE_STUDENT.PERFORM,
      coachId,
      studentId
    });
    const huddleAdmitFunctionResult = yield call(huddleCoachReleaseStudentServerApi, {
      coachId,
      studentId
    });
    invariant(huddleAdmitFunctionResult, 'invalid-action' + action);
    yield put({
      type: HUDDLE_ACTION.COACH_RELEASE_STUDENT.SUCCESS
    });
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.COACH_RELEASE_STUDENT.FAILURE,
      error
    });
  }
}

function* huddleStudentEnterSaga(action: HuddleAction) {
  try {
    debug('huddleEnterSaga 1 ');
    const {
      coachId,
      userId
    } = action;
    invariant(coachId && userId, 'invalid-action' + action);
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    const role = yield select((state: State) => state.userReducer.role);
    invariant(role === ROLE.STUDENT, ' ROLE Needs Student');
    const entering: Huddle = yield select((state: State) => state.huddleReducer.entering);
    debug('entering',entering);
    yield put({
      type: HUDDLE_ACTION.STUDENT_ENTER.PERFORM,
      coachId,
      userId
    });


    if (entering) {
      debug('huddleEnterSaga already entering', entering);
      return;
    }
    const huddleInitial: Huddle = yield select((state: State) => state.huddleReducer.huddle);

    if (!huddleInitial) {
      debug('Huddle Arrive');
      yield put(huddleStudentArrive(action, coachId, userId));
      yield take(HUDDLE_ACTION.STUDENT_ARRIVE.SUCCESS);
    }

    yield put(webconSessionInitialize(action, coachId, userId));
    debug('huddleEnterSaga-2  ->  webconSessionInitialize');
    yield take(WEBCON_ACTION.SESSION.INITIALIZE.SUCCESS);
    yield put(webconEnableWebcam(action, coachId));
    debug('huddleEnterSaga-3');
    yield take(WEBCON_ACTION.ENABLE_WEBCAM.SUCCESS);

    yield put(webconEnableMicrophone(action, coachId));
    debug('huddleEnterSaga-4');
    yield take(WEBCON_ACTION.ENABLE_MICROPHONE.SUCCESS);

    debug('huddleEnterSaga-5  JOIN ROOM ');
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    yield put(webconSessionEnter(action, coachId, userId));

    yield take(WEBCON_ACTION.SESSION.ENTER.SUCCESS);

    debug('huddleEnterSaga-6 ', coachId, userId);
    const huddleRet = yield call(huddleStudentEnterServerApi, {
      coachId,
      userId
    });
    debug('huddleEnterFunction Return', coachId, userId);
    const currentSyncCoachId = yield select((state: State) => state.wsReducer.coachId);
    debug('Huddle Enter ', currentSyncCoachId, coachId);

    if (currentSyncCoachId !== coachId) {
      debug('Init2', userId, coachId);
      yield put(webSocketInit(userId,coachId));
    }

    const {
      huddle
    } = huddleRet.data;
    const huddleUser = getHuddleUser(huddle, userId);

    if (huddleUser && huddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED) {
      yield put({
        type: HUDDLE_ACTION.STUDENT_ENTER.SUCCESS,
        huddle
      });
    }
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.STUDENT_ENTER.FAILURE,
      error
    });
  }
}

function* huddleCoachEnterSaga(action: HuddleAction) {
  try {
    debug('huddleEnterSaga 1 ');
    const {
      coachId,
    } = action;
    invariant(coachId , 'invalid-action' + action);
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    // const role = yield select((state: State) => state.userReducer.role);
    // invariant(role === ROLE.COACH, ' ROLE Needs COACH'+role);
    const entering: Huddle = yield select((state: State) => state.huddleReducer.entering);
    debug('entering',entering);
    yield put({
      type: HUDDLE_ACTION.COACH_ENTER.PERFORM,
      coachId,
    });


    if (entering) {
      debug('huddleEnterSaga already entering', entering);
      return;
    }

    const huddlePreRet = yield call(huddleCoachPreEnterServerApi, {
      coachId,
    });
    debug('huddlePreRet',huddlePreRet );
    yield put({
      type: HUDDLE_ACTION.COACH_ENTER.PRE_ENTER,
      huddle: huddlePreRet.data.huddle
    });
    debug('huddleEnterSaga  PRE ENTER DONE ');

    debug('huddleEnterSaga JOIN ROOM ');
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    yield put(webconSessionEnter(action, coachId, coachId));

    yield take(WEBCON_ACTION.SESSION.ENTER.SUCCESS);

    debug('huddleEnterSaga JOIN ROOM SUCCESS');

    debug('huddleEnterFunction ', coachId);
    const huddleRet = yield call(huddleCoachEnterServerApi, {
      coachId,
    });
    debug('huddleEnterFunction Return', coachId);
    const currentSyncCoachId = yield select((state: State) => state.wsReducer.coachId);
    debug('Huddle Enter ', currentSyncCoachId, coachId);

    if (currentSyncCoachId !== coachId) {
      debug('Init2',  coachId);
      yield put(webSocketInit(coachId,coachId));
    }

    const {
      huddle
    } = huddleRet.data;
    const huddleUser = getHuddleUser(huddle, coachId);

    if (huddleUser && huddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED) {
      yield put({
        type: HUDDLE_ACTION.COACH_ENTER.SUCCESS,
        huddle
      });
    }
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.COACH_ENTER.FAILURE,
      error
    });
  }
}

function* huddleLeaveSaga(action: HuddleAction) {
  try {
    const {
      coachId,
      userId
    } = action;
    invariant(coachId && userId, 'invalid-action' + action);
    yield put({
      type: HUDDLE_ACTION.LEAVE.PERFORM,
      coachId,
      userId
    });


    yield put(webconSessionLeave(action, coachId, userId));
    const jitsiAction = yield take([WEBCON_ACTION.SESSION.LEAVE.SUCCESS, WEBCON_ACTION.SESSION.LEAVE.FAILURE]);

    if (jitsiAction.type === WEBCON_ACTION.SESSION.LEAVE.FAILURE) {
      yield put({
        type: HUDDLE_ACTION.LEAVE.FAILURE,
        error: jitsiAction.error
      });
      return;
    }

    const huddleLeaveFunctionResult = yield call(huddleLeaveServerApi, {
      coachId,
      userId
    });
    invariant(huddleLeaveFunctionResult,'huddleLeaveFunctionResult');
    yield put({type:WS_ACTION.SYNC.STOP});
    //yield put(webSocketInit(userId,null));
    yield put({
      type: HUDDLE_ACTION.LEAVE.PERFORM,
      coachId
    });
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.LEAVE.FAILURE,
      error
    });
  }
}

function* huddleDismissSaga(action: HuddleAction) {
  try {
    const {
      coachId,
      studentId
    } = action;
    invariant(coachId  && studentId, 'invalid-action' + action);
    yield put({
      type: HUDDLE_ACTION.DISMISS.PERFORM,

      studentId,
      coachId
    });
    const huddleLeaveFunctionResult = yield call(huddleDismissServerApi, {
      coachId,
      studentId
    });
    invariant(huddleLeaveFunctionResult,'huddleLeaveFunctionResult');
    yield put({
      type: HUDDLE_ACTION.DISMISS.SUCCESS,
      studentId,
      coachId
    });
  } catch (error) {
    yield put({
      type: HUDDLE_ACTION.DISMISS.FAILURE,
      error
    });
  }
}

function huddleUserState(huddleUser: HuddleUser): HuddleUserSummary {
  if (!huddleUser) {
    return {};
  }
  return {
    userProfile: !!huddleUser.userProfile,
    userId: huddleUser.userProfile?.userId,
    huddleUserStatus: huddleUser.huddleUserStatus,
    audioMode: huddleUser.audioMode ,
    heyCoach: huddleUser.heyCoach
  };
}

function* huddleDebugHuddleState() {
  try {
    debug('huddleDebugHuddleState ');
    const huddle: Huddle = yield select((state: State) => state.huddleReducer.huddle);
    debug('huddleDebugHuddleState ', huddle);
    if (!huddle) {
      const responseAction: HuddleSummaryAction = {
        type: HUDDLE_ACTION.DEBUG.HUDDLE_STATE_SUMMARY,
        huddleSummary: {
          coachId: null,
          coachUser: {},
          studentHuddleUsers: {}
        }

      };
      yield put(responseAction);
      return;
    }
    const studentHuddleUsers = {};
    Object.keys(huddle.studentHuddleUsers).forEach(key => {
      studentHuddleUsers[key] = huddleUserState(huddle.studentHuddleUsers[key]);
    });
    // const sids1 = Object.keys(huddle.studentHuddleUsers);
    // debug("huddleDebugHuddleState", huddle.studentHuddleUsers[sids1[0]].huddleUserStatus);
    //

    const responseAction: HuddleSummaryAction = {
      type: HUDDLE_ACTION.DEBUG.HUDDLE_STATE_SUMMARY,
      huddleSummary: {
        coachId: huddle?.coachId,
        coachUser: huddleUserState(huddle?.coachUser),
        studentHuddleUsers
      }

    };
    yield put(responseAction);


  } catch (error) {
    console.error(error);
    yield put({
      type: WEBCON_ACTION.COACH_RECIEVE_TRANSPORT.FAILURE,
      error
    });
  }
}

function* huddleUpdateSuccessSaga(action: HuddleAction) {
  try {
    const {
      huddle
    } = action;
    const role = yield select((state: State) => state.userReducer.role);
    const userId = yield select((state: State) => state.userReducer.userId);
    if (role === ROLE.COACH){
      let studentId : UserId;
      let  localSpeechMode = SPEECH_MODE.OFF;
      Object.keys(huddle.studentHuddleUsers).forEach(_studentId => {
        if (huddle.studentHuddleUsers[_studentId].audioMode === AUDIO_MODE.WALKIE_TALKIE) {
          studentId = _studentId;
          localSpeechMode = SPEECH_MODE.DICTATING;
        }
      });
      yield put(setSpeechMode(localSpeechMode, studentId));
    }
    if (role === ROLE.STUDENT) {
      if (huddle.studentHuddleUsers[userId]?.audioMode === AUDIO_MODE.WALKIE_TALKIE) {
        yield put(setSpeechMode( SPEECH_MODE.DICTATING, userId));
      }
      else  if (huddle.studentHuddleUsers[userId]?.heyCoach){
        yield put(setSpeechMode( SPEECH_MODE.DICTATING, userId));
      }
      else if (huddle.studentHuddleUsers[userId]?.huddleUserStatus === HUDDLE.USER_STATUS.ENTERING) {
        yield put(setSpeechMode( SPEECH_MODE.LISTEN_HEY_COACH, userId));
      }
      else {
        yield put(setSpeechMode( SPEECH_MODE.OFF, userId));
      }


    }
  }catch (error) {
    console.error(error);
  }
}

function* huddleStudentAdmitFromCoachSaga(action:HuddleAction) {
  try {
    const {
      huddle
    } = action;
    const userId = yield select((state: State) => state.userReducer.userId);
    const {
      coachId
    } = huddle;
    debug("huddleStudentAdmitFromCoachSaga start ",userId, coachId);
    invariant(coachId !== HUDDLE.NO_COACH, ' No video for NO_COACH');
    yield put(webSocketInit(userId, coachId));
    yield take(WS_ACTION.SYNC.CONNECTED);
    yield put(webconSessionInitialize(action, coachId, userId));


    debug("huddleStudentAdmitFromCoachSaga end ",userId, coachId);
    // const sendTransportAction = yield take(WEBCON_ACTION.ANYONE_SEND_TRANSPORT.SUCCESS);
    //

  }catch (error) {
    console.error(error);
  }
}

function* huddleCoachConnectsToEnteredStudentSaga(action:HuddleAction){
  try {
    const {
      coachId,
      studentId
    } = action;

    debug("huddleCoachConnectsToEnteredStudentSaga start ",studentId, coachId);
    invariant(coachId && studentId, 'huddleCoachConnectsToEnteredStudentSaga action');

    debug('huddleCoachConnectsToEnteredStudentSaga-2 webconCoachConsumeStudentVideo ',  studentId);
    yield put(webconCoachConsumeStudentVideo(action, coachId, studentId));
    yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE]);

    yield put(webconCoachConsumeStudentAudio(action, coachId, studentId));
    yield take([WEBCON_ACTION.COACH_CONSUME_STUDENT_AUDIO.SUCCESS, WEBCON_ACTION.COACH_CONSUME_STUDENT_VIDEO.FAILURE]);
    debug('huddleCoachConnectsToEnteredStudentSaga-3 webconCoachConsumeStudentVideo ',  studentId);
  }catch (error) {
    console.error(error);
  }
}


function* huddleHeyCoachSaga(action:HuddleAction){
  try {
    const {
      userId,
      coachId
    } = action;

    debug("huddleHeyCoachSaga start ",userId,coachId);
    invariant(userId , 'huddleHeyCoachSaga action');


    const heyCoachReturn = yield call(huddleHeyCoachModeServerApi, {
      coachId,
      userId,
      heyCoach: true
    });
    const data = heyCoachReturn?.data;
    const huddle = data?.huddle;

    yield put({
      type: HUDDLE_ACTION.HEY_COACH.SUCCESS,
      origin: ACTION_ORIGIN.INSTIGATE,
      huddle
    });


  }catch (error) {
    console.error(error);
  }
}
function* huddleCoachDisconnectToStudentSaga(action:HuddleAction){
  try {
    const {
      huddle
    } = action;

    debug("huddleCoachDisconnectToStudentSaga start ", huddle);
    invariant(huddle , 'huddleCoachDisconnectToStudentSaga action');
    yield put({
      type: WEBCON_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST,

    });

    yield put({type:HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.SUCCESS});
    debug('huddleCoachDisconnectToStudentSaga-3 ');
  }catch (error) {
    yield put({type:HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.FAILURE, error});
    console.error(error);
  }
}

export default function* huddleSagas(): Saga<void> {
  debug('huddleSagas Start');
  yield all([
    takeEvery(HUDDLE_ACTION.UPDATE.SUCCESS, huddleUpdateSuccessSaga),
    takeEvery(HUDDLE_ACTION.DEBUG.HUDDLE_STATE, huddleDebugHuddleState),
    takeEvery(HUDDLE_ACTION.AUDIO_MODE.REQUEST, huddleWalkieTalkieModeSaga),
    takeEvery(HUDDLE_ACTION.STUDENT_ARRIVE.REQUEST, huddleStudentArriveSaga),
    takeEvery(HUDDLE_ACTION.COACH_ADMIT_STUDENT.REQUEST, huddleCoachAdmitStudentSaga),
    takeEvery(HUDDLE_ACTION.COACH_RELEASE_STUDENT.REQUEST, huddleCoachReleaseStudentSaga),
    takeEvery(HUDDLE_ACTION.STUDENT_ENTER.REQUEST, huddleStudentEnterSaga),
    takeEvery(HUDDLE_ACTION.COACH_ENTER.REQUEST, huddleCoachEnterSaga),
    takeEvery(HUDDLE_ACTION.LEAVE.REQUEST, huddleLeaveSaga),
    takeEvery(HUDDLE_ACTION.DISMISS.REQUEST, huddleDismissSaga),
    takeEvery (HUDDLE_ACTION.STUDENT_ADMIT_FROM_COACH.REQUEST, huddleStudentAdmitFromCoachSaga),
    takeEvery(HUDDLE_ACTION.COACH_CONNECTS_TO_ENTERED_STUDENT.REQUEST, huddleCoachConnectsToEnteredStudentSaga),
    takeEvery(HUDDLE_ACTION.COACH_DISCONNECT_TO_STUDENT.REQUEST, huddleCoachDisconnectToStudentSaga),
    takeEvery(HUDDLE_ACTION.HEY_COACH.REQUEST, huddleHeyCoachSaga)
  ]);
}

