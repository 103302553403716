//region Header
import * as React from "react";

import {Button, Checkbox, IconButton, List, Text, TextInput} from 'react-native-paper';
import {ScrollView, StyleSheet, View} from "react-native";

import {queryProgressionSets} from "../redux/queries/SessionQueries";
import {
  CURRICULUM,
  CurriculumStudentResponse,
  Problem,
  ProblemGenerationParams,
  ProgressionSet,
  ProgressionSetId,
  Standard,
  WidgetSetId
} from "../sharedDefs/curriculumDefs";
import {queryProgressions, queryStandards} from "../redux/queries/CurriculumQueries";
import {ProgressionSetUtil} from "../sharedDefs/ProgressionSetUtil";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import {uuidv4} from "../sharedDefs/serverApiDefs";

import WidgetInventory, {WorkbenchToWidget} from "../widgets/WidgetInventory";
import ResponseDataUtil from "./ResponseDataUtil";
import {COLOR} from "../utils/UiConstants";
import {adminProgressionSetSaveApi} from "../redux/serverApi";
import DropDown from "../components/FormControls/DropDown";


const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column'
  },
  gridColumnContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flex: 1
  },
  gridRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'row',
    flex: 1
  },
  coachView: {
    flex: 1,
    height: 250
  },
  studentView: {
    flex: 1,
    height: 250
  },
  problemList: {
    flex: 1,
    marginTop: 30,
    width: 200
  }
});
//endregion

export default function ProgressionSetWorkbench(): JSX.Element {
  // region state

  const [dev, setDev] = React.useState<boolean>(true);
  const [demo, setDemo] = React.useState<boolean>(false);
  const [staging, setStaging] = React.useState<boolean>(false);
  const [production, setProduction] = React.useState<boolean>(false);

  const [showLevelDropDown, setShowLevelDropDown] = React.useState<boolean>(false);
  const [showDomainIdDropDown, setShowDomainIdDropDown] = React.useState<boolean>(false);
  const [showStandardIdDropDown, setShowStandardIdDropDown] = React.useState<boolean>(false);
  const [showGenerationWorkbenchDropDown, setShowGenerationWorkbenchDropDown] = React.useState<boolean>(false);
  const [showWidgetSetIdDropDown, setShowWidgetSetIdDropDown] = React.useState<boolean>(false);


  const [unsaved, setUnsaved] = React.useState<boolean>(false);
  const [progressionSetList, setProgressionSetList] = React.useState<Array<ProgressionSet>>([]);
  const [progressionSetId, setProgressionSetId] = React.useState<ProgressionSetId>(null);
  const [progressionSet, setProgressionSet] = React.useState<ProgressionSet>(null);
  const [problemChoices, setProblemChoices] = React.useState<Array<Problem>>([]);
  const [problemList, setProblemList] = React.useState<Array<Problem>>([]);


  const [problem, setProblem] = React.useState<Problem>(null);
  const [problemIndex, setProblemIndex] = React.useState<number>(null);
  const [standards, setStandards] = React.useState<Array<Standard>>([]);
  const [curriculumStudentResponse, setCurriculumStudentResponse] = React.useState<CurriculumStudentResponse>(null);
  const [widgetSetId, setWidgetSetId] = React.useState<WidgetSetId>(null);
  const [levelId, setLevelId] = React.useState<string>('K');
  const [domainId, setDomainId] = React.useState<string>(null);
  const [standardId, setStandardId] = React.useState<string>(null);
  const [generationWorkbench, setGenerationWorkbench] = React.useState<string>(null);

  // const [formObject, setFormObject] = React.useState(defaultValues);

  //endregion
  // region callBacks
  React.useEffect(() => queryProgressionSets(setProgressionSetList), []);

  const saveProgressionSet = () => {

    const _progressionSet = {...progressionSet};
    _progressionSet.domainId = domainId;
    _progressionSet.levelId = levelId;
    _progressionSet.standardId = standardId;
    _progressionSet.widgetSetId = widgetSetId;
    _progressionSet.problemList = problemList;

    adminProgressionSetSaveApi({
      progressionSet:_progressionSet,
      dev,
      demo,
      production,
      staging
    })
      .then(() => {
        return queryProgressions(levelId, domainId, standardId);
      })
      .then(_progressionSetList => {
        setUnsaved(false);
        setProgressionSetList(_progressionSetList);
        if (!_progressionSetList.find(p => p.progressionSetId === progressionSetId)) {
          setProgressionSetId(null);
          setProgressionSet(null);
        }
      });

  };

  const newProgression = () => {
    console.log('setUnsaved', true);
    setUnsaved(true);
    const standard: Standard = standards.find(s => s.standardId === standardId);

    const _progressionSet: ProgressionSet = {
      progressionSetId: uuidv4('PS'),
      progressionSetName: '',
      progressionSetOrder: progressionSetList.length,
      standardId: standardId,
      standardNumber: standard?.standardNumber,
      standardName: standard?.standardName,
      standardDescription: standard?.standardDescription,

      widgetIdList: generationWorkbench? WorkbenchToWidget[generationWorkbench].widgetIdList: [],
      widgetSetId: generationWorkbench? WorkbenchToWidget[generationWorkbench].widgetIdList[0]: null,
      subjectId: 'MATH',
      levelId: levelId,
      domainId: standard?.domainId,
      domainPostfix: standard?.domainId.substr(5),
      domainLabel: standard?.domainLabel,
      domainDescription: standard?.domainDescription,
      problemGenerationFunction:null,
      problemGenerationParams: {},
      numberOfProblems: 10,
      problemRandomSeed:null,
      problemList:[],
      instructions: '[]',
      timeStamp: Date.now()
    };
    console.log('setProgressionSet 2', _progressionSet);
    setProgressionSet(_progressionSet);


  };
  React.useEffect(() => {

    if (progressionSet) {
      try {
        const _problems = ProgressionSetUtil.createProblemList(progressionSet);
        console.log('Set Problems2 ',problemIndex, _problems.length);

        setProblemChoices(_problems);
        let _problemIndex = problemIndex;
        if (problemIndex === null ||problemIndex === undefined){
          setProblemIndex(0);
          _problemIndex = 0;
        }
        if (_problems?.length > _problemIndex) {
          changeProblem(_problems[_problemIndex],_problemIndex);
        } else {
          if (_problems?.length > 0 ) {
            _problemIndex = 0;
            setProblemIndex(_problemIndex);
            changeProblem(_problems[_problemIndex],_problemIndex);
          }
          else {
            changeProblem(null, null);
          }
        }
      } catch (e) {
        console.log('Set Problems ERROR', e);
        setProblemChoices([]);
      }
    }

  }, [progressionSet,  problemIndex, widgetSetId]);

  React.useEffect(() => {
    queryStandards(levelId, domainId)
      .then(_standards => {
        setStandards(_standards);
      });


    queryProgressions(levelId, domainId, standardId)
      .then(_progressionSetList => {
        setProgressionSetList(_progressionSetList);
        if (!_progressionSetList.find(p => p.progressionSetId === progressionSetId)) {
          if (!unsaved) {
            console.log('setProgressionSet 1', unsaved);
            setProgressionSetId(null);
            setProgressionSet(null);
          }
        }
      });
  }, [levelId, domainId, standardId]);

  const changeProblem = (_problem: Problem, _problemIndex: number) => {
    console.log('changeProblem',_problemIndex,_problem);
    setProblem(_problem);
    setProblemIndex(_problemIndex);
    setCurriculumStudentResponse({
      responseKeyValues: _problem?.params?.expectedResponseKeyValues && JSON.parse(JSON.stringify(_problem?.params?.expectedResponseKeyValues)),
      interactionId: '', responseState: '', timeStamp: 0
    });

  };

  const changeProblemGenerationParams =(problemGenerationParams:ProblemGenerationParams) => {
    const _progressionSet = {
      ...progressionSet,
      problemGenerationParams,
    };
    console.log('setProgressionSet changeProblemGenerationParams', _progressionSet);
    setProgressionSet(_progressionSet);
  };


  const onChangeWidgetSetId = (key: string, widgetSetId: string) => {
    //    onChange(widgetSetId, value);
    setWidgetSetId(widgetSetId);
    if (progressionSet) {
      const _progressionSet = {
        ...progressionSet,
        widgetSetId
      };
      console.log('\n\n\nsetProgressionSet 5', _progressionSet);
      setProgressionSet(_progressionSet);

    }
  };



  // endregion

  const {problemGenerationParams } = progressionSet || {};
  return (
    <View style={styles.root} testID="CurriculumWorkbench">
      {/* region topBar */}
      <View style={[styles.gridRowContainer, {maxHeight: 80, backgroundColor: '#DDDDDD'}]}>
        <DropDown label="Level"   value={levelId}
          mode={"outlined"}
          visible={showLevelDropDown}
          showDropDown={() => setShowLevelDropDown(true)}
          onDismiss={() => { setShowLevelDropDown(false);}}
          setValue={(_levelId) => {
            setLevelId(_levelId);
          }}
          list={Object.keys(CURRICULUM.LEVELS).map(levelId => ({
            label: CURRICULUM.LEVELS[levelId],
            value: levelId
          }))}/>
        <DropDown label="Domain"
          value={domainId}
          mode={"outlined"}
          visible={showDomainIdDropDown}
          showDropDown={() => setShowDomainIdDropDown(true)}
          onDismiss={() => { setShowDomainIdDropDown(false);}}
          setValue={(_domainId) => {
            setDomainId(_domainId);
          }}
          list={[{label: 'Not Specified', value: null},
            ...Object.keys(CURRICULUM.DOMAINS).map(domainId => ({
              label: CURRICULUM.DOMAINS[domainId],
              value: domainId
            }))]}/>
        {
          standards.length > 0 && (
            <DropDown label="Standard"
              value={standardId}
              mode={"outlined"}
              visible={showStandardIdDropDown}
              showDropDown={() => setShowStandardIdDropDown(true)}
              onDismiss={() => { setShowStandardIdDropDown(false);}}
              setValue={(_standardId) => {
                setStandardId(_standardId);
                const s : Standard= standards.find( s=> s.standardId === _standardId);
                if (s) {
                  setDomainId(s.domainId);
                }
              }}
              list={[{label: 'Not Specified', value: null},
                ...standards.map(standard => ({
                  label: standard.standardId,
                  value: standard.standardId
                }))]}/>)}
        <Text style={textStyles.titleBar}>Progression Set Editor</Text>
        {
          standardId && (
            <Text>
              {standards.find(standard => standard.standardId === standardId)?.standardDescription}
            </Text>
          )
        }
        <View style={gridStyles.gridColumnContainer}>
        </View>
      </View>
      {/* endregion*/}
      <View style={styles.gridRowContainer}>

        {/* region Progression List  Left Column */}
        <View style={styles.gridColumnContainer}>
          {/* region SaveProgressionSet */}
          {progressionSet && (
            <View style={[gridStyles.gridRowContainer, {maxHeight: 200, backgroundColor: COLOR.GREY}]}>
              <View style={gridStyles.gridColumnContainer}>
                <View style={gridStyles.formRowContainer}>
                  <Checkbox
                    status={dev ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setDev(!dev);
                    }}
                  />
                  <Text>Dev</Text>
                </View>
                <View style={gridStyles.formRowContainer}>
                  <Checkbox
                    status={demo ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setDemo(!demo);
                    }}
                  />
                  <Text>Demo</Text>
                </View>
                <View style={gridStyles.formRowContainer}>
                  <Checkbox
                    status={staging ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setStaging(!staging);
                    }}
                  />
                  <Text>Staging</Text>
                </View>
                <View style={gridStyles.formRowContainer}>
                  <Checkbox
                    status={production ? 'checked' : 'unchecked'}
                    onPress={() => {
                      setProduction(!production);
                    }}
                  />
                  <Text>Production</Text>
                </View>
              </View>
              <View style={gridStyles.gridColumnContainer}>
                <Button icon="content-save" mode="contained" onPress={() => {
                  saveProgressionSet();
                }}>
                  Save
                </Button>
              </View>
            </View>)
          }
          {/* endregion */}
          <Button icon="open-in-new" mode="contained" onPress={() => {
            newProgression();
          }}>
            New Progression
          </Button>
          <List.Section title="ProgressionSets">
            {
              !unsaved && progressionSetList.map((_progressionSet: ProgressionSet) => {
                return <List.Item
                  key={_progressionSet.progressionSetId}
                  style={{backgroundColor: progressionSetId === _progressionSet.progressionSetId ? COLOR.RED: COLOR.WHITE}}
                  title={_progressionSet.progressionSetName}
                  onPress={
                    () => {
                      setProgressionSetId(_progressionSet.progressionSetId);
                      setProgressionSet(_progressionSet);
                      const wb  = Object.entries(WorkbenchToWidget).find(_wb => _wb[1].problemGenerationFunction === _progressionSet.problemGenerationFunction);
                      console.log("WB", wb, _progressionSet.problemGenerationFunction);
                      setGenerationWorkbench(wb[0]);
                    }
                  }/>;
              })
              // !unsaved && progressionSetList.map((_progressionSet: ProgressionSet) => {
              //   return (<View style={styles.gridRowContainer} key={_progressionSet.progressionSetId}>
              //     <RadioButton
              //
              //       value={_progressionSet.progressionSetId}
              //       status={progressionSetId === _progressionSet.progressionSetId ? 'checked' : 'unchecked'}
              //       onPress={() => {
              //         setProgressionSetId(_progressionSet.progressionSetId);
              //         setProgressionSet(_progressionSet);
              //
              //       }}
              //     />
              //     <Text>{_progressionSet.progressionSetName}</Text>
              //   </View>);
              // })
            }
          </List.Section>
          {
            unsaved && function () {

              return (
                <View style={styles.gridColumnContainer}>
                  <TextInput style={textStyles.textInput} label="progressionSetName"
                    value={progressionSet?.progressionSetName || ''}
                    onChangeText={progressionSetName => {
                      console.log('progressionSetName', progressionSetName);
                      setProgressionSet({
                        ...progressionSet,
                        progressionSetName
                      });
                    }}
                  />
                </View>
              );

            }()
          }
        </View>
        {/* endregion */}
        {/* region Problem Generation*/}
        <View style={styles.gridColumnContainer}>
          {
            progressionSet && (<View style={gridStyles.formRowContainer}>
              <DropDown label="GenerationWorkbench"
                value={generationWorkbench}
                mode={"outlined"}
                visible={showGenerationWorkbenchDropDown}
                showDropDown={() => setShowGenerationWorkbenchDropDown(true)}
                onDismiss={() => { setShowGenerationWorkbenchDropDown(false);}}
                setValue={ (value)=> {

                  setGenerationWorkbench(value);

                  const widgetIdList = WorkbenchToWidget[value].widgetIdList;
                  console.log('widgetIdList',widgetIdList);
                  const problemGenerationFunction = WorkbenchToWidget[value].problemGenerationFunction;
                  const widgetSetId = widgetIdList[0];
                  if (progressionSet) {
                    const _progressionSet = {
                      ...progressionSet,
                      problemGenerationFunction,
                      widgetIdList,
                      widgetSetId
                    };

                    setProgressionSet(_progressionSet);

                  }}}
                list={Object.keys(WorkbenchToWidget).map(key => {
                  return {
                    label: key, value: key
                  };
                })
                }
              />

            </View>)
          }


          {
            progressionSet?.widgetIdList && (<View style={gridStyles.formRowContainer}>
              <DropDown label="widgetSetId"
                mode={"outlined"}
                visible={showWidgetSetIdDropDown}
                showDropDown={() => setShowWidgetSetIdDropDown(true)}
                onDismiss={() => { setShowWidgetSetIdDropDown(false);}}
                value={progressionSet.widgetSetId}
                setValue={ (value)=> {
                  onChangeWidgetSetId('widgetSetId',value);}}

                list={progressionSet?.widgetIdList.map(key => {
                  return {
                    label: key, value: key
                  };
                })
                }
              />

            </View>)
          }

          <TextInput style={[textStyles.textInput,gridStyles.formRowContainer]} label="number of problems"  value={progressionSet?.numberOfProblems?.toString(10)||''}
            onChangeText={_numberOfProblems => {
              try {
                const numberOfProblems = parseInt(_numberOfProblems,10);
                const _progressionSet = {...progressionSet, numberOfProblems};
                setProgressionSet(_progressionSet);
              }
              catch (e){
                console.error('Use Number');
                const _progressionSet = {...progressionSet, numberOfProblems: null};
                setProgressionSet(_progressionSet);
              }
            }}
          />
          <View style={gridStyles.formRowContainer}>
            <Checkbox
              status={progressionSet?.problemRandomSeed ? 'checked' : 'unchecked'}
              onPress={() => {
                if (progressionSet?.problemRandomSeed) {
                  const problemRandomSeed = null;
                  const _progressionSet = {...progressionSet, problemRandomSeed};
                  setProgressionSet(_progressionSet);

                }
                else {
                  const problemRandomSeed = Date.now().toString(10);
                  const _progressionSet = {...progressionSet, problemRandomSeed};
                  setProgressionSet(_progressionSet);
                }
              }}
            />
            <Text>Random Order</Text>
          </View>

          {(progressionSet && generationWorkbench) &&
          React.createElement(WorkbenchToWidget[generationWorkbench].workbench,
            {
              problemIndex,
              problemGenerationParams,
              onGeneratorChange: changeProblemGenerationParams
            })
          }
        </View>
        {/* endregion*/}
        {/* region Problem Choices*/}
        <View style={styles.gridColumnContainer}>
          {
            problemChoices && (
              <View style={gridStyles.gridColumnContainer}>
                <ScrollView>
                  <List.Section title="Problem Choices">
                    {
                      problemChoices.map((_problem: Problem, _problemIndex:number) => {
                        return <List.Item
                          left={props =>  <IconButton
                            {...props}
                            icon="plus-thick"
                            color={COLOR.GREEN}
                            size={20}
                            onPress={() => {
                              let p;
                              if (problemList) {
                                p = [...problemList, _problem];
                              }
                              else {
                                p = [_problem];
                              }
                              changeProblem(_problem,_problemIndex);
                              setProblemList(p);
                            }
                            }
                          />}
                          key={_problem.problemId}
                          style={{backgroundColor: (_problem === problem) ? COLOR.RED: COLOR.WHITE}}
                          title={JSON.stringify(_problem.params.prompts)}
                          onPress={
                            () => {
                              changeProblem(_problem,_problemIndex);
                            }
                          }/>;

                        // return (
                        //   <DataTable.Row key={_problem.problemId} onPress={() => changeProblem(_problem,_problemIndex)}>
                        //     <DataTable.Cell>{JSON.stringify(_problem?.params?.prompts)}</DataTable.Cell>
                        //     <DataTable.Cell>{JSON.stringify(_problem?.params?.expectedResponseKeyValues.set || _problem?.params?.expectedResponseKeyValues?.setList)}</DataTable.Cell>
                        //   </DataTable.Row>
                        // );
                      })}
                  </List.Section>
                </ScrollView>
              </View>
            )
          }
        </View>
        {/* endregion*/}
        {/* region Problems selected */}
        <View style={styles.gridColumnContainer}>


          <View style={{maxWidth: 200}}>
            <Text style={textStyles.labelText}>Problem List</Text>
            {
              problemList?.map( (_problem, index) => {
                return (
                  <View style={{flexDirection:'row'}} key={ 'coach' +index+'_'+ _problem.problemId}>
                    <IconButton
                      icon="minus-circle-outline"
                      color={COLOR.RED}
                      size={20}
                      onPress={() => {
                        const newProblemList = problemList.filter(p => p.problemId !== _problem.problemId );
                        console.log('newProblemList',newProblemList.length, problemList.length);
                        setProblem(null);
                        setProblemList(newProblemList);
                      }
                      }
                    />
                    {
                      React.createElement(WidgetInventory(_problem.widgetSetId).coach, {
                        key: 'coach' + _problem.problemId,
                        curriculumStudentInteraction: {
                          problem: _problem,
                          position: 0,
                          interactionId: '', studentId: 'null', coachId: 'null',
                          widgetSetId: _problem.widgetSetId
                        },
                        curriculumStudentResponse: ResponseDataUtil.createCurriculumStudentResponse(_problem, "IID"),
                      })
                    }
                    <View style={{flexDirection: 'column', width: 30}}>
                      {index !== 0 && <IconButton
                        style={{margin: 0}}
                        icon="arrow-up-circle-outline"
                        color={COLOR.GREEN}
                        size={20}
                        onPress={() => {


                          const newProblemList = [...problemList];
                          const p = newProblemList[index];
                          newProblemList[index] = newProblemList[index - 1];
                          newProblemList[index - 1] = p;
                          setProblemList(newProblemList);
                        }
                        }
                      />}

                      {index < problemList.length - 1 &&
                      <IconButton
                        style={{margin:0}}
                        icon="arrow-down-circle-outline"
                        color={COLOR.GREEN}
                        size={20}
                        onPress={() => {
                          const newProblemList = [...problemList];
                          const p = newProblemList[index];
                          newProblemList[index] = newProblemList[index + 1];
                          newProblemList[index + 1] = p;
                          setProblemList(newProblemList);
                        }
                        }
                      />}
                    </View>
                  </View>
                );
              })
            }

          </View>

        </View>
        {/* endregion */}

        {/* region Widget Views*/}
        <View style={[styles.gridColumnContainer, {minWidth: 790, width: 790}]}>
          {
            progressionSet?.widgetSetId && (
              <>
                <Text style={textStyles.titleBar}>Student</Text>
                <View style={{width: 760, height: 760}}>
                  {
                    React.createElement(WidgetInventory(progressionSet?.widgetSetId).student, {
                      key: 'student' + progressionSet?.widgetSetId,
                      curriculumStudentInteraction: {
                        problem,
                        position: 0,
                        interactionId: '',
                        studentId: 'null',
                        coachId: 'null',
                        widgetSetId: progressionSet?.widgetSetId
                      },
                      inWorkbench: true,
                      curriculumStudentResponse,
                      onResponse: (key: string, value: string, index?: number) => {
                        const _curriculumStudentResponse = ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);
                        console.log('Response', _curriculumStudentResponse);
                        setCurriculumStudentResponse(_curriculumStudentResponse);
                      }
                    })
                  }
                </View>

              </>
            )
          }
          {
            progressionSet?.widgetSetId && (
              <View style={{maxWidth: 200}}>
                <Text style={textStyles.titleBar}>Coach</Text>
                {
                  React.createElement(WidgetInventory(progressionSet?.widgetSetId).coach, {
                    key: 'coach' + progressionSet?.widgetSetId,
                    curriculumStudentInteraction: {
                      problem,
                      position: 0,
                      interactionId: '', studentId: 'null', coachId: 'null',
                      widgetSetId: progressionSet?.widgetSetId
                    },
                    curriculumStudentResponse,
                  })
                }

              </View>
            )
          }

        </View>
        {/* endregion */}
        {/* region Widget Iphone*/}
        <View style={[styles.gridColumnContainer]}>
          {
            progressionSet?.widgetSetId && (
              <>
                <Text style={textStyles.titleBar}>Iphone8</Text>
                <View style={{width: 375, height: 375}}>
                  {
                    React.createElement(WidgetInventory(progressionSet?.widgetSetId).student, {
                      key: 'student' + progressionSet?.widgetSetId,
                      curriculumStudentInteraction: {
                        problem,
                        position: 0,

                        interactionId: '', studentId: 'null', coachId: 'null',
                        widgetSetId: progressionSet?.widgetSetId
                      },
                      inWorkbench: true,
                      curriculumStudentResponse,
                      onResponse: (key: string, value: string, index?: number) => {
                        const _curriculumStudentResponse = ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);
                        console.log('Response', _curriculumStudentResponse);
                        setCurriculumStudentResponse(_curriculumStudentResponse);
                      }
                    })
                  }
                </View>

              </>
            )
          }
          {
            progressionSet?.widgetSetId && (
              <>
                <Text style={textStyles.titleBar}>Iphone 12 Plus Max</Text>
                <View style={{width: 425, height: 425}}>
                  {
                    React.createElement(WidgetInventory(progressionSet?.widgetSetId).student, {
                      key: 'student' + progressionSet?.widgetSetId,
                      curriculumStudentInteraction: {
                        problem,
                        position: 0,

                        interactionId: '', studentId: 'null', coachId: 'null',
                        widgetSetId: progressionSet?.widgetSetId
                      },
                      inWorkbench: true,
                      curriculumStudentResponse,
                      onResponse: (key: string, value: string, index?: number) => {
                        const _curriculumStudentResponse = ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);
                        console.log('Response', _curriculumStudentResponse);
                        setCurriculumStudentResponse(_curriculumStudentResponse);
                      }
                    })
                  }
                </View>

              </>
            )
          }

        </View>
        {/* endregion */}
      </View>
    </View>
  );

}
