import * as React from "react";
import {Button, DataTable, Text} from 'react-native-paper';
import {queryHuddleNoCoach} from "../../redux/queries/SessionQueries";
import {Huddle} from "../../sharedDefs/huddleDefs";
import {StudentId} from "../../sharedDefs/userDefs";
import {huddleCoachAdmitStudent} from "../../redux/reducer/huddleReducer";
import {useDispatch, useSelector} from "react-redux";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {State} from "../../redux/reducer";
import {View} from "react-native";
import {textStyles} from "../../widgets/WidgetStyles";

const debug = debugModule(DEBUG_MODULE.WEBCON.COACH);
type CoachAvailableStudentsProps = {
  allowAdmit: boolean;

};
export default function CoachAvailableStudents(props: CoachAvailableStudentsProps): JSX.Element {

  const dispatch = useDispatch();
  const {allowAdmit} = props;


  const [huddleNoCoach, setHuddleNoCoach] = React.useState<Huddle>(null);
  const coachId = useSelector((state: State) => state.userReducer.userId);
  const handleAdmission = (studentId) => {
    debug('useCallback handleAdmission');
    dispatch(huddleCoachAdmitStudent({type: 'CoachStudentWebCon'},
      coachId, studentId));
  };


  React.useEffect(() => {
    console.log('findExtraStudents effect');

    return queryHuddleNoCoach(setHuddleNoCoach);


  }, []);

  const studentIds: Array<StudentId> = huddleNoCoach?.studentHuddleUsers? Object.keys(huddleNoCoach?.studentHuddleUsers) :[];
  const rows = studentIds.map ( studentId => {
    const studentHuddleUser =huddleNoCoach.studentHuddleUsers[studentId];

    return (
      <DataTable.Row key={studentId}>
        {allowAdmit &&  <DataTable.Cell><Button testID="admitStudent" onPress={() => {handleAdmission(studentId);}}>Admit</Button></DataTable.Cell>}
        <DataTable.Cell >{studentHuddleUser.userProfile.firstName}</DataTable.Cell>
        <DataTable.Cell >{studentHuddleUser.userProfile.levelId}</DataTable.Cell>
        <DataTable.Cell >{studentHuddleUser.huddleUserStatus}</DataTable.Cell>
      </DataTable.Row>
    );
  });
  return (
    <View>
      <Text style={textStyles.titleBar}>Students awaiting coaches</Text>
      <DataTable>
        <DataTable.Header>
          {allowAdmit && <DataTable.Title>Actions</DataTable.Title>}
          <DataTable.Title >Name</DataTable.Title>
          <DataTable.Title >Level</DataTable.Title>
          <DataTable.Title >Status</DataTable.Title>
        </DataTable.Header>
        {rows}
      </DataTable>
    </View>
  );
}
