//
import * as React from "react";
import { StyleSheet } from "react-native";
import { DataTable, IconButton } from "react-native-paper";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
const debug = debugModule(DEBUG_MODULE.COMPONENTS.TABLE);
export type Column = {
  title: string;
  field: string;
  render: (row: any) => string | JSX.Element;
};
export type Action = {
  icon: string;
  tooltip: string;
  onClick: (event: any, rowData: Array<Record<string,any>>) => any;
};
const styles = StyleSheet.create({});
export function makeHeader(columns: Array<Column>, actionIcon?: string): JSX.Element {
  const titles = columns.map(column => <DataTable.Title key={column.field}>{column.title}</DataTable.Title>);
  const allTitles = /* actionIcon ? [<DataTable.Title key="action" />, ...titles] : */ titles;
  return <DataTable.Header key="header">
    {allTitles}
  </DataTable.Header>;
}
let rowIndex = 0;
export function makeRows(columns: Array<Column>, data: Array<Record<string,any>>, actionIcon?: any, callback?: (rowdata: Record<string,any>) => void): Array<JSX.Element> {
  return data.map((dataElement, index) => {
    const cells = columns.map(column => {
      const field = column.render(dataElement);
      debug('field', column.field, field);
      return <DataTable.Cell key={column.field} onPress={() => {
        debug('pressCell', callback);

        if (callback) {
          callback(dataElement);
        }
      }}>
        {field}
      </DataTable.Cell>;
    });
    const actionCell = <DataTable.Cell key="action" onPress={() => {
      debug('pressCell', callback);

      if (callback) {
        callback(dataElement);
      }
    }}>
      <IconButton icon={actionIcon} size={20} onPress={() => {
        debug('pressCell', callback);

        if (callback) {
          callback(dataElement);
        }
      }} />
    </DataTable.Cell>;
    const allCells = actionIcon ? [actionCell, ...cells] : cells;
    rowIndex += 1;
    return <DataTable.Row testID={'action' + index} key={`${rowIndex}`} onPress={() => {
      debug('press', callback);

      if (callback) {
        callback(dataElement);
      }
    }}>
      {allCells}
    </DataTable.Row>;
  });
}
export function makeTable(columns: Array<Column>, data: Array<any>, actionIcon?: string, callback?: (rowdata: Record<string,any>) => void): JSX.Element {
  return <DataTable>
    {makeHeader(columns, actionIcon)}
    {makeRows(columns, data, actionIcon, callback)}
  </DataTable>;
}
