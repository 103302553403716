import type { WidgetSetId} from "../sharedDefs/curriculumDefs";
import AdditionProgressionView from "./AdditionProgressionView";
import MultiplicationProgressionView from "./MultiplicationProgressionView";
import NotFoundProgressionView from "./NotFoundProgressionView";
import CompareNumbersWidget from "./CompareNumbersWidget";
import DotDivisionWidget from "./DotDivisionWidget";
import DivisionWidget from "./DivisionWidget";
import InstructionsWidget from "./InstructionsWidget";
import MultipleChoiceWidget from "./MultipleChoiceWidget";
import MultiplicationWidget from "./MultiplicationWidget";
import NotFoundWidget from "./NotFoundWidget";
import AdditionPartnerWidget from "./AdditionPartnerWidget";
import SubtractionWidget from "./SubtractionWidget";
import SingleDigitSelectWidget from "./SingleDigitSelectWidget";

import {ProgressionView, WidgetSet} from "./widgetStructs";
import AdditionWidget from "./AdditionWidget";
import MultiplicationWorkbench from "../curriculum/MultiplicationWorkbench";
import DivisionWorkbench from "../curriculum/DivisionWorkbench";
import AdditionWorkbench from "../curriculum/AdditionWorkbench";
import SubtractionWorkbench from "../curriculum/SubtractionWorkbench";
import CompareNumbersWorkbench from "../curriculum/CompareNumbersWorkbench";
import AdditionPartnerWorkbench from "../curriculum/AdditionPartnerWorkbench";
import MultipleChoiceWorkbench from "../curriculum/MultipleChoiceWorkbench";

const WidgetInventoryMap = {
  AdditionWidget,
  SubtractionWidget,
  DivisionWidget,
  DotDivisionWidget,
  AdditionPartnerWidget,
  CompareNumbersWidget,
  MultiplicationWidget,
  MultipleChoiceWidget,
  InstructionsWidget,
  SingleDigitSelectWidget,
};


// export const WIDGETS = Object.keys(WidgetInventoryMap);
// [ 'AdditionWidget','SingleDigitSelectWidget', 'PartnerListWidget', 'DotDivisionWidget', 'CompareNumbersWidget', 'MultiplicationWidget', 'MultipleChoiceWidget', 'InstructionsWidget'];


export const WorkbenchToWidget: Record<string, { widgetIdList: Array<WidgetSetId>, workbench: any, problemGenerationFunction:string }> = {
  MultiplicationWorkbench: {
    widgetIdList: ['MultiplicationWidget'],
    workbench: MultiplicationWorkbench,
    problemGenerationFunction:'multiplicationGeneration'
  },
  DivisionWorkbench: {
    widgetIdList: ['DivisionWidget','DotDivisionWidget'],
    workbench: DivisionWorkbench,
    problemGenerationFunction:'divisionGeneration'
  },
  AdditionWorkbench: {
    widgetIdList: ['AdditionWidget'],
    workbench: AdditionWorkbench,
    problemGenerationFunction:'additionGeneration'
  },
  SubtractionWorkbench: {
    widgetIdList: ['SubtractionWidget'],
    workbench: SubtractionWorkbench,
    problemGenerationFunction:'subtractionGeneration'
  },
  CompareNumbersWorkbench: {
    widgetIdList: ['CompareNumbersWidget'],
    workbench: CompareNumbersWorkbench,
    problemGenerationFunction:'compareGeneration'
  },
  AdditionPartnerWorkbench: {
    widgetIdList: ['AdditionPartnerWidget'],
    workbench: AdditionPartnerWorkbench,
    problemGenerationFunction:'additionPartnerGeneration'
  },
  MultipleChoiceWorkbench: {
    widgetIdList: ['MultipleChoiceWidget'],
    workbench: MultipleChoiceWorkbench,
    problemGenerationFunction:'multipleChoiceGeneration'
  }
};

export default function WidgetInventory(widgetId: WidgetSetId): WidgetSet {
  const widget = WidgetInventoryMap[widgetId];


  if (widget) {
    return widget;
  }

  return NotFoundWidget;
}
export const PROGRESSION_VIEWS = ['AdditionProgressionView', 'PartnerProgressionView', 'DivisionProgressionView', 'CompareProgressionView', 'MultiplicationProgressionView', 'NotFoundProgressionView'];
const ProgressionSetViewInventoryMap = {
  AdditionProgressionView,
  MultiplicationProgressionView,
  NotFoundProgressionView
};
export function ProgressionSetViewInventory(progressionSetViewName: string): ProgressionView {
  const progressionView = ProgressionSetViewInventoryMap[progressionSetViewName];

  if (progressionView) {
    return progressionView;
  }

  return NotFoundProgressionView;
}


