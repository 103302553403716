

export const ROLE: Record<string, string> = {
  COACH: 'coach',
  STUDENT: 'student',
  PARENT: 'parent',
  ADMIN: 'admin'
};
export type RoleType = string;

export const USER_ACTION = {
  ADMIN_REGISTER: {
    REQUEST: 'USER_ACTION.ADMIN_REGISTER.REQUEST',
    SUCCESS: 'USER_ACTION.ADMIN_REGISTER.SUCCESS',
    FAILURE: 'USER_ACTION.ADMIN_REGISTER.FAILURE'
  },
  COACH_REGISTER: {
    REQUEST: 'USER_ACTION.COACH_REGISTER.REQUEST',
    SUCCESS: 'USER_ACTION.COACH_REGISTER.SUCCESS',
    FAILURE: 'USER_ACTION.COACH_REGISTER.FAILURE'
  },
  PARENT_REGISTER: {
    REQUEST: 'USER_ACTION.PARENT_REGISTER.REQUEST',
    SUCCESS: 'USER_ACTION.PARENT_REGISTER.SUCCESS',
    FAILURE: 'USER_ACTION.PARENT_REGISTER.FAILURE'
  },
  LOGIN: {
    REQUEST: 'USER_ACTION.LOGIN.REQUEST',
    SUCCESS: 'USER_ACTION.LOGIN.SUCCESS',
    FAILURE: 'USER_ACTION.LOGIN.FAILURE'
  },
  LOGOUT: {
    REQUEST: 'USER_ACTION.LOGOUT.REQUEST',
    SUCCESS: 'USER_ACTION.LOGOUT.SUCCESS',
    FAILURE: 'USER_ACTION.LOGOUT.FAILURE'
  },
  PROFILE: {
    UPDATE: 'USER_ACTION.PROFILE.UPDATE',
    SUCCESS: 'USER_ACTION.PROFILE.SUCCESS',
    FAILURE: 'USER_ACTION.PROFILE.FAILURE'
  },
  USER_DATA_SYNC: {
    START: 'USER_ACTION.USER_DATA_SYNC.START',
    STARTED: 'USER_ACTION.USER_DATA_SYNC.STARTED',
    SYNC_EVENT: 'USER_ACTION.USER_DATA_SYNC.SYNC_EVENT',
    STOP: 'USER_ACTION.USER_DATA_SYNC.STOP',
    STOPPED: 'USER_ACTION.USER_DATA_SYNC.STOPPED'
  },
  STUDENT: {
    CREATE: {
      REQUEST: 'USER_ACTION.STUDENT.CREATE.REQUEST',
      SUCCESS: 'USER_ACTION.STUDENT.CREATE.SUCCESS',
      FAILURE: 'USER_ACTION.STUDENT.CREATE.FAILURE'
    },
    LOGIN: {
      REQUEST: 'USER_ACTION.STUDENT.LOGIN.REQUEST',
      SUCCESS: 'USER_ACTION.STUDENT.LOGIN.SUCCESS',
      FAILURE: 'USER_ACTION.STUDENT.LOGIN.FAILURE'
    },
    LOGOUT: {
      REQUEST: 'USER_ACTION.STUDENT.LOGOUT.REQUEST',
      SUCCESS: 'USER_ACTION.STUDENT.LOGOUT.SUCCESS',
      FAILURE: 'USER_ACTION.STUDENT.LOGOUT.FAILURE'
    }
  }
};

export const PROFILE = {
  LEVELS: {
    PK: 'PreK',
    K: 'Kindergarten',
    G1: 'First Grade',
    G2: 'Second Grade',
    G3: 'Third Grade',
    G4: 'Forth Grade' ,
    G5: 'Fifth Grade' ,

  },
  OCCUPATION: {
    HIGHSCHOOL: 'highschool',
    COLLEGE: 'college',
    OTHER: 'other',
    TEACHER: 'teacher',
  },
  OCCUPATION_LABEL: {
    HIGHSCHOOL: 'High School Student',
    COLLEGE: 'College Student',
    TEACHER: 'Teacher',
    OTHER: 'Other',
  },
  GOAL: {
    ACCELERATE: 'ACCELERATE',
    STRUGGLING: 'STRUGGLING',
    CONFIDENCE: 'CONFIDENCE',
    ENJOYMENT: 'ENJOYMENT'
  },
  GOAL_LABEL: {
    ACCELERATE: 'Accelerate',
    STRUGGLING: 'Struggling',
    CONFIDENCE: 'Confidence',
    ENJOYMENT: 'Enjoyment'
  },
  STUDENT: 'student',
  PARENT: 'parent',
  ADMIN: 'admin',
  STATUS: {
    APPLIED: 'APPLIED',
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    ACTIVE: 'ACTIVE'
  },
  STATUS_LABEL: {
    APPLIED: 'APPLIED',
    APPROVED: 'APPROVED',
    DECLINED: 'DECLINED',
    ACTIVE: 'ACTIVE'
  }
};
export type UserId = string;
export type CoachId = UserId;
export type ParentId= UserId;
export type StudentId = UserId;

export type Email = string;
export type UserProfilePreSave = {
  email: Email;
  firstName: string;
  lastName: string;
  role: RoleType;
  birthDateTimestamp?: any;
  imageId?: string;
  levelId: string;
  birthMonth?: string;
  birthYear?: string;
  zipcode?:string;
  mobileNumber?: string;
  textReminders?: boolean;
  goal?: string;
  status?: string;
  occupation: string;
  essay: string;
  // Source of Truth
  studentIds: Array<StudentId>;
  parentIds: Array<ParentId>;
  coachIds: Array<CoachId>;
};


export type UserProfile = UserProfilePreSave & {
  userId: UserId;
  age?: number;
  name: string;
};
export type UserProfileUpdates = {
  userId?: UserId;
  firstName?: string;
  lastName?: string;
  imageId?: string;

  status?: string;
  occupation?: string;
  essay?: string;
  mobileNumber?: string;
  textReminders?: boolean;
};
export type UserData = {
  userProfile: UserProfile;
  parents: Array<UserProfile>;
  children: Array<UserProfile>;
  students: Array<UserProfile>;
  coaches: Array<UserProfile>;
};
