import {EventChannel, eventChannel} from "redux-saga";
import {all, call, cancel, fork, put, select, take, takeEvery, takeLatest} from "redux-saga/effects";
import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {ValidationError} from "../../utils/validation";
import {navGoHome, navGoto} from "../reducer/navigationReducer";
import {auth, firestore, storage} from "../rsf";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";
import {NAV} from "../structs/navigationStruct";
import type {UserData, UserProfile} from "../../sharedDefs/userDefs";
import {PROFILE, ROLE, USER_ACTION} from "../../sharedDefs/userDefs";
import type {UserAction} from "../structs/userStruct";
import {userProfileUpdateServerApi, userRegisterRoleServerApi, userStudentCreateServerApi} from "../serverApi";
import {ActionType} from "../../sharedDefs/commonStruct";
import {WS_ACTION} from "../../sharedDefs/webSocketDefs";
import {webSocketInit, webSocketStop} from "../reducer/wsReducer";
import {State} from "../reducer";
import {UserProfileUpdateResult, UserStudentCreateResult} from "../../sharedDefs/serverApiDefs";

//import {NativeModules, NativeEventEmitter} from 'react-native';
//const { WebRTCModule } = NativeModules;
//debug('WebRTCModule ', !!WebRTCModule)
const debug = debugModule(DEBUG_MODULE.USER.SAGA);

function* userParentRegisterSaga(action: UserAction) {
  try {
    const {
      parentRegistrationObject,
    } = action;

    invariant(parentRegistrationObject, 'validation-error');
    const {
      email, password,
      studentEssay,
      studentFirstName,
      parentFirstName,
      parentLastName,
      birthYear,
      zipcode,
      birthMonth,
      levelId,
      studentImage
    } = parentRegistrationObject;


    debug('userParentRegisterSaga-1');
    let _parentUser;
    try {
      _parentUser = yield call([auth, auth.createUserWithEmailAndPassword], email, password);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        _parentUser = yield call([auth, auth.signInWithEmailAndPassword], email, password);
      } else {
        throw e;
      }
    }
    debug('userParentRegisterSaga-2', _parentUser);
    const parentUid = _parentUser.user.uid;
    // if (useEmulator) {
    //   auth.setEmulatorRole(uid, role);
    // } else {
    yield call(userRegisterRoleServerApi, {
      userId: parentUid,
      customClaims: {
        role: ROLE.PARENT
      }
    });
    // }
    debug('userParentRegisterSaga-3 Register Role set', action.email);
    const parentUserProfile: UserProfile = {
      userId: parentUid,
      email,
      firstName: parentFirstName,
      lastName: parentLastName,
      name: parentFirstName + ' ' + parentLastName,
      role: ROLE.PARENT,
      zipcode,
      occupation: '',
      levelId:null,
      essay: '',
      parentIds: [],
      coachIds: [],
      studentIds: []
    };

    yield call(userProfileUpdateServerApi, {
      userProfile: parentUserProfile
    });
    debug('userParentRegisterSaga-4 userProfileUpdateServerApi');
    const studentProfile: UserProfile = {
      userId: null,
      email: null,
      firstName: studentFirstName,
      lastName: '',
      birthYear,
      birthMonth,
      name: studentFirstName,
      role: ROLE.STUDENT,
      occupation: '',
      levelId,
      essay: studentEssay,
      parentIds: [parentUid],
      coachIds: [],
      studentIds: []
    };
    const userStudentCreateResult = yield call(userStudentCreateServerApi, {
      studentProfile,
    });
    debug('userParentRegisterSaga-5 userStudentCreateServerApi');
    const {
      studentUserData,
      parentUserData
    } = userStudentCreateResult.data as UserStudentCreateResult;

    if (studentImage) {

      const storageRef = storage.ref();
      const imageRef = storageRef.child('images/' + studentUserData.userProfile.userId + '.png');

      debug('userParentRegisterSaga-5.5 studentImage');
      imageRef.put(studentImage, {
        contentType: 'image/png',
      });

    }
    debug('userParentRegisterSaga-6 stud' +
      'ent Image');

    yield put({
      type: USER_ACTION.PARENT_REGISTER.SUCCESS,
      studentId: studentUserData.userProfile.userId,
      userData: parentUserData,
      userId: parentUid,
      role: ROLE.PARENT
    });
  } catch (error) {
    yield put({
      type: USER_ACTION.PARENT_REGISTER.FAILURE,
      error
    });


  }
}

function* userCoachRegisterSaga(action: UserAction): any {
  try {
    const {
      coachRegistrationObject
    } = action;
    invariant(coachRegistrationObject, 'validation-error');

    const {
      email,
      password,
      firstName,
      lastName,
      coachEssay,
      occupation,
      birthDay,
      birthMonth,
      birthYear,
      coachImage
    } = coachRegistrationObject;
    const role = ROLE.COACH;
    debug('userCoachRegisterSaga-1 ', action.email);

    let _user;
    try {
      _user = yield call([auth, auth.createUserWithEmailAndPassword], email, password);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        _user = yield call([auth, auth.signInWithEmailAndPassword], email, password);
      } else {
        throw e;
      }
    }

    debug('userCoachRegisterSaga-2 Auth Called', action.email);
    const {
      uid
    } = _user.user;
    // if (useEmulator) {
    //   auth.setEmulatorRole(uid, role);
    // } else {
    yield call(userRegisterRoleServerApi, {
      userId: uid,
      customClaims: {
        role: ROLE.COACH
      }
    });
    // }


    const birthDateTimestamp = new Date(parseInt(birthYear), parseInt(birthMonth), parseInt(birthDay)).getTime();

    const userProfile: UserProfile = {
      userId: uid,
      email,
      firstName,
      lastName,
      name: firstName + ' ' + lastName,
      role,
      levelId:null,
      occupation,
      essay: coachEssay,
      birthDateTimestamp,
      status: PROFILE.STATUS.APPLIED,
      parentIds: [],
      coachIds: [],
      studentIds: []
    };
    const userProfileUpdateResult = yield call(userProfileUpdateServerApi, {
      userProfile
    });

    const {userData } =  userProfileUpdateResult.data as UserProfileUpdateResult;
    if (coachImage) {

      const storageRef = storage.ref();
      const imageRef = storageRef.child('images/' + uid + '.png');
      debug('userCoachRegisterSaga-3 Image');
      imageRef.put(coachImage, {
        contentType: 'image/png',
      });

    }
    yield put({
      type: USER_ACTION.COACH_REGISTER.SUCCESS,
      userId: uid,
      userData: userData,
      role: ROLE.COACH
    });
    yield put(navGoHome(role));
  } catch (error) {
    console.error(error, action.email);
    yield put({
      type: USER_ACTION.COACH_REGISTER.FAILURE,
      error
    });
  }
}

function* userAdminRegisterSaga(action: UserAction): any {
  try {
    const {
      email,
      password,
    } = action;
    invariant(email && password, 'validation-error');


    const role = ROLE.ADMIN;
    debug('Register ', action.email);

    let _user;
    try {
      _user = yield call([auth, auth.createUserWithEmailAndPassword], email, password);
    } catch (e) {
      if (e.code === 'auth/email-already-in-use') {
        _user = yield call([auth, auth.signInWithEmailAndPassword], email, password);
      } else {
        throw e;
      }
    }

    debug('Register Auth Called', action.email);
    const {
      uid
    } = _user.user;
    // if (useEmulator) {
    //   auth.setEmulatorRole(uid, role);
    // } else {
    yield call(userRegisterRoleServerApi, {
      userId: uid,
      customClaims: {
        role
      }
    });
    // }
    debug('Register Role set', action.email);
    const userProfile: UserProfile = {
      userId: uid,
      email,
      firstName: 'admin',
      lastName: 'admin',
      name: 'admin',
      occupation: '',
      levelId:null,
      essay: '',
      role,
      parentIds: [],
      coachIds: [],
      studentIds: []
    };
    const userProfileUpdateResult = yield call(userProfileUpdateServerApi, {
      userProfile
    });

    const {userData } =  userProfileUpdateResult.data as UserProfileUpdateResult;
    // const {
    //   userData
    // } = userProfileUpdateResult.data;
    yield put({
      type: USER_ACTION.ADMIN_REGISTER.SUCCESS,
      userId: uid,
      role: ROLE.ADMIN,
      userData
    });
    yield put(navGoHome(role)); // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    console.error(error, action.email);
    yield put({
      type: USER_ACTION.ADMIN_REGISTER.FAILURE,
      error
    });
  }
}

function* userProfileUpdateSaga(action: UserAction): any {
  try {
    if (!action.userProfile) {
      throw new ValidationError('validation-error', action);
    }

    const userProfilePreSave = action.userProfile;
    const userId = yield select((state: State) => state.userReducer.userId);
    const userProfile = {
      ...userProfilePreSave,
      userId
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userProfileUpdateResult = yield call(userProfileUpdateServerApi, {
      userProfile
    });
    const {
      userData
    } = userProfileUpdateResult.data;
    yield put({
      type: USER_ACTION.PROFILE.SUCCESS,
      origin: 'userProfileUpdateSaga',
      userData
    });
    yield put({
      type: USER_ACTION.USER_DATA_SYNC.START,
      userId: userData.userProfile.userId
    });
    const role = yield select((state: State) => state.userReducer.role);

    if (role === ROLE.COACH) {
      yield put(navGoto(NAV.COACH.HOME));
    }

    if (role === ROLE.PARENT) {
      yield put(navGoto(NAV.PARENT.HOME));
    }
  } catch (error) {
    yield put({
      type: USER_ACTION.PROFILE.FAILURE,
      error
    });
  }
}

function getRole(): Promise<string> {
  return auth.currentUser.getIdTokenResult().then(idTokenResult => {
    const {
      role
    } = idTokenResult.claims;
    return role;
  });
}

function* userLoginSaga(action: UserAction): any {
  try {
    const {
      email,
      password
    } = action;

    const loginUser = yield call([auth, auth.signInWithEmailAndPassword], email, password);
    // const userId = loginUser.user.uid;
    // yield put(webSocketInit(userId,null));
    // yield take(WS_ACTION.SYNC.CONNECTED);

    const role = yield call(getRole);

    const getUserDoc = id => firestore.collection(COLLECTION.USER_DATA).doc(id).get();

    const snapshot = yield call(getUserDoc, loginUser.user.uid);
    const userData = snapshot.data();

    if (userData) {
      yield put({
        type: USER_ACTION.PROFILE.SUCCESS,
        origin: 'userLoginSaga',
        userData
      });
    }

    yield put({
      type: USER_ACTION.LOGIN.SUCCESS,
      userId: loginUser.user.uid,
      role: userData.userProfile.role
    });
    yield put({
      type: USER_ACTION.USER_DATA_SYNC.START,
      userId: loginUser.user.uid,
      role
    });


    if (userData) {
      yield put(navGoHome(role));
    }
  } catch (error) {
    yield put({
      type: USER_ACTION.LOGIN.FAILURE,
      error
    });
  }
}

function* userLogoutSaga(): any {
  try {
    debug('userLogoutSaga-1');
    yield call([auth, auth.signOut]);
    debug('userLogoutSaga-2');
    yield put({type: WS_ACTION.SYNC.STOP});
    yield put(navGoto(NAV.AUTH.HOME));
    debug('userLogoutSaga-3');
    yield put({
      type: USER_ACTION.LOGOUT.SUCCESS
    }); // successful logout will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    debug('userLogoutSaga-err');
    yield put({
      type: USER_ACTION.LOGOUT.FAILURE,
      error
    });
  }
}

async function getLoginUserWithRetry(uid: string): Promise<UserData> {
  debug('getLoginUserWithRetry-1', uid);

  const localDelay = ms => new Promise(res => setTimeout(res, ms));

  for (let i = 0; i < 5; i += 1) {

    const snapshot = await firestore.collection(COLLECTION.USER_DATA).doc(uid).get();
    if (!snapshot.exists) {
      await localDelay(1000);
      debug('getLoginUserWithRetry-5 Delayed and retrying', i, uid);
    } else {
      debug('getLoginUserWithRetry-3 GET DOC EXISTS', uid);
      return snapshot.data() as UserData;
    }

  }

  // attempts failed after 5 attempts
  throw new Error('getLoginUserWithRetry failed');
}

function* loginStatusWatcher(): any {
  // events on this channel fire when the user logs in or logs out
  debug('loginStatusWatcher started');
  const channel = eventChannel(emit => auth.onAuthStateChanged(user => {
    emit({
      type: 'LOGIN_WATCHER',
      loginUser: user
    });
  }));

  while (true) {
    const theTake = yield take(channel);
    const {
      loginUser
    } = theTake;
    debug('loginStatusWatcher got event', loginUser?.uid);

    if (loginUser?.uid) {
      //
      // loginUser.getIdTokenResult()
      //   .then((token) => {
      //   })
      //   .catch(e => {
      //     console.error('TOKEN ERROR', e);
      //   });
      debug('loginStatusWatcher PUT', USER_ACTION.LOGIN.SUCCESS);
      yield put({
        type: USER_ACTION.LOGIN.SUCCESS,
        userId: loginUser.uid
      });
      const userData = yield call(getLoginUserWithRetry, loginUser.uid);
      // const getUserDoc = (id) => firestore.collection(COLLECTION.USER_DATA)
      //   .doc(id)
      //   .get();
      // debug('loginStatusWatcher GET DOC BEFORE', loginUser.uid);
      // const snapshot = yield call(getUserDoc, loginUser.uid);
      //
      // const userData = snapshot.data();
      debug('loginStatusWatcher GET DOC AFTER', userData);

      if (userData) {
        yield put({
          type: USER_ACTION.PROFILE.SUCCESS,
          origin: "LoginStatusWatcher",
          userData
        });
        yield put({
          type: USER_ACTION.USER_DATA_SYNC.START,
          userId: loginUser.uid
        });
      }

      const getIdTokenFunc = () => loginUser.getIdTokenResult();

      const token = yield call(getIdTokenFunc);

      if (token && token.claims && token.claims.role) {
        yield put(navGoHome(token.claims.role));
      }
    } else {
      yield put({
        type: USER_ACTION.LOGOUT.SUCCESS
      });
      yield put({
        type: USER_ACTION.USER_DATA_SYNC.STOP
      });
    }
  }
}

function* userSyncStartSaga(userSyncAction: UserAction) {
  let userSyncChannel;

  try {
    const {
      userId
    } = userSyncAction;
    debug('userSyncStartSaga', USER_ACTION.USER_DATA_SYNC.START, userId);
    const currentUserSyncChannel: EventChannel<ActionType> = yield select((state: State) => state.userReducer.userSyncChannel);
    const currentUserId: string = yield select((state: State) => state.userReducer.userId);

    if (userId === currentUserId && currentUserSyncChannel) {
      yield put({
        type: USER_ACTION.USER_DATA_SYNC.STARTED,
        userSyncChannel: currentUserSyncChannel,
        userId
      });
      return;
    }

    if (!currentUserId && currentUserSyncChannel) {
      if (userSyncChannel) {
        currentUserSyncChannel.close();
      }
    }

    if (userId !== currentUserId) {
      return;
    }
    const ref = firestore.collection(COLLECTION.USER_DATA).doc(userId);
    userSyncChannel = eventChannel(emit => ref.onSnapshot(emit));
    yield put({
      type: USER_ACTION.USER_DATA_SYNC.STARTED,
      userSyncChannel,
      userId
    });

    while (true) {
      const dataRef = yield take(userSyncChannel);
      const userData = dataRef.data();
      yield put({
        type: USER_ACTION.USER_DATA_SYNC.SYNC_EVENT,
        userData
      });
    }
  } catch (err) {
    if (userSyncChannel) {
      yield cancel(userSyncChannel);
    }

    yield put({
      type: USER_ACTION.USER_DATA_SYNC.STOPPED
    });
  }
}

function* userSyncStopSaga() {
  const userSyncChannel = yield select((state: State) => state.userReducer.userSyncChannel);
  userSyncChannel?.close();
  yield put({
    type: USER_ACTION.USER_DATA_SYNC.STOPPED
  });
}

//
// function* retryFunctionCall(functionName: string, init: {method: string, body: string}) {
//   for (let i = 0; i < 5; i += 1) {
//     try {
//       return yield call(rsf.functions.call, functionName, {}, init);
//     } catch (err) {
//
//       if (i < 4) {
//         yield delay(2000);
//       }
//     }
//   }
//   // attempts failed after 5 attempts
//   throw new Error('API request failed after 5 attempts');
// }
function* userStudentCreateSaga(action: UserAction): any {
  try {
    const {
      userProfile
    } = action;

    const coachId = yield select((state: State) => state.huddleReducer.coachId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userStudentCreateResult = yield call(userStudentCreateServerApi, {
      studentProfile: userProfile
    });
    const {
      studentUserData,
      parentUserData
    } = userStudentCreateResult.data;
    if (coachId) {
      debug('webSocketInit userSaga1', studentUserData.userProfile.userId);
      yield put(webSocketInit(studentUserData.userProfile.userId, coachId));
    }
    yield put({
      type: USER_ACTION.STUDENT.CREATE.SUCCESS,
      studentId: studentUserData.userProfile.userId,
      userData: parentUserData
    });
  } catch (error) {
    yield put({
      type: USER_ACTION.STUDENT.CREATE.FAILURE,
      error
    });
  }
}

function* userStudentLoginSaga(action: UserAction): any {
  console.log('userStudentLoginSaga 0');
  try {
    if (!action.studentId) {
      throw new ValidationError('validation-error', action);
    }
    console.log('userStudentLoginSaga 1');
    const {
      studentId,
      coachId
    } = action;
    yield put({
      type: USER_ACTION.USER_DATA_SYNC.START,
      userId: studentId,
      role: ROLE.STUDENT
    });
    console.log('userStudentLoginSaga 2 ', studentId, coachId);
    const getUserDoc = id => firestore.collection(COLLECTION.USER_DATA).doc(id).get();

    const snapshot = yield call(getUserDoc, studentId);
    const userData = snapshot.data();
    if (userData && coachId) {
      debug('webSocketInit user saga 2', studentId);
      yield put(webSocketInit(studentId, coachId));
    }
    debug('userStudentLoginSaga 3 ', userData);
    if (userData) {

      yield put({
        type: USER_ACTION.STUDENT.LOGIN.SUCCESS,
        userData,
        userId: studentId,
        role: ROLE.STUDENT
      });
    } else {
      throw new ValidationError('data missing', action);
    }

    if (coachId) {
      yield put(navGoto(NAV.STUDENT.CLASS_MODE, {
        coachId
      }));
    } else {
      yield put(navGoto(NAV.STUDENT.HOME));
    }
  } catch (error) {
    console.log('userStudentLoginSaga error', error);
    yield put({
      type: USER_ACTION.STUDENT.CREATE.FAILURE,
      error
    });
  }
}

function* userStudentLogoutSaga(): any {
  try {
    debug('webSocketInit logout');
    yield put(webSocketStop());
    yield put({
      type: USER_ACTION.STUDENT.LOGOUT.SUCCESS
    });
  } catch (error) {
    yield put({
      type: USER_ACTION.STUDENT.CREATE.FAILURE,
      error
    });
  }
}

export default function* userSagas(): Generator<any, any, any> {
  debug('userSagasQQQQW Start');
  yield fork(loginStatusWatcher);
  yield all([
    takeEvery(USER_ACTION.PARENT_REGISTER.REQUEST, userParentRegisterSaga),
    takeLatest(USER_ACTION.COACH_REGISTER.REQUEST, userCoachRegisterSaga),
    takeLatest(USER_ACTION.ADMIN_REGISTER.REQUEST, userAdminRegisterSaga),
    takeEvery(USER_ACTION.USER_DATA_SYNC.STOP, userSyncStopSaga),
    takeEvery(USER_ACTION.USER_DATA_SYNC.START, userSyncStartSaga),
    takeEvery(USER_ACTION.LOGOUT.REQUEST, userLogoutSaga),
    takeLatest(USER_ACTION.STUDENT.CREATE.REQUEST, userStudentCreateSaga),
    takeEvery(USER_ACTION.PROFILE.UPDATE, userProfileUpdateSaga),
    takeEvery(USER_ACTION.LOGIN.REQUEST, userLoginSaga),
    takeEvery(USER_ACTION.STUDENT.LOGIN.REQUEST, userStudentLoginSaga),
    takeEvery(USER_ACTION.STUDENT.LOGOUT.REQUEST, userStudentLogoutSaga)]);
}
