/* eslint-disable camelcase */
import {StyleSheet} from "react-native";
import type {TextStyleProp, ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {COLOR} from "../utils/UiConstants";

export const rootStyles: {
  studentRoot: ViewStyleProp;
  coachRoot: ViewStyleProp;
  listRoot: ViewStyleProp;
} = StyleSheet.create({
  studentRoot: {
    paddingTop: 10,
    display: 'flex',
    width: '100%',
    height: '80%',
    maxWidth: 700,
    maxHeight: 600,
    zIndex: 1,
    position: 'relative',
    backgroundColor: COLOR.LT_BLUE,
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  },
  coachRoot: {
    paddingTop: 10,
    display: 'flex',
    maxWidth: 700,
    width: '100%',
    zIndex: 1,
    position: 'relative',
    backgroundColor: COLOR.LT_BLUE,
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  },
  listRoot: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    backgroundColor: COLOR.LT_BLUE,
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  }
});
export const gridStyles: {
  formRowContainer:ViewStyleProp;
  gridColumnCenteredContainer: ViewStyleProp;
  gridColumnContainer: ViewStyleProp;
  gridRowContainer: ViewStyleProp;
  gridRowCenteredContainer: ViewStyleProp;
  item1: ViewStyleProp;
  item2: ViewStyleProp;
  item3: ViewStyleProp;
  item4: ViewStyleProp;
  item5: ViewStyleProp;
  item6: ViewStyleProp;
  item7: ViewStyleProp;
  item8: ViewStyleProp;
  item9: ViewStyleProp;
} = StyleSheet.create({
  gridColumnContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'column',
    flex: 1
  },
  gridColumnCenteredContainer: {
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'space-around',
    flex: 1
  },
  gridRowCenteredContainer: {
    // backgroundColor: COLOR.YELLOW,
    alignSelf:'center',
    flexDirection: 'row',
    flex: 1
  },
  gridRowContainer: {
    //backgroundColor: COLOR.RED,
    flexDirection: 'row',
    flex: 1
  },
  formRowContainer: {
    // backgroundColor: COLOR.YELLOW,
    flexDirection: 'row',
    maxHeight: 60,
    minHeight: 30,
    margin:5,
    flex: 1
  },
  item1: {
    // backgroundColor: COLOR.YELLOW,
    flex: 1
  },
  item2: {
    // backgroundColor: COLOR.YELLOW,
    flex: 2
  },
  item3: {
    // backgroundColor: COLOR.YELLOW,
    flex: 3
  },
  item5: {
    // backgroundColor: COLOR.YELLOW,
    flex: 5
  },
  item4: {
    // backgroundColor: COLOR.YELLOW,
    flex: 4
  },
  item6: {
    // backgroundColor: COLOR.YELLOW,
    flex: 6
  },
  item7: {
    // backgroundColor: COLOR.YELLOW,
    flex: 7
  },
  item9: {
    // backgroundColor: COLOR.YELLOW,
    flex: 9
  },
  item8: {
    // backgroundColor: COLOR.YELLOW,
    flex: 9
  }
});

export const widgetBlockStyles: (widgetWidth: number) => {
  problemBlock: ViewStyleProp;
  problemBlock2: ViewStyleProp;
  problemBlock3: ViewStyleProp;
  problemBlock4: ViewStyleProp;
  problemBlockActive: ViewStyleProp;

} =  (widgetWidth: number) => { return StyleSheet.create({
  problemBlock: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    alignSelf:'center',
    justifyContent:'center',
    width: Math.round(widgetWidth/7),
    height: Math.round(widgetWidth/7),
    backgroundColor: COLOR.WHITE
  },
  problemBlockActive: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    alignSelf:'center',
    justifyContent:'center',
    width: Math.round(widgetWidth/7),
    height: Math.round(widgetWidth/7),
    backgroundColor: COLOR.YELLOW
  },
  problemBlock2: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    alignSelf:'center',
    justifyContent:'center',
    width: Math.round(1.2*widgetWidth/7),
    height: Math.round(widgetWidth/7),
    backgroundColor: COLOR.WHITE
  },
  problemBlock3: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    alignSelf:'center',
    justifyContent:'center',
    width: Math.round(1.4*widgetWidth/7),
    height: Math.round(widgetWidth/7),
    backgroundColor: COLOR.WHITE
  },
  problemBlock4: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    alignSelf:'center',
    justifyContent:'center',
    width: Math.round(1.6*widgetWidth/7),
    height: Math.round(widgetWidth/7),
    backgroundColor: COLOR.WHITE
  },
});};

export const blockStyles: {
  coachProblemBlockRight: ViewStyleProp;
  listBlock: ViewStyleProp;
  coachProblemBlock: ViewStyleProp;
  problemBlockActive: ViewStyleProp;
  problemBlock: ViewStyleProp;
  problemBlock2: ViewStyleProp;
  problemBlock3: ViewStyleProp;
  problemBlock4: ViewStyleProp;
  problemBlockRight: ViewStyleProp;
  problemBlockLeft: ViewStyleProp;
} = StyleSheet.create({
  listBlock: {
    borderRadius: 2,
    borderColor: COLOR.BASE,
    borderWidth: 1,
    width: 20,
    height: 20,
    backgroundColor: COLOR.WHITE
  },
  problemBlockActive: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 90,
    height: 90,
    backgroundColor: COLOR.YELLOW
  },
  problemBlock: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 90,
    height: 90,
    backgroundColor: COLOR.WHITE
  },
  problemBlock2: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 110,
    height: 90,
    backgroundColor: COLOR.WHITE
  },
  problemBlock3: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 130,
    height: 90,
    backgroundColor: COLOR.WHITE
  },
  problemBlock4: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 150,
    height: 90,
    backgroundColor: COLOR.WHITE
  },
  coachProblemBlock: {
    borderRadius: 6,
    borderColor: COLOR.BASE,
    borderWidth: 1,
    width: 30,
    height: 30,
    backgroundColor: COLOR.WHITE
  },
  problemBlockLeft: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 90,
    height: 90,
    backgroundColor: COLOR.WHITE,
    alignSelf: 'flex-start'
  },
  coachProblemBlockRight: {
    borderRadius: 6,
    borderColor: COLOR.BASE,
    borderWidth: 1,
    width: 30,
    height: 30,
    backgroundColor: COLOR.WHITE,
    alignSelf: 'flex-end'
  },
  problemBlockRight: {
    borderRadius: 10,
    borderColor: COLOR.BASE,
    borderWidth: 3,
    width: 90,
    height: 90,
    backgroundColor: COLOR.WHITE,
    alignSelf: 'flex-end'
  }
});
export const widgetTextStyles: (widgetWidth: number) => {
  problemText: TextStyleProp;
} =  (widgetWidth: number) => { return StyleSheet.create({
  problemText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: Math.round(widgetWidth/14),
    maxWidth:widgetWidth,
    textAlign: 'center',
    alignSelf:'center',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:  Math.round(widgetWidth/100),
    paddingRight:  Math.round(widgetWidth/100)
  },
});};

export const textStyles:{
  textInput: TextStyleProp; smallText: TextStyleProp;
  labelText: TextStyleProp;
  formText: TextStyleProp;
  titleBar: TextStyleProp;
  coachProblemText: TextStyleProp;
  responseTextCorrect: TextStyleProp;
  responseTextIncorrect: TextStyleProp;
  problemText: TextStyleProp;
  coachProblemTextLeft: TextStyleProp;
  coachProblemTextRight: TextStyleProp;
  problemTextLeft: TextStyleProp;
  problemTextRight: TextStyleProp;
  listText: TextStyleProp;
} = StyleSheet.create({
  problemText:  {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'center',
    padding: 10
  },
  textInput: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 12,
    textAlign: 'left',
    padding: 2,
    backgroundColor:'white'
  },
  labelText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 20,
    textAlign: 'left',
    padding: 5,

  },
  smallText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 10,
    textAlign: 'left',
    padding: 1,

  },
  formText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 20,
    textAlign: 'left',
    padding: 5,
    backgroundColor:'white'
  },
  coachProblemText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 14,
    textAlign: 'center',
    padding: 5
  },
  responseTextCorrect: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'center',
    padding: 10,
    color: COLOR.GREEN
  },
  responseTextIncorrect: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'center',
    padding: 10,
    color: COLOR.RED
  },
  coachProblemTextLeft: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 14,
    textAlign: 'left',
    padding: 5
  },
  coachProblemTextRight: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 20,
    textAlign: 'right',
    padding: 5
  },
  problemTextLeft: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'left',
    padding: 10
  },
  problemTextRight: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    textAlign: 'right',
    padding: 10
  },
  listText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 12,
    textAlign: 'center',
    padding: 2
  },
  titleBar: {
    flex: 1,
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 50,
    alignSelf: 'center',
    maxHeight:60
  }
});
