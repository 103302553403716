import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import type {Problem} from "../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {ProgressionViewPropsType} from "./widgetStructs";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
const styles = StyleSheet.create({
  problemList: {
    flex: 1,
    marginTop: 30,
    width: 200
  }
});
export default function AdditionProgressionView(props: ProgressionViewPropsType) : JSX.Element{
  const {
    curriculumStudentProgression
  } = props;
  const {
    problemList
  } = curriculumStudentProgression;

  if (!problemList) {
    return <Text key="simple">Empty Progression Set</Text>;
  }

  debug('problemList', problemList);
  const problemViews = problemList.map((problem: Problem, index: number) => {
    const {
      a,
      b
    } = problem.params.prompts;
    debug('problem', JSON.stringify(problem, null, 2));
    const {
      response
    } = problem.params.expectedResponseKeyValues.set || {};
    return <Text key={'k' + index.toString()}>{a + '+' + b + '=' + response}</Text>;
  });
  return (<View testID="AdditionProgressionView" style={styles.problemList}>
    {problemViews}
  </View>);
}
