import invariant from "invariant";
import * as React from "react";
import {useRef} from "react";
import {Image, SafeAreaView, ScrollView, StyleSheet, Text, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import CoachWidgetContainer from "../../curriculum/CoachWidgetContainer";
import WidgetInventory from "../../widgets/WidgetInventory";
import type {State} from "../../redux/reducer";
import type {ChatItem} from "../../sharedDefs/chatDefs";
import {CHAT} from "../../sharedDefs/chatDefs";
import type {CoachId, UserId} from "../../sharedDefs/userDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {COLOR} from "../../utils/UiConstants";
import {webSocketInit} from "../../redux/reducer/wsReducer";
import {getChatInitializeActionForStudent} from "../../redux/queries/ChatQueries";

const debug = debugModule(DEBUG_MODULE.CHAT.UI);
const styles = StyleSheet.create({
  root: {
    maxHeight: 300,
    flex: 1,
    padding: 10,
    // borderRadius: 10,
    // borderColor: COLOR.BASE,
    // borderWidth: 3,
    width: '100%'
  },
  scrollView: {
    flex: 1,
    flexDirection: 'column'
  },
  studentBubble: {
    marginTop: 2,
    marginLeft: 5,
    borderBottomLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: COLOR.LT_BLUE,
    backgroundColor: COLOR.LT_BLUE,
    borderWidth: 1,
    alignSelf: 'flex-start'
  },
  coachBubble: {
    marginTop: 2,
    marginRight: 5,
    borderBottomLeftRadius: 5,
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: COLOR.GREY,
    backgroundColor: COLOR.GREY,
    borderWidth: 1,
    alignSelf: 'flex-end'
  },
  assignProgression: {
    width: 250,
    marginRight: 5,
    marginTop: 6,
    borderRadius: 5,
    borderColor: COLOR.GREY,
    borderWidth: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row'
  },
  completeProgression: {
    marginRight: 5,
    borderRadius: 5,
    borderColor: COLOR.GREY,
    borderWidth: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row',
    shadowColor: 'black',
    shadowOffset: {
      width: 5,
      height: -5
    },
    shadowOpacity: 0.3,
    shadowRadius: 8
  },
  studentSpeech: {
    alignSelf: 'flex-start',
    flex: 1
  },
  coachSpeech: {
    alignSelf: 'flex-end',
    flex: 1
  }
});
export type CoachChatViewPropType = {
  studentId: UserId;
  coachId: CoachId
};
export default function CoachChatView(props: CoachChatViewPropType): JSX.Element {
  const {
    studentId,
    coachId
  } = props;
  const scrollViewRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  const userId = useSelector((state: State) => state.userReducer.userId);

  React.useEffect(() => {
    debug('Init3', userId, coachId);
    console.log('getChatInitializeActionForStudent');
    dispatch(webSocketInit(userId, coachId));
    getChatInitializeActionForStudent(studentId)
      .then( chatAction => {
        console.log('getChatInitializeActionForStudent chatAction',chatAction);
        dispatch(chatAction);
      });

  }, []);
  const chatItems = useSelector((state: State) => state.chatReducer.chatMap[studentId]);
  const rows = chatItems && chatItems.filter((chatItem: ChatItem, index: number, items) => {
    if (chatItem.curriculumStudentInteraction && index + 1 < items.length) {
      const nextItem = items[index + 1];

      if (nextItem.curriculumStudentInteraction && chatItem.curriculumStudentInteraction?.problem?.problemId === nextItem?.curriculumStudentInteraction?.problem?.problemId) {
        return false;
      }
    }

    return true;
  }).map((chatItem: ChatItem, index: number) => {
    switch (chatItem.chatItemEvent) {
      case CHAT.EVENT.TEXT:
      {
        if (chatItem.chatUserId === chatItem.studentId) {
          return <View style={styles.studentBubble} key={index.toString()}>
            <Text style={styles.studentSpeech}>
              {' '}
              {chatItem.text}
            </Text>
          </View>;
        }

        return <View style={styles.coachBubble} key={index.toString()}>
          <Text style={styles.coachSpeech}>
            {' '}
            {chatItem.text}
          </Text>
        </View>;
      }

      case CHAT.EVENT.INTERACTION:
      {
        const curriculumStudentInteraction = chatItem.curriculumStudentInteraction;
        const curriculumStudentResponse = chatItem.curriculumStudentResponse;
        invariant(curriculumStudentInteraction, 'chat-struct');
        const {
          problem
        } = curriculumStudentInteraction;
        const {
          widgetSetId
        } = problem || {};
        const widgetSet = WidgetInventory(widgetSetId);
        return <CoachWidgetContainer curriculumStudentInteraction={curriculumStudentInteraction} curriculumStudentResponse={curriculumStudentResponse} key={index.toString()}>
          {React.createElement(widgetSet.coach, {
            curriculumStudentInteraction,
            curriculumStudentResponse
          })}
        </CoachWidgetContainer>;
      }
      case CHAT.EVENT.RESPONSE:
      {
        const curriculumStudentInteraction = chatItem.curriculumStudentInteraction;
        const curriculumStudentResponse = chatItem.curriculumStudentResponse;
        invariant(curriculumStudentInteraction, 'chat-struct');
        const {
          problem
        } = curriculumStudentInteraction;
        const {
          widgetSetId
        } = problem || {};
        const widgetSet = WidgetInventory(widgetSetId);
        return <CoachWidgetContainer curriculumStudentInteraction={curriculumStudentInteraction} curriculumStudentResponse={curriculumStudentResponse} key={index.toString()}>
          {React.createElement(widgetSet.coach, {
            curriculumStudentInteraction,
            curriculumStudentResponse
          })}
        </CoachWidgetContainer>;
      }
      case CHAT.EVENT.ASSIGN_PROGRESSION:
      {
        return <View style={{
          flexDirection: 'row',
          flex: 1,
          width: '100%',
          justifyContent: 'center'
        }}>
          <Image style={{
            marginTop: 5,
            height: 20,
            resizeMode: 'center',
            flexGrow: 1,
            alignContent: 'stretch',
            flex: 10
          }} source={require('../../assets/icons/horizontalRedLine.png')} />
          <View style={styles.assignProgression} key={index.toString()}>
            <Image style={{
              height: 20,
              width: 20,
              resizeMode: 'center',
              flex: 0.1
            }} source={require('../../assets/icons/play_circle-24px 1.png')} />
            <Text style={styles.coachSpeech}>
              {' '}
                  Start -
              {chatItem.curriculumStudentProgression?.progressionSet?.progressionSetName || chatItem.curriculumStudentProgression?.assessment?.assessmentName}
            </Text>
          </View>
          <Image style={{
            marginTop: 5,
            height: 20,
            resizeMode: 'center',
            alignContent: 'stretch',
            flexGrow: 1,
            flex: 10
          }} source={require('../../assets/icons/horizontalRedLine.png')} />
        </View>;
      }

      case CHAT.EVENT.COMPLETE_PROGRESSION:
      {
        return <View style={{
          flexDirection: 'row',
          width: '100%'
        }}>
          <Image style={{
            height: 20,
            resizeMode: 'center',
            flex: 0.1
          }} source={require('../../assets/icons/horizontalRedLine.png')} />
          <View style={styles.completeProgression} key={index.toString()}>
            <Image style={{
              height: 20,
              width: 20,
              resizeMode: 'center',
              flex: 0.1
            }} source={require('../../assets/icons/play_circle-24px 1.png')} />
            <Text style={styles.coachSpeech}> Complete Progression</Text>
          </View>
          <Image style={{
            height: 20,
            resizeMode: 'center',
            flex: 0.1
          }} source={require('../../assets/icons/horizontalRedLine.png')} />
        </View>;
      }

      case CHAT.EVENT.HEY_COACH:
      {
        return <View style={styles.completeProgression} key={index.toString()}>
          <Text style={styles.coachSpeech}> Hey Coach </Text>
          <Image style={{
            height: 20,
            width: 20,
            resizeMode: 'center',
            zIndex: 400
          }} source={require('../../assets/icons/heyCoach.png')} />

        </View>;
      }

      default:
      {
        debug('chat Default ', chatItem);
        return <View style={styles.coachBubble} key={index.toString()}>
          <Text style={styles.coachSpeech}>
            {' '}
                DEFAULT
            {chatItem.chatItemEvent}
          </Text>
        </View>;
      }
    }
  });

  return <SafeAreaView testID="CoachChatView" style={styles.root}>
    <ScrollView style={styles.scrollView} ref={scrollViewRef} onContentSizeChange={() => {
      console.log('onContentSizeChange');
      scrollViewRef?.current?.scrollToEnd({
        animated: true
      });}}>
      {
        /* <Text style={{color:'#000'}} key={"emoji"}>{  }</Text> */
      }
      {rows}
    </ScrollView>
  </SafeAreaView>;
}
