import * as React from "react";
import {Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import NumberKeypad from "./NumberKeypad";
import {gridStyles, rootStyles, textStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import AnswerBlock from "./AnswerBlock";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
export function AdditionWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  // problemValidate(problemValues, valueTypeSet);
  // const userId = useSelector((state: State) => state.userReducer.userId);
  const {
    sum
  } = curriculumStudentResponse?.responseKeyValues?.set || {};
  return <View testID="AdditionWidgetCoach" style={rootStyles.coachRoot}>
    <View style={gridStyles.gridRowContainer}>
      <Text style={textStyles.coachProblemTextLeft}>
        {curriculumStudentInteraction?.problem?.params?.prompts?.a}
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>
        +
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>{curriculumStudentInteraction?.problem?.params?.prompts?.b}</Text>
      <Text style={textStyles.coachProblemTextLeft}>=</Text>
      <Text style={textStyles.coachProblemTextLeft}>{sum}</Text>

    </View>
  </View>;
}
export function AdditionWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    onResponse,
    inWorkbench
  } = props;

  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params.prompts || {};

  const {
    sum
  } = curriculumStudentResponse?.responseKeyValues?.set || {};

  const [widgetWidth, setWidgetWidth] = React.useState(0);
  const [widgetHeight, setWidgetHeight] = React.useState(0);

  const onKeyPress = React.useCallback((_answer: string) => {

    debug('onKeyPress', _answer, sum, JSON.stringify(curriculumStudentResponse.responseKeyValues));
    let newSum = _answer;

    if (sum && parseInt(sum,10) <10) {
      newSum = sum + _answer;
      debug('onKeyPress append '+sum , _answer,'=',newSum);
    }

    if (parseInt(newSum, 10) >= 100) {
      newSum = '';
    }
    debug('onKeyPress newProduct', newSum);
    onResponse('sum', newSum);
  },[curriculumStudentResponse, curriculumStudentInteraction]);


  return <View key="1"  testID="AdditionWidgetStudent" style={[rootStyles.studentRoot]} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    <View style={gridStyles.gridColumnCenteredContainer}>
      <View  style={gridStyles.gridRowContainer}>
        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {a}
          {' '}
          +
          {b}
          {' '}
          =
        </Text>

        <AnswerBlock  widgetWidth={widgetWidth} actual={sum} expected={problem?.params?.expectedResponseKeyValues?.set?.sum} ></AnswerBlock>

      </View>
      <View  style={gridStyles.gridRowContainer}>
        <NumberKeypad  widgetWidth={widgetWidth} inWorkbench={inWorkbench} onKeyPress={onKeyPress} />
      </View>
    </View>
  </View>;
}
const defaultProgressionSetId = 'Math.K.OA.A.2.Add1Within10.1';
const AdditionWidget: WidgetSet = {
  widgetSetId: 'AdditionWidget',
  coach: AdditionWidgetCoach,
  student: AdditionWidgetStudent,
  defaultProgressionSetId,

};
export default AdditionWidget;
