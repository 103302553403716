
import React from "react";

import {Image, View} from "react-native";
import type { ConfidenceType } from "../sharedDefs/curriculumDefs";
import { CURRICULUM } from "../sharedDefs/curriculumDefs";


const confidenceLottieSrc = (confidence?: ConfidenceType): any => {
  switch (confidence) {
    case CURRICULUM.CONFIDENCE.VERY_HIGH:
      return require('../assets/icons/confidenceVeryHigh.png');

    case CURRICULUM.CONFIDENCE.HIGH:
      return require('../assets/icons/confidenceHigh.png');

    case CURRICULUM.CONFIDENCE.MEDIUM:
      return require('../assets/icons/confidenceMedium.png');

    case CURRICULUM.CONFIDENCE.LOW:
      return require('../assets/icons/confidenceLow.png');

    case CURRICULUM.CONFIDENCE.VERY_LOW:
      return require('../assets/icons/confidenceVeryLow.png');

    default:
      return require('../assets/icons/confidenceMedium.png');
  }
};

export type LottieProps = {
  confidenceConstant:string
}
export default function Lottie(props: LottieProps): JSX.Element {

  return <View ><Image style={{height:45, width:45}} source={confidenceLottieSrc(props.confidenceConstant)}/></View>;
}
