export const NAV_ACTION = {
  GOTO: 'NAV_ACTION.GOTO',
  SUCCESS: 'NAV_ACTION.SUCCESS',
  SKIPPED: 'NAV_ACTION.SKIPPED',
  GOHOME: 'NAV_ACTION.GOHOME',
  OFF: 'NAV_ACTION.OFF',
  ON: 'NAV_ACTION.ON'
};
export const DELAY = {
  REQUEST: 'DELAY.REQUEST',
  DONE: 'DELAY.DONE'
};
export type NavigationAction = {
  type: string;
  screenName?: string;
  screenParams?: Record<string,any>;
  role?: string;
  delayTime?: number;
};
export const NAV = {
  COACH: {
    HOME: 'CoachHome',
    CLASS_MODE: 'CoachClassMode',
    APPLICATION: 'CoachApplication'
  },
  AUTH: {
    HOME: 'Home',
    LOGIN: 'Login',
    REGISTER_PARENT: 'RegisterParent',
    REGISTER_COACH: 'RegisterCoach',
    RESET_PASSWORD: 'ResetPassword',
    MAINTENANCE: 'Maintenance',
    TESTPAGE: 'TestPage'
  },
  PARENT: {
    HOME: 'ParentHome',
    APPLICATION: 'ParentApplication',
    CHILD_FORM: 'ParentChildForm',

  },
  STUDENT: {
    HOME: 'StudentHome',
    CLASS_MODE: 'StudentClassMode'
  },
  ADMIN: {
    CURRICULUM_WORKBENCH: 'CurriculumWorkbench',
    ASSESSMENT_WORKBENCH: 'AssessmentWorkbench',
    COACH_DETAIL: 'AdminCoachDetail',
    COACH_LIST: 'AdminCoachList',
    HOME: 'AdminHome'
  }
};
