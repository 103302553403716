import type { NavigationAction } from "../structs/navigationStruct";
import { DELAY, NAV_ACTION } from "../structs/navigationStruct";
import { addReducerLogging, loggerCopy } from "./common";
import { DEBUG_MODULE, debugModule } from "../../utils/debugModule";
import {ActionType} from "../../sharedDefs/commonStruct";
const debug = debugModule(DEBUG_MODULE.NAVIGATION.REDUCER);
export const delayRequest = (delayTime?: number): NavigationAction => ({
  type: DELAY.REQUEST,
  delayTime: delayTime || 1000
});
export const navGoto = (screenName: string, screenParams?: Record<string, string>): NavigationAction => ({
  type: NAV_ACTION.GOTO,
  screenName,
  screenParams
});
export const navOn = (): NavigationAction => ({
  type: NAV_ACTION.ON
});
export const navOff = (): NavigationAction => ({
  type: NAV_ACTION.OFF
});
export const navGoHome = (role: string): NavigationAction => ({
  type: NAV_ACTION.GOHOME,
  role
});
export type NavigationState = {
  isOff: boolean;

};
const initialState: NavigationState = {
  isOff: false,

};

function _navigationReducer(state: NavigationState = initialState, action: ActionType = {type:''}): NavigationState {
  switch (action.type) {
    case NAV_ACTION.ON:
      return { ...state,
        isOff: false
      };

    case NAV_ACTION.OFF:
      debug('NAV OFF');
      return { ...state,
        isOff: true
      };

    default:
      return state;
  }
}

function actionForLogger(action: NavigationAction) {
  return loggerCopy(action);
}

export default function navigationReducer(state: NavigationState = initialState, action: NavigationAction = {type:''}): NavigationState {
  const prefix = 'NAV_ACTION.';

  if (!action.type.startsWith(prefix)) {
    return state;
  }

  debug('NAV ACTION', action);

  const newState = _navigationReducer(state, action);

  return addReducerLogging(debug, state, newState, actionForLogger(action));
}
