import * as React from "react";
import {StyleSheet, Text, View} from "react-native";
import {Button, Surface} from "react-native-paper";
// import logo from 'ba-images/logo.svg';
import {useDispatch, useSelector} from "react-redux";
import CplTextField from "../../components/FormControls/CplTextField";
import {navigateTo} from "../../NavigationAction";
import type {State} from "../../redux/reducer";
import {navGoHome} from "../../redux/reducer/navigationReducer";
import {userLoginRequest} from "../../redux/reducer/userReducer";
import {NAV} from "../../redux/structs/navigationStruct";
// import styles from './user-jss';
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";

const debug = debugModule(DEBUG_MODULE.USER.AUTH);
type LoginProps = Record<string, never>;
const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: 300,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  surfaceContainer: {
    margin: 0,
    flex: 1,
    flexDirection: 'column',

    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 300,
  },
  surface: {
    flex: 1,
    width: 300,
    minWidth: 300,
    paddingTop: 10,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    height:300,
    maxHeight:300
  },
  input: {},
  btnArea: {},
  footer: {}
});
const defaultValues = REACT_APP_PREFILL ? {
  email: REACT_APP_PREFILL + '@gmail.com',
  password: '12345678'
} : {email:'', password: ''};
export default function Login(props: LoginProps): JSX.Element {
  const dispatch = useDispatch();

  const loggedIn = useSelector((state: State) => state.userReducer.loggedIn);

  const role = useSelector((state: State) => state.userReducer.role);
  const [formObject, setFormObject] = React.useState(defaultValues);
  const onChange = React.useCallback((field: string, value: any) => {
    const f = {};
    f[field] = value;
    const newFormObject: any = {
      ...formObject,
      ...f
    };
    setFormObject(newFormObject);
  }, [formObject]);
  const submitLoginForm = React.useCallback(() => {
    debug('Login', formObject);
    dispatch(userLoginRequest(formObject.email, formObject.password));
  }, [formObject]);
  React.useEffect(() => {
    if (loggedIn && role) {
      dispatch(navGoHome(role));
    }
  }, [loggedIn, role]);
  const errors = {};
  return <View style={styles.root}>

    <View style={styles.root}>
      <View style={styles.surfaceContainer}>
        <Surface style={styles.surface}>
          <View>

            <CplTextField textContentType={'emailAddress'} label="Email" errors={errors} name="email" onChange={onChange} formObject={formObject}/>

            <CplTextField textContentType={'password'} label="Password" errors={errors} name="password" onChange={onChange}
              formObject={formObject}/>
          </View>
          <View style={styles.btnArea}>
            <Button testID="submit_login" mode="contained" onPress={() => submitLoginForm()}>
              Continue
              {
                /* <ArrowForward style={classNames(styles.rightIcon, styles.iconSmall)} disabled={submitting || pristine} /> */
              }
            </Button>
          </View>

          <View style={styles.footer}>
            <Text>Cannot Login?</Text>
            <Button mode="contained" onPress={() => {
              navigateTo(NAV.AUTH.REGISTER_PARENT);
            }}>
              Start your child learning
            </Button>

          </View>

        </Surface>
      </View>
    </View>
  </View>;
}
