import {Button, Text, TextInput} from 'react-native-paper';
import {View} from "react-native";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import * as React from "react";
import {MultipleChoiceListParams, MultipleChoiceProblemParams} from "../sharedDefs/ProgressionSetUtil";

type WorkbenchGeneratorProps = {
  problemIndex: number;
  problemGenerationParams: MultipleChoiceListParams;
  onGeneratorChange: ( problemGenerationParams: MultipleChoiceListParams, _problemIndex?: number) => void;
}
export default function MultipleChoiceWorkbench(props: WorkbenchGeneratorProps): JSX.Element {
  const {onGeneratorChange, problemGenerationParams, problemIndex} = props;

  const generationProblemParams: MultipleChoiceProblemParams = problemGenerationParams?.problemParams?.length >problemIndex? problemGenerationParams.problemParams[problemIndex]:null;

  console.log('\n\n\n QQQ generationProblemParams',problemIndex, generationProblemParams);

  const changeProblemGeneration = (_generationProblemParams: MultipleChoiceProblemParams) => {
    console.log('\nchangeProblemGeneration ',_generationProblemParams, problemIndex);
    const _problemGenerationParams: MultipleChoiceListParams = {...problemGenerationParams};
    _problemGenerationParams.problemParams[problemIndex] = _generationProblemParams;

    const _generatorString = ` return multipleChoiceGeneration(${JSON.stringify(_problemGenerationParams)})`;
    console.log('_generatorString',problemIndex, _generatorString,_problemGenerationParams);
    onGeneratorChange( _problemGenerationParams, problemIndex);
  };


  const createNewProblem = () => {
    const _problemGenerationParams = {...problemGenerationParams};

    _problemGenerationParams.problemParams.push({
      question: null,
      questionImage: null,
      correct: null,
      correctImage: null,
      alternativeStrings: new Array(3).fill(null),
      alternativeImages: new Array(3).fill(null),
    });
    console.log(' _problemGenerationParams.problemParams.length-1', _problemGenerationParams.problemParams.length-1);

    onGeneratorChange(_problemGenerationParams, _problemGenerationParams.problemParams.length-1);
  };
  React.useEffect(() => {
    console.log('MULTIPLE CHOICE INITIALIZE', problemGenerationParams);
    if (!problemGenerationParams || Object.keys(problemGenerationParams).length != 3 || problemGenerationParams.problemParams === undefined || !problemIndex ) {
      console.log('Object.keys(problemGenerationParams).length', problemGenerationParams);
      const params: MultipleChoiceListParams = {
        problemParams: [{
          question: null,
          questionImage: null,
          correct: null,
          correctImage: null,
          alternativeStrings: new Array(3).fill(null),
          alternativeImages: new Array(3).fill(null),
        }]

      };

      console.log('      onGeneratorChange(params, 0) ', params);
      onGeneratorChange(params, 0);
    }
  }, []);

  const imageChange = (e, propName: string, index?: number) => {
    console.log('imageChange');
    const files = Array.from(e.target.files) as Array<File>;
    const file: File = files[0];
    const reader = new FileReader();

    reader.onload = function (readerEvent) {
      const image = new Image();
      image.onload = function (imageEvent) {

        const canvas = document.createElement('canvas');
        const max_size = 512;
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > max_size) {
            height *= max_size / width;
            width = max_size;
          }
        } else {
          if (height > max_size) {
            width *= max_size / height;
            height = max_size;
          }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg');


        const _problemGenerationParams = {...problemGenerationParams};

        console.log('\n\n\n\n\n\nimageChange reader.result', propName, dataUrl.length, (reader.result as string).length);
        if (index === undefined) {
          const _generationProblemParams = {...generationProblemParams};

          _generationProblemParams[propName] = dataUrl;
          changeProblemGeneration(_generationProblemParams);
        } else {
          const _generationProblemParams = {...generationProblemParams};
          const imageArray = _generationProblemParams[propName];
          imageArray[index] = dataUrl;
          _generationProblemParams[propName] = imageArray;
          changeProblemGeneration(_generationProblemParams);
        }
      };
      image.src = readerEvent.target.result as string;

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      const _generationProblemParams = {...generationProblemParams};
      _generationProblemParams[propName] = null;
      changeProblemGeneration(_generationProblemParams);
    };
    reader.readAsDataURL(file);
  };

  return (
    <View style={gridStyles.gridColumnContainer}>
      <Text style={textStyles.titleBar}>MultipleChoice Web</Text>

      <View style={gridStyles.formRowContainer}>
        <Button icon="plus-box" mode="contained" onPress={() => {
          createNewProblem();
        }}>
          Create New
        </Button>
      </View>
      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="question"
          value={generationProblemParams?.question || ''}
          onChangeText={question => {
            try {
              if (question.length === 0) {
                changeProblemGeneration({...generationProblemParams, question: null});
              } else {
                changeProblemGeneration({...generationProblemParams, question});
              }

            } catch (e) {
              console.error('Use Number');
              changeProblemGeneration({...generationProblemParams, question: null});
            }
          }}
        />
        <input type="file" id="multi" onChange={(e) => {
          return imageChange(e, 'questionImage');
        }} multiple/>

      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="correct" value={generationProblemParams?.correct || ''}
          onChangeText={correct => {
            console.log('correct ',correct,generationProblemParams);
            try {
              if (correct.length === 0) {
                changeProblemGeneration({...generationProblemParams, correct: null});
              } else {
                changeProblemGeneration({...generationProblemParams, correct});
              }
            } catch (e) {
              changeProblemGeneration({...generationProblemParams, correct: null});
            }
          }}
        />
        <input type="file" id="multi" onChange={(e) => {
          return imageChange(e, 'correctImage');
        }} multiple/>

      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="Alt0" value={generationProblemParams?.alternativeStrings?.[0] || ''}
          onChangeText={s => {
            const index = 0;
            try {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];

              if (s.length === 0) {
                alternativeStrings[index] = null;
              } else {
                alternativeStrings[index] = s;
              }
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            } catch (e) {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];
              alternativeStrings[index] = null;
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            }
          }}
        />
        <input type="file" id="multi" onChange={(e) => {
          return imageChange(e, 'alternativeImages', 0);
        }} multiple/>

      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="Alt1" value={generationProblemParams?.alternativeStrings?.[1] || ''}
          onChangeText={s => {
            const index = 1;
            try {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];

              if (s.length === 0) {
                alternativeStrings[index] = null;
              } else {
                alternativeStrings[index] = s;
              }
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            } catch (e) {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];
              alternativeStrings[index] = null;
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            }
          }}
        />
        <input type="file" id="multi" onChange={(e) => {
          return imageChange(e, 'alternativeImages', 1);
        }} multiple/>

      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="Alt2" value={generationProblemParams?.alternativeStrings?.[2] || ''}
          onChangeText={s => {
            const index = 2;
            try {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];

              if (s.length === 0) {
                alternativeStrings[index] = null;
              } else {
                alternativeStrings[index] = s;
              }
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            } catch (e) {
              const alternativeStrings = [...generationProblemParams?.alternativeStrings];
              alternativeStrings[index] = null;
              changeProblemGeneration({...generationProblemParams, alternativeStrings});
            }
          }}
        />
        <input type="file" id="multi" onChange={(e) => {
          return imageChange(e, 'alternativeImages', 2);
        }} multiple/>

      </View>


      {/*<Text style={textStyles.formText}>{generatorString}</Text>*/}

    </View>
  );
}
