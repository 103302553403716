import * as React from "react";
import {SafeAreaView, StyleSheet, Text, View} from "react-native";
import {Avatar, Button, Surface, TextInput} from "react-native-paper";
import {useDispatch} from "react-redux";
import {coachRegisterRequest, CoachRegistrationObject} from "../../redux/reducer/userReducer";
import {PROFILE} from "../../sharedDefs/userDefs";
// import styles from './user-jss';
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {REACT_APP_PREFILL} from "../../utils/environment";
import {gridStyles, textStyles} from "../../widgets/WidgetStyles";


import type {TextStyleProp, ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {COLOR} from "../../utils/UiConstants";
import DropDown from "../../components/FormControls/DropDown";
import RegistrationProgress, {RegistrationProgressButtons} from "./RegistrationProgress";
import Dropzone from "react-dropzone";
import {validEmail, validPassword} from "../../utils/validation";

const debug = debugModule(DEBUG_MODULE.USER.AUTH);


const styles = StyleSheet.create({
  root: {
    width: '100%'
  },

});
const defaultValues: CoachRegistrationObject = REACT_APP_PREFILL ? {
  email: REACT_APP_PREFILL + '@gmail.com',
  password: '12345678',
  passwordConfirm: '12345678',
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  coachEssay: '',
  occupation: '',
  zipcode: '',
  firstName: '',
  lastName: ''
} : {
  email: '',
  password: '',
  passwordConfirm: '',
  birthDay: null,
  birthMonth: null,
  birthYear: null,
  occupation: '',
  coachEssay: '',
  zipcode: '',
  firstName: '',
  lastName: ''
};


export default function RegisterCoach(): JSX.Element {
  const dispatch = useDispatch();
  const [birthDay, setBirthDay] = React.useState<string>(null);
  const [birthMonth, setBirthMonth] = React.useState<string>(null);
  const [birthYear, setBirthYear] = React.useState<string>(null);
  const [showDayDropDown, setShowDayDropDown] = React.useState<boolean>(false);
  const [showMonthDropDown, setShowMonthDropDown] = React.useState<boolean>(false);
  const [showYearDropDown, setShowYearDropDown] = React.useState<boolean>(false);
  const [rootWidth, setRootWidth] = React.useState<number>(0);


  const [imageSrc, setImageSrc] = React.useState(null);
  const [page, setPage] = React.useState<number>(0);
  const [coachRegistrationObject, setCoachRegistrationObject] = React.useState<CoachRegistrationObject>(defaultValues);


  const pageWidth = Math.min(800, rootWidth);
  const sentenceWidth = Math.min(600, Math.round(3 * rootWidth / 4));
  const registrationStyles: (widgetWidth: number) => {
    page: ViewStyleProp;
    buttonWord: ViewStyleProp;
    buttonSentence: ViewStyleProp;
    textInputWord: ViewStyleProp;
    textInputSentence: ViewStyleProp;
    buttonLabelSentence: TextStyleProp;
    buttonLabel: TextStyleProp;
  } = (rootWidth: number) => {
    return StyleSheet.create({
      page: {
        backgroundColor: COLOR.PAPER.grey50,
        flex: 1,
        maxWidth: pageWidth,
        minWidth: pageWidth,
        alignSelf: 'center'
      },
      buttonWord: {

        maxWidth: Math.max(200, Math.min(300, Math.round(rootWidth / 4))),
        minWidth: Math.max(200, Math.min(300, Math.round(rootWidth / 4))),
        margin: 5,
        alignSelf: 'center'
      },
      buttonLabelSentence: {
        fontFamily: 'ArialRoundedMTBold',
        fontSize: Math.round(Math.min(600, Math.round(3 * rootWidth / 4)) / 25),
        margin: 5,
        textAlign: 'center',
      },
      buttonLabel: {
        fontSize: Math.round(Math.min(600, Math.round(3 * rootWidth / 4)) / 25),
      },
      buttonSentence: {
        maxWidth: sentenceWidth,
        minWidth: sentenceWidth,

        margin: 5,
        alignSelf: 'center'
      },
      textInputWord: {
        maxWidth: Math.max(300, Math.min(400, Math.round(rootWidth / 2))),
        minWidth: Math.max(300, Math.min(400, Math.round(rootWidth / 2))),
        margin: 5,
        alignSelf: 'center'
      },
      textInputSentence: {
        maxWidth: sentenceWidth,
        minWidth: sentenceWidth,
        margin: 5,
        alignSelf: 'center'
      },
    });
  };

  const onChange = (field: string, value: any, _page?: number) => {
    const newFormObject = {
      ...coachRegistrationObject,
    };
    newFormObject[field] = value;
    console.log('newFormObject-1', field, value);
    setCoachRegistrationObject(newFormObject);
    if (typeof _page !== 'undefined') {
      setPage(_page);
    }
    console.log('NEW formObject', newFormObject, coachRegistrationObject);
  };


  function submitForm() {
    debug('Register ', coachRegistrationObject);
    coachRegistrationObject.birthYear = birthYear;
    coachRegistrationObject.birthMonth = birthMonth;
    coachRegistrationObject.birthDay = birthDay;
    if (coachRegistrationObject.email && coachRegistrationObject.password) {
      dispatch(coachRegisterRequest(coachRegistrationObject));
    }
  }

  const steps = 4;


  return <Surface style={styles.root} testID="Register" onLayout={(event) => {
    setRootWidth(event?.nativeEvent?.layout?.width);

  }}>
    {page == 0 &&
    <View style={registrationStyles(rootWidth).page}>
      <RegistrationProgress page={page} steps={steps}/>


      <Text style={textStyles.labelText}>What best describes your current status?</Text>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonLabelSentence}
        mode="outlined"
        onPress={() => onChange('occupation', PROFILE.OCCUPATION.HIGHSCHOOL, 1)}>{PROFILE.OCCUPATION_LABEL.HIGHSCHOOL}</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonLabelSentence}
        mode="outlined"
        onPress={() => onChange('occupation', PROFILE.OCCUPATION.COLLEGE, 1)}>{PROFILE.OCCUPATION_LABEL.COLLEGE}</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonLabelSentence}
        mode="outlined"
        onPress={() => onChange('occupation', PROFILE.OCCUPATION.TEACHER, 1)}>{PROFILE.OCCUPATION_LABEL.TEACHER}</Button>
      <Button labelStyle={registrationStyles(rootWidth).buttonLabel}
        style={registrationStyles(rootWidth).buttonLabelSentence}
        mode="outlined"
        onPress={() => onChange('occupation', PROFILE.OCCUPATION.OTHER, 1)}>{PROFILE.OCCUPATION_LABEL.OTHER}</Button>

    </View>}
    {
      page == 1 &&
      <View style={registrationStyles(rootWidth).page}>
        <RegistrationProgress page={page} steps={steps}/>

        <Text style={textStyles.labelText}>Personal Information</Text>
        <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={coachRegistrationObject.firstName} label="First Name"
          onChangeText={(value) => {
            onChange('firstName', value);
          }}/>

        <TextInput
          mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={coachRegistrationObject.lastName} label="Last Name"
          onChangeText={(value) => {
            onChange('lastName', value);
          }}/>
        <TextInput
          mode="outlined" style={registrationStyles(rootWidth).textInputWord}
          value={coachRegistrationObject.zipcode} label="ZipCode"
          onChangeText={(value) => {
            onChange('zipcode', value);
          }}/>
        <Text style={textStyles.smallText}>To Locate Students closest to you</Text>
        <View style={[gridStyles.gridRowContainer, {
          justifyContent: 'space-between',
          alignSelf: "center",
          minWidth: sentenceWidth,
          maxWidth: sentenceWidth
        }]}>
          <View style={{maxWidth: sentenceWidth / 3}}>
            <DropDown label={'Birth Month'} value={birthMonth}
              setValue={(_birthMonth) => {
                setBirthMonth(_birthMonth);
              }}
              visible={showMonthDropDown}
              mode={"outlined"}
              showDropDown={() => setShowMonthDropDown(true)}
              onDismiss={() => {
                setShowMonthDropDown(false);
              }}
              list={[{label: 'Jan', value: '01'},
                {label: 'Feb', value: '02'},
                {label: 'Mar', value: '03'},
                {label: 'Apr', value: '04'},
                {label: 'May', value: '05'},
                {label: 'Jun', value: '06'},
                {label: 'Jul', value: '07'},
                {label: 'Aug', value: '08'},
                {label: 'Sep', value: '09'},
                {label: 'Oct', value: '10'},
                {label: 'Nov', value: '11'},
                {label: 'Dec', value: '12'},

              ]}
            />
          </View>
          <View style={{maxWidth: sentenceWidth / 3}}>
            <DropDown
              label={'Birth Day'}
              value={birthDay}
              setValue={(_birthDay) => {
                setBirthDay(_birthDay);
              }}
              visible={showDayDropDown}
              mode={"outlined"}
              showDropDown={() => setShowDayDropDown(true)}
              onDismiss={() => {
                setShowDayDropDown(false);
              }}
              list={[{label: '1', value: '01'},
                {label: '2', value: '02'},
                {label: '3', value: '03'},
                {label: '4', value: '04'},
                {label: '5', value: '05'},
                {label: '6', value: '06'},
                {label: '7', value: '07'},
                {label: '8', value: '08'},
                {label: '9', value: '09'},
                {label: '10', value: '10'},
                {label: '11', value: '11'},
                {label: '12', value: '12'},
                {label: '13', value: '13'},
                {label: '14', value: '14'},
                {label: '15', value: '15'},
                {label: '16', value: '16'},
                {label: '17', value: '17'},
                {label: '18', value: '18'},
                {label: '19', value: '19'},
                {label: '20', value: '20'},
                {label: '21', value: '21'},
                {label: '22', value: '22'},
                {label: '23', value: '23'},
                {label: '24', value: '24'},
                {label: '25', value: '25'},
                {label: '26', value: '26'},
                {label: '27', value: '27'},
                {label: '28', value: '28'},
                {label: '29', value: '29'},
                {label: '30', value: '30'},
                {label: '31', value: '31'},
              ]}
            />
          </View>
          <View style={{maxWidth: sentenceWidth / 3}}>
            <DropDown dropDownStyle={{maxWidth: sentenceWidth / 2}} label={'Birth Year'}
              value={birthYear}
              setValue={(_birthYear) => {
                setBirthYear(_birthYear);
              }}
              visible={showYearDropDown}
              mode={"outlined"}
              showDropDown={() => setShowYearDropDown(true)}
              onDismiss={() => setShowYearDropDown(false)}
              list={[{label: '2010', value: '2010'},
                {label: '2011', value: '2011'},
                {label: '2012', value: '2012'},
                {label: '2013', value: '2013'},
                {label: '2014', value: '2014'},
                {label: '2015', value: '2015'},
                {label: '2016', value: '2016'},
              ]}
            />
          </View>
        </View>
        <RegistrationProgressButtons valid={
          !!coachRegistrationObject.firstName && !!coachRegistrationObject.lastName &&
          !!birthDay && !!birthMonth &&
          !!birthYear && !!coachRegistrationObject.zipcode
        } page={page} setPage={setPage} steps={steps}/>
      </View>
    }
    {
      page == 2 &&
      <SafeAreaView style={[registrationStyles(rootWidth).page, {height: 600}]}>
        <RegistrationProgress page={page} steps={steps}/>


        <Dropzone
          accept="image/*"
          maxFiles={1}
          multiple={false}
          onDrop={acceptedFiles => {
            if (acceptedFiles.length > 0) {

              const preview = URL.createObjectURL(acceptedFiles[0]);
              setImageSrc(preview);
              // const newFormObject = {
              //   ...parentRegistrationObject,
              // };
              // console.log(typeof  preview);
              // newFormObject.studentImage = preview;
              // setParentRegistrationObject(newFormObject);


              const fr = new FileReader();
              fr.onload = function () {
                // setImageSrc(fr.result);
                const newFormObject = {
                  ...coachRegistrationObject,
                };
                console.log(typeof fr.result);
                newFormObject.coachImage = fr.result as ArrayBuffer;
                console.log('newFormObject-2');
                setCoachRegistrationObject(newFormObject);

              };
              fr.readAsArrayBuffer(acceptedFiles[0]);
            }
          }}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Text>Upload and image for your child</Text>
                {imageSrc && <img style={{width: 100, height: 100}} src={imageSrc} alt={'preview'}/>}
                {imageSrc && <Text>PREVIEW</Text>}
                {!imageSrc && < Avatar.Icon size={100} icon="cloud-upload"> </Avatar.Icon>}
              </div>
            </section>
          )}
        </Dropzone>

        <Text style={textStyles.labelText}>Coach Essay</Text>


        <TextInput style={registrationStyles(rootWidth).textInputSentence} mode="outlined" multiline numberOfLines={5}
          value={coachRegistrationObject.coachEssay}
          label="Describe your motivation or interest in becoming a coach"
          placeholder={"I have always liked....., I want to make a difference by ...."}
          onChangeText={(value) => {
            onChange('coachEssay', value);
          }}/>
        <RegistrationProgressButtons valid={!!coachRegistrationObject.coachEssay} page={page} setPage={setPage}
          steps={steps}/>
      </SafeAreaView>
    }
    {page == 3 &&
    <View style={registrationStyles(rootWidth).page}>
      <RegistrationProgress page={page} steps={steps}/>

      <Text style={textStyles.labelText}>Create Coach Account</Text>

      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord}
        value={coachRegistrationObject.email} label="Email"
        onChangeText={(value) => {
          onChange('email', value);
        }}/>
      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord} secureTextEntry
        value={coachRegistrationObject.password} label="Password"
        onChangeText={(value) => {
          onChange('password', value);
        }}/>
      <TextInput mode="outlined" style={registrationStyles(rootWidth).textInputWord} secureTextEntry
        value={coachRegistrationObject.passwordConfirm} label="PasswordConfirm"
        onChangeText={(value) => {
          onChange('passwordConfirm', value);
        }}/>

      <RegistrationProgressButtons
        valid={validEmail(coachRegistrationObject.email) && validPassword(coachRegistrationObject.password) && coachRegistrationObject.password === coachRegistrationObject.passwordConfirm}
        page={page} setPage={setPage} steps={steps} submitForm={submitForm}/>

    </View>
    }
  </Surface>;
}
