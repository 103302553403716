import invariant from "invariant";
import * as React from "react";
import {ScrollView, Text, View} from "react-native";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import NumberKeypad from "./NumberKeypad";
import {blockStyles, gridStyles, rootStyles, textStyles, widgetBlockStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);

export function AdditionPartnerWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse

  } = props;

  console.log('AdditionPartnerWidgetCoach',curriculumStudentResponse,curriculumStudentInteraction);
  if (curriculumStudentResponse?.responseKeyValues?.setList) {
    const rows = curriculumStudentResponse.responseKeyValues.setList.map((pair, index) => <View key={index.toString()}
      style={gridStyles.gridRowContainer}>
      <View style={blockStyles.coachProblemBlock}>
        <Text style={textStyles.coachProblemText}>
          {pair.a}
        </Text>
      </View>
      <Text style={textStyles.coachProblemText}>
        +
      </Text>
      <View style={blockStyles.coachProblemBlock}>
        <Text style={textStyles.coachProblemText}>
          {pair.b}
        </Text>
      </View>
      <Text style={textStyles.coachProblemText}>
        =
      </Text>
      <Text style={textStyles.coachProblemText}>
        {curriculumStudentInteraction.problem?.params.prompts.sum}
      </Text>
    </View>);
    return <View testID="AdditionPartnerWidgetCoach" style={rootStyles.coachRoot}>
      <View style={gridStyles.gridColumnContainer}>{rows}</View>
    </View>;
  }

  return <View testID="AdditionPartnerWidgetCoach" style={rootStyles.coachRoot}/>;
}

export function AdditionPartnerWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    inWorkbench,

    onResponse
  } = props;
  const [widgetWidth, setWidgetWidth] = React.useState(0);



  const [currentPosition, setCurrentPosition] = React.useState(0);
  const currentIndex = Math.floor(currentPosition / 2);
  const currentField: string = currentPosition % 2 === 0 ? 'a' : 'b';
  React.useEffect(() => {
    setCurrentPosition(0);
  }, [curriculumStudentInteraction?.problem?.problemId]);
  const onKeyPress = React.useCallback((_answer: string) => {
    debug('onKeyPress', _answer);
    invariant(Array.isArray(curriculumStudentResponse.responseKeyValues.setList), 'widget-state-error');
    onResponse(currentField, _answer, currentIndex);

    if (currentPosition < (curriculumStudentResponse.responseKeyValues?.setList?.length || 0) * 2) {
      setCurrentPosition(currentPosition + 1);
    } else {
      setCurrentPosition(0);
    }
  }, []);
  debug('curriculumStudentResponse?.responseKeyValues?.setList', curriculumStudentResponse?.responseKeyValues?.setList);


  return (<View testID="AdditionPartnerWidgetStudent" style={rootStyles.studentRoot} onLayout={(event) => {
    setWidgetWidth(event?.nativeEvent?.layout?.width);

  }}>
    <View style={[gridStyles.gridColumnCenteredContainer]}>
      <ScrollView style={{flex:3}}>
        {curriculumStudentResponse?.responseKeyValues?.setList &&
        curriculumStudentResponse.responseKeyValues.setList.map((pair, index) => <View key={index.toString()}
          style={gridStyles.gridRowContainer}>
          <View key="a"
            style={index === currentIndex && currentField === 'a' ? widgetBlockStyles(widgetWidth).problemBlockActive : widgetBlockStyles(widgetWidth).problemBlock}>
            <Text style={widgetTextStyles(widgetWidth).problemText}>
              {pair.a}
            </Text>
          </View>
          <Text key="plus" style={widgetTextStyles(widgetWidth).problemText}>
            +
          </Text>
          <View key="b"
            style={index === currentIndex && currentField === 'b' ? widgetBlockStyles(widgetWidth).problemBlockActive : widgetBlockStyles(widgetWidth).problemBlock}>

            <Text style={widgetTextStyles(widgetWidth).problemText}>
              {pair.b}
            </Text>
          </View>
          <Text key="eq" style={widgetTextStyles(widgetWidth).problemText}>
            =
          </Text>
          <Text key="sum" style={widgetTextStyles(widgetWidth).problemText}>
            {curriculumStudentInteraction.problem?.params.prompts.sum}
          </Text>
        </View>)
        }
      </ScrollView>

      <View style={[gridStyles.gridRowContainer,{flex:1}]}>
        <NumberKeypad widgetWidth={widgetWidth} inWorkbench={inWorkbench} onKeyPress={onKeyPress}/>
      </View>
    </View>
  </View>);


}


const defaultProgressionSetId = 'Math.G1.OA.A.1.PartnerWithin10.1';
const AdditionPartnerWidget: WidgetSet= {
  widgetSetId: 'AdditionPartnerWidget',
  coach: AdditionPartnerWidgetCoach,
  student: AdditionPartnerWidgetStudent,
  defaultProgressionSetId,

};
export default AdditionPartnerWidget;
