import {diff} from "deep-object-diff";
import invariant from "invariant";
import type {
  ConfidenceType,
  Problem,
  CurriculumStudentResponse,
  ResponseKeyValues,
  InteractionId
} from "../sharedDefs/curriculumDefs";
import {CURRICULUM} from "../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.UTIL);

function responseEmptyFromExpected(expected: any): any {
  if (!expected) {
    return undefined;
  }

  if (typeof expected === 'string') {
    return '';
  }

  if (Array.isArray(expected)) {
    return expected.map(item => responseEmptyFromExpected(item));
  }

  if (typeof expected === 'object') {
    const responseKeyValues = {};
    Object.entries(expected).forEach(([key, value]) => {
      debug(key, value);
      responseKeyValues[key] = responseEmptyFromExpected(value);
    });
    return responseKeyValues;
  }

  return '';
}

const ResponseDataUtil = {
  responseAddConfidence: (curriculumStudentResponse: CurriculumStudentResponse, confidence: ConfidenceType): CurriculumStudentResponse => ({ ...curriculumStudentResponse,
    confidence,
    timeStamp: Date.now()
  }),
  responseAddValue: (curriculumStudentResponse: CurriculumStudentResponse, key: string, value: string, index?: number): CurriculumStudentResponse => {
    debug('on ResponseKeyValues', key, value, index, typeof index);
    invariant(typeof value === 'string', 'value not a string');
    let responseKeyValues: ResponseKeyValues;

    if (typeof index === 'number') {
      const setList = [...(curriculumStudentResponse.responseKeyValues.setList || [])];

      if (setList.length <= index) {
        responseKeyValues = { ...curriculumStudentResponse.responseKeyValues
        };
      } else {
        debug('on ResponseKeyValues SetList', key, value, index);
        debug('on ResponseKeyValues 111 setList[index]', setList[index]);
        const set = { ...setList[index]
        };
        set[key] = value;
        debug('on ResponseKeyValues set', set);
        setList.splice(index, 1, set);
        debug('on ResponseKeyValues 222 setList[index]', setList[index]);
        debug('on ResponseKeyValues setList', setList);
        responseKeyValues = { ...curriculumStudentResponse.responseKeyValues,
          setList
        };
      }

      debug('on ResponseKeyValues responseKeyValues', JSON.stringify(responseKeyValues, null, 2));
    } else {
      invariant(curriculumStudentResponse.responseKeyValues.set, 'invalid responseKeyValues data');
      const set: Record<string, string> = { ...curriculumStudentResponse.responseKeyValues.set
      };
      debug('Original set', set);
      set[key] = value;
      debug('Updates set', set);
      responseKeyValues = { ...curriculumStudentResponse.responseKeyValues,
        set
      };
      debug('on ResponseKeyValues SET responseKeyValues', set, JSON.stringify(responseKeyValues, null, 2));
    }

    const _responseData: CurriculumStudentResponse = { ...curriculumStudentResponse,
      responseKeyValues,
      timeStamp: Date.now()
    };
    debug('CurriculumStudentResponse ', JSON.stringify(curriculumStudentResponse, null, 2), 'After:', JSON.stringify(curriculumStudentResponse, null, 2), key, value, index);
    return _responseData;
  },
  responseOfArrayStatusSet: (curriculumStudentResponse: CurriculumStudentResponse, problem: Problem): CurriculumStudentResponse => {
    invariant(Array.isArray(curriculumStudentResponse.responseKeyValues.setList), 'expecting an array ResponseKeyValues');
    const expectedPairs = problem.params.expectedResponseKeyValues.setList;
    let originalPairs = [...curriculumStudentResponse.responseKeyValues.setList];
    let hasResponse = false;
    let hasMissing = false;
    originalPairs.forEach((original,) => {
      Object.values(original).forEach(value => {
        if (value === '') {
          hasMissing = true;
        }

        if (value !== '') {
          hasResponse = true;
        }
      });
    });

    if (!hasResponse && hasMissing) {
      return { ...curriculumStudentResponse,
        responseState: CURRICULUM.RESPONSE_STATE.NO_RESPONSE,
        timeStamp: Date.now()
      };
    }

    if (hasResponse && hasMissing) {
      return { ...curriculumStudentResponse,
        responseState: CURRICULUM.RESPONSE_STATE.INCOMPLETE,
        timeStamp: Date.now()
      };
    }

    let responseState = CURRICULUM.RESPONSE_STATE.CORRECT;
    const orderedPairs = [];
    expectedPairs && expectedPairs.forEach(expected => {
      if (responseState === CURRICULUM.RESPONSE_STATE.CORRECT) {
        let found = false;
        const modifiedPairs = [...originalPairs];
        originalPairs.forEach((original, index) => {
          if (!found) {
            const d = diff(expected, original);

            if (d && Object.keys(d).length === 0) {
              orderedPairs.push(original);
              modifiedPairs.splice(index, 1);
              found = true;
            }
          }
        });

        if (!found) {
          responseState = CURRICULUM.RESPONSE_STATE.INCORRECT;
        }

        originalPairs = modifiedPairs;
      }
    });
    const d = diff(expectedPairs, orderedPairs);

    if (d && Object.keys(d).length > 0) {
      responseState = CURRICULUM.RESPONSE_STATE.INCORRECT;
    }

    if (originalPairs.length > 0) {
      responseState = CURRICULUM.RESPONSE_STATE.INCORRECT;
    }

    return { ...curriculumStudentResponse,
      responseState,
      timeStamp: Date.now()
    };
  },
  responseStatusSet: (curriculumStudentResponse: CurriculumStudentResponse, problem: Problem): CurriculumStudentResponse => {
    if (Array.isArray(curriculumStudentResponse.responseKeyValues.setList)) {
      return ResponseDataUtil.responseOfArrayStatusSet(curriculumStudentResponse, problem);
    }

    let hasResponse = false;
    let hasMissing = false;
    let responseRequired = false; // Instructions

    Object.values(curriculumStudentResponse.responseKeyValues.set || {}).forEach(value => {
      if (value === '') {
        hasMissing = true;
        responseRequired = true;
      }

      if (value !== '') {
        hasResponse = true;
        responseRequired = true;
      }
    });

    if (responseRequired && !hasResponse && hasMissing) {
      return { ...curriculumStudentResponse,
        responseState: CURRICULUM.RESPONSE_STATE.NO_RESPONSE,
        timeStamp: Date.now()
      };
    }

    if (responseRequired && hasResponse && hasMissing) {
      return { ...curriculumStudentResponse,
        responseState: CURRICULUM.RESPONSE_STATE.INCOMPLETE,
        timeStamp: Date.now()
      };
    }

    let responseState = CURRICULUM.RESPONSE_STATE.CORRECT;

    const d = diff(problem?.params.expectedResponseKeyValues, curriculumStudentResponse.responseKeyValues);

    if (d && Object.keys(d).length > 0) {
      responseState = CURRICULUM.RESPONSE_STATE.INCORRECT;
    }

    return { ...curriculumStudentResponse,
      responseState,
      timeStamp: Date.now()
    };
  },
  createCurriculumStudentResponse: (problem: Problem, interactionId: InteractionId): CurriculumStudentResponse => {
    const responseKeyValues = responseEmptyFromExpected(problem?.params.expectedResponseKeyValues);
    return {
      interactionId,
      responseKeyValues,
      responseState: CURRICULUM.RESPONSE_STATE.NO_RESPONSE,
      timeStamp: new Date().getMilliseconds()
    };
  }
};
export default ResponseDataUtil;
