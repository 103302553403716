import * as React from "react";
import {Image, Pressable, StyleSheet, Text, View} from "react-native";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import {COLOR} from "../utils/UiConstants";
import {gridStyles, rootStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {Avatar} from "react-native-paper";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);



const imageChoiceStyle = (widgetWidth) =>{
  return {
    width: Math.round((widgetWidth-10)/5),
    height: Math.round((widgetWidth-10)/5),
    borderRadius: 10,
    borderWidth:0,
  };
};

const imageQuestionStyle = (widgetWidth) =>{
  console.log('widgetWidth',widgetWidth);
  if (widgetWidth <= 200) {
    return {
      width: Math.round((Math.min(150, widgetWidth) - 10) / 2),
      height: Math.round((Math.min(150, widgetWidth) - 10) / 2),
      borderRadius: 10,
      borderWidth: 0,
    };
  }
  return {
    width: Math.round((Math.min(500,widgetWidth)-10)/2),
    height: Math.round((Math.min(500,widgetWidth)-10)/2),
    borderRadius: 10,
    borderWidth:0,
  };
};

function choice(widgetWidth: number, position:number, choiceString:string, choiceImage: string): JSX.Element{

  const letter = ['A','B','C','D'];
  const iconStyle = StyleSheet.create(
    {topLeft: {
      position: 'absolute',
      left:     0,
      top:      0,
      backgroundColor: COLOR.GREEN
    }});
  if (choiceImage && choiceString){
    return <View style={{alignItems: 'center', height:'100%',maxWidth: Math.round((widgetWidth-10)/2)}}>
      <Avatar.Text color={COLOR.BASE} style={iconStyle.topLeft} size={widgetWidth/15} label={letter[position]} />

      <View style={[gridStyles.gridRowContainer,{alignItems: 'center', height:'100%'}]}>
        <Text key="a" style={widgetTextStyles(widgetWidth/4).problemText}>
          {choiceString}
        </Text>
        <Image key="aImage" style={imageChoiceStyle(widgetWidth)} source={{
          uri: choiceImage
        }}/>
      </View>
    </View>;
  }
  if (choiceString){
    return <View style={{alignItems: 'center', height:'100%', maxWidth: Math.round((widgetWidth-10)/2)}}>
      <Avatar.Text color={COLOR.BASE} style={iconStyle.topLeft} size={widgetWidth/10} label={letter[position]} />

      <View style={[gridStyles.gridRowContainer,{width:'100%', justifyContent:'flex-start'}]}>

        <Text key="a" style={[widgetTextStyles(widgetWidth/1.5).problemText,{width:'100%', textAlign: 'left'}]}>
          {choiceString}
        </Text>
      </View>
    </View>;
  }

  if (choiceImage){
    return <View style={{alignItems: 'center', height:'100%', maxWidth: Math.round((widgetWidth-10)/2)}}>
      <Avatar.Text color={COLOR.BASE} style={iconStyle.topLeft} size={widgetWidth/10} label={letter[position]} />

      <View style={gridStyles.gridColumnCenteredContainer}>
        <Image key="aImage" style={imageChoiceStyle(widgetWidth)} source={{
          uri: choiceImage
        }}/>
      </View>
    </View>;
  }

}


function questionView(widgetWidth: number,  questionString:string, questionImage: string): JSX.Element{


  if (questionImage && questionString){
    return <View style={{alignItems: 'center', height:'100%',maxWidth: Math.round(widgetWidth-10)}}>

      <View style={[gridStyles.gridRowContainer,{alignItems: 'center', height:'100%'}]}>
        <Text key="a" style={widgetTextStyles(widgetWidth/2).problemText}>
          {questionString}
        </Text>
        <Image key="aImage" style={imageQuestionStyle(widgetWidth)} source={{
          uri: questionImage
        }}/>
      </View>
    </View>;
  }
  if (questionString){
    return <View style={{alignItems: 'center', height:'100%', maxWidth: Math.round(widgetWidth-10)}}>
      <View style={[gridStyles.gridRowContainer,{width:'100%', justifyContent:'flex-start'}]}>

        <Text key="a" style={[widgetTextStyles(widgetWidth/1.2).problemText,{width:'100%', textAlign: 'left'}]}>
          {questionString}
        </Text>
      </View>
    </View>;
  }

  if (questionImage){
    return <View style={{alignItems: 'center',  height:'100%', maxWidth: Math.round(widgetWidth-10)}}>
      <View style={gridStyles.gridColumnCenteredContainer}>
        <Image key="aImage" style={imageQuestionStyle(widgetWidth)} source={{
          uri: questionImage
        }}/>
      </View>
    </View>;
  }

}

const choiceStyle = (widgetWidth) =>{
  return {
    width: Math.round((widgetWidth-10)/2),
    borderRadius: 10,
    borderWidth:1,
  };
};



export function MultipleChoiceWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    //curriculumStudentResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;

  const {
    prompts
  } = problem?.params || {};
  const {
    question,
    questionImage,

  } = prompts || {};

  const [widgetWidth, setWidgetWidth] = React.useState(0);
  const [widgetHeight, setWidgetHeight] = React.useState(0);

  return <View testID="MultipleChoiceWidgetCoach" style={rootStyles.coachRoot} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    <View style={gridStyles.gridColumnContainer}>
      <View style={[gridStyles.gridRowContainer,{flex:3, minHeight: 80}]}>
        <View style={{width:widgetWidth}}>
          {
            questionView(widgetWidth, question, questionImage)
          }
        </View>
      </View>
      <View style={[gridStyles.gridRowContainer,{flex:3, minHeight: 20}]}>
        <View style={choiceStyle(widgetWidth)} testID="button_A"         >
          {choice(widgetWidth,0,prompts?.choiceString0, prompts?.choiceImage0)}
        </View>

        <View style={choiceStyle(widgetWidth)} testID="button_B">
          {choice(widgetWidth,1,prompts?.choiceString1, prompts?.choiceImage1)}
        </View>
      </View>
      <View style={[gridStyles.gridRowContainer,{flex:3, minHeight: 20}]}>
        <View style={choiceStyle(widgetWidth)} testID="button_c">
          {choice(widgetWidth,2,prompts?.choiceString2, prompts?.choiceImage2)}
        </View>

        <View style={choiceStyle(widgetWidth)} testID="button_D">
          {choice(widgetWidth,3,prompts?.choiceString3, prompts?.choiceImage3)}
        </View>
      </View>

    </View>
  </View>;
}



export function MultipleChoiceWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    onResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;

  const {
    prompts
  } = problem?.params || {};
  const {
    question,
    questionImage,

  } = prompts || {};
  //console.log('MultipleChoiceWidgetStudent ', !!question,!!questionImage, !!prompts, !!problem, !!problem?.params);
  const keys = Object.keys(prompts || {}).sort();
  const {
    answer
  } = curriculumStudentResponse?.responseKeyValues?.set || {};


  const onKeyPress = (_answer: string) => {
    debug('onKeyPress', _answer);
    onResponse('selection', _answer);
  };
  const [widgetWidth, setWidgetWidth] = React.useState(0);
  const [widgetHeight, setWidgetHeight] = React.useState(0);

  // console.log('PROMPTS ',JSON.stringify(prompts,null,2));

  return <View testID="MultipleChoiceWidgetStudent" style={rootStyles.studentRoot} onLayout={(event) => {
    setWidgetWidth( event?.nativeEvent?.layout?.width);
    setWidgetHeight( event?.nativeEvent?.layout?.height);
  }}>
    <View style={gridStyles.gridColumnContainer}>
      <View style={[gridStyles.gridRowContainer,{flex:2}]}>
        <View style={{width:widgetWidth}}>
          {
            questionView(widgetWidth, question, questionImage)
          }
        </View>
      </View>
      <View style={gridStyles.gridRowContainer}>
        <Pressable style={choiceStyle(widgetWidth)} testID="button_A" onPress={() => {
          onKeyPress('0');
        }}>
          {choice(widgetWidth,0,prompts?.choiceString0, prompts?.choiceImage0)}
        </Pressable>

        <Pressable style={choiceStyle(widgetWidth)} testID="button_B" onPress={() => {
          onKeyPress('1');
        }}>
          {choice(widgetWidth,1,prompts?.choiceString1, prompts?.choiceImage1)}
        </Pressable>
      </View>
      <View style={gridStyles.gridRowContainer}>
        <Pressable style={choiceStyle(widgetWidth)} testID="button_c" onPress={() => {
          onKeyPress('2');
        }}>
          {choice(widgetWidth,2,prompts?.choiceString2, prompts?.choiceImage2)}
        </Pressable>

        <Pressable style={choiceStyle(widgetWidth)} testID="button_D" onPress={() => {
          onKeyPress('3');
        }}>
          {choice(widgetWidth,3,prompts?.choiceString3, prompts?.choiceImage3)}
        </Pressable>
      </View>

    </View>
  </View>;
}


const defaultProgressionSetId = 'Math.K.CC.A.3.MultipleChoice.1';
const MultipleChoiceWidget: WidgetSet = {
  widgetSetId: 'MultipleChoiceWidget',
  coach: MultipleChoiceWidgetCoach,
  student: MultipleChoiceWidgetStudent,
  defaultProgressionSetId,

};
export default MultipleChoiceWidget;
