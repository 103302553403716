//
import {Button, Pressable, StyleSheet, Text, View} from "react-native";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../redux/reducer";
import SelfWebCon, {VIDEO_SIZE} from "../../components/WebCon/SelfWebCon";
import {userLogoutRequest} from "../../redux/reducer/userReducer";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {navigateTo} from "../../NavigationAction";
import {NAV} from "../../redux/structs/navigationStruct";
import {COLOR} from "../../utils/UiConstants";
import DeviceChooser from "../../components/WebCon/DeviceChooser";

const debug = debugModule(DEBUG_MODULE.USER.CONTAINER);
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height:420
  },
  topRightView: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: 22
  },
  buttonStyle:{
    width:200,
    margin:10,
    backgroundColor:COLOR.WHITE,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    flex:1,
    flexDirection:"column",
    width: 300,
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    zIndex:100,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
type CoachSessionGalleryProps = {
  hideMenu: ()=>void;

};
export default function CoachMenuContents(props: CoachSessionGalleryProps ): JSX.Element {
  const dispatch = useDispatch();

  const localVideoTrack = useSelector((state: State) => state.webconReducer.localVideoTrack);

  const logout = React.useCallback(() => {
    debug('logout');
    dispatch(userLogoutRequest());
  }, []);



  return (
    <View testID="CoachMenuContents" style={styles.topRightView}>
      <View style={styles.modalView}>
        <Button title={'Dismiss'} onPress={props.hideMenu}/>
        <View  style={styles.centeredView}>
          { localVideoTrack && <SelfWebCon VIDEO_CONTAINER_SIZE={VIDEO_SIZE}/>}
        </View>
        <View  style={styles.centeredView}>
          { localVideoTrack && <DeviceChooser/>}
        </View>
        <Pressable style={styles.buttonStyle}  onPress={logout}>
          <Text>Logout</Text>
        </Pressable>
        <Pressable style={styles.buttonStyle}  onPress={() => {
          navigateTo(NAV.COACH.APPLICATION);
        }}>
          <Text>Application</Text>
        </Pressable>
      </View>
    </View>);
}
