import * as React from "react";
import {StyleSheet, Text, View} from "react-native";

import {WidgetSet} from "./widgetStructs";
import {COLOR} from "../utils/UiConstants";

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative',
    backgroundColor: COLOR.LT_BLUE,
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  }
});
export function NotFoundWidgetCoach(): JSX.Element {

  return <View testID="NotFoundWidgetCoach" style={styles.root}><Text>NotFoundWidgetCoach</Text></View>;
}
export function NotFoundWidgetStudent(): JSX.Element {

  return <View testID="NotFoundWidgetStudent" style={styles.root}><Text>NotFoundWidgetStudent</Text></View>;
}
const NotFoundWidget: WidgetSet = {
  widgetSetId: 'NotFoundWidget',
  coach: NotFoundWidgetCoach,
  student: NotFoundWidgetStudent,
  defaultProgressionSetId: '',

};
export default NotFoundWidget;
