/* istanbul ignore file */
import axios from "axios";
import invariant from "invariant";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import type {
  AdminApplicationAssessBody,
  AdminApplicationAssessResult, AdminAssessmentSaveBody, AdminAssessmentSaveResult,
  AdminImportFromAirtableBody,
  AdminImportFromAirtableResult,
  AdminProgressionSetSaveBody,
  AdminProgressionSetSaveResult,
  CurriculumInteractionAdvanceBody,
  CurriculumInteractionAdvanceResult,
  CurriculumProgressionSetAssignBody,
  CurriculumProgressionSetAssignResult,
  CurriculumProgressionSetCompletedBody,
  CurriculumProgressionSetCompletedResult,
  CurriculumStudentProgressionSetSummaryBody,
  CurriculumStudentProgressionSetSummaryResult,
  CurriculumSubmitPerformBody, CurriculumWidgetSetChangeRequestBody, CurriculumWidgetSetChangeRequestResult,

  HuddleAudioModeBody,
  HuddleAudioModeResult,
  HuddleCoachAdmitStudentBody,
  HuddleCoachAdmitStudentResult,
  HuddleCoachEnterBody,
  HuddleCoachEnterResult,
  HuddleCoachReleaseStudentBody,
  HuddleCoachReleaseStudentResult,
  HuddleDismissBody,
  HuddleDismissResult,
  HuddleHeyCoachModeBody,
  HuddleHeyCoachModeResult,
  HuddleLeaveBody,
  HuddleLeaveResult,
  HuddleStudentArriveBody,
  HuddleStudentArriveResult,
  HuddleStudentEnterBody,
  HuddleStudentEnterResult,
  ParentCoachAssignStudentBody,
  ParentCoachAssignStudentResult,
  TestBody,
  TestResult,
  UserProfileUpdateBody,
  UserProfileUpdateResult,
  UserRegisterRoleBody,
  UserRegisterRoleResult,
  UserStudentCreateBody,
  UserStudentCreateResult
} from "../sharedDefs/serverApiDefs";
import {API_URI, CurriculumResponsePerformBody} from "../sharedDefs/serverApiDefs";
import {NODE_URL} from "../utils/environment";
import {CurriculumResponseDetail} from "../sharedDefs/curriculumDefs";

const debug = debugModule(DEBUG_MODULE.JEST.TESTS);
const nodeURL = NODE_URL+'/';
export function testServerApi(body: TestBody): Promise<{
  status: number;
  data: TestResult;
}> {
  const url = nodeURL + API_URI.TEST;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    debug('Test server result', res);
    const result: TestResult = res.data;
    result.retObject.retDate = new Date(result.retObject.retDate);
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}

export function adminProgressionSetSaveApi(body: AdminProgressionSetSaveBody): Promise<{
  status: number;
  data: AdminProgressionSetSaveResult;
}> {
  const url = nodeURL + API_URI.ADMIN_PROGRESSION_SET_SAVE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: AdminProgressionSetSaveResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}

export function adminAssessmentSaveApi(body: AdminAssessmentSaveBody): Promise<{
  status: number;
  data: AdminAssessmentSaveResult;
}> {
  const url = nodeURL + API_URI.ADMIN_ASSESSMENT_SAVE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: AdminAssessmentSaveResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}

export function adminApplicationAssessServerApi(body: AdminApplicationAssessBody): Promise<{
  status: number;
  data: AdminApplicationAssessResult;
}> {
  const url = nodeURL + API_URI.ADMIN_APPLICATION_ASSESS;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: AdminApplicationAssessResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function curriculumProgressionSetCompletedServerApi(body: CurriculumProgressionSetCompletedBody): Promise<{
  status: number;
  data: CurriculumProgressionSetCompletedResult;
}> {
  const url = nodeURL + API_URI.CURRICULUM_PROGRESSION_SET_COMPLETED;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: CurriculumProgressionSetCompletedResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}


export function curriculumStudentProgressionSetSummaryServerApi(body: CurriculumStudentProgressionSetSummaryBody): Promise<{
  status: number;
  data: CurriculumStudentProgressionSetSummaryResult;
}> {
  const url = nodeURL + API_URI.CURRICULUM_STUDENT_PROGRESSION_SET_SUMMARY;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: CurriculumStudentProgressionSetSummaryResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function curriculumInteractionAdvanceServerApi(body: CurriculumInteractionAdvanceBody): Promise<{
  status: number;
  data: CurriculumInteractionAdvanceResult;
}> {
  const url = nodeURL + API_URI.CURRICULUM_INTERACTION_ADVANCE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }
    const result: CurriculumInteractionAdvanceResult = res.data;
    return {
      status: res.status,
      data:result
    };
  });
}
export function curriculumSubmitPerformServerApi(body: CurriculumSubmitPerformBody): Promise<{ status: number;
}> {
  const url = nodeURL + API_URI.CURRICULUM_SUBMIT_PERFORM;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    return {
      status: res.status,

    };
  });
}
export function curriculumResponsePerformServerApi(body: CurriculumResponsePerformBody): Promise<{
  status: number;
  data: CurriculumResponseDetail;
}> {
  const url = nodeURL + API_URI.CURRICULUM_RESPONSE_PERFORM;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: CurriculumResponseDetail = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function curriculumProgressionSetAssignServerApi(body: CurriculumProgressionSetAssignBody): Promise<{
  status: number;
  data: CurriculumProgressionSetAssignResult;
}> {
  const url = nodeURL + API_URI.CURRICULUM_PROGRESSION_SET_ASSIGN;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: CurriculumProgressionSetAssignResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleStudentArriveServerApi(body: HuddleStudentArriveBody): Promise<{
  status: number;
  data: HuddleStudentArriveResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_STUDENT_ARRIVE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleStudentArriveResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleCoachAdmitStudentServerApi(body: HuddleCoachAdmitStudentBody): Promise<{
  status: number;
  data: HuddleCoachAdmitStudentResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_COACH_ADMIT_STUDENT;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleCoachAdmitStudentResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleCoachReleaseStudentServerApi(body: HuddleCoachReleaseStudentBody): Promise<{
  status: number;
  data: HuddleCoachReleaseStudentResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_COACH_RELEASE_STUDENT;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleCoachReleaseStudentResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleStudentEnterServerApi(body: HuddleStudentEnterBody): Promise<{
  status: number;
  data: HuddleStudentEnterResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_STUDENT_ENTER;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleStudentEnterResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}

export function huddleCoachPreEnterServerApi(body: HuddleCoachEnterBody): Promise<{
  status: number;
  data: HuddleStudentEnterResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_COACH_PRE_ENTER;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleCoachEnterResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleCoachEnterServerApi(body: HuddleCoachEnterBody): Promise<{
  status: number;
  data: HuddleStudentEnterResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_COACH_ENTER;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleCoachEnterResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleLeaveServerApi(body: HuddleLeaveBody): Promise<{
  status: number;
  data: HuddleLeaveResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_LEAVE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleLeaveResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleDismissServerApi(body: HuddleDismissBody): Promise<{
  status: number;
  data: HuddleDismissResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_DISMISS;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleDismissResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function huddleAudioModeServerApi(body: HuddleAudioModeBody): Promise<{
  status: number;
  data: HuddleAudioModeResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_AUDIO_MODE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleAudioModeResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}


export function huddleHeyCoachModeServerApi(body: HuddleHeyCoachModeBody): Promise<{
  status: number;
  data: HuddleHeyCoachModeResult;
}> {
  const url = nodeURL + API_URI.HUDDLE_HEY_COACH_MODE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: HuddleHeyCoachModeResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}

export function parentCoachAssignStudentServerApi(body: ParentCoachAssignStudentBody): Promise<{
  status: number;
  data: ParentCoachAssignStudentResult;
}> {
  const url = nodeURL + API_URI.PARENT_COACH_ASSIGN_TO_STUDENT;
  invariant(body.coachId && body.studentId, ' invalid action');
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: ParentCoachAssignStudentResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function userRegisterRoleServerApi(body: UserRegisterRoleBody): Promise<{
  status: number;
  data: UserRegisterRoleResult;
}> {
  const url = nodeURL + API_URI.USER_REGISTER_ROLE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: UserRegisterRoleResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function userProfileUpdateServerApi(body: UserProfileUpdateBody): Promise<{
  status: number;
  data: UserProfileUpdateResult;
}> {
  const url = nodeURL + API_URI.USER_PROFILE_UPDATE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: UserProfileUpdateResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function userStudentCreateServerApi(body: UserStudentCreateBody): Promise<{
  status: number;
  data: UserStudentCreateResult;
}> {
  const url = nodeURL + API_URI.USER_STUDENT_CREATE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: UserStudentCreateResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}
export function adminImportFromAirtableServerApi(body: AdminImportFromAirtableBody): Promise<{
  status: number;
  data: AdminImportFromAirtableResult;
}> {
  const url = nodeURL + API_URI.ADMIN_IMPORT_FROM_AIRTABLE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: AdminImportFromAirtableResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}



export function curriculumWidgetSetChangeRequestServerApi(body: CurriculumWidgetSetChangeRequestBody): Promise<{
  status: number;
  data: CurriculumWidgetSetChangeRequestResult;
}> {
  const url = nodeURL + API_URI.CURRICULUM_WIDGET_SET_ID_CHANGE;
  debug('POST->' + url, body);
  return axios.post(url, body).then(res => {
    if (res.status === 500) {
      throw new Error(res.data?.error);
    }

    const result: CurriculumWidgetSetChangeRequestResult = res.data;
    debug('RESULT', JSON.stringify(result));
    return {
      status: res.status,
      data: result
    };
  });
}


