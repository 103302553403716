import * as React from "react";
import {Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import CompareKeypad from "./CompareKeypad";
import {gridStyles, rootStyles, textStyles, widgetTextStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import AnswerBlock from "./AnswerBlock";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);

export function CompareNumbersWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params?.prompts || {};
  const {
    compare
  } = curriculumStudentResponse?.responseKeyValues.set || {};
  return <View testID="CompareNumbersWidgetCoach" style={rootStyles.coachRoot}>
    <View style={gridStyles.gridRowContainer}>
      <View style={gridStyles.item3}>
        <Text style={textStyles.coachProblemText}>
          {a}
          {compare}
          {b}
        </Text>
      </View>
    </View>
  </View>;
}

export function CompareNumbersWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse,
    inWorkbench,
    onResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;
  const {
    a,
    b
  } = problem?.params.prompts || {};
  const {
    compare
  } = curriculumStudentResponse?.responseKeyValues?.set || {};
  const [widgetWidth, setWidgetWidth] = React.useState(0);



  const onKeyPress = React.useCallback((_answer: string) => {
    debug('onKeyPress', _answer);
    onResponse('compare', _answer);
  }, [curriculumStudentResponse, curriculumStudentInteraction]);
  return <View testID="CompareNumbersWidgetStudent" style={rootStyles.studentRoot} onLayout={(event) => {
    setWidgetWidth(event?.nativeEvent?.layout?.width);

  }}>
    <View style={gridStyles.gridColumnCenteredContainer}>
      <View style={gridStyles.gridRowContainer}>
        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {a}
        </Text>
        <AnswerBlock widgetWidth={widgetWidth} actual={compare}
          expected={problem?.params?.expectedResponseKeyValues?.set?.compare}></AnswerBlock>

        <Text style={widgetTextStyles(widgetWidth).problemText}>
          {b}
        </Text>

      </View>
      <CompareKeypad inWorkbench={inWorkbench} onKeyPress={onKeyPress}/>
    </View>;
  </View>;
}

const defaultProgressionSetId = 'Math.K.CC.A.3.CompareBelow 100.1';
const CompareNumbersWidget: WidgetSet = {
  widgetSetId: 'CompareNumbersWidget',
  coach: CompareNumbersWidgetCoach,
  student: CompareNumbersWidgetStudent,
  defaultProgressionSetId,
};
export default CompareNumbersWidget;
