import * as React from "react";
import { View } from "react-native";
import AdminCoachGallery from "./AdminCoachGallery";
import {Button} from "react-native-paper";
import {useDispatch} from "react-redux";
import {navGoto} from "../../redux/reducer/navigationReducer";

import {NAV} from "../../redux/structs/navigationStruct";



function AdminHome(): JSX.Element {
  const dispatch = useDispatch();

  const gotoAssesments = () => {
    dispatch(navGoto(NAV.ADMIN.ASSESSMENT_WORKBENCH));
  };
  const gotoWorkbench = () => {
    dispatch(navGoto(NAV.ADMIN.CURRICULUM_WORKBENCH));
  };
  return <View testID="AdminHome">

    <Button onPress={gotoWorkbench}> Progression Set</Button>
    <Button onPress={gotoAssesments}> Assessment</Button>
    {
      /*  <Button testID="admin_coaches" size="medium" color="secondary" style={styles.button}>AdminCoaches</Button> */
    }
    {
      /* </NavLink> */
    }
    <AdminCoachGallery />
  </View>;
}

export default AdminHome;
