import {CoachId, UserId} from "../../sharedDefs/userDefs";
import {eventChannel} from "redux-saga";
import {all, call, cancel, put, select, take, takeEvery} from "redux-saga/effects";
import {io} from "socket.io-client";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {WS_ACTION} from "../../sharedDefs/webSocketDefs";
import type {State} from "../reducer";

import type {Saga} from "./curriculumSaga";
import {SOCKET_IO_URL} from "../../utils/environment";
import {ActionType} from "../../sharedDefs/commonStruct";
import invariant from 'invariant';
import {WebconAction} from "../structs/webconStruct";
import {WsAction} from "../structs/webSocketStruct";
import {emitWithResponse} from "./sagaUtils";
import {webSocketInit} from "../reducer/wsReducer";

const debug = debugModule(DEBUG_MODULE.WS.SAGA);

function createWsChannel(userId: UserId, coachId: CoachId) {

  return eventChannel(emit => {

    const socketClient = io(SOCKET_IO_URL,
      //{ transports:['websocket']}
    );

    socketClient.connect();
    socketClient.on('connect', () => {


      emit({
        type: WS_ACTION.SYNC.CONNECTED,
        socketClient,
        userId,
        coachId
      });
      debug('\n\n\nWS_ACTION.INITIALIZE.REQUEST ', userId, coachId);
      const action: WsAction = {
        type: WS_ACTION.INITIALIZE.REQUEST,
        userId,
        coachId

      };
      socketClient.emit(action.type, action);

    });


    socketClient.on('disconnect', () => {
      debug('disconnect.');
      emit({
        type: WS_ACTION.SYNC.DISCONNECTED
      });
    });
    socketClient.on('close', () => {
      debug('close.');
      emit({
        type: WS_ACTION.SYNC.CLOSED
      });
    });
    socketClient.onAny((event, action: ActionType) => {
      if (action && action.type) {
        debug(`RECEIVED BACK FROM ROOM got back ${event} ${action.timestamp}  action= ${JSON.stringify(action)}`);
        emit(action);
      } else {
        debug(`!!!!!RECEIVED BACK NON ACTION ${event}  action= ${JSON.stringify(action)}`);
      }
    });
    socketClient.on('connect_error', error => {
      debug('connect.error', error);
      emit({
        type: WS_ACTION.SYNC.ERROR,
        error
      });
    });
    return () => {
      debug('unsubscribe', socketClient);
      socketClient?.close();
    };
  });
}

function* webSocketSyncStartSaga(wsSyncAction: WsAction) {
  debug('webSocketSyncStartSaga-1');
  const {userId, coachId} = wsSyncAction;
  const currentUserId = yield select((state: State) => state.wsReducer.userId);
  const currentCoachId = yield select((state: State) => state.wsReducer.coachId);
  const socketClient = yield select((state: State) => state.wsReducer.socketClient);
  if (!currentUserId ) {
    debug('webSocketSyncStartSaga-2');
    yield put({type: WS_ACTION.SYNC.VERIFIED_START, userId, coachId});
  }
  else if (coachId !==currentCoachId && socketClient ) {
    debug('webSocketSyncStartSaga-3', currentCoachId,'->',coachId,'for',userId);
    const a = webSocketInit(userId, coachId);
    socketClient.emit(a.type, a);
  }

}

function* webSocketSyncVerifiedStartSaga(wsSyncAction: WsAction) {
  console.log('webSocketSyncVerifiedStartSaga');

  let wsSyncChannel;

  try {
    const {userId, coachId} = wsSyncAction;
    wsSyncChannel = yield select((state: State) => state.wsReducer.wsSyncChannel);
    const connecting = yield select((state: State) => state.wsReducer.connecting);
    const socketClient = yield select((state: State) => state.wsReducer.socketClient);
    const currentUserId = yield select((state: State) => state.wsReducer.userId);
    const currentCoachId = yield select((state: State) => state.wsReducer.coachId);

    if (connecting) {
      return;
    }
    yield put({
      type: WS_ACTION.SYNC.CONNECTING
    });

    if (wsSyncChannel && socketClient) {

      invariant(currentUserId === userId && coachId === currentCoachId, 'Can not change user or session for a socket ' + currentUserId + ' -> ' + userId);
      // if(currentUserId !== userId || coachId !== currentSessionId){
      const a = webSocketInit(userId, coachId);
      socketClient.emit(a.type,a);
      // }
      yield put({
        type: WS_ACTION.SYNC.CONNECTED,
        socketClient,
        userId,
        coachId
      });
      return;
    }

    if (socketClient) {
      invariant(currentUserId === userId && coachId === currentCoachId, 'Can not change user or session for a socket');
      //socketClient.emit(action.type, action);
      yield put({
        type: WS_ACTION.SYNC.CONNECTED,
        socketClient,
        userId,
        coachId
      });
      return;
    }



    wsSyncChannel = createWsChannel(userId, coachId);
    yield put({
      type: WS_ACTION.SYNC.CHANNEL_STARTED,
      wsSyncChannel,
      userId,
      coachId
    });

    while (true) {
      debug('WEBSOCKET channel wait');
      const action = yield take(wsSyncChannel);
      debug('WEBSOCKET channel loop take ', action);
      const sendAction = {
        ...action
      };
      yield put(sendAction);
    }
  } catch (err) {
    console.error(err);

    if (wsSyncChannel) {
      yield cancel(wsSyncChannel);
    }

    yield put({
      type: WS_ACTION.SYNC.STOPPED
    });
  }
}

function* webSocketSyncStopSaga() {
  debug('webSocketSyncStopSaga');
  //const socketClient : SocketClient= yield select((state:State) => state.wsReducer.socketClient);
  const wsSyncChannel = yield select((state: State) => state.wsReducer.wsSyncChannel);
  debug('webSocketSyncStopSaga', wsSyncChannel);
  wsSyncChannel?.close();
  yield put({
    type: WS_ACTION.SYNC.STOPPED
  });
}

function* webSocketCheckRoomsSaga(nodeAction: WebconAction) {
  const socketClient = yield select((state: State) => state.wsReducer.socketClient);
  const responseAction = yield call(emitWithResponse, socketClient, nodeAction) as WebconAction;
  yield put(responseAction);

}

export default function* webSocketSagas(): Saga<void> {
  debug('webSocketSagas Start');
  yield all([
    takeEvery(WS_ACTION.SYNC.START, webSocketSyncStartSaga),
    takeEvery(WS_ACTION.SYNC.VERIFIED_START, webSocketSyncVerifiedStartSaga),
    takeEvery(WS_ACTION.SYNC.STOP, webSocketSyncStopSaga),
    takeEvery(WS_ACTION.CHECK_ROOMS.REQUEST, webSocketCheckRoomsSaga)
  ]);
}

