import * as React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {UserProfile} from "../../sharedDefs/userDefs";
import {storage} from "../../redux/rsf";
import {Avatar} from "react-native-paper";

const styles = StyleSheet.create({
  avatar: {},
  label: {
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontSize: 10
  },
});
export type CplAvatarProps = {
  showName?: boolean,
  userProfile: UserProfile;
  onPress?: (userProfile: UserProfile) => void;
};

export default function CplAvatar(props: CplAvatarProps): JSX.Element {
  const {
    showName,
    userProfile,
    onPress,
  } = props;
  const [imageSourceURL, setImageSourceURL] = React.useState(null);

  const storageRef = storage.ref();

  React.useEffect(() => {
    let isActive = true;
    try {
      const imageRef = storageRef.child('images/' + userProfile.userId + '.png');
      imageRef.getDownloadURL()
        .then(imageSrc => {
          if (isActive && imageSrc) {
            setImageSourceURL(imageSrc);
          }
        })
        .catch(error => {
          if (isActive) {
            setImageSourceURL(null);
          }
        });
    }
    catch (error){
      if (isActive) {
        setImageSourceURL(null);
      }
    }
    return () => {isActive = false;};
  }, [userProfile]);

  let avatar;
  if (showName) {
    avatar = (
      <View>
        {imageSourceURL && <Avatar.Image style={styles.avatar} size={40} source={{uri:imageSourceURL}}/>}
        <Text style={styles.label}>{userProfile.firstName}</Text>
      </View>);
  } else {
    avatar = (
      <View>
        {imageSourceURL && <Avatar.Image style={styles.avatar} size={40} source={{uri:imageSourceURL}}/>}
      </View>);
  }


  if (onPress) {
    return (
      <TouchableOpacity onPress={() => onPress(userProfile)}>
        {avatar}
      </TouchableOpacity>
    );
  }
  return avatar;

}
