import {all, call, put, takeEvery} from "redux-saga/effects";

import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import type {UserId} from "../../sharedDefs/userDefs";
import {ADMIN_ACTION} from "../reducer/adminReducer";
import {adminApplicationAssessServerApi} from "../serverApi";
import {NAV_ACTION} from "../structs/navigationStruct";

const debug = debugModule(DEBUG_MODULE.ADMIN.SAGA);

export function* adminCoachAssessSaga(action: {
  type: string;
  coachId: UserId;
}): any {
  try {
    const {
      type,
      coachId
    } = action;
    const body = {
      type,
      userId: coachId
    };
    yield call(adminApplicationAssessServerApi, body);
    yield put({
      type: NAV_ACTION.GOTO,
      url: '/a/coaches'
    });
    yield put({
      type: ADMIN_ACTION.COACH.ASSESS.SUCCESS
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: ADMIN_ACTION.COACH.ASSESS.FAILURE,
      error
    });
  }

  return action.coachId;
}
export default function* adminSagas(): Generator<any, any, any> {
  debug('adminSagas Start');
  yield all([
    takeEvery(ADMIN_ACTION.COACH.ASSESS.APPROVE, adminCoachAssessSaga),
    takeEvery(ADMIN_ACTION.COACH.ASSESS.DECLINE, adminCoachAssessSaga),
  ]);
}
