import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {inspect} from "util";
import type {State} from "../redux/reducer";
import {LOGGING} from "../redux/reducer/loggingReducer";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {useTestPage} from "../utils/environment";

const debug = debugModule(DEBUG_MODULE.NAVIGATION.SAGA);

const safeStringify = obj => {
  let cache = [];
  const indent = 2;
  const retVal = JSON.stringify(obj, (key, value) => typeof value === 'object' && value !== null ? cache.includes(value) ? undefined // Duplicate reference found, discard key
    : cache.push(value) && value // Store value in our collection
    : value, indent);
  cache = null;
  return retVal;
};


export default function TestPage(): JSX.Element {
  // const noPageElement:JSX.Element = (<div> No Page Element</div>);




  const dispatch = useDispatch();
  const actionLog = useSelector((state: State) => state.loggingReducer.actions);


  // const [pageElement, setPageElement] = React.useState(noPageElement);
  const [response, setResponse] = React.useState(<div/>);
  const [waitString, setWaitString] = React.useState('');
  const [actionString, setActionString] = React.useState('');
  const [pageNameString, setPageNameString] = React.useState('');
  const [log, setLog] = React.useState('');
  const pageNameChange = React.useCallback(e => {
    const text = e.target.value;
    if (text !== pageNameString) {
      setPageNameString(text);
    }
  }, []);
  const actionChange = React.useCallback(e => {
    const text = e.target.value;
    if (text !== actionString) {
      setActionString(text);
    }
  }, []);
  const waitForChange = React.useCallback(e => {
    const text = e.target.value;
    if (text !== waitString) {
      setWaitString(text);
    }
  }, [waitString]);
  const clearClick = React.useCallback(() => {
    setWaitString('');
    setActionString('');
    setLog('');
    setResponse(<div/>);
  }, [waitString, log, actionString, response]);
  const dispatchClick = React.useCallback(async () => {
    try {
      await dispatch({
        type: LOGGING.CLEAR_ACTIONS
      });
      debug('NAV dispatchPress actionSting=', actionString);
      const action = JSON.parse(actionString);
      await dispatch(action);
    } catch (error) {
      const responseAction = {
        type: 'ERROR',
        poss: 1,
        error: inspect(error)
      };
      setResponse(<div id="responseDivId">{JSON.stringify(responseAction)}</div>);
    }
  }, [response, actionString]);
  React.useEffect(() => {
    try {
      let logString = '';

      if (Array.isArray(actionLog)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const action of actionLog) {
          logString = logString + '\n' + inspect(action);

          if (action.type === waitString) {
            setResponse(<div id="responseDivId">{safeStringify(action)}</div>);
          }
        }
      } else {
        logString = 'actionLog not array ' + inspect(actionLog);
      }

      setLog(logString);
    } catch (error) {
      const responseAction = {
        type: 'ERROR',
        poss: 2,
        error: inspect(error)
      };
      setResponse(<div id="responseDivId">{JSON.stringify(responseAction)}</div>);
    }
  }, [actionLog]);


  // React.useEffect(() => {
  //   if (pageNameString === 'CoachClassMode'){
  //     setPageElement((<CoachClassMode/>))
  //   }
  //   else if (pageNameString === 'StudentClassMode') {
  //     setPageElement((<StudentClassMode/>))
  //   }
  //   else {
  //     setPageElement(noPageElement)
  //   }
  // }, [pageNameString]);


  if (!useTestPage){
    return <div/>;
  }
  return <div style={{height: 300}}>

    <div style={{height: 100}}>
      <input id="QQQwaitFor" type="text" onChange={waitForChange} value={waitString}/>
      <input id="QQQaction" type="text" onChange={actionChange} value={actionString}/>
      <input id="QQQpageName" type="text" onChange={pageNameChange} value={pageNameString}/>
      <button id="QQQdispatch" type="submit" onClick={dispatchClick} title="dispatch">Dispatch</button>
      <button id="QQQclear" type="button" onClick={clearClick} title="waitFor">Clear</button>
      <div>
        RESPONSE
      </div>
      {response}
      <div>
        LOG
      </div>
      <div>
        {log}
      </div>

    </div>

  </div>;
}
