import * as React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { CURRICULUM } from "../sharedDefs/curriculumDefs";
import { COLOR } from "../utils/UiConstants";
import Lottie from "./Lottie";
const styles = StyleSheet.create({
  buttonRow: {
    flex: 1,
    flexDirection: 'row'
  },
  smallButton: {
    backgroundColor: COLOR.GREEN,
    alignContent: 'center',
    width: 45,
    borderRadius: 10,
    margin: 10,
    height: 45
  },
  buttonText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 35,
    alignSelf: 'center'
  },
  buttonIcon: {
    width: 30,
    height: 30,
    alignSelf: 'center'
  }
});
export type ConfidenceSelectProps = {
  onKeyPress: (key: string) => void;
};
export default function ConfidenceSelect(props: ConfidenceSelectProps): JSX.Element {
  const {
    onKeyPress
  } = props;
  return <View style={styles.buttonRow}>
    <Pressable style={styles.smallButton} testID="confidenceVERYLow" onPress={() => {
      onKeyPress(CURRICULUM.CONFIDENCE.VERY_LOW);
    }}>
      <Lottie confidenceConstant={CURRICULUM.CONFIDENCE.VERY_LOW} />
    </Pressable>
    <Pressable style={styles.smallButton} testID="confidenceLow" onPress={() => {
      onKeyPress(CURRICULUM.CONFIDENCE.LOW);
    }}>
      <Lottie confidenceConstant={CURRICULUM.CONFIDENCE.LOW}  />
    </Pressable>
    <Pressable style={styles.smallButton} testID="confidenceMedium" onPress={() => {
      onKeyPress(CURRICULUM.CONFIDENCE.MEDIUM);
    }}>
      <Lottie confidenceConstant={CURRICULUM.CONFIDENCE.MEDIUM}  />
    </Pressable>
    <Pressable  testID={CURRICULUM.CONFIDENCE.HIGH} style={styles.smallButton}  onPress={() => {
      onKeyPress(CURRICULUM.CONFIDENCE.HIGH);
    }}>
      <Lottie confidenceConstant={CURRICULUM.CONFIDENCE.HIGH} />
    </Pressable>

    <Pressable testID={'CURRICULUM.CONFIDENCE.VERY_HIGH'} style={styles.smallButton}  onPress={() => {
      onKeyPress(CURRICULUM.CONFIDENCE.VERY_HIGH);
    }}>
      <Lottie confidenceConstant={CURRICULUM.CONFIDENCE.VERY_HIGH} />
    </Pressable>

  </View>;
}
