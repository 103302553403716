import * as React from "react";
import {Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import NumberKeypad from "./NumberKeypad";
import {blockStyles, gridStyles, rootStyles, textStyles} from "./WidgetStyles";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);
export function SingleDigitSelectWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    curriculumStudentResponse
  } = props;
  // problemValidate(problemValues, valueTypeSet);
  // const userId = useSelector((state: State) => state.userReducer.userId);
  const response: string = typeof curriculumStudentResponse?.responseKeyValues?.set?.response === 'string' ? curriculumStudentResponse?.responseKeyValues?.set?.response : '';
  return <View testID="SingleDigitSelectWidgetCoach" style={rootStyles.coachRoot}>
    <View style={gridStyles.gridRowContainer}>
      <Text style={textStyles.coachProblemTextLeft}>
        {curriculumStudentInteraction.problem.params.prompts.a}
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>
        {curriculumStudentInteraction.problem.params.prompts.op}
      </Text>
      <Text style={textStyles.coachProblemTextLeft}>{curriculumStudentInteraction.problem.params.prompts.b}</Text>
      <Text style={textStyles.coachProblemTextLeft}>=</Text>
      <Text style={textStyles.coachProblemTextLeft}>{response}</Text>

    </View>
  </View>;
}
export function SingleDigitSelectWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    onResponse
  } = props;
  // problemValidate(problemValues, valueTypeSet);
  //

  const [responseItem, setResponseItem] = React.useState('?');

  React.useEffect(() => {
    setResponseItem('?');
  }, [curriculumStudentInteraction.problem]);
  const onKeyPress = React.useCallback((e: any) => {
    debug('onKeyPress', e);
    let _answer = e;

    if (_answer.length > 1) {
      _answer = _answer.slice(-1);
    }

    if (_answer.length === 1 && _answer >= '0' && _answer <= '9') {
      setResponseItem(_answer);
      onResponse('response', _answer);
    } else {
      setResponseItem('');
    }
  },[]);
  return <View testID="SingleDigitSelectWidgetStudent" style={rootStyles.studentRoot}>
    <Text style={textStyles.titleBar}> Question</Text>

    <View style={gridStyles.gridRowContainer}>
      <Text style={textStyles.problemText}>{curriculumStudentInteraction.problem.params.prompts.a}</Text>
      <Text style={textStyles.problemText}>{curriculumStudentInteraction.problem.params.prompts.op}</Text>
      <Text style={textStyles.problemText}>{curriculumStudentInteraction.problem.params.prompts.b}</Text>
      <Text style={textStyles.problemText}>=</Text>
      <View style={blockStyles.problemBlockActive}><Text style={textStyles.problemText}>{responseItem}</Text></View>
    </View>
    <View style={gridStyles.gridRowContainer}>
      <NumberKeypad widgetWidth={400} inWorkbench={false} onKeyPress={onKeyPress} />
    </View>
    <View style={gridStyles.gridRowContainer}>
      {
        /* <CplButton style={styles.actionButton} onPress={onButtonNext}>Next</CplButton> */
      }
    </View>
  </View>;
}

const defaultProgressionSetId = 'Math.K.OA.A.2.Add1Within10.1';
const SingleDigitSelectWidget: WidgetSet = {
  widgetSetId: 'SingleDigitSelectWidget',
  coach: SingleDigitSelectWidgetCoach,
  student: SingleDigitSelectWidgetStudent,
  defaultProgressionSetId,

};
export default SingleDigitSelectWidget;
