import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import type { UserProfile } from "../../sharedDefs/userDefs";
type CoachCardProps = {
  user: UserProfile;
  width:number;
};
const styles = StyleSheet.create({});
export default function AdminCoachCard(props: CoachCardProps): JSX.Element {
  const {
    user
  } = props;

  const hire = () => {};

  const detailOpen = () => {};

  return <View testID="AdminCoachCard"><Text>AdminCoachCard</Text></View>; // <Card style={classNames(styles.cardCoach, isWidthUp('sm', width))}>
  //     <View style={styles.status}>
  //       {user.status === 'Hired' && (
  //         <Chip label="Hired" style={styles.chipDiscount} />
  //       )}
  //       {user.status === 'Applied' && (
  //         <Chip label="Applied" style={styles.chipDiscount} />
  //       )}
  //       {user.status === 'Active' && (
  //         <Chip label="Active" style={styles.chipDiscount} />
  //       )}
  //     </View>
  //     <CardMedia
  //       style={styles.mediaCoach}
  //       // image={user.thumbnail}
  //       title={user.email}
  //     />
  //     <CardContent style={styles.floatingButtonWrap}>
  //       {user.status === 'Applied' && (
  //         <Tooltip title="Add to cart" placement="top">
  //           <Fab onClick={hire} size="small" color="secondary" aria-label="add" style={styles.buttonAdd}>
  //             Applied
  //           </Fab>
  //         </Tooltip>
  //       )}
  //       <Text noWrap gutterBottom variant="h5" style={styles.title} component="h2">
  //         {user.email}
  //       </Text>
  //       <Text component="p" style={styles.desc}>
  //         {user.role}
  //       </Text>
  //       <View style={styles.ratting}>
  //         some kind of rating
  //       </View>
  //     </CardContent>
  //     <CardActions style={styles.price}>
  //       <Text variant="h5">
  //           {user.role}
  //       </Text>
  //
  //       <View style={styles.rightAction}>
  //         <Button size="small" variant="outlined" color="secondary" onClick={detailOpen}>
  //           See Detail
  //         </Button>
  //         {user.status === 'Applied' && (
  //           <Tooltip title="Add to cart" placement="top">
  //             <IconButton color="secondary" onClick={hire} style={styles.buttonAddList}>
  //               <AddShoppingCart />
  //             </IconButton>
  //           </Tooltip>
  //         )}
  //       </View>
  //     </CardActions>
  //   </Card>
  // );
}
