import * as React from "react";
import {Image, StyleSheet, Text, View} from "react-native";

import {WidgetSet, WidgetStudentPropsType} from "./widgetStructs";
import type {WidgetCoachPropsType} from "../sharedDefs/curriculumDefs";
import {COLOR} from "../utils/UiConstants";

const styles = StyleSheet.create({
  image: {
    height: 270,
    width: 640
  },
  coachImage: {
    height: 100,
    width: 200
  },
  studentInstructionRoot: {
    display: 'flex',
    width: '100%',
    maxWidth: 700,
    zIndex: 1,
    position: 'relative',
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  },
  coachInstructionRoot: {
    display: 'flex',
    width: '100%',
    maxWidth: 700,
    zIndex: 1,
    position: 'relative',
    backgroundColor: COLOR.LT_BLUE,
    borderRadius: 10,
    flexDirection: 'column',
    alignContent: 'center'
  }
});
export function InstructionsWidgetCoach(props: WidgetCoachPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;
  // const {} = problem?.params.prompts || {};
  // const {} = responseData.responseKeyValues?.set || {};
  //const url = '../../assets/mocks/' + (problem?.imageUrl || '');

  const imageSrc = function imageLocator(imageId) {
    switch (imageId) {
      case 'division_1':
        return require('../assets/mocks/division_1.png');

      case 'division_2':
        return require('../assets/mocks/division_2.png');

      default:
        return require('../assets/student1.png');
    }
  }(problem?.imageUrl);

  return <View testID="InstructionsWidgetCoach" style={styles.coachInstructionRoot}>
    <Image style={styles.coachImage} source={imageSrc} />
  </View>;
}
export function InstructionsWidgetStudent(props: WidgetStudentPropsType): JSX.Element {
  const {
    curriculumStudentInteraction,
    onResponse
  } = props;
  const {
    problem,
  } = curriculumStudentInteraction;
  React.useEffect(() => {
    onResponse('instruction', 'instruction');
  }, [problem]);

  const imageSrc = function imageLocator(imageId) {
    switch (imageId) {
      case 'division_1':
        return require('../assets/mocks/division_1.png');

      case 'division_2':
        return require('../assets/mocks/division_2.png');

      default:
        return require('../assets/student1.png');
    }
  }(problem?.imageUrl);

  return <View testID="InstructionsWidgetStudent" style={styles.studentInstructionRoot}>
    <Text style={{
      fontFamily: 'ArialRoundedMTBold',
      fontSize: 20,
      textAlign: 'center',
      padding: 2
    }}>
        Instructions
    </Text>
    <Image style={styles.image} source={imageSrc} />
  </View>;
}

const defaultProgressionSetId = 'Instructions';
const InstructionsWidget: WidgetSet = {
  widgetSetId: 'InstructionsWidget',
  coach: InstructionsWidgetCoach,
  student: InstructionsWidgetStudent,

  defaultProgressionSetId,

};
export default InstructionsWidget;
