import {CoachId} from "../../sharedDefs/userDefs";
import {AUDIO_MODE, HUDDLE, HuddleUser} from "../../sharedDefs/huddleDefs";
import {Image, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {
  CURRICULUM,
  CurriculumStudentInteraction,
  CurriculumStudentProgression,
  curriculumWidgetSetChangeRequest,
  ProgressionSet
} from "../../sharedDefs/curriculumDefs";
import {Menu} from "react-native-paper";
import {COLOR} from "../../utils/UiConstants";
import * as React from "react";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import {useDispatch} from "react-redux";
import {firestore} from "../../redux/rsf";
import {queryProgressionSets} from "../../redux/queries/SessionQueries";
import {
  curriculumProgressionSetAssignRequest,
  curriculumProgressionSetProblemAdvanceRequest
} from "../../redux/reducer/curriculumReducer";
import {setSpeechMode} from "../../redux/reducer/speechReducer";
import {SPEECH_MODE} from "../../sharedDefs/speechDefs";
import {huddleAudioMode} from "../../redux/reducer/huddleReducer";
import {COLLECTION} from "../../sharedDefs/firestoreDefs";

const debug = debugModule(DEBUG_MODULE.WEBCON.COACH);
const debugSpeech = debugModule(DEBUG_MODULE.WEBCON.SPEECH);


const styles = StyleSheet.create({
  talkee: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    flex: 1
  },
  menuButton: {
    alignSelf: 'flex-end',
    marginRight: 10,
    height: 40,
    width: 40,
    flex: 1
  },

  footer: {
    opacity: 0.6,
    borderColor: COLOR.BASE,
    height: 60,
    maxHeight: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
    flex: 16
  },
  progressionButton: {
    width: 150,
    height: '100%'
  },
  footer_progression: {
    alignSelf: 'flex-start',
    flex: 1,
    maxWidth: 300,
    height: 30,
    justifyContent: 'center'
  },
  footer_HEY_COACH: {
    backgroundColor: COLOR.RED
  },
  footer_COMMUNICATING: {
    backgroundColor: COLOR.LT_BLUE
  },
  footer_OFF: {
    backgroundColor: COLOR.GREY
  },
  footer_CONNECTING: {
    backgroundColor: COLOR.YELLOW
  },
  buttonIcon: {
    width: 50,
    height: 40
  }
});
type CoachsStudentWebConFooterPropType = {
  studentHuddleUser: HuddleUser;
  coachId: CoachId;
};
export default function CoachsStudentWebConFooter(props: CoachsStudentWebConFooterPropType): JSX.Element {

  const dispatch = useDispatch();
  const {studentHuddleUser,
    coachId
  } = props;
  const {
    userProfile,
    audioMode,
    heyCoach
  } = studentHuddleUser;
  let footerColor = styles.footer_OFF;

  const studentId = userProfile.userId;
  const [curriculumStudentInteraction, setCurriculumStudentInteraction] = React.useState<CurriculumStudentInteraction>(null);

  const [curriculumStudentProgression, setCurriculumStudentProgression] = React.useState<CurriculumStudentProgression>(null);
  const [progressionSetList, setProgressionSetList] = React.useState<Array<ProgressionSet>>([]);
  const [widgetIdMenuVisible, setWidgetIdMenuVisible]= React.useState(false);
  const [progressionMenuVisible, setProgressionMenuVisible] = React.useState(false);
  const [menuVisible, setMenuVisible] = React.useState(false);
  React.useEffect(() => queryProgressionSets(_progressionSetList => {
    debug('useEffect queryProgressionSets');
    setProgressionSetList(_progressionSetList);
  }), []);

  React.useEffect(() => {
    if (studentId) {
      const unsubscribe = firestore.collection(COLLECTION.CURRICULUM_STUDENT_PROGRESSION).doc(studentId).onSnapshot(snap => {
        const _curriculumStudentProgression = snap.data() as CurriculumStudentProgression;
        setCurriculumStudentProgression(_curriculumStudentProgression);
      });

      //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
      return () => unsubscribe();
    }
    else {
      setCurriculumStudentProgression(null);
    }
  }, [studentId]);

  React.useEffect(() => {
    if (studentId) {
      const unsubscribe = firestore.collection(COLLECTION.CURRICULUM_STUDENT_INTERACTION).doc(studentId).onSnapshot(snap => {
        const _curriculumStudentInteraction = snap.data() as CurriculumStudentInteraction;
        setCurriculumStudentInteraction(_curriculumStudentInteraction);
      });

      //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
      return () => unsubscribe();
    }
    else {
      setCurriculumStudentProgression(null);
    }
  }, [studentId]);


  const assignProgressionSet = React.useCallback((progressionSet: ProgressionSet) => {
    debug('assignProgressionSet', progressionSet);
    dispatch(curriculumProgressionSetAssignRequest(studentId, coachId, progressionSet?.progressionSetId, null));
  }, []);



  const problemAdvanceCallback = React.useCallback(() => {
    debugSpeech('useCallback problemAdvanceCallback');
    dispatch(curriculumProgressionSetProblemAdvanceRequest(studentId, coachId));
  }, []);
  const onWalkieTalkieStart = React.useCallback(() => {
    debugSpeech('useCallback onWalkieTalkieStart');
    dispatch(setSpeechMode(SPEECH_MODE.DICTATING, studentId));
    dispatch(huddleAudioMode({type: 'CoachStudentWebCon'}, studentId, AUDIO_MODE.WALKIE_TALKIE));
  }, []);
  const onWalkieTalkieEnd = React.useCallback(() => {
    debugSpeech('useCallback onWalkieTalkieEnd');
    dispatch(setSpeechMode(SPEECH_MODE.OFF, studentId));
    dispatch(huddleAudioMode({type: 'CoachStudentWebCon'}, studentId, AUDIO_MODE.OFF));

  }, []);

  const dismissStudent = React.useCallback(() => {
    debug('useCallback dismissStudent TODO');
  }, []);

  const changeWidgetId = (widgetSetId:string)=> {
    dispatch(curriculumWidgetSetChangeRequest(studentId,coachId,curriculumStudentInteraction,widgetSetId));
  };

  if (heyCoach) {
    footerColor = styles.footer_HEY_COACH;
  }
  if (audioMode === AUDIO_MODE.WALKIE_TALKIE) {
    debug(' footer ');
    footerColor = styles.footer_COMMUNICATING;
  } else if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ADMITTED || studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVED || studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVING || studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERING) {
    debug(' footer CONNECTING');
    footerColor = styles.footer_CONNECTING;
  } else {
    // debug(' fall through', studentHuddleUser.huddleUserStatus, speechMode);
  }
  console.log('curriculumStudentProgression?.progressionMode',curriculumStudentProgression?.progressionMode);
  console.log('curriculumStudentProgression?.progressionSet?.progressionSetName || curriculumStudentProgression?.assessment?.assessmentName',curriculumStudentProgression?.progressionSet?.progressionSetName , curriculumStudentProgression?.assessment?.assessmentName);
  console.log('curriculumStudentProgression',studentId, !!curriculumStudentProgression, !!curriculumStudentProgression?.progressionSet,  !!curriculumStudentProgression?.assessment);

  return (
    <View testID="footer" style={[styles.footer, footerColor]}>
      <View style={{flex: 1}}>
        { curriculumStudentProgression?.progressionMode !== CURRICULUM.PROGRESSION_MODE.COMPLETED &&(
          <View style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text style={styles.footer_progression}>
              <Menu visible={progressionMenuVisible} onDismiss={() => {setProgressionMenuVisible(false);}}
                anchor={<TouchableOpacity onPress={() => {setProgressionMenuVisible(true);}} style={[styles.progressionButton, {
                  flexDirection: 'row'
                }]} activeOpacity={0.5}>
                  <Text style={{
                    justifyContent: 'center',
                    fontFamily: 'ArialRoundedMTBold',
                    fontSize: 20,
                    marginTop: 6,
                    textAlign: 'center'
                  }}>
                    {curriculumStudentProgression?.progressionSet?.progressionSetName || curriculumStudentProgression?.assessment?.assessmentName}
                  </Text>
                  <Image source={require('../../assets/icons/arrow_drop_down-24px-5 1.png')}
                    style={styles.buttonIcon}/>
                </TouchableOpacity>}>
                {progressionSetList.map((progressionSet, index) => <Menu.Item key={index.toString()}
                  onPress={() => {
                    assignProgressionSet(progressionSet);
                  }} title={progressionSet.progressionSetName}/>)}
              </Menu>
            </Text>

            <TouchableOpacity style={{
              width: 30,
              height: 30,
              borderColor: COLOR.BASE,
              backgroundColor: COLOR.GREEN,
              borderWidth: 1,
              alignItems: 'center',
              alignSelf: 'center'
            }} onPress={problemAdvanceCallback}>
              <Image style={{
                height: 30,
                width: 30,
                resizeMode: 'center'
              }} source={require('../../assets/icons/play_circle-24px 1.png')}/>
            </TouchableOpacity>
          </View>)
        }
        {curriculumStudentProgression?.progressionMode === CURRICULUM.PROGRESSION_MODE.COMPLETED && (
          <Text style={styles.footer_progression}>
            <Menu visible={progressionMenuVisible} onDismiss={() => {setProgressionMenuVisible(false);}}
              anchor={<TouchableOpacity  onPress={() => {setProgressionMenuVisible(true);}}  style={[styles.progressionButton, {
                flexDirection: 'row'
              }]} activeOpacity={0.5}>
                <Text style={{
                  justifyContent: 'center',
                  fontFamily: 'ArialRoundedMTBold',
                  fontSize: 20,
                  marginTop: 6,
                  textAlign: 'center'
                }}>
                Progression
                </Text>
                <Image source={require('../../assets/icons/arrow_drop_down-24px-5 1.png')}
                  style={styles.buttonIcon}/>
              </TouchableOpacity>}>
              {progressionSetList.map((progressionSet, index) => <Menu.Item key={index.toString()} onPress={() => {
                assignProgressionSet(progressionSet);
              }} title={progressionSet.progressionSetName}/>)}
            </Menu>
          </Text>)
        }
      </View>

      <View style={{flex: 1}}>
        { curriculumStudentInteraction?.problem?.widgetIdList?.length > 1 &&(
          <View style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text style={styles.footer_progression}>
              <Menu visible={widgetIdMenuVisible} onDismiss={() => {setWidgetIdMenuVisible(false);}}
                anchor={<TouchableOpacity onPress={() => {setWidgetIdMenuVisible(true);}} style={[styles.progressionButton, {
                  flexDirection: 'row'
                }]} activeOpacity={0.5}>
                  <Text style={{
                    justifyContent: 'center',
                    fontFamily: 'ArialRoundedMTBold',
                    fontSize: 20,
                    marginTop: 6,
                    textAlign: 'center'
                  }}>
                    {curriculumStudentInteraction?.problem?.widgetSetId}
                  </Text>
                  <Image source={require('../../assets/icons/arrow_drop_down-24px-5 1.png')}
                    style={styles.buttonIcon}/>
                </TouchableOpacity>}>
                {curriculumStudentInteraction?.problem?.widgetIdList.map((widgetId, index) => <Menu.Item key={index.toString()}
                  onPress={() => {
                    changeWidgetId(widgetId);
                  }} title={widgetId}/>)}
              </Menu>
            </Text>
          </View>
        )}
      </View>
      <View style={{flex: 1, flexDirection: "row"}}>
        {
          (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED && audioMode !== AUDIO_MODE.WALKIE_TALKIE) && (
            <View style={styles.talkee}
              onStartShouldSetResponder={() => true}
              onResponderStart={onWalkieTalkieStart}
              onResponderRelease={onWalkieTalkieEnd}>
              < Image
                source={require('../../assets/icons/talkee.png'
                )
                }
                style={styles.buttonIcon}
              />
            </View>)
        }
        {
          (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED && audioMode === AUDIO_MODE.WALKIE_TALKIE) && (
            <View style={styles.talkee}
              onStartShouldSetResponder={() => true}
              onResponderStart={onWalkieTalkieEnd}
            >
              < Image
                source={require('../../assets/icons/talkee.png'
                )
                }
                style={styles.buttonIcon}
              />
            </View>)
        }
        {
          (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVING || studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERING) &&
        (<View>
          <Text>Connecting .....</Text>
        </View>)
        }

      </View>
      <View style={{flex: 1}}>
        {function () {
          if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERED) {
            return (
              <Menu visible={menuVisible}
                onDismiss={()=>{setMenuVisible(false);}}
                anchor={
                  <TouchableOpacity onPress={()=>{setMenuVisible(true);}} style={styles.menuButton} activeOpacity={0.5}>

                    <Image source={require('../../assets/icons/settingsMenu.png')} style={styles.buttonIcon}/>
                  </TouchableOpacity>
                }>
                <Menu.Item
                  onPress={dismissStudent}
                  title="Dismiss Student"/>
                <Menu.Item

                  title="Remote Control"/>
                <Menu.Item

                  title=" Alert Parent"/>

              </Menu>);
          }

          if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVED) {
            return (
              <Menu
                visible={menuVisible}
                onDismiss={()=>{setMenuVisible(false);}}
                anchor={
                  <TouchableOpacity onPress={()=>{setMenuVisible(true);}} style={styles.menuButton} activeOpacity={0.5}>

                    <Image source={require('../../assets/icons/settingsMenu.png')} style={styles.buttonIcon}/>
                  </TouchableOpacity>
                }>
                <Menu.Item
                  onPress={dismissStudent}
                  title="Dismiss Student"/>
                <Menu.Item

                  title="Remote Control"/>
                <Menu.Item

                  title=" Alert Parent"/>
              </Menu>);
          }

          if (studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ARRIVING || studentHuddleUser.huddleUserStatus === HUDDLE.USER_STATUS.ENTERING) {
            return (
              <View/>
            );
          }
          return (
            <Menu visible={menuVisible}  onDismiss={()=>{setMenuVisible(false);}}
              anchor={<TouchableOpacity onPress={()=>{setMenuVisible(true);}} style={styles.menuButton} activeOpacity={0.5}>

                <Image source={require('../../assets/icons/settingsMenu.png')} style={styles.buttonIcon}/>
              </TouchableOpacity>}>
              <Menu.Item title=" Alert Parent"/>
            </Menu>

          );
        }()
        }
      </View>

    </View>
  );
}
