/* eslint-disable no-new-func */
import seedrandom from "seedrandom";
import type {Problem, ProblemParams, ProgressionSetId, WidgetSetId} from "./curriculumDefs";
import {ProblemGenerationParams} from "./curriculumDefs";
import invariant from "invariant";


export type MultipleChoiceProblemParams = {
  question: string,
  correct: string;
  alternativeStrings: Array<string>;
  questionImage: string,
  correctImage: string;
  alternativeImages: Array<string>,
}


export type AdditionProblemParams = {
  minA:number,
  maxA:number,
  minB:number,
  maxB:number,
  minSum:number,
  maxSum:number,
}

export type SubtractionProblemParams = {
  minA:number,
  maxA:number,
  minB:number,
  maxB:number,
  minDifference:number,
  maxDifference:number,
}


export type MultiplicationProblemParams = {
  minA:number,
  maxA:number,
  minB:number,
  maxB:number,
  minProduct:number,
  maxProduct:number,
}

export type CompareProblemParams = {
  minA :number, maxA :number, minB:number, maxB :number, differenceValueString: string
}


export type DivisionProblemParams = {
  minDivisor:number,
  maxDivisor:number, minDividend:number, maxDividend:number, minQuotient:number, maxQuotient:number
}

export type MultipleChoiceListParams = {

  problemParams: Array<MultipleChoiceProblemParams>
}



export type AdditionPartnerParams = {
  min: number;
  max: number,
}


function filterTrimProblems(numberOfProblems: number,problemRandomSeed:string, problems: Array<ProblemParams>) {
  if (problemRandomSeed) {

    const filteredProblems: Array<ProblemParams> = [];
    const problemSet = new Set();
    const seededRandom = seedrandom(problemRandomSeed);

    while (filteredProblems.length < numberOfProblems) {
      const index = Math.floor(seededRandom() * (problems.length + 1));

      const p = problems[index];

      if (!problemSet.has(p)) {
        problemSet.add(p);
        filteredProblems.push(p);
      }
    }

    return filteredProblems;
  } else {
    if (numberOfProblems >= problems.length) {
      return problems;
    }
    return problems.slice(0, numberOfProblems);
  }
}
export const ProblemGeneration : Record<string,( numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:ProblemGenerationParams) => Array<ProblemParams>> = {

  additionGeneration: (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:AdditionProblemParams): Array<ProblemParams> => {
    const problems: Array<ProblemParams> = [];
    console.log('additionGeneration');
    const {
      minA,maxA,
      minB,maxB,
      minSum,maxSum,
    } = problemGenerationParams ;
    for (let a = minA; a <= maxA; a += 1) {
      for (let b = Math.max(minB, minSum - a); a + b <= maxSum && b <= maxB; b += 1) {
        problems.push({
          prompts: {
            a: a.toString(),
            b: b.toString()
          },
          expectedResponseKeyValues: {
            set: {
              sum: (a + b).toString()
            }
          }
        });
      }
    }
    return filterTrimProblems(numberOfProblems,problemRandomSeed, problems);
  },


  subtractionGeneration: (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:SubtractionProblemParams): Array<ProblemParams> => {
    const problems: Array<ProblemParams> = [];
    console.log('subtractionGeneration');
    const {
      minA,maxA,
      minB,maxB,
      minDifference,maxDifference,
    } = problemGenerationParams ;

    for (let a = minA; a <= maxA; a += 1) {
      for (let b = Math.max(minB, a - maxDifference); a - b >= minDifference && b <= maxB; b += 1) {
        problems.push({
          prompts: {
            a: a.toString(),
            b: b.toString()
          },
          expectedResponseKeyValues: {
            set: {
              difference: (a - b).toString()
            }
          }
        });
      }
    }
    return filterTrimProblems(numberOfProblems,problemRandomSeed,  problems);

  },


  multiplicationGeneration:(numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:MultiplicationProblemParams): Array<ProblemParams> => {
    const problems: Array<ProblemParams> = [];
    console.log('multiplicationGeneration');
    const {
      minA,maxA,
      minB,maxB,
      minProduct,maxProduct,
    } = problemGenerationParams ;
    for (let a = minA; a <= maxA; a += 1) {
      for (let b = Math.max(minB, Math.round(minProduct / a)); a * b <= maxProduct && b <= maxB; b += 1) {
        if (b >= minB && (a * b >= minProduct)) {
          problems.push({
            prompts: {
              a: a.toString(),
              b: b.toString()
            },
            expectedResponseKeyValues: {
              set: {
                product: (a * b).toString()
              }
            }
          });
        }
      }
    }
    return filterTrimProblems(numberOfProblems, problemRandomSeed, problems);
  },


  divisionGeneration: (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:DivisionProblemParams): Array<ProblemParams> => {
    const problems: Array<ProblemParams> = [];
    const {
      minDivisor, maxDivisor, minDividend, maxDividend, minQuotient, maxQuotient
    } = problemGenerationParams ;
    console.log('divisionGeneration', minDivisor, maxDivisor, minDividend, maxDividend, minQuotient, maxQuotient);
    for (let divisor = minDivisor; divisor <= maxDivisor; divisor += 1) {
      for (let quotient = minQuotient; quotient <= maxQuotient; quotient += 1) {

        if (((divisor * quotient) >= minDividend) && ((divisor * quotient) <= maxDividend)) {
          problems.push({
            prompts: {
              dividend: (divisor * quotient).toString(),
              divisor: divisor.toString()
            },
            expectedResponseKeyValues: {
              set: {
                quotient: quotient.toString()
              }
            }
          });
        }
      }
    }

    return filterTrimProblems(numberOfProblems, problemRandomSeed, problems);
  },

  compareGeneration: (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:CompareProblemParams): Array<ProblemParams> => {
    const {
      minA, maxA, minB, maxB, differenceValueString
    } = problemGenerationParams ;
    const problems: Array<ProblemParams> = [];

    let differenceValues: Array<number> ;
    try {
      differenceValues = JSON.parse(differenceValueString);
    } catch (e) {
      console.log('parse error -' + differenceValueString + '-');
      differenceValues = [];
    }
    console.log('compareGeneration', differenceValues, differenceValueString);
    for (let a = minA; a <= maxA; a += 1) {
      for (let i = 0; i < differenceValues.length; i += 1) {
        const diff = differenceValues[i];

        if (minB <= (a - diff) && (a - diff) <= maxB) {
          const b = a - diff;
          let compare = '=';
          if (a > b) {
            compare = '>';
          }
          if (a < b) {
            compare = '<';
          }
          problems.push({
            prompts: {
              a: a.toString(),
              b: b.toString()
            },
            expectedResponseKeyValues: {
              set: {
                compare
              }
            }
          });
        }
        if (minB <= (a + diff) && (a + diff) <= maxB) {
          const b = a + diff;
          let compare = '=';
          if (a > b) {
            compare = '>';
          }
          if (a < b) {
            compare = '<';
          }
          problems.push({
            prompts: {
              a: a.toString(),
              b: b.toString()
            },
            expectedResponseKeyValues: {
              set: {
                compare
              }
            }
          });
        }
      }
    }

    return filterTrimProblems(numberOfProblems, problemRandomSeed, problems);
  },


  additionPartnerGeneration: (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:AdditionPartnerParams): Array<ProblemParams> => {
    const {
      min, max    } = problemGenerationParams ;

    const problems: Array<ProblemParams> = [];

    for (let i = min; i <= max; i += 1) {
      const prompts = {
        sum: i.toString(),
        op: 'partner'
      };
      const setList = [];

      for (let j = 1; j < i; j += 1) {
        const pair = {
          a: j.toString(),
          b: (i - j).toString()
        };
        setList.push(pair);
      }
      if (setList.length > 0) {
        const expectedResponseKeyValues = {
          setList
        };
        problems.push({
          prompts,
          expectedResponseKeyValues
        } as ProblemParams);
      }
    }
    return filterTrimProblems(numberOfProblems, problemRandomSeed, problems);
  },


  multipleChoiceGeneration :  (numberOfProblems: number,problemRandomSeed:string, problemGenerationParams:MultipleChoiceListParams): Array<ProblemParams> => {
    const {
      problemParams  } = problemGenerationParams ;

    console.log('multipleChoiceGeneration 1');
    const problems: Array<ProblemParams> = [];
    const seededRandom = seedrandom(problemRandomSeed || "SEED" + Date.now());

    problemParams?.forEach((params:MultipleChoiceProblemParams)=> {

      const numAlts = Math.max(params.alternativeStrings.length, params.alternativeImages.length);
      const answer = Math.floor(seededRandom() * (numAlts));

      // if (numAlts > 5) {
      //   console.log('params.alternativeStrings.length,params.alternativeImages.length', params.alternativeStrings.length, params.alternativeImages.length);
      // }

      const prompts = {
        question: params.question,
        questionImage: params.questionImage,

      };

      let poss = 0;
      for (let i = 0; i < numAlts; i += 1) {

        if (poss === answer) {
          poss++;
        }
        prompts['choiceString' + poss] = params.alternativeStrings[i];
        prompts['choiceImage' + poss] = params.alternativeImages[i];
        poss++;

      }
      prompts['choiceString' + answer] = params.correct;
      prompts['choiceImage' + answer] = params.correctImage;



      const expectedResponseKeyValues = {
        answer
      };
      problems.push({
        prompts,
        expectedResponseKeyValues
      } as ProblemParams);

    });
    return filterTrimProblems(numberOfProblems, problemRandomSeed, problems);
  },


  noGenerator : (() =>
  {
    return [];
  })
};
function getRandomSubarray(arr, size) {
  const shuffled = arr.slice(0);
  let i = arr.length;
  let temp;
  let index;
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}
function createProblem(params: ProblemParams, widgetIdList: Array<WidgetSetId>,progressionSetId: ProgressionSetId,problemOrder:number) : Problem {
  const problemId = progressionSetId + '_' + problemOrder;
  const widgetSetId = (widgetIdList?.length > 0)? widgetIdList?.[0]: null;
  invariant(widgetIdList && widgetIdList.length > 0,'WidgetIdList createProblem');

  return {
    problemId,
    problemOrder,
    params,
    widgetSetId,
    widgetIdList,
    progressionSetId
  };
}



export const ProgressionSetUtil = {
  createProblemList: ({
    problemGenerationFunction,
    problemGenerationParams,
    numberOfProblems,
    problemRandomSeed,
    instructions,
    progressionSetId,
    widgetIdList,
  }:{
    problemGenerationFunction:string,
    problemGenerationParams:ProblemGenerationParams,
    numberOfProblems:number,
    problemRandomSeed:string,
    instructions:string,
    widgetIdList:Array<WidgetSetId>
    progressionSetId:ProgressionSetId,

}): Array<Problem> => {


    let instructionProblems = [];

    if (instructions && instructions.length > 0) {
      try {
        const _instructions = JSON.parse(instructions);
        instructionProblems = _instructions.map((imageUrl, problemOrder) => {
          const problemId = progressionSetId + '_' + problemOrder;
          const params: ProblemParams = {
            prompts: {},
            expectedResponseKeyValues: {
              set: {}
            }
          };
          return {
            problemId,
            problemOrder,
            params,
            widgetSetId: 'InstructionsWidget',
            widgetIdList: ['InstructionsWidget'],
            progressionSetId: progressionSetId,
            imageUrl
          } as Problem;
        });
      } catch (error) {
        console.error('Parsing instructions' + instructions);
      }
    }
    console.log('createProblemList ', problemGenerationFunction, !!problemGenerationParams);
    if (problemGenerationFunction && problemGenerationParams) {
      const problemParamsList = ProblemGeneration[problemGenerationFunction](numberOfProblems,problemRandomSeed,problemGenerationParams );
      invariant(widgetIdList && widgetIdList.length > 0,'WidgetIdList createProblemList');

      let _problems = problemParamsList.map((problemParams, index) => createProblem(problemParams,widgetIdList,progressionSetId, index + instructionProblems.length));
      if (numberOfProblems && numberOfProblems < _problems.length){
        _problems = getRandomSubarray(_problems, numberOfProblems);
      }
      return [...instructionProblems, ..._problems];
    }

    return [];
  }
};
