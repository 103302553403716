import invariant from "invariant";
import {all, call, put, select, takeEvery} from "redux-saga/effects";
import ResponseDataUtil from "../../curriculum/ResponseDataUtil";
import type {
  CurriculumAction,
  CurriculumStudentInteraction,
  CurriculumStudentProgression,
  CurriculumStudentResponse
} from "../../sharedDefs/curriculumDefs";
import {CURRICULUM_ACTION, curriculumProgressionSetAssignSuccess,} from "../../sharedDefs/curriculumDefs";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";
import type {State} from "../reducer";
import {chatAssignProgression, chatCompleteProgression} from "../reducer/chatReducer";
import {
  curriculumProgressionSetCompletedRequest,
  curriculumProgressionSetProblemAdvanceRequest,
  curriculumStudentResponsePerform
} from "../reducer/curriculumReducer";
import {
  curriculumInteractionAdvanceServerApi,
  curriculumProgressionSetAssignServerApi,
  curriculumProgressionSetCompletedServerApi,
  curriculumResponsePerformServerApi,
  curriculumSubmitPerformServerApi,
  curriculumWidgetSetChangeRequestServerApi,
} from "../serverApi";

import {
  CurriculumInteractionAdvanceBody,
  CurriculumProgressionSetAssignResult,
  CurriculumWidgetSetChangeRequestResult
} from "../../sharedDefs/serverApiDefs";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.SAGA);
export type Saga<T> = Generator<any, T, any>;



function* curriculumProgressionSetProblemAdvanceSaga(action: CurriculumAction) {
  debug('curriculumProgressionSetProblemAdvanceSaga start');

  try {
    const {
      studentId,
      coachId,
    } = action;
    invariant(studentId && coachId, 'invalid-action');
    const curriculumStudentProgression: CurriculumStudentProgression = yield select((state: State) => state.curriculumReducer.curriculumStudentProgressionMap[studentId]);
    const curriculumStudentInteraction: CurriculumStudentInteraction = yield select((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
    invariant(curriculumStudentInteraction && curriculumStudentProgression, 'invalid-action');
    const positionValue = curriculumStudentInteraction.position;
    const {
      problemList
    } = curriculumStudentProgression;

    if (positionValue + 1 > problemList.length) {

      yield put(chatCompleteProgression(studentId, coachId, studentId));
      yield put({
        type: CURRICULUM_ACTION.PROGRESSION_SET.ADVANCE_SET.REQUEST
      }as CurriculumAction);
    } else {
      const position = positionValue + 1;
      const problem = problemList[position];

      if (!problem) {
        const coachId = yield select((state: State) => state.huddleReducer.huddle?.coachId);
        yield put(chatCompleteProgression(studentId, coachId, studentId));
        yield put(curriculumProgressionSetCompletedRequest(studentId, coachId));
        return;
      }

      const {
        widgetSetId,
      } = problem || {};

      invariant(coachId, 'Coach null');
      const curriculumInteractionAdvanceBody :CurriculumInteractionAdvanceBody = {
        problem,
        position,
        coachId,
        widgetSetId,
        studentId
      };
      // const curriculumInteractionAdvanceResponse =
      yield call(curriculumInteractionAdvanceServerApi, curriculumInteractionAdvanceBody);
      // QQQQ;
      // const curriculumStudentInteraction : CurriculumStudentInteraction = curriculumInteractionAdvanceResponse.data.curriculumStudentInteraction;
      // const curriculumStudentResponse: CurriculumStudentResponse = curriculumInteractionAdvanceResponse.data.curriculumStudentResponse;
      // debug('curriculumInteractionAdvanceServerApi', curriculumStudentInteraction);
      // invariant(curriculumStudentInteraction.interactionId, 'curriculumInteraction.interactionId null');
      // yield put(chatAddInteraction(studentId, coachId, studentId, curriculumStudentInteraction,curriculumStudentResponse));
      // yield put({
      //   type: CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.SUCCESS,
      //   problem,
      //   position,
      //   studentId,
      //   curriculumStudentInteraction,
      //   curriculumStudentResponse
      // }as CurriculumAction);
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: CURRICULUM_ACTION.STUDENT_RESPONSE.FAILURE,
      error
    }as CurriculumAction);
  }
}

function* curriculumSubmitPerformSaga(action: CurriculumAction) {
  debug('problemSubmitSaga start');

  try {
    const {

      curriculumStudentResponse,
      coachId,
      studentId
    } = action;
    invariant(curriculumStudentResponse && curriculumStudentResponse.confidence && studentId, 'invalid-action');
    const curriculumStudentInteraction = yield select((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
    const {
      problem,
    } = curriculumStudentInteraction;
    const assignedResponseData = ResponseDataUtil.responseStatusSet(curriculumStudentResponse, problem);
    yield put({
      type: CURRICULUM_ACTION.SUBMIT.UPDATE,
      curriculumStudentResponse: assignedResponseData,
      studentId
    }as CurriculumAction);
    yield call(curriculumSubmitPerformServerApi, {
      curriculumStudentResponse: assignedResponseData,
      problem,
      coachId,
      studentId
    });
    //  QQQ yield put({
    //   type: CURRICULUM_ACTION.SUBMIT.SUCCESS,
    //   curriculumStudentResponse: assignedResponseData,
    //   studentId
    // } as CurriculumAction);

    yield put(curriculumProgressionSetProblemAdvanceRequest(studentId,  coachId));

  } catch (error) {
    console.error(error);
    yield put({
      type: CURRICULUM_ACTION.SUBMIT.FAILURE,
      error
    }as CurriculumAction);
  }
}

function* curriculumResponseKvPerformSaga(action: CurriculumAction) {
  debug('curriculumResponseKvPerformSaga start', action.key, action.value);
  const { studentId, key,value,index} = action;


  const curriculumStudentInteraction : CurriculumStudentInteraction = yield select((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
  const curriculumStudentResponse : CurriculumStudentResponse = yield select((state: State) => state.curriculumReducer.curriculumStudentResponseMap[studentId]);
  const {
    problem,
    coachId
  } = curriculumStudentInteraction || {};


  debug('curriculumResponseKvPerformSaga INTERACTION ID  ',curriculumStudentResponse?.interactionId, curriculumStudentInteraction?.interactionId);

  invariant(curriculumStudentResponse?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch');

  const _responseData = key === 'instruction' ? curriculumStudentResponse : ResponseDataUtil.responseAddValue(curriculumStudentResponse, key, value, index);

  const curriculumStudentResponseUpdated = ResponseDataUtil.responseStatusSet(_responseData, problem);
  // curriculumStudentResponseUpdated.interactionId = interactionId;

  invariant(curriculumStudentResponseUpdated?.interactionId === curriculumStudentInteraction?.interactionId, ' interaction mismatch');

  yield put(curriculumStudentResponsePerform(studentId, coachId,curriculumStudentResponseUpdated));

}

function* curriculumResponsePerformSaga(action: CurriculumAction) {
  debug('curriculumResponsePerformSaga start', action.curriculumStudentResponse.interactionId);

  try {
    const {
      curriculumStudentResponse,
      coachId,
      studentId
    } = action;

    // debug('curriculumResponsePerformSaga curriculumStudentResponse', JSON.stringify(curriculumStudentResponse,null,2));

    invariant(curriculumStudentResponse && coachId && studentId, 'invalid-action');
    const curriculumStudentInteraction : CurriculumStudentInteraction = yield select((state: State) => state.curriculumReducer.curriculumStudentInteractionMap[studentId]);
    const curriculumStudentResponseFromStore : CurriculumStudentInteraction = yield select((state: State) => state.curriculumReducer.curriculumStudentResponseMap[studentId]);
    const {
      problem
    } = curriculumStudentInteraction;
    debug('curriculumResponsePerformSaga Before invar',curriculumStudentResponse.interactionId,'  ',curriculumStudentInteraction.interactionId);


    invariant( curriculumStudentInteraction.interactionId === curriculumStudentResponse.interactionId, ' invalid curr interaction in state ' +curriculumStudentInteraction.interactionId +' '+ curriculumStudentResponse.interactionId+' '+curriculumStudentResponseFromStore.interactionId);
    const assignedResponseData = ResponseDataUtil.responseStatusSet(curriculumStudentResponse, problem);
    invariant(!assignedResponseData.confidence, 'confidence only on submit');

    yield put({
      type: CURRICULUM_ACTION.STUDENT_RESPONSE.UPDATE,
      curriculumStudentResponse: assignedResponseData,
      studentId
    }as CurriculumAction);
    yield call(curriculumResponsePerformServerApi, {

      curriculumStudentResponse: assignedResponseData,
      coachId,
      problem,
      studentId
    });
    // QQQ
    // yield put({
    //   type: CURRICULUM_ACTION.STUDENT_RESPONSE.SUCCESS,
    //   curriculumStudentResponse: assignedResponseData,
    //   interactionId,
    //   studentId
    // }as CurriculumAction);
  } catch (error) {
    console.error(error);
    yield put({
      type: CURRICULUM_ACTION.STUDENT_RESPONSE.FAILURE,
      error
    }as CurriculumAction);
  }
}


function* curriculumProgressionSetAssignSaga(action: CurriculumAction) {
  debug('curriculumProgressionSetAssignSaga start');

  try {
    const {
      progressionSetId,
      assessmentId,
      coachId,
      studentId
    } = action;

    invariant((progressionSetId || assessmentId) && studentId && coachId, 'invalid-action');
    debug('curriculumProgressionSetAssignSaga start ' + studentId);
    const progressionSetResult = yield call(curriculumProgressionSetAssignServerApi, {
      progressionSetId,
      assessmentId,
      coachId,
      studentId
    });
    debug('curriculumProgressionSetAssignSaga progressionSetResult ' + progressionSetResult);
    const {
      curriculumStudentProgression,
      curriculumStudentInteraction,
      curriculumStudentResponse
    } = progressionSetResult.data as CurriculumProgressionSetAssignResult;
    // const {
    //   progressionSetSampleList,
    //   problemList
    // } = (curriculumStudentProgression as CurriculumStudentProgression);


    yield put(chatAssignProgression(studentId, coachId, coachId, curriculumStudentProgression));
    // console.log('progressionSet ' + studentId, progressionSetSampleList);
    // console.log('problemList ' + studentId, problemList);
    // const problem = problemList && problemList.length > 0 ? problemList[0] : undefined;
    // const position = 0;
    // const widgetSetId = problem?.widgetSetId;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    invariant(coachId, 'Coach null');


    yield put(curriculumProgressionSetAssignSuccess(studentId,coachId,curriculumStudentProgression,curriculumStudentInteraction, curriculumStudentResponse));


    debug('PPP curriculumProgressionSetAssignSaga end ' + studentId);
  } catch (error) {
    console.error(error);
    yield put({
      type: CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.FAILURE,
      error
    }as CurriculumAction);
  }
}


function* curriculumProgressionSetCompletedSaga(action: CurriculumAction) {
  const {
    studentId,
    coachId,
    curriculumStudentInteraction,
    widgetSetId
  } = action;
  invariant(studentId, 'illegal-action');
  debug('curriculumProgressionSetCompletedSaga');

  try {
    yield call(curriculumProgressionSetCompletedServerApi, {
      studentId
    });
    yield put({
      type: CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.SUCCESS,
      studentId
    }as CurriculumAction);
  } catch (error) {
    yield put({
      type: CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.FAILURE,
      studentId,
      error
    }as CurriculumAction);
  }
}

function* curriculumWidgetSetChangeRequestSaga(action: CurriculumAction) {
  const {
    studentId,
    coachId,
    curriculumStudentInteraction,
    widgetSetId
  } = action;
  invariant(studentId, 'illegal-action');
  debug('curriculumProgressionSetCompletedSaga');

  try {
    const widgetSetChangeResult = yield call(curriculumWidgetSetChangeRequestServerApi, {
      studentId,
      coachId,
      curriculumStudentInteraction,
      widgetSetId
    });

    const {
      resultAction
    } = widgetSetChangeResult.data as CurriculumWidgetSetChangeRequestResult;
    yield put(resultAction);
  } catch (error) {
    yield put({
      type: CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.FAILURE,
      studentId,
      error
    }as CurriculumAction);
  }
}

export default function* curriculumSagas(): Saga<void> {
  debug('curriculumSagas Start');
  yield all([
    takeEvery(CURRICULUM_ACTION.PROGRESSION_SET.COMPLETED.REQUEST, curriculumProgressionSetCompletedSaga),
    takeEvery(CURRICULUM_ACTION.PROGRESSION_SET.PROBLEM_ADVANCE.REQUEST, curriculumProgressionSetProblemAdvanceSaga),
    takeEvery(CURRICULUM_ACTION.STUDENT_RESPONSE.PERFORM, curriculumResponsePerformSaga),
    takeEvery(CURRICULUM_ACTION.STUDENT_RESPONSE.KV_PERFORM, curriculumResponseKvPerformSaga),
    takeEvery(CURRICULUM_ACTION.SUBMIT.PERFORM, curriculumSubmitPerformSaga),
    takeEvery(CURRICULUM_ACTION.PROGRESSION_SET.ASSIGN.REQUEST, curriculumProgressionSetAssignSaga),
    takeEvery(CURRICULUM_ACTION.INTERACTION.WIDGET_SET_CHANGE.REQUEST,curriculumWidgetSetChangeRequestSaga )
  ]);
}
