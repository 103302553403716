import * as React from "react";
import { StyleSheet, Text,TextInput, View } from "react-native";

const styles = StyleSheet.create({
  formControl: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  label: {
    fontFamily: 'Poppins-Medium',
    fontSize: 18},
  input: {
    backgroundColor: 'lightgray',
    maxWidth: 200,
    borderColor: '#000000',
    borderWidth: 1,
    fontFamily: 'Poppins-Medium',
    fontSize: 18
  },
  errors: {}
});
type CplTextFieldProps = {
  label: string;
  name: string;
  testID?: string;
  formObject: Record<string, any>;
  errors: Record<string,any>;
  required?: any;
  multiline?: boolean;
  numberOfLines?: number;
  textContentType?:'none'|'emailAddress'|'password';
  onChange: (name: string, newValue: string) => void;
};
export default function CplTextField(props: CplTextFieldProps): JSX.Element {
  const {
    label,
    multiline,
    numberOfLines,
    errors,
    formObject,
    onChange,
    testID,
    name,
    textContentType
  } = props;
  let value = formObject &&  formObject[name];

  if (!value){
    value = '';
  }


  if (multiline) {
    return <View>
      <Text style={styles.label}>{label}</Text>
      <TextInput testID={testID || name} style={styles.input} onChangeText={v => onChange(name, v)} value={value} multiline={multiline} numberOfLines={numberOfLines || 4} />
    </View>;
  }

  return <View>
    <Text style={styles.label}>{label}</Text>
    <TextInput testID={testID || name} secureTextEntry={textContentType==='password'}  textContentType={textContentType} style={styles.input} onChangeText={v => onChange(name, v)} value={value} />
  </View>;
}
