import {Text, TextInput} from 'react-native-paper';
import {View} from "react-native";
import {gridStyles, textStyles} from "../widgets/WidgetStyles";
import * as React from "react";

export type DivisionParams = {

  minDivisor: number;
  maxDivisor: number,
  minDividend: number,
  maxDividend: number,
  minQuotient: number,
  maxQuotient: number
}
type WorkbenchGeneratorProps = {
  problemGenerationParams: DivisionParams;
  onGeneratorChange: ( problemGenerationParams:DivisionParams )=>void;
}
export default function DivisionWorkbench(props:WorkbenchGeneratorProps): JSX.Element {
  const {onGeneratorChange,problemGenerationParams} = props;


  React.useEffect( () => {

    if (!problemGenerationParams || Object.keys(problemGenerationParams).length != 8 || problemGenerationParams.minDivisor === undefined) {
      console.log('Object.keys(problemGenerationParams).length', problemGenerationParams);
      const params : DivisionParams ={

        minDivisor: 1,
        maxDivisor: 10,
        minDividend: 10,
        maxDividend: 100,
        minQuotient: 1,
        maxQuotient: 10
      };
      onGeneratorChange(params);
    }
  },[]);



  return (
    <View  style={gridStyles.gridColumnContainer} >
      <Text style={textStyles.titleBar}>Division</Text>

      <View style={[gridStyles.formRowContainer]}>
        <Text style={[textStyles.labelText]}>
        Divisor/Dividend = Quotient
        </Text>
      </View>
      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minDivisor"  value={problemGenerationParams?.minDivisor?.toString(10) ||''}
          onChangeText={minDivisor => {
            try {
              if (minDivisor.length === 0){
                onGeneratorChange({...problemGenerationParams,minDivisor: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, minDivisor: parseInt(minDivisor, 10)});
              }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minDivisor: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxDivisor"  value={problemGenerationParams?.maxDivisor?.toString(10)||''}
          onChangeText={maxDivisor => {
            try {if (maxDivisor.length === 0){
              onGeneratorChange({...problemGenerationParams,maxDivisor: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, maxDivisor: parseInt(maxDivisor, 10)});
            }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxDivisor: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minDividend"  value={problemGenerationParams?.minDividend?.toString(10)||''}
          onChangeText={minDividend => {
            try {if (minDividend.length === 0){
              onGeneratorChange({...problemGenerationParams,minDividend: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, minDividend: parseInt(minDividend, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minDividend: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxDividend"  value={problemGenerationParams?.maxDividend?.toString(10)||''}
          onChangeText={maxDividend => {
            try {if (maxDividend.length === 0){
              onGeneratorChange({...problemGenerationParams,maxDividend: null });
            }
            else {
              onGeneratorChange({...problemGenerationParams, maxDividend: parseInt(maxDividend, 10)});
            }

            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxDividend: null });
            }
          }}
        />
      </View>

      <View style={gridStyles.formRowContainer}>
        <TextInput style={textStyles.textInput} label="minQuotient"  value={problemGenerationParams?.minQuotient?.toString(10)||''}
          onChangeText={minQuotient => {
            try {
              if (minQuotient.length === 0){
                onGeneratorChange({...problemGenerationParams,minQuotient: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, minQuotient: parseInt(minQuotient, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,minQuotient: null });
            }
          }}
        />
        <TextInput style={textStyles.textInput} label="maxQuotient"  value={problemGenerationParams?.maxQuotient?.toString(10)||''}
          onChangeText={maxQuotient => {
            try {
              if (maxQuotient.length === 0){
                onGeneratorChange({...problemGenerationParams,maxQuotient: null });
              }
              else {
                onGeneratorChange({...problemGenerationParams, maxQuotient: parseInt(maxQuotient, 10)});
              }
            }
            catch (e){
              console.error('Use Number');
              onGeneratorChange({...problemGenerationParams,maxQuotient: null });
            }
          }}
        />
      </View>


    </View>
  );
}
