import * as React from "react";
import {Platform, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import {DEBUG_MODULE, debugModule} from "../utils/debugModule";
import {COLOR} from "../utils/UiConstants";

import type {ViewStyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {useTestPage} from "../utils/environment";

const debug = debugModule(DEBUG_MODULE.CURRICULUM.WIDGETS);

const styles = StyleSheet.create({
  buttonRow: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    // alignSelf: 'center',
    // alignItems: 'center',
    flexWrap: "wrap"
  },
  smallButton: {
    backgroundColor: COLOR.GREEN,
    alignContent: 'center',
    width: 45,
    borderRadius: 10,
    margin: 10,
    height: 45
  },
  buttonText: {
    fontFamily: 'ArialRoundedMTBold',
    fontSize: 35,
    alignSelf: 'center'
  },
  hidden: {
    width: 0,
    height: 0
  }
});
export type NumberKeypadProps = {
  onKeyPress: (key: string) => void;
  inWorkbench: boolean;
  widgetWidth: number;
  style?:ViewStyleProp;

};
export default function NumberKeypad(props: NumberKeypadProps): JSX.Element {
  const {
    onKeyPress,
    inWorkbench,
    widgetWidth,
    style
  } = props;
  const onTyped = React.useCallback((e: {
    readonly nativeEvent: {
      readonly key: string;
    };
  }) => {
    const {
      key
    } = e.nativeEvent;
    debug('onTyped', key);

    if (key >= '0' && key <= '9') {
      onKeyPress(key);
    }
  }, []);
  const textInputRef = React.useRef(null);
  const [rowStyle, setRowStyle] = React.useState({});
  const [pressableStyle, setPressableStyle] = React.useState({});
  const [buttonTextStyle, setButtonTextStyle] = React.useState({});

  React.useEffect(() => {

    if (widgetWidth >= 650) {
      setRowStyle({
        maxWidth: 650,
        alignSelf: 'center',
        alignContent:'space-between',
        justifyContent:'space-evenly',
        // backgroundColor: COLOR.WHITE,
        width: '100%',
        flex: 1,
        flexDirection: 'row',

        maxHeight:95,
      });
      setPressableStyle({
        backgroundColor: COLOR.GREEN,
        alignContent: 'center',

        width: 75,
        borderRadius: 10,
        justifyContent:'center',
        margin: 10,
        height: 75
      });
      setButtonTextStyle({
        fontFamily: 'ArialRoundedMTBold',
        fontSize: 45,
        alignSelf: 'center'
      });
    } else if (widgetWidth > 400) {
      setRowStyle({
        maxWidth: 325,
        alignSelf: 'center',
        // backgroundColor: COLOR.RED,
        justifyContent:'space-evenly',
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        maxHeight:65,
        flexWrap: "wrap",
      });
      setPressableStyle({
        backgroundColor: COLOR.GREEN,
        alignContent: 'center',
        width: 45,
        borderRadius: 10,
        justifyContent:'center',
        margin: 10,
        height: 45
      });
      setButtonTextStyle({
        fontFamily: 'ArialRoundedMTBold',
        fontSize: 35,
        alignSelf: 'center'
      });
    } else {
      setRowStyle({
        maxWidth: 250,
        alignSelf: 'center',
        // backgroundColor: COLOR.YELLOW,
        justifyContent:'space-evenly',
        width: '100%',
        flex: 1,
        flexDirection: 'row',
        flexWrap: "wrap",
        maxHeight:50,
      });
      setPressableStyle({
        backgroundColor: COLOR.GREEN,
        alignContent: 'center',
        width: 35,
        borderRadius: 10,
        justifyContent:'center',
        margin: 7,
        height: 35
      });
      setButtonTextStyle({
        fontFamily: 'ArialRoundedMTBold',
        fontSize: 30,
        alignSelf: 'center'
      });
    }

  }, [widgetWidth]);


  return <View style={
    {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1
    }}
  >
    <View style={ style?[ rowStyle, style]: [rowStyle]}>
      <Pressable style={pressableStyle} testID="button_0" key="button_0" onPress={() => {
        onKeyPress('0');
      }}>
        <Text style={buttonTextStyle}>0</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_1" key="button_1" onPress={() => {
        onKeyPress('1');
      }}>
        <Text style={buttonTextStyle}>1</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_2" key="button_2" onPress={() => {
        onKeyPress('2');
      }}>
        <Text style={buttonTextStyle}>2</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_3" key="button_3" onPress={() => {
        onKeyPress('3');
      }}>
        <Text style={buttonTextStyle}>3</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_4" key="button_4" onPress={() => {
        onKeyPress('4');
      }}>
        <Text style={buttonTextStyle}>4</Text>
      </Pressable>
    </View>
    <View style={[ rowStyle]}>
      <Pressable style={pressableStyle} testID="button_5" key="button_5" onPress={() => {
        onKeyPress('5');
      }}>
        <Text style={buttonTextStyle}>5</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_6" key="button_6" onPress={() => {
        onKeyPress('6');
      }}>
        <Text style={buttonTextStyle}>6</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_7" key="button_7" onPress={() => {
        onKeyPress('7');
      }}>
        <Text style={buttonTextStyle}>7</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_8" key="button_8" onPress={() => {
        onKeyPress('8');
      }}>
        <Text style={buttonTextStyle}>8</Text>
      </Pressable>
      <Pressable style={pressableStyle} testID="button_9" key="button_9" onPress={() => {
        onKeyPress('9');
      }}>
        <Text style={buttonTextStyle}>9</Text>
      </Pressable>


      {!useTestPage && !inWorkbench && Platform.OS !== 'ios' &&
      <TextInput key="ti " testID="textInput" ref={textInputRef} style={styles.hidden} autoFocus onBlur={() => {
        textInputRef?.current?.focus();
      }} onKeyPress={ onTyped}/>}

    </View>
  </View>;
}
