import {Device} from "mediasoup-client";
import {Transport} from "mediasoup-client/lib/Transport.js";
import type {DeviceInfos, TransportDef, WebconActionNode} from "../../sharedDefs/webconDefs";
import {UserId} from "../../sharedDefs/userDefs";
import {Consumer} from "mediasoup-client/lib/Consumer";
import {Producer} from "mediasoup-client/lib/Producer";
import {DEBUG_MODULE, debugModule} from "../../utils/debugModule";

const debug = debugModule(DEBUG_MODULE.WEBCON.MOCK);
export class MockDevice {

}
export class MockTrack {
  public kind: string;
  constructor(_kind:string) {
    this.kind = _kind;
  }


}
export class MockMediaStream {
  getVideoTracks(): Array<MockTrack>{
    return [new MockTrack('video')];
  }
  getAudioTracks(): Array<MockTrack>{
    return [new MockTrack('audio')];
  }
}
export function getUserMedia(args): MockMediaStream {
  return new MockMediaStream();
}
export function enumerateMockDevices(): Array<MockDevice>{
  const mockDevices : Array<MockDevice> = [];

  return mockDevices;
}

export class MockProducer{
  on(key:string, topCallback:(args, callback, errorBack)=>void){
    debug('MockProducer on');
  }
}
export class MockTransport{
  on(key:string, topCallback:(args, callback, errorBack)=>void){

    debug('MockTransport on');
  }
  produce(args): MockProducer{
    debug('MockTransport produce');
    return new MockProducer();
  }
}
export class MockMediasoupDevice {
  get rtpCapabilities() {
    return {
      codecs: [{
        mimeType: 'video/vp9'
      }]
    };
  }
  load({routerRtpCapabilities}): Promise<void> {
    debug('MockMediasoupDevice load');
    return Promise.resolve();
  }
  createRecvTransport(args): MockTransport{
    debug('MockMediasoupDevice createRecvTransport');
    return new MockTransport();
  }
  createSendTransport(args): MockTransport{
    debug('MockMediasoupDevice createSendTransport');
    return new MockTransport();
  }
}

export type WebconState = {
  deviceInfos?: DeviceInfos;
  mediasoupDevice?: Device;
  anyoneSendTransport?: Transport;
  anyoneSendTransportDef?: TransportDef;
  studentReceiveTransport?: Transport;
  studentReceiveTransportDef?: TransportDef;
  coachReceiveStudentTransports?: Record<UserId, Transport>;
  coachReceiveStudentTransportDefs?: Record<UserId, TransportDef>;
  coachVideoConsumers?: Record<UserId, Consumer>;
  coachAudioConsumers?: Record<UserId, Consumer>;
  routerRtpCapabilities?: any;

  studentVideoConsumer?: Consumer;
  studentAudioConsumer?: Consumer;
  webcamProducer?: Producer;
  webcamDeviceId?: string;
  microphoneDeviceId?: string;
  speakerDeviceId?: string;
  microphoneProducer?: Producer;

  sessionState?: string;

  localVideoTrack?: any;
  localAudioTrack?: any;
};
export type WebconAction = WebconActionNode & {
  mediasoupDevice?: Device;
  anyoneSendTransport?: Transport;
  studentReceiveTransport?: Transport;
  studentReceiveTransportDef?: TransportDef;
  coachReceiveStudentTransports?: Record<UserId, Transport>;
  coachReceiveStudentTransportDefs?: Record<UserId, TransportDef>;

  coachVideoConsumer?: Consumer;
  coachAudioConsumer?: Consumer;
  studentVideoConsumer?: Consumer;
  studentAudioConsumer?: Consumer;
  webcamProducer?: Producer;
  microphoneProducer?: Producer;

  webcamDeviceId?: string;
  microphoneDeviceId?: string;
  speakerDeviceId?: string;

  localAudioTrack?: any;
  localVideoTrack?: any;
  routerRtpCapabilities?: any;
};

export type WebconSummary = {
  mediasoupDevice: boolean;
  anyoneSendTransport: string;
  anyoneSendTransportDef?:boolean;
  studentReceiveTransport: string;
  studentReceiveTransportDef?: boolean;
  studentVideoConsumer: boolean;
  studentAudioConsumer: boolean;
  coachReceiveStudentTransports: number;
  coachReceiveStudentTransportDefs: number;
  coachVideoConsumers: number;
  coachAudioConsumers: number;
  webcamProducer: boolean;
  microphoneProducer: boolean;
  microphoneDeviceId: boolean;
  webcamDeviceId?: boolean;
  routerRtpCapabilities?: boolean;
  localVideoTrack: boolean;
}
export type WebconSummaryAction = {
  type: string;
  webconSummary: WebconSummary
}
